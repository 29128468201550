import CustomDateRangePicker from '../../../../../general-components/date-range-picker/DateRangePicker'
import useKidAttendance from '../../../../../hooks/useKidAttendance'

const AttendanceFilter = () => {
  const {handleDateChange} = useKidAttendance()

  return (
    <>
      <div className='ms-auto '>
        <CustomDateRangePicker handleDateChange={handleDateChange} />
      </div>
    </>
  )
}

export {AttendanceFilter}
