import React, {useState} from 'react'

import {Link} from 'react-router-dom'
import {useIntl} from 'react-intl'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import useLocalStorage from '../../hooks/useLocalStorage'
import {storeLinks} from './storeLinks'

function StoreDownload() {
  const intl = useIntl()

  const company = window.location.pathname.split('/')[1]

  const [open, setOpen] = useLocalStorage('donwloadStore', '1')

  return (
    <div className='w-100 d-flex   '>
      <Accordion expanded={open === '1'} style={{width: '100%'}} className='bg-body'>
        <AccordionSummary
          aria-controls='panel1-content'
          id='panel1-header'
          onClick={() => setOpen((prev: string) => (prev === `1` ? '-1' : `1`))}
        >
          <div className='w-100 d-flex align-items-center justify-content-between'>
            {open !== '1' && (
              <h5 className='mw-200px mw-sm-300px fw-bold  '>
                {intl.formatMessage({
                  id: 'KIDLING.DOWNLOAD_FROM_STORE_NOTE',
                })}
              </h5>
            )}
            <button className='btn ms-auto' type='button'>
              <i className='fa-solid fa-angle-down m-0 p-0'></i>
            </button>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className='d-flex align-items-center justify-content-center w-100 w-sm-80 mx-auto '>
            <img src='/header.png' alt='header' className='w-150px object-fit-contain ' />
            <div className='d-flex justify-content-start flex-column'>
              <h5 className='mw-200px mw-sm-300px fw-bold  '>
                {intl.formatMessage({
                  id: 'KIDLING.DOWNLOAD_FROM_STORE_NOTE',
                })}
              </h5>
              <div className='d-flex flex-column flex-sm-row align-items-start ms-0 ms-sm-auto  '>
                <Link
                  to={
                    storeLinks[company.toLocaleLowerCase()]
                      ? storeLinks[company.toLocaleLowerCase()].ios
                      : storeLinks['kidling'].ios
                  }
                  target='_blank'
                  className='h-50px w-150px  me-2 '
                >
                  <img
                    src='/ios.png'
                    alt='appstore'
                    className='h-50px w-150px object-fit-contain '
                  />
                </Link>
                <Link
                  to={
                    storeLinks[company.toLocaleLowerCase()]
                      ? storeLinks[company.toLocaleLowerCase()].android
                      : storeLinks['kidling'].android
                  }
                  target='_blank'
                  className='h-50px w-150px  '
                >
                  <img
                    src='/play.png'
                    alt='appstore'
                    className='h-50px w-150px object-fit-contain '
                  />
                </Link>
              </div>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  )
}

export default StoreDownload
