/* eslint-disable jsx-a11y/anchor-is-valid */

import {FC} from 'react'
import {Notifications} from '../views/notifications/Notifications'
import {KTCard} from '../../_metronic/helpers'

const Feeds: FC = () => {
  return (
    <KTCard>
      <Notifications />
    </KTCard>
  )
}

export {Feeds}
