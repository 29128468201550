import moment from 'moment'
import {getKidsMealTimesByDate} from '../../controller/meal'
import {useEffect, useState} from 'react'
import {useAppContext} from '../../_metronic/layout/core/AppContextProvider'
import {useAuth} from '../views/auth'

const useEatingHabits = () => {
  const {
    kidsMealTimes,
    setKidsMealTimes,
    setGlobalLoader,
    internalPagesFilters,
    globalLoader,
    internalPagesDatesFilters,
    setInternalPagesDatesFilters,
  } = useAppContext()
  const [data, setData] = useState([])
  const {auth, currentUser} = useAuth()
  const [initalCall, setinitalCall] = useState(true)
  const [date, setDate] = useState<any>({})

  useEffect(() => {
    const newFilters: any = {...internalPagesDatesFilters}

    newFilters.mealtimes = {
      ...newFilters.mealtimes,
      From: date?.startDate
        ? moment(date?.startDate).startOf('day').format('yyyy-MM-DDTHH:mm:ss')
        : moment().startOf('day').format('yyyy-MM-DDTHH:mm:ss'),
      To: date?.endDate
        ? moment(date?.endDate).endOf('day').format('yyyy-MM-DDTHH:mm:ss')
        : moment().endOf('day').format('yyyy-MM-DDTHH:mm:ss'),
    }

    setInternalPagesDatesFilters(newFilters)
  }, [date])
  useEffect(() => {
    let temp: any = []
    kidsMealTimes?.map((item: any) => {
      let mealTimes: any = []
      item.kidMealTimes?.map((meal: any) =>
        mealTimes.push({
          id: meal.kidMealTimeId,
          title: meal.foodItemName,
          tag: meal.mealPortionName,
          date: meal.dateTime,
          desc: meal.notes,
          subtitle: meal.mealTimeName,
        })
      )
      temp.push({
        image: item.imagePath,
        name: `${item.firstName} ${item.lastName}`,
        subItems: mealTimes,
      })
    })
    setData(temp)
  }, [kidsMealTimes])

  const readMealTimes = async () => {
    let filter: string = ''
    setGlobalLoader(true)

    filter = '1=1'

    const res = await getKidsMealTimesByDate(
      filter,
      currentUser?.contact?.contactId || 0,
      internalPagesDatesFilters.mealtimes.From
        ? moment(internalPagesDatesFilters.mealtimes.From).format('yyyy-MM-DDTHH:mm:ss')
        : moment().startOf('day').format('yyyy-MM-DDTHH:mm:ss'),
      internalPagesDatesFilters.mealtimes.To
        ? moment(internalPagesDatesFilters.mealtimes.To).format('yyyy-MM-DDTHH:mm:ss')
        : moment().endOf('day').format('yyyy-MM-DDTHH:mm:ss'),
      initalCall ? 20 : -1
    )

    setKidsMealTimes(res)
    setGlobalLoader(false)
    setinitalCall(false)
  }
  const handleDateChange = (selected: any) => {
    setDate(selected[0])
  }
  return {readMealTimes, data, handleDateChange}
}

export default useEatingHabits
