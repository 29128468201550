import axios from 'axios'
import * as endpoints from '../config/endpoints'
import moment from 'moment'

export const getAttendanceByFilter = async (kidId: number, From: string, To: string) => {
  const params = {kidId, From, To}
  return axios
    .get(`${endpoints.GET_ATTENDANCE_BY_KID} `, {params})
    .then((response) => {
      return response.data?.data?.sort((a: any, b: any) =>
        moment(b?.dateTimeIn).diff(a?.dateTimeIn)
      )
    })
    .catch((error) => {
      console.log(error)
    })
}

export const deleteAttendance = async (id: string) => {
  return axios
    .delete(`${endpoints.ATTENDANCE_BY_KID}/${id}`)
    .then((response) => response)
    .catch((error) => {
      console.log(error)
    })
}

export const updateAttendance = async (data: any) => {
  return axios
    .put(`${endpoints.ATTENDANCE_BY_KID}`, data)
    .then((response) => response)
    .catch((error) => {
      console.log(error)
    })
}
