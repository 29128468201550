import {useState, createContext, useContext} from 'react'
const AppContext: any = createContext<any>(null)

export const useAppContext = (): any => {
  return useContext(AppContext)
}

export default function AppContextProvider({children}: any) {
  const [kidsList, setKidsList] = useState<any>([])
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [filters, setFilters] = useState('')
  const [languages, setLanguages] = useState([])
  const [translations, setTranslations] = useState([])
  const [mealtime, setMealtime] = useState([])
  const [attendanceLocations, setAttendanceLocations] = useState([])
  const [absentReasons, setAbsentReasons] = useState([])
  const [activities, setActivities] = useState([])
  const [moods, setMoods] = useState([])
  const [requestItems, setRequestItems] = useState([])
  const [allEvents, setAllEvents] = useState([])
  const [mealPortions, setMealPortions] = useState([])
  const [allRooms, setAllRooms] = useState([])
  const [foodItems, setFoodItems] = useState([])
  const [sleepHabits, setSleepHabits] = useState([])
  const [currentUserImage, setCurrentUserImage] = useState(null)
  const [employeeInfo, setEmployeeInfo] = useState(null)
  const [globalLoader, setGlobalLoader] = useState(false)
  const [day, setDay] = useState(1)
  const [allAttendance, setAllAttendance] = useState([])
  const [allAbsense, setAllAbsense] = useState([])
  const [kidsActivites, setKidsActivites] = useState([])
  const [kidsMealTimes, setKidsMealTimes] = useState([])
  const [kidsSleeptimes, setKidsSleeptimes] = useState([])
  const [kidsDrinks, setKidsDrinks] = useState([])
  const [kidsProtocol, setKidsProtocol] = useState([])
  const [kidsRequests, setKidsRequests] = useState([])
  const [kidsServices, setKidsServices] = useState([])
  const [kidsMedicalRecords, setKidsMedicalRecords] = useState([])
  const [internalPagesFilters, setInternalPagesFilters] = useState({
    activity: {},
    attendance: {},
    absense: {},
    mealtimes: {},
    sleeptimes: {},
    drinks: {},
    moods: {},
    protocols: {},
    requests: {},
    services: {},
    medical: {},
    mood: {},
    diapers: {},
    assessment: {},
  })
  const [mainSearchTerm, setMainSearchTerm] = useState('')
  const [companies, setCompanies] = useState([])
  const [employees, setEmployees] = useState([])
  const [classes, setClasses] = useState([])
  const [medicalTypes, setMedicalTypes] = useState([])
  const [delivered, setDelivered] = useState(0)
  const [tabMessages, setTabMessages] = useState(null)
  const [allSubMessages, setAllSubMessages] = useState([])
  const [messagesFilters, setMessagesFilters] = useState({
    inbox: {},
    sent: {},
    marked: {},
    deleted: {},
  })
  const [globalLoader2, setGlobalLoader2] = useState(false)
  const [kidMediaFiles, setKidMediaFiles] = useState([])
  const [companyMetaData, setCompanyMetaData] = useState({})
  const [messagesCounts, setMessagesCounts] = useState(0)
  const [childFilter, setChildFilter] = useState(null)
  const [dashboardNotifications, setDashboardNotifications] = useState([])
  const [eventsCategories, setEventsCategories] = useState([])

  const [internalPagesDatesFilters, setInternalPagesDatesFilters] = useState<any>({
    mealtimes: {},
    requests: {},
    assessment: {},
  })
  const [genders, setGenders] = useState([])
  const [salutations, setSalutations] = useState([])
  const [countries, setCountries] = useState([])
  const [cities, setCities] = useState([])
  const [states, setStates] = useState([])
  const [mobileConfiguration, setMobileConfiguration] = useState(null)
  const [accounts, setAccounts] = useState([])
  const [calenderDateFilter, setCalenderDateFilter] = useState({})
  const [calendarFilter, setCalendarFilter] = useState(null)
  const [pinboard, setPinboard] = useState([])
  const [tabMessagesCounts, setTabMessagesCounts] = useState(null)
  const [kidMoods, setKidMoods] = useState([])
  const [kidsDiapers, setKidsDiapers] = useState([])
  const [selectedImages, setSelectedImages] = useState([])
  const [allHashtags, setAllHashtags] = useState([])
  const [allKidsAssessments, setAllKidsAssessments] = useState([])
  const [isFinishedLoadingMetaData, setIsFinishedLoadingMetaData] = useState(false)
  const [todayNotifications, setTodayNotifications] = useState(null)
  const [dashboardKidsFilter, setDashboardKidsFilter] = useState(null)
  const [inboxData, setInboxData] = useState<any>([])
  const [allAbsences, setAllAbsences] = useState([])
  const [dateFilter, setDateFilter] = useState<any>()
  return (
    <AppContext.Provider
      value={{
        filters,
        kidsList,
        attendanceLocations,
        mealtime,
        translations,
        languages,
        searchTerm,
        activities,
        absentReasons,
        moods,
        requestItems,
        allEvents,
        mealPortions,
        allRooms,
        foodItems,
        sleepHabits,
        currentUserImage,
        employeeInfo,
        allAttendance,
        globalLoader,
        day,
        allAbsense,
        kidsActivites,
        kidsMealTimes,
        kidsSleeptimes,
        kidsDrinks,
        kidsProtocol,
        kidsRequests,
        kidsServices,
        internalPagesFilters,
        companies,
        employees,
        classes,
        medicalTypes,
        kidsMedicalRecords,
        delivered,
        tabMessages,
        allSubMessages,
        messagesFilters,
        globalLoader2,
        mainSearchTerm,
        kidMediaFiles,
        companyMetaData,
        messagesCounts,
        childFilter,
        dashboardNotifications,
        eventsCategories,
        internalPagesDatesFilters,
        salutations,
        genders,
        states,
        cities,
        countries,
        mobileConfiguration,
        accounts,
        calenderDateFilter,
        calendarFilter,
        pinboard,
        tabMessagesCounts,
        kidMoods,
        kidsDiapers,
        selectedImages,
        allHashtags,
        allKidsAssessments,
        isFinishedLoadingMetaData,
        todayNotifications,
        dashboardKidsFilter,
        inboxData,
        allAbsences,
        dateFilter,
        setDateFilter,
        setKidsList,
        setSearchTerm,
        setLanguages,
        setTranslations,
        setAttendanceLocations,
        setFilters,
        setMealtime,
        setAbsentReasons,
        setActivities,
        setMoods,
        setRequestItems,
        setAllEvents,
        setMealPortions,
        setAllRooms,
        setFoodItems,
        setSleepHabits,
        setCurrentUserImage,
        setEmployeeInfo,
        setAllAttendance,
        setGlobalLoader,
        setDay,
        setAllAbsense,
        setKidsActivites,
        setKidsMealTimes,
        setKidsSleeptimes,
        setKidsDrinks,
        setKidsProtocol,
        setKidsRequests,
        setKidsServices,
        setInternalPagesFilters,
        setCompanies,
        setEmployees,
        setClasses,
        setMedicalTypes,
        setKidsMedicalRecords,
        setDelivered,
        setTabMessages,
        setAllSubMessages,
        setMessagesFilters,
        setGlobalLoader2,
        setMainSearchTerm,
        setKidMediaFiles,
        setCompanyMetaData,
        setMessagesCounts,
        setChildFilter,
        setDashboardNotifications,
        setEventsCategories,
        setInternalPagesDatesFilters,
        setSalutations,
        setGenders,
        setStates,
        setCities,
        setCountries,
        setMobileConfiguration,
        setAccounts,
        setCalenderDateFilter,
        setCalendarFilter,
        setPinboard,
        setTabMessagesCounts,
        setKidMoods,
        setKidsDiapers,
        setSelectedImages,
        setAllHashtags,
        setAllKidsAssessments,
        setIsFinishedLoadingMetaData,
        setTodayNotifications,
        setDashboardKidsFilter,
        setInboxData,
        setAllAbsences,
      }}
    >
      {children}
    </AppContext.Provider>
  )
}
