import {
  USER_CONTACTS,
  UPDATEPARENTIMG,
  CHANGE_PASSWORD,
  MOBILE_CONFIGURATION,
  CHANGE_PASSWORD_BY_OTP,
  SEND_OTP_BY_EMAIL,
} from '../config/endpoints'
import axios from 'axios'

export const updateUserImage = async (data: any) => {
  return axios
    .put(UPDATEPARENTIMG, data)
    .then((response) => response.data)
    .catch((error) => {
      console.log(error)
    })
}

export const getContactInfoByUserId = async (id: any, token: string, url: string) => {
  const axiosInstance = axios.create()
  axiosInstance.interceptors.request.use(
    (config: any) => {
      config.headers['token'] = `${token}`

      return config
    },
    (err: any) => Promise.reject(err)
  )
  return axiosInstance
    .get(`${url ?? localStorage.getItem('apiUrl')}contact/listwhere/usersId=${id}`, {
      headers: {token},
    })
    .then((response) => response.data[0])
    .catch((error) => {
      console.log(error)
    })
}

export const updateContactInfo = async (data: any) => {
  return axios
    .put(USER_CONTACTS, data)
    .then((response) => response.data)
    .catch((error) => {
      console.log(error)
    })
}

export const changePassword = async (data: any) => {
  return axios
    .post(`${CHANGE_PASSWORD}`, data)
    .then((response) => response)
    .catch((error) => {
      console.log('in the catch errorr', error)
      return false
    })
}

export const getMobileConfiguration = async () => {
  return axios
    .get(MOBILE_CONFIGURATION)
    .then((response) => response.data)
    .catch((error) => {
      console.log(error)
    })
}

export const sendOtpByEmail = async (data: any) => {
  const axiosInstance = axios.create()
  axiosInstance.interceptors.request.use(
    (config: any) => {
      //  config.headers['AuthorizationKey'] = `${process.env.REACT_APP_LANGUAGE_API_KEY}`

      config.headers['email'] = `${data.email}`
      return config
    },
    (err: any) => Promise.reject(err)
  )
  return axiosInstance
    .post(`${SEND_OTP_BY_EMAIL}`)
    .then((response: any) => response)
    .catch((error) => {
      console.log(error)
    })
}

export const changePassowrdByOtp = async (data: any) => {
  const axiosInstance = axios.create()
  axiosInstance.interceptors.request.use(
    (config: any) => {
      //  config.headers['AuthorizationKey'] = `${process.env.REACT_APP_LANGUAGE_API_KEY}`
      config.headers['password'] = `${data.password}`
      config.headers['username'] = `${data.email}`
      config.headers['otp'] = `${data.otp}`
      return config
    },
    (err: any) => Promise.reject(err)
  )
  return axiosInstance
    .post(`${CHANGE_PASSWORD_BY_OTP}`)
    .then((response: any) => response)
    .catch((error) => {
      console.log(error)
    })
}
