import TopBarProgress from 'react-topbar-progress-indicator'
import {getCSSVariableValue} from '../../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../../_metronic/helpers'
import {FC, Suspense} from 'react'

const SuspensedView: FC<WithChildren> = ({children}) => {
  TopBarProgress.config({
    barThickness: 3,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}
export default SuspensedView
