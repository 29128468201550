/* eslint-disable react-hooks/exhaustive-deps */
import {FC, ReactElement, useEffect, useState} from 'react'
import {CKEditor} from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import makeAnimated from 'react-select/animated'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Popover from 'react-bootstrap/Popover'
import data from '@emoji-mart/data'
import Picker from '@emoji-mart/react'
import {useIntl} from 'react-intl'
import {useAppContext} from '../../../../_metronic/layout/core/AppContextProvider'
import {getAllEmployeesByKita} from '../../../../controller/employee'
import {MessageAttachModal} from '../components/message-attachement/MessageAttachModal'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {useAuth} from '../../auth'
import {
  getAllMessagesCounts,
  getMessage,
  getSubMessages,
  sendNewMessage,
} from '../../../../controller/messages'
import {toast} from 'react-toastify'
import {useParams} from 'react-router-dom'
import {getBase64} from '../../../utils/getBase64'
import AvatarWithLetters from '../../../general-components/avatar-with-letters'
import moment from 'moment'
import SelectWithAllOption from '../../../general-components/select-with-all/SelectWithAllOption'
import {selectStyle, selectStyleDarkMode} from '../../../utils/select-style'
import {useThemeMode} from '../../../../_metronic/partials/layout/theme-mode/ThemeModeProvider'

const animatedComponents = makeAnimated()

interface Props {
  parentId?: string
  senderId?: string
  companyId?: number
}
const ComposeMessage: FC<Props> = ({parentId, senderId, companyId}): ReactElement => {
  const [editor, setEditor] = useState<any>(null)
  const {mode} = useThemeMode()
  const params = useParams()
  const intl = useIntl()
  const {
    employees,
    setEmployees,

    setTabMessagesCounts,
    setAllSubMessages,
    setGlobalLoader,
    allSubMessages,
  } = useAppContext()
  const [loading, setloading] = useState(false)
  const {currentUser} = useAuth()
  const {companies} = useAppContext()
  const [selectedCompany, setSelectedCompany] = useState<any>([companies[0]])
  const [selectedEmployee, setSelectedEmployee] = useState<any>([])
  const [showAttachModal, setShowAttachModal] = useState(false)
  const [selectedFiles, setSelectedFiles] = useState([])
  const [base64Files, setBase64Files] = useState([])
  const [selectError, setSelectError] = useState(true)
  const [showSelectError, setShowSelectError] = useState(false)
  const [highPriority, setHighPriority] = useState(false)
  const [isSending, setIsSending] = useState(false)

  const [audio] = useState(new Audio('/audio/message.mp3'))
  const [initalValues, setInitalValues] = useState({
    subject: '',
    message: '',
  })

  const schema = Yup.object().shape({
    subject: Yup.string().required(intl.formatMessage({id: 'KIDLING.REQUIRED'})),
    message: Yup.string().required(intl.formatMessage({id: 'KIDLING.REQUIRED'})),
  })

  const formik = useFormik({
    initialValues: initalValues,
    validationSchema: schema,
    enableReinitialize: true,
    onSubmit: async (values, {setSubmitting}) => {
      try {
        setSubmitting(true)
        setIsSending(true)

        if (!selectError) {
          setShowSelectError(false)
          let employeeIdList: any = []

          selectedEmployee?.map((emp: any) => employeeIdList.push(emp.value))
          if (employeeIdList.length > 0) {
            const data = {
              notification: {
                notificationTitle: values.subject,
                notificationTypeId: 1,
                message: values.message,
                companyId: allSubMessages?.length > 0 ? allSubMessages[0]?.companyId : 0,
                dateTime: moment().format('yyyy-MM-DDTHH:mm:ss'),
                notificationStatusId: 2,
                usersId: currentUser?.usersId,
                parentId: parentId || 0,
                highPriority,
              },
              acknowledgeRequired: true,
              employeeIdList: employeeIdList,
              attachmentList: base64Files,
            }

            const resp: any = await sendNewMessage(data)

            if (!!resp) {
              await readMessages()
              audio.play()
              if ('vibrate' in navigator) {
                // vibration API supported
                navigator.vibrate(1000)
              }
              if (params?.id) {
                readSubMessages()
              }

              toast.success(intl.formatMessage({id: 'COMMON.SUCCESS'}))

              setSelectedEmployee(null)
              formik.resetForm()
              setBase64Files([])
              setSelectedFiles([])
            } else {
              toast.error(intl.formatMessage({id: 'COMMON.FAIL'}))
            }
          }
        } else {
          setShowSelectError(true)
        }
      } catch (ex) {
        console.log(ex)
      } finally {
        setSubmitting(false)
        setIsSending(false)
      }
    },
  })

  useEffect(() => {
    if (companies?.length > 0) setSelectedCompany([companies[0]])
  }, [companies])

  useEffect(() => {
    if (parentId && allSubMessages.length > 0)
      setInitalValues((prev) => ({
        ...prev,
        subject: `AW-` + allSubMessages[0].notificationTitle,
      }))
  }, [parentId, allSubMessages])

  useEffect(() => {
    if (selectedEmployee?.length === 0) setSelectError(true)
    else setSelectError(false)
  }, [selectedEmployee])

  useEffect(() => {
    if (
      ((selectedCompany?.length > 0 && selectedCompany[0]?.value) || !!companyId) &&
      companies?.length > 0 &&
      !loading
    ) {
      readEmployees()
    }
  }, [companies, selectedCompany, companyId])

  useEffect(() => {
    if (senderId)
      for (const emp of employees) {
        if (emp?.usersId === senderId) {
          setSelectedEmployee([emp])
          //setSelectedCompany([companies.find((i: any) => i.value === emp.companyId)])
        }
      }
  }, [senderId, employees])

  useEffect(() => {
    if (companies.length > 0)
      for (const emp of employees) {
        if (emp?.usersId === senderId) {
          setSelectedCompany((prev: any) => [
            ...prev,
            companies.find((i: any) => i.value === emp.companyId),
          ])
        }
      }
  }, [companies])

  useEffect(() => {
    const convertToBase64 = async () => {
      let temp: any = []
      selectedFiles?.map(async (singleFile: any) => {
        const nameWithExt = singleFile.name.split('.')
        const ext = nameWithExt[nameWithExt.length - 1]

        getBase64(singleFile, (result: string) => {
          temp.push({fileName: singleFile.name.split('.')[0], imagePath: result, extention: ext})
        })
      })
      setBase64Files(temp)
    }
    convertToBase64()
  }, [selectedFiles])

  const readEmployees = async () => {
    try {
      // let filter = 'employeeReceiveCommunicationFromParents=true AND '

      if (companyId || (selectedCompany.length > 0 && selectedCompany[0]?.value)) {
        setloading(true)
        // if (companyId) filter += `CompanyIds.Any(x => x == ${companyId})`
        // else
        //   selectedCompany?.map((item: any, index: number) => {
        //     if (index > 0) filter += ' | '
        //     filter += `CompanyIds.Any(x => x == ${item.value})`
        //   })

        const resp = await getAllEmployeesByKita(companyId ? companyId : selectedCompany[0]?.value)

        let temp: any = []
        resp?.map((item: any) =>
          temp.push({
            value: item.employeeId,
            label: item.employeeName,
            email: item.email,
            imagePath: item.imagePath,
            usersId: item.usersId,
            companyId: item.companyId,
          })
        )

        setEmployees(temp)
        setloading(false)
      }
    } catch (e) {
      setloading(false)
    }
  }

  const removeFile = (file: any) => {
    const temp = selectedFiles.filter((item: any) => item !== file)
    setSelectedFiles(temp)
  }

  const readMessages = async (filter?: string) => {
    const resp1 = await getAllMessagesCounts(currentUser?.usersId ?? 0)
    setTabMessagesCounts(resp1)
  }

  const readSubMessages = async () => {
    try {
      setGlobalLoader(true)
      const message = await getMessage(params.id ?? '-1')

      if (message?.length > 0) {
        const subMessages = await getSubMessages({
          userId: currentUser?.usersId,
          parentId: params.id,
        })
        setAllSubMessages([...message, ...subMessages])
      }
    } catch (e) {
      console.log(e)
    } finally {
      setGlobalLoader(false)
    }
  }

  return (
    <div className='card'>
      {!parentId && (
        <div className='card-header'>
          <div className='card-title'>
            {intl.formatMessage({id: 'KIDLING.HOME.COMPOSE_MESSAGE'})}
          </div>
        </div>
      )}

      <div className='card-body p-1 m-0 m-sm-2'>
        <form
          id='kt_inbox_reply_form'
          className={`rounded ${parentId && 'border-top mt-md-5'}`}
          onSubmit={formik.handleSubmit}
        >
          {showSelectError && (
            <div className='alert alert-danger'>
              {intl.formatMessage({
                id: 'KIDLING.MESSAGE.YOU_NEED_TO_SELECT_AT_LEAST_SINGLE_GROUP_EMPLOYEE_OR_KID',
              })}
            </div>
          )}
          <label className='form-check form-switch form-check-custom form-check-solid   p-6'>
            <input
              className='form-check-input w-30px h-20px'
              type='checkbox'
              name='notifications'
              onChange={() => setHighPriority(!highPriority)}
            />
            <span className='form-check-label  fs-7 text-dark'>
              {intl.formatMessage({id: 'KIDLING.PARENT.HIGH.PRIORITY'})}
            </span>
          </label>
          <div className='d-block p-2'>
            {!parentId && companies.length > 1 && (
              <div className='d-flex align-items-center   px-0 min-h-50px'>
                <SelectWithAllOption
                  openMenuOnFocus={true}
                  value={selectedCompany}
                  onChange={(val: any) => setSelectedCompany([val])}
                  options={companies}
                  isClearable={true}
                  components={animatedComponents}
                  isLoading={loading}
                  formatOptionLabel={(item: any) => (
                    <div className='d-flex align-items-center '>
                      <div className='d-flex flex-column'>
                        <p className='p-0 m-0'>{item.label}</p>
                      </div>
                    </div>
                  )}
                  // isMulti
                  // allowSelectAll
                  className='w-100'
                  styles={mode === 'dark' ? selectStyleDarkMode : selectStyle}
                  theme={(theme: any) => ({
                    ...theme,

                    colors: {
                      ...theme.colors,
                      primary25: 'var(--bs-dark-light)',
                      primary: 'var(--bs-gray)',
                      neutral0: 'var(--bs-dark-light)',
                    },
                  })}
                  placeholder={intl.formatMessage({id: 'KIDLING.COMPANY'})}
                />
              </div>
            )}
            <div className='d-flex align-items-center   px-0 min-h-50px'>
              <SelectWithAllOption
                openMenuOnFocus={true}
                value={selectedEmployee}
                onChange={(val: any) => setSelectedEmployee(val)}
                options={
                  (selectedCompany && selectedCompany?.length > 0) || companyId ? employees : []
                }
                isClearable={true}
                components={animatedComponents}
                isMulti
                allowSelectAll
                isLoading={loading}
                formatOptionLabel={(item: any) => (
                  <div className='d-flex align-items-center '>
                    {item.value !== '*' && (
                      <AvatarWithLetters
                        image={item.imagePath}
                        alt={`${item.label[0].charAt(0).toUpperCase()}  `}
                        rounded
                        extrasmall
                      />
                    )}

                    <div className='d-flex flex-column ms-2  '>
                      <p className='p-0 m-0'>{item.label}</p>
                      {/* <p className='p-0 m-0 text-muted'>{item.email}</p> */}
                    </div>
                  </div>
                )}
                className='w-100'
                styles={mode === 'dark' ? selectStyleDarkMode : selectStyle}
                theme={(theme: any) => ({
                  ...theme,

                  colors: {
                    ...theme.colors,
                    primary25: 'var(--bs-dark-light)',
                    primary: 'var(--bs-gray)',
                    neutral0: 'var(--bs-dark-light)',
                  },
                })}
                placeholder={intl.formatMessage({id: 'KIDLING.EMPLOYEE'})}
              />
            </div>

            <div className='border-bottom mt-4 '>
              <input
                className='form-control border-3 border-primary px-8 min-h-50px required mb-2 fs-4'
                placeholder={intl.formatMessage({id: 'KIDLING.HOME.SUBJECT'})}
                name='subject'
                onChange={formik.handleChange}
                value={formik.values.subject}
                disabled={parentId ? true : false}
              />
              {formik.touched.subject && formik.errors.subject && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span className='alert-danger'>{formik.errors.subject}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='z-0'>
              <CKEditor
                editor={ClassicEditor}
                data={formik.values.message}
                onReady={(editor: any) => {
                  setEditor(editor)
                  editor.editing.view.change((writer: any) => {
                    writer.setAttribute(
                      'spellcheck',
                      'false',
                      editor.editing.view.document.getRoot()
                    )
                  })
                }}
                onChange={(event: any, editor: any) => {
                  const data = editor.getData()
                  formik.setFieldValue('message', data)
                  editor?.focus()
                }}
                config={{
                  language: 'de',
                  toolbar: [
                    'Heading',
                    'bold',
                    'italic',
                    'link',
                    '|',
                    'bulletedList',
                    'numberedList',
                    'todoList',
                    'alignment',
                    '|',
                    'blockQuote',
                    'insertTable',
                    'undo',
                    'redo',
                  ],
                }}
              />
              {formik.touched.message && formik.errors.message && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span className='alert-danger'>{formik.errors.message}</span>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className='d-flex flex-column py-4'>
            {selectedFiles?.map((selectedFile: any) => (
              <div className='d-flex align-items-center  '>
                <i className='bi bi-paperclip mx-1'></i>
                <p className='m-0'>{selectedFile.name}</p>
                <button className='btn' type='button' onClick={() => removeFile(selectedFile)}>
                  <i className='bi bi-trash-fill text-danger'></i>
                </button>
              </div>
            ))}
          </div>
          <div className='d-flex flex-stack flex-wrap gap-2 py-5 ps-8 pe-5 border-top'>
            <div className='d-flex align-items-center me-3'>
              <div className='btn-group me-4'>
                <button
                  type='submit'
                  className='btn btn-primary d-flex aling-items-center'
                  data-kt-users-modal-action='submit'
                  disabled={formik.isSubmitting}
                >
                  {!isSending && (
                    <span className='indicator-label mx-4'>
                      {intl.formatMessage({id: 'KIDLING.HOME.SEND'})}
                    </span>
                  )}
                  {isSending && (
                    <span className=''>
                      {intl.formatMessage({id: 'KIDLING.HOME.PLEASE_WAIT'})}...{' '}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>

              <OverlayTrigger
                placement='top'
                trigger='click'
                rootClose
                overlay={
                  <Popover>
                    <Picker
                      data={data}
                      onEmojiSelect={(data: any) => {
                        editor.model.change((writer: any) => {
                          const selection = editor.model.document.selection
                          const currentAttributes = selection.getAttributes()
                          const insertPosition = selection.getFirstPosition()
                          writer.insertText(data.native, currentAttributes, insertPosition)
                        })
                      }}
                    />
                  </Popover>
                }
              >
                <button className='btn px-2' type='button'>
                  <i className='fa-regular fa-face-smile fs-2 text-primary'></i>
                </button>
              </OverlayTrigger>
              <button
                className='btn px-2'
                type='button'
                onClick={() => setShowAttachModal(!showAttachModal)}
              >
                <i className='fa-solid fa-paperclip fs-3 m-0'></i>
              </button>
            </div>
          </div>
        </form>
      </div>
      {showAttachModal && (
        <MessageAttachModal
          setShowAttachModal={setShowAttachModal}
          setSelectedFiles={setSelectedFiles}
        />
      )}
    </div>
  )
}

export default ComposeMessage
