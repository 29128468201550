/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import {FC, useEffect} from 'react'
import {DashboardHeader} from '../views/dashboard/DashboardHeader'
import {Notifications} from '../views/notifications/Notifications'
import {useAuth} from '../views/auth'
import {getPinboard} from '../../controller/pinboard'
import moment from 'moment'
import {useAppContext} from '../../_metronic/layout/core/AppContextProvider'

const Dashboard: FC = () => {
  const {currentUser} = useAuth()
  const contact = currentUser?.contact
  const {setPinboard, accounts} = useAppContext()

  useAppContext()
  useEffect(() => {
    const read = async () => {
      const resp = await getPinboard(moment().startOf('day').format('yyyy-MM-DDTHH:mm:ss'))
      setPinboard(resp)
    }
    read()
  }, [])

  return (
    <div className=' rounded'>
      <DashboardHeader contact={contact} />
      {accounts?.length > 0 && <Notifications />}
    </div>
  )
}

export default Dashboard
