/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {OverlayTrigger} from 'react-bootstrap'
import data from '@emoji-mart/data'
import Picker from '@emoji-mart/react'
import {CKEditor} from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import {useIntl} from 'react-intl'
import Popover from 'react-bootstrap/Popover'
import {toast} from 'react-toastify'
import {useAuth} from '../auth'
import {sendNewMessage} from '../../../controller/messages'
import {NotificationCard} from './NotificationCard'
import moment from 'moment'
import {getEmployeeInfoByUserId} from '../../../controller/employee'
import {Link} from 'react-router-dom'

type Props = {
  notification: any
  readMessages: any
  updateReadMessages?: any
}

const NotificationMessageCard: React.FC<Props> = ({
  notification,
  readMessages,
  updateReadMessages,
}) => {
  const company = window.location.pathname.split('/')[1]
  const [audio] = useState(new Audio('/audio/message.mp3'))
  const intl = useIntl()
  const {auth, currentUser} = useAuth()
  const [editor, setEditor] = useState<any>(null)
  const [showReply, setShowReply] = useState(false)
  const [message, setMessage] = useState('')
  const [parentId, setParentId] = useState(-1)
  const [loader, setLoader] = useState(false)
  const [employeeId, setEmployeeId] = useState(null)

  useEffect(() => {
    const read = async () => {
      const resp = await getEmployeeInfoByUserId(
        notification?.recipients?.length > 0 ? notification?.recipients[0]?.usersId : 0
      )

      setEmployeeId(resp.employeeId)
    }
    read()
  }, [notification])

  const sendReply = async () => {
    setLoader(true)
    if (employeeId) {
      const data = {
        notification: {
          notificationTitle: `RE-${notification.notificationTitle}`,
          notificationTypeId: 1,
          message: message,
          companyId: auth?.companyId,
          dateTime: moment().format('yyyy-MM-DDTHH:mm:ss'),
          notificationStatusId: 2,
          usersId: currentUser?.usersId,
          parentId: parentId,
        },
        acknowledgeRequired: true,
        employeeIdList: [employeeId],
      }

      const resp: any = await sendNewMessage(data)
      readMessages()
      await updateReadMessages(parentId)
      setLoader(false)
      setShowReply(false)
      if (resp.status === 200) {
        audio.play()
        if ('vibrate' in navigator) {
          // vibration API supported
          navigator.vibrate(1000)
        }

        toast.success(intl.formatMessage({id: 'COMMON.SUCCESS'}))
      } else {
        toast.error(intl.formatMessage({id: 'COMMON.FAIL'}))
      }
    }
  }

  return (
    <div className={`card shadow-sm w-100 my-4 `}>
      {/* begin::Body */}
      <div className='card-body pb-4'>
        {/* begin::Header */}
        <Link
          to={
            company
              ? `/${company}/messages/inbox/details/${
                  notification?.parentId ? notification?.parentId : notification?.notificationId
                }`
              : `/kidling/messages/inbox/details/${
                  notification?.parentId ? notification?.parentId : notification?.notificationId
                } `
          }
        >
          <NotificationCard
            notification={notification}
            setShowReply={notification?.subMessages?.length === 0 ? setShowReply : null}
            isReply={false}
            setParentId={() => {
              setParentId(
                notification?.parentId ? notification?.parentId : notification?.notificationId
              )
            }}
          />
          {notification?.subMessages?.map((reply: any, index: number) => (
            <React.Fragment key={index}>
              <NotificationCard
                notification={reply}
                setShowReply={notification?.subMessages?.length - 1 === index ? setShowReply : null}
                isReply={true}
                setParentId={() => {
                  setParentId(
                    notification?.parentId ? notification?.parentId : notification?.notificationId
                  )
                }}
              />
            </React.Fragment>
          ))}
        </Link>
        {/* begin::Reply input */}
        {showReply && (
          <div className='card'>
            <div className='card-body p-1 m-0 m-sm-2'>
              <form id='kt_inbox_reply_form' className='rounded border mt-10'>
                <div className='z-0'>
                  <CKEditor
                    editor={ClassicEditor}
                    // data={formik.values.message}
                    onReady={(editor: any) => {
                      setEditor(editor)
                      editor.editing.view.change((writer: any) => {
                        writer.setAttribute(
                          'spellcheck',
                          'false',
                          editor.editing.view.document.getRoot()
                        )
                      })
                    }}
                    onChange={(event: any, editor: any) => {
                      const data = editor.getData()

                      setMessage(data)

                      editor?.focus()
                    }}
                    config={{
                      language: 'de',
                      toolbar: [
                        'Heading',
                        'bold',
                        'italic',
                        'link',
                        '|',
                        'bulletedList',
                        'numberedList',
                        'todoList',
                        'alignment',
                        '|',
                        'blockQuote',
                        'insertTable',
                        'undo',
                        'redo',
                      ],
                    }}
                  />
                </div>

                <div className='d-flex flex-stack flex-wrap gap-2 py-5 ps-8 pe-5 border-top'>
                  <div className='d-flex align-items-center me-3'>
                    <div className='btn-group me-4'>
                      <button
                        type='button'
                        className='btn btn-primary d-flex aling-items-center'
                        data-kt-users-modal-action='submit'
                        disabled={loader}
                        onClick={sendReply}
                      >
                        {!loader && (
                          <span className='indicator-label mx-4'>
                            {intl.formatMessage({id: 'KIDLING.HOME.SEND'})}
                          </span>
                        )}
                        {loader && (
                          <span className=''>
                            {intl.formatMessage({id: 'KIDLING.HOME.PLEASE_WAIT'})}...{' '}
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          </span>
                        )}
                      </button>
                    </div>

                    <OverlayTrigger
                      placement='top'
                      trigger='click'
                      rootClose
                      overlay={
                        <Popover>
                          <Picker
                            data={data}
                            onEmojiSelect={(data: any) => {
                              editor.model.change((writer: any) => {
                                const selection = editor.model.document.selection
                                const currentAttributes = selection.getAttributes()
                                const insertPosition = selection.getFirstPosition()
                                writer.insertText(data.native, currentAttributes, insertPosition)
                              })
                            }}
                          />
                        </Popover>
                      }
                    >
                      <button className='btn px-2' type='button'>
                        <i className='fa-regular fa-face-smile fs-2 text-primary'></i>
                      </button>
                    </OverlayTrigger>
                    {/* <button className='btn px-2' type='button'>
                      <i className='fa-solid fa-paperclip fs-3 m-0'></i>
                    </button> */}
                  </div>
                </div>
              </form>
            </div>
          </div>
        )}
        {/* edit::Reply input */}
      </div>
      {/* end::Body */}
    </div>
  )
}

export {NotificationMessageCard}
