/* eslint-disable react-hooks/exhaustive-deps */
import {FC, ReactElement, useEffect} from 'react'
import {Avatar} from '@mui/material'
import useCalendar from '../../../../hooks/useCalendar'
import {useAppContext} from '../../../../../_metronic/layout/core/AppContextProvider'
import {useSearchParams} from 'react-router-dom'

const CalenderFilter: FC = (): ReactElement => {
  const {calendarFilter, setCalendarFilter} = useAppContext()
  const {filters} = useCalendar()
  const [searchParams, setSearchParams] = useSearchParams()
  useEffect(() => {
    const kidId = searchParams.get('kidId') || searchParams.get('KidId')

    if (Number(kidId) > 0) {
      const kidItem = filters?.find((item: any) => item.id === Number(kidId))

      setCalendarFilter(kidItem)
    } else {
      searchParams.delete('kidId')
      searchParams.delete('KidId')
      setSearchParams(searchParams)
      setCalendarFilter(filters[0])
    }
  }, [searchParams, filters])
  return (
    <div className='w-100 d-flex align-items-center justify-content-center flex-wrap'>
      {filters?.length > 1 &&
        filters?.map((item: any, index: number) => (
          <div
            className={`btn btn-outline  btn-outline-dashed btn-active-light-primary  text-start pt-8 px-12 pb-2 m-2  d-flex align-items-center flex-column position-relative ${
              calendarFilter?.id === item.id && 'active'
            } `}
            data-kt-button='true'
            key={index}
            onClick={() => {
              setSearchParams({kidId: item.id})
            }}
          >
            <Avatar src={item.imagePath} alt={`${item.type.charAt(0).toUpperCase()}`} />

            <input
              className={`form-check-input  position-absolute top-0 start-0 m-2`}
              type='radio'
              name='childFilter'
              checked={calendarFilter?.id === item.id}
            />

            <div className='form-check form-check-custom form-check-solid form-check-sm d-flex align-items-center   '>
              <div className='fs-4 fw-bold text-gray-800  '>{item.type}</div>
            </div>
          </div>
        ))}
    </div>
  )
}

export {CalenderFilter}
