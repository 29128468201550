import axios from 'axios'
import {
  SALUTATION,
  BLOODTYPE,
  RELIGION,
  GENDER,
  COUNTRY,
  STATE,
  CITY,
  ADDRESSTYPE,
  LANGUAGE,
  CONTACT_RELATION_TYPE,
  NATIONALITY,
} from '../config/endpoints'

import {getContactInfoByUserId} from './users'

export const getSalutations = async () => {
  return axios
    .get(`${SALUTATION}`)
    .then((response) => {
      const resp = response.data
      return resp
    })
    .catch((error) => {
      console.log(error)
    })
}

export const getBloodTypes = async () => {
  return axios
    .get(`${BLOODTYPE}`)
    .then((response) => {
      const resp = response.data
      return resp
    })
    .catch((error) => {
      console.log(error)
    })
}

export const getReligions = async () => {
  return axios
    .get(`${RELIGION}`)
    .then((response) => {
      const resp = response.data
      return resp
    })
    .catch((error) => {
      console.log(error)
    })
}

export const getGenders = async () => {
  return axios
    .get(`${GENDER}`)
    .then((response) => {
      const resp = response.data
      return resp
    })
    .catch((error) => {
      console.log(error)
    })
}

export const getCountries = async () => {
  return axios
    .get(`${COUNTRY}`)
    .then((response) => {
      const resp = response.data
      return resp
    })
    .catch((error) => {
      console.log(error)
    })
}
export const getCountryById = async (countryId: number) => {
  return axios
    .get(`${COUNTRY}/${countryId}`)
    .then((response) => {
      const resp = response.data
      return resp.countryName
    })
    .catch((error) => {
      console.log(error)
    })
}

export const getStates = async (countryId?: number) => {
  const filter = countryId ? `countryId=${countryId}` : ''
  return axios
    .get(`${STATE}/listwhere/${filter}`)
    .then((response) => {
      const resp = response.data
      return resp
    })
    .catch((error) => {
      console.log(error)
    })
}
export const getStateById = async (stateId: number) => {
  return axios
    .get(`${STATE}/${stateId}`)
    .then((response) => {
      const resp = response.data
      return resp.stateName
    })
    .catch((error) => {
      console.log(error)
    })
}

export const getCities = async (cityId: number) => {
  return axios
    .get(`${CITY}/listwhere/stateId=${cityId}`)
    .then((response) => {
      const resp = response.data
      return resp
    })
    .catch((error) => {
      console.log(error)
    })
}
export const getCityById = async (cityId: number) => {
  return axios
    .get(`${CITY}/${cityId}`)
    .then((response) => {
      const resp = response.data
      return resp.cityName
    })
    .catch((error) => {
      console.log(error)
    })
}
export const getRawLanguages = async () => {
  return axios
    .get(`${LANGUAGE}`)
    .then((response) => {
      const resp = response.data
      return resp
    })
    .catch((error) => {
      console.log(error)
    })
}

export const getAddressTypes = async () => {
  return axios
    .get(`${ADDRESSTYPE}`)
    .then((response) => {
      const resp = response.data
      return resp
    })
    .catch((error) => {
      console.log(error)
    })
}

export const getUserData = async (id: any, data: any, token: string, url: any) => {
  const contact = await getContactInfoByUserId(id, token, url)
  data.contact = contact
  const kids = await getAllContactKids(contact?.contactId, token, url)
  data.kids = kids
  delete data.password
  return data
}

export const padTo2Digits = (num: any) => {
  return num.toString().padStart(2, '0')
}

export const formatDate = (date: any) => {
  if (date !== '' && date) {
    date = new Date(date)
  } else date = new Date()
  return [date.getFullYear(), padTo2Digits(date.getMonth() + 1), padTo2Digits(date.getDate())].join(
    '-'
  )
}
export const formatAMPM = (date: any) => {
  return new Date(date).toLocaleString('en-US', {hour: 'numeric', hour12: true})
}

export const formatDatePicker = (date: any) => {
  if (date !== '' && date) {
    date = new Date(date)
  } else date = new Date()
  return [padTo2Digits(date.getDate()), padTo2Digits(date.getMonth() + 1), date.getFullYear()].join(
    '.'
  )
}

export const getSubDomain = (string: string, char1: string, char2: string) => {
  return string.slice(string.indexOf(char1) + 2, string.lastIndexOf(char2))
}

export const getAllContactKids = async (contactId: any, token: string, url: string) => {
  const axiosInstance = axios.create()
  axiosInstance.interceptors.request.use(
    (config: any) => {
      config.headers['token'] = `${token}`

      return config
    },
    (err: any) => Promise.reject(err)
  )
  return axiosInstance
    .get(`${url ?? localStorage.getItem('apiUrl')}mobile/GetKidContacts/contactId=${contactId}`, {
      headers: {token},
    })

    .then((response) => {
      let resp = response.data.data
      let temp: any = []

      resp?.forEach((item: any) => {
        const temp1 = {...item}
        delete temp1.kid
        temp.push({...item.kid, ...temp1})
      })

      return temp
    })
    .catch((error) => {
      console.log(error)
    })
}

export const getContactRelationType = async () => {
  return axios
    .get(`${CONTACT_RELATION_TYPE}`)
    .then((response) => {
      const resp = response.data
      return resp
    })
    .catch((error) => {
      console.log(error)
    })
}

export const getAllCountries = async () => {
  return axios
    .get(`${COUNTRY}`)
    .then((response) => response.data)
    .catch((error) => {
      console.log(error)
    })
}

export const getAllCities = async (filter: string) => {
  return axios
    .get(`${CITY}/listwhere/1=1`)
    .then((response) => response.data)
    .catch((error) => {
      console.log(error)
    })
}

export const getNationalities = async () => {
  return axios
    .get(`${NATIONALITY}`)
    .then((response) => {
      const resp = response.data
      return resp
    })
    .catch((error) => {
      console.log(error)
    })
}
