import moment from 'moment'
import * as endpoints from '../config/endpoints'
import axios from 'axios'

export const getAllEventsWithRecurrents = async (filter: string, From?: string, To?: string) => {
  return axios
    .get(`${endpoints.EVENTS_WITH_RECURRENTS}/1=1?${filter}`, {
      params: {From: From && To ? From : null, To: From && To ? To : null},
    })
    .then((response) => response.data.data)
    .catch((error) => {
      console.log(error)
    })
}

export const readEventsCategory = async (filter: string) => {
  return axios
    .get(`${endpoints.EVENTS_CATEGORY}/listwhere/${filter}`)
    .then((response) => response.data)
    .catch((error) => {
      console.log(error)
    })
}

export const readHolidays = async (CompanyId: string) => {
  return axios
    .get(`${endpoints.HOLIDAY}/listwhere/CompanyId=${CompanyId}`)
    .then((response) =>
      response.data?.data?.sort((a: any, b: any) => moment(a?.holidayDate).diff(b?.holidayDate))
    )
    .catch((error) => {
      console.log(error)
    })
}

export const readDaysOff = async (CompanyId: string) => {
  const params = {CompanyId}
  return axios
    .get(`${endpoints.DAYOFF}`, {params})
    .then((response) =>
      response.data?.data?.sort((a: any, b: any) => moment(a?.dayOffDate).diff(b?.dayOffDate))
    )
    .catch((error) => {
      console.log(error)
    })
}

export const updateContactAttendanceStatus = async (filter: any) => {
  return axios
    .post(`${endpoints.CONTACT_ATTENDACE_UPDATE}?${filter} `)
    .then((response) => response)
    .catch((error) => {
      console.log(error)
    })
}

export const updateKidAttendanceStatus = async (filter: any) => {
  return axios
    .post(`${endpoints.KID_ATTENDACE_UPDATE}?${filter} `)
    .then((response) => response)
    .catch((error) => {
      console.log(error)
    })
}
