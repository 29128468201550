import {KIDCONTACT, KID_CONTACTS, ADD_NEW_CONTACT} from '../config/endpoints'
import axios from 'axios'
export const AddNewContact = async (data: any) => {
  return axios
    .post(ADD_NEW_CONTACT, data)
    .then((response) => response.data)
    .catch((error) => {
      console.log(error)
    })
}

export const UpdateKidContact = async (data: any) => {
  return axios
    .put(KIDCONTACT, data)
    .then((response) => response.data)
    .catch((error) => {
      console.log(error)
    })
}

export const GetKidContacts = async (filter: any) => {
  return axios
    .get(`${KID_CONTACTS}/${filter}`)
    .then((response) => response.data.data)
    .catch((error) => {
      console.log(error)
    })
}
