import {useEffect, useState} from 'react'
import {useAppContext} from '../../_metronic/layout/core/AppContextProvider'
import {deleteAbsense, getAbsenseById, updateAbsense} from '../../controller/absense'
import moment from 'moment'
import {toast} from 'react-toastify'
import {useIntl} from 'react-intl'

const useAbsence = () => {
  const intl = useIntl()
  const [date, setDate] = useState<any>({})
  const {setGlobalLoader, childFilter, setAllAbsences} = useAppContext()
  const [showDelete, setShowDelete] = useState(false)
  const [currentId, setCurrentId] = useState('')

  const handleDeleteAbsense = async (id: string) => {
    setCurrentId(id)
    setShowDelete(true)
  }

  const doDelete = async () => {
    setShowDelete(false)
    if (currentId !== '') {
      setGlobalLoader(true)
      const resp = await deleteAbsense(currentId)
      if (resp) {
        await readAbsense()
        setGlobalLoader(false)
        toast.success(intl.formatMessage({id: 'COMMON.SUCCESS'}))
      } else {
        setGlobalLoader(false)
        toast.error(intl.formatMessage({id: 'COMMON.FAIL'}))
      }
    }
  }
  const editCell = async (row: any, col: string, value: any) => {
    setGlobalLoader(true)
    const data = {
      ...row,
      [col]: value,
    }

    toast.success(intl.formatMessage({id: 'COMMON.SUCCESS'}))
    setGlobalLoader(false)

    const resp = await updateAbsense(data)
    if (resp) {
      await readAbsense()
      setGlobalLoader(false)
      toast.success(intl.formatMessage({id: 'COMMON.SUCCESS'}))
    } else {
      setGlobalLoader(false)
      toast.error(intl.formatMessage({id: 'COMMON.FAIL'}))
    }
  }

  const readAbsense = async () => {
    setGlobalLoader(true)

    const res = await getAbsenseById(
      childFilter.kidId ?? -1,
      moment(date?.startDate).startOf('day').format('yyyy-MM-DDTHH:mm:ss'),
      moment(date?.endDate).endOf('day').format('yyyy-MM-DDTHH:mm:ss')
    )

    setAllAbsences(res)
    setGlobalLoader(false)
  }
  const handleDateChange = (selected: any) => {
    setDate(selected[0])
  }

  return {
    handleDateChange,
    readAbsense,
    editCell,
    handleDeleteAbsense,
    doDelete,
    setShowDelete,
    showDelete,
    date,
  }
}
export default useAbsence
