/* eslint-disable react-hooks/exhaustive-deps */
import {FC, useEffect, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import clsx from 'clsx'
import Select from 'react-select'
import {useIntl} from 'react-intl'
import {toast} from 'react-toastify'
import {useListView} from '../../messages/core/ListViewProvider'
import {useAppContext} from '../../../../_metronic/layout/core/AppContextProvider'
import {useAuth} from '../../auth'
import {BulkAbsence} from '../../../../controller/bulk-actions'
import {getDashboard} from '../../../../controller/parent'
import {getAbsentReasons} from '../../../../controller/kids'
import {selectStyle} from '../../../../helpers/select-style'
import ResponsiveDatePicker from '../../../general-components/responsive-material-datepicker/ResponsiveDatePicker'

type Props = {
  isUserLoading: boolean
  kidId: number
  companyId?: string
  setShowAbsenceModel: any
}
const KidAbsenseModalForm: FC<Props> = ({isUserLoading, kidId, companyId, setShowAbsenceModel}) => {
  const {setItemIdForUpdate, selected, clearSelected} = useListView()
  const {absentReasons, setAbsentReasons, setAccounts} = useAppContext()
  const {currentUser} = useAuth()
  const intl = useIntl()
  const [userForEdit] = useState({
    employee: '',
    contact: '',
    fromDate: new Date(),
    toDate: new Date(),
    reason: '',
  })

  useEffect(() => {
    if (absentReasons.length === 0) load()
  }, [])

  const kidSchema = Yup.object().shape({
    employee: Yup.string(),
    contact: Yup.string(),
    fromDate: Yup.date().typeError(intl.formatMessage({id: 'KIDLING.INVALID_DATETIME'})),
    toDate: Yup.date().typeError(intl.formatMessage({id: 'KIDLING.INVALID_DATETIME'})),
    reason: Yup.mixed().required(intl.formatMessage({id: 'KIDLING.REQUIRED'})),
  })

  const cancel = () => {
    setShowAbsenceModel(false)
    setItemIdForUpdate(undefined)
  }

  const formik = useFormik({
    initialValues: userForEdit,
    validationSchema: kidSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)

      try {
        let kidIds: number[] = [kidId]
        const data = {
          kidIds,
          updatedDateTime: new Date(),
          employeeId: null,
          fromDate: values.fromDate,
          toDate: values.toDate,
          kidAbsentReasonId: values.reason || null,
          companyId: companyId,
          contactRemarks: values.contact,
          contactId: currentUser?.contact?.contactId,
        }

        const resp = await BulkAbsence(data)

        if (!!resp) {
          const contactId = currentUser?.contact?.contactId
          const data = await getDashboard('?contactId=' + contactId)

          if (data.kids) {
            setAccounts(data?.kids)
          }
          clearSelected()
          toast.success(intl.formatMessage({id: 'COMMON.SUCCESS'}))
          cancel()
        } else {
          toast.error(intl.formatMessage({id: 'COMMON.FAIL'}))
        }
      } catch (ex) {
        console.error(ex)
      } finally {
        setSubmitting(true)
        cancel()
      }
    },
  })

  const load = async () => {
    const resp = await getAbsentReasons()
    if (resp) setAbsentReasons(resp)
  }
  return (
    <>
      <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit} noValidate>
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column overflow-auto me-n7 pe-7'
          id='kt_modal_add_user_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_user_header'
          data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
          data-kt-scroll-offset='300px'
        >
          <div className='symbol-group symbol-hover'>
            {selected?.map((item: any, index: number) => (
              <div className='d-flex flex-column justify-content-center align-items-center'>
                <div className='symbol symbol-circle symbol-50px' key={index}>
                  <img
                    src={item.imagePath !== '' ? item.imagePath : '/media/avatars/blank.png'}
                    alt=''
                  />
                </div>
                <div className='dispaly-6'>{item.firstName}</div>
              </div>
            ))}
          </div>
          <div className='row'>
            <div className='col-12 col-sm-6'>
              <div className='fv-row my-7'>
                <label className='form-label fs-6 fw-semibold text-muted'>
                  {intl.formatMessage({id: 'KIDLING.HOME.FROM_DATE'})}
                </label>
                <div
                  className={clsx(
                    {'is-invalid': formik.touched.fromDate && formik.errors.fromDate},
                    {
                      'is-valid': formik.touched.fromDate && !formik.errors.fromDate,
                    }
                  )}
                >
                  <ResponsiveDatePicker
                    onAccept={(val: any) => {
                      formik.setFieldValue('fromDate', val)
                      formik.setFieldValue('toDate', val)
                    }}
                    value={formik.values.fromDate}
                  />
                </div>
              </div>
            </div>
            <div className='col-12 col-sm-6'>
              <div className='fv-row my-7'>
                <label className='form-label fs-6 fw-semibold text-muted'>
                  {' '}
                  {intl.formatMessage({id: 'KIDLING.HOME.TO_DATE'})}
                </label>
                <div
                  className={clsx(
                    {'is-invalid': formik.touched.toDate && formik.errors.toDate},
                    {
                      'is-valid': formik.touched.toDate && !formik.errors.toDate,
                    }
                  )}
                >
                  <ResponsiveDatePicker
                    onAccept={(val: any) => {
                      formik.setFieldValue('toDate', val)
                    }}
                    value={formik.values.toDate}
                  />
                </div>
              </div>
            </div>
            <div className='col-12  '>
              <div className='fv-row mb-7'>
                <label className='form-label fs-6 fw-semibold text-muted'>
                  {intl.formatMessage({id: 'KIDLING.HOME.KID_ABSENT_REASON'})}
                </label>

                <Select
                  onChange={(val: any) => formik.setFieldValue('reason', val.kidAbsentReasonId)}
                  options={absentReasons}
                  isClearable={true}
                  formatOptionLabel={(item: any) => (
                    <p className='p-0 m-0'>{item.kidAbsentReasonName}</p>
                  )}
                  className='w-100  '
                  placeholder={intl.formatMessage({id: 'KIDLING.HOME.KID_ABSENT_REASON'})}
                  isLoading={absentReasons.length === 0}
                  theme={(theme) => ({
                    ...theme,

                    colors: {
                      ...theme.colors,
                      primary25: 'var(--bs-dark-light)',
                      primary: 'var(--bs-gray)',
                      neutral0: 'var(--bs-dark-light)',
                    },
                  })}
                  styles={selectStyle}
                />
                {formik.touched.reason && formik.errors.reason && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.reason}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='col-12 '>
              <div className='fv-row mb-7'>
                <label className='  form-label fs-6 fw-semibold text-muted'>
                  {intl.formatMessage({id: 'KIDLING.HOME.CONTACT_REMARKS'})}
                </label>
                <textarea
                  {...formik.getFieldProps('contact')}
                  className={clsx(
                    'form-control   mb-3 mb-lg-0',
                    {'is-invalid': formik.touched.contact && formik.errors.contact},
                    {
                      'is-valid': formik.touched.contact && !formik.errors.contact,
                    }
                  )}
                  name='contact'
                  autoComplete='off'
                  rows={1}
                />
                {formik.touched.contact && formik.errors.contact && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span className='alert-danger'>{formik.errors.contact}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className='col-12 col-sm-6'></div>
          </div>
        </div>
        {/* end::Scroll */}

        {/* begin::Actions */}
        <div className='text-center pt-15'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            data-kt-users-modal-action='cancel'
            disabled={formik.isSubmitting || isUserLoading}
          >
            {intl.formatMessage({id: 'KIDLING.HOME.DISCARD'})}{' '}
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-users-modal-action='submit'
            disabled={isUserLoading || formik.isSubmitting || !formik.isValid || !formik.touched}
          >
            <span className='indicator-label'>
              {intl.formatMessage({id: 'AUTH.GENERAL.SUBMIT_BUTTON'})}
            </span>
            {(formik.isSubmitting || isUserLoading) && (
              <span className='indicator-progress'>
                {intl.formatMessage({id: 'KIDLING.HOME.PLEASE_WAIT'})}...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Actions */}
      </form>
    </>
  )
}

export {KidAbsenseModalForm}
