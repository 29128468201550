/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react'
import {KTSVG} from '../../../_metronic/helpers'
import {Link} from 'react-router-dom'
import {useAppContext} from '../../../_metronic/layout/core/AppContextProvider'
import {useLocation} from 'react-router'
import {useIntl} from 'react-intl'
import {useAuth} from '../../views/auth'
import {readFileFrom} from '../../../controller/image'
import {updateUserImage} from '../../../controller/users'
import {toast} from 'react-toastify'
import {getCityById} from '../../../controller/common'

import CropImageModal from '../../general-components/Crop-image-modal/CropImageModal'
import {useLang} from '../../../_metronic/i18n/Metronici18n'
const AccountHeader: React.FC = () => {
  const location = useLocation()
  const {currentUser, setCurrentUser} = useAuth()
  const account = currentUser?.contact
  const intl = useIntl()
  const [preview, setPreview] = useState<any>(null)
  const [selectedFile, setSelectedFile] = useState<any>(null)
  const [base64Image, setBase64Image] = useState<any>(null)
  const {setCurrentUserImage} = useAppContext()
  const [isLoading, setIsLoading] = useState(false)
  const company = window.location.pathname.split('/')[1]
  const [showCropModal, setShowCropModal] = useState<any>({show: false, data: null})
  const lang = useLang()

  const load = async () => {
    if (currentUser?.imagePath) {
      getImage()
    }
    if (currentUser?.contact) {
      // Streetname housenumber, postalcode cityname
      let temp = ''
      let contact = currentUser.contact
      if (contact.streetName) {
        temp = contact.streetName
      }
      if (contact.houseNumber) {
        if (contact.streetName) temp += ' ' + contact.houseNumber
        else temp = contact.houseNumber
      }

      if (contact.postalCode) {
        if (temp !== '') temp += ', '
        temp += contact.postalCode
      }

      if (contact.cityId) {
        const city = await getCityById(contact.cityId)
        if (temp !== '') {
          temp += ' '
        }
        temp += city
      }

      // setAddress(temp)
    }
  }
  useEffect(() => {
    load()
  }, [])

  useEffect(() => {
    if (!!base64Image) updateImage(base64Image)
  }, [base64Image])

  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined)
      return
    }
    const objectUrl = URL.createObjectURL(selectedFile)
    setPreview(objectUrl)
    getBase64(selectedFile)

    // free memory when ever this component is unmounted
    return () => {
      URL.revokeObjectURL(objectUrl)
    }
  }, [selectedFile])

  const getBase64 = async (file: any) => {
    const reader = new FileReader()

    reader.addEventListener('load', () => {
      return setBase64Image(reader.result)
    })

    reader.readAsDataURL(file)
  }
  const updateImage = async (image: string) => {
    setIsLoading(true)
    const resp = await updateUserImage({id: currentUser?.usersId, imagePath: image})
    setIsLoading(false)
    if (resp) {
      let temp = currentUser
      if (temp) {
        temp.imagePath = resp.imagePath
        setCurrentUser(temp)
      }

      setCurrentUserImage(image ? base64Image : null)
      toast.success(intl.formatMessage({id: 'COMMON.SUCCESS'}))
    }
  }

  const getImage = async () => {
    const resp = await readFileFrom(currentUser?.imagePath ?? '')
    if (resp) setPreview(resp)
  }

  return (
    <div className='card mb-5 mb-xl-10'>
      <div className='card-body pt-9 pb-0'>
        <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
          <div className='me-7 mb-4'>
            <div
              className='image-input image-input-outline'
              data-kt-image-input='true'
              style={{
                backgroundImage: preview
                  ? `url(${preview})`
                  : 'url("/media/svg/avatars/blank.svg")',
              }}
            >
              <div
                className='image-input-wrapper w-125px h-125px'
                style={{
                  backgroundImage: preview
                    ? `url(${preview})`
                    : 'url("/media/svg/avatars/blank.svg")',
                }}
              ></div>

              <label
                className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                data-kt-image-input-action='change'
                data-bs-toggle='tooltip'
                title='Change avatar'
              >
                {isLoading ? (
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                ) : (
                  <i className='fa-solid fa-pencil'>
                    <span className='path1'></span>
                    <span className='path2'></span>
                  </i>
                )}

                <input
                  type='file'
                  name='avatar'
                  accept='.png, .jpg, .jpeg'
                  onChange={
                    (e: any) =>
                      setShowCropModal({show: true, data: URL.createObjectURL(e.target?.files[0])})
                    //setSelectedFile(e.target?.files.length > 0 ? e.target?.files[0] : null)
                  }
                  lang={lang}
                />
                <input type='hidden' name='avatar_remove' lang={lang} />
              </label>

              <span
                className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                data-kt-image-input-action='cancel'
                data-bs-toggle='tooltip'
                title='Cancel avatar'
              >
                <i className='fa-solid fa-xmark'>
                  <span className='path1'></span>
                  <span className='path2'></span>
                </i>
              </span>

              <span
                className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                data-kt-image-input-action='remove'
                data-bs-toggle='tooltip'
                title='Remove avatar'
                onClick={() => {
                  updateImage('')
                  setPreview(null)
                }}
              >
                <i className='fa-solid fa-xmark'>
                  <span className='path1'></span>
                  <span className='path2'></span>
                </i>
              </span>
            </div>
          </div>

          <div className=' d-flex align-items-center '>
            <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
              <div className='d-flex flex-column'>
                <div className='d-flex align-items-center mb-2'>
                  <a href='#' className='text-gray-800 text-hover-primary fs-2 fw-bold me-1'>
                    {account?.contactName}
                  </a>
                </div>

                <div className='d-flex flex-wrap fw-semibold fs-6 mb-4 pe-2 flex-column'>
                  {/* {account?.profession && (
                    <a
                      href='#'
                      className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'
                    >
                      <KTSVG
                        path='/media/icons/duotune/communication/com006.svg'
                        className='svg-icon-4 me-1'
                      />
                      {account.profession}
                    </a>
                  )} */}
                  {/* {address && (
                    <a
                      href='#'
                      className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'
                    >
                      <KTSVG
                        path='/media/icons/duotune/general/gen018.svg'
                        className='svg-icon-4 me-1'
                      />
                      {address}
                    </a>
                  )} */}
                  <a
                    href='#'
                    className='d-flex align-items-center text-gray-400 text-hover-primary me-5  mb-2'
                  >
                    <KTSVG
                      path='/media/icons/duotune/communication/com011.svg'
                      className='svg-icon-4 me-1'
                    />
                    {currentUser?.email}
                  </a>

                  {account?.phoneNumber ||
                    (account?.cellPhoneNumber && (
                      <a
                        href='#'
                        className='d-flex align-items-center text-gray-400 text-hover-primary me-5  mb-2'
                      >
                        <i className='fa-solid fa-phone me-1'></i>
                        {account?.phoneNumber || account?.cellPhoneNumber}
                      </a>
                    ))}
                </div>
              </div>
            </div>

            <div className='d-flex flex-wrap flex-stack'>
              {/*
              <div className='d-flex flex-column flex-grow-1 pe-8'>
                <div className='d-flex flex-wrap'>
                  <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                    <div className='d-flex align-items-center'>
                      <KTSVG
                        path='/media/icons/duotune/arrows/arr066.svg'
                        className='svg-icon-3 svg-icon-success me-2'
                      />
                      
                      <div className='fs-2 fw-bolder'>
                        {currentUser?.contact?.totalAnnualIncome || 0}€
                  </div>
                    </div>

                    <div className='fw-bold fs-6 text-gray-400'>
                      {' '}
                      {intl.formatMessage({id: 'PARENT.AnnualEarning'})}
                    </div>
                  </div>
                </div>
              </div>
              */}
              {/* <div className='d-flex align-items-center w-200px w-sm-300px flex-column mt-3'>
                <div className='d-flex justify-content-between w-100 mt-auto mb-2'>
                  <span className='fw-semibold fs-6 text-gray-400'>
                    {' '}
                    {intl.formatMessage({id: 'PARENT.PROFILECOMPLETION'})}
                  </span>
                  <span className='fw-bold fs-6'>50%</span>
                </div>
                <div className='h-5px mx-3 w-100 bg-light mb-3'>
                  <div
                    className='bg-success rounded h-5px'
                    role='progressbar'
                    style={{width: '50%'}}
                  ></div>
                </div>
              </div> */}
            </div>
          </div>
        </div>

        <div className='d-flex overflow-auto h-55px'>
          <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bold flex-nowrap'>
            <li className='nav-item'>
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  ((location.pathname === `/${company}/profile/overview` ||
                    location.pathname === `/kidling/profile/overview`) &&
                    'active')
                }
                to={company ? `/${company}/profile/overview` : '/kidling/profile/overview'}
              >
                {intl.formatMessage({id: 'COMMON.Overview'})}
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  ((location.pathname === `/${company}/profile/settings` ||
                    location.pathname === `/kidling/profile/settings`) &&
                    'active')
                }
                to={company ? `/${company}/profile/settings` : '/kidling/profile/settings'}
              >
                {intl.formatMessage({id: 'COMMON.Settings'})}
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  ((location.pathname === `/${company}/profile/address` ||
                    location.pathname === `/kidling/profile/address`) &&
                    'active')
                }
                to={company ? `/${company}/profile/address` : '/kidling/profile/address'}
              >
                {intl.formatMessage({id: 'COMMON.Address'})}
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  ((location.pathname === `/${company}/profile/changePassword` ||
                    location.pathname === `/kidling/profile/changePassword`) &&
                    'active')
                }
                to={
                  company ? `/${company}/profile/changePassword` : '/kidling/profile/changePassword'
                }
              >
                {intl.formatMessage({id: 'KIDLING.CHANGE_PASSWORD'})}
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  ((location.pathname === `/${company}/profile/notifications` ||
                    location.pathname === `/kidling/profile/notifications`) &&
                    'active')
                }
                to={
                  company ? `/${company}/profile/notifications` : '/kidling/profile/notifications'
                }
              >
                {intl.formatMessage({id: 'KIDLING.PROFILE.NOTIFICATIONS'})}
              </Link>
            </li>
            {/*}
            <li className='nav-item'>
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  (location.pathname === '/profile/billing' && 'active')
                }
                to='/profile/billing'
              >
                Billing Info
              </Link>
              </li>*/}
          </ul>
        </div>
      </div>
      <CropImageModal
        setShowModal={setShowCropModal}
        showModal={showCropModal}
        setImage={setSelectedFile}
      />
    </div>
  )
}

export {AccountHeader}
