import {InboxSelectionCell} from './InboxSelectionCell'
import moment from 'moment'
import {useLang} from '../../../../../../_metronic/i18n/Metronici18n'
import {UserCustomHeader} from '../../../../../general-components/table-custom-header/UserCustomHeader'
import {MESSAGE_LENGTH, MOBILE_MESSAGE_LENGTH} from '../../../../../../config/constants'
import AvatarWithLetters from '../../../../../general-components/avatar-with-letters'
import {useThemeMode} from '../../../../../../_metronic/partials/layout/theme-mode/ThemeModeProvider'

function _columns({handleMarkPinned, navigate, setReciepiantDialog}: any) {
  const lang = useLang()
  const {mode} = useThemeMode()
  return [
    {
      id: 'selection',

      Cell: ({...props}) => <InboxSelectionCell id={props.data[props.row.index].notificationId} />,
      width: 5,
    },
    {
      width: 5,
      id: 'Attributes',
      accessor: 'id',
      Cell: ({cell}: any) => {
        const pinned = cell.row.original.pinned
        return (
          <div className='d-flex  align-items-center'>
            <button
              className='btn btn-sm btn-icon btn-clear btn-active-light-primary me-3'
              type='button'
              onClick={() => handleMarkPinned(cell.row.original.notificationId, pinned)}
            >
              <i className={`fa-solid fa-star fs-5 m-0 ${pinned && 'text-warning'}`}></i>
            </button>
            {cell.row.original.attachmentExists && (
              <i className={`fa-solid fa-paperclip fs-5 m-0  `}></i>
            )}

            {cell.row.original.readDateTime && (
              <i className='fa-solid fa-spinner fs-7 text-success mx-3'></i>
            )}
            {cell.row.original.highPriority && (
              <i className='fa-sharp fa-solid fa-circle-exclamation text-danger ms-4'></i>
            )}
          </div>
        )
      },
    },
    {
      Header: (props: any) => <UserCustomHeader tableProps={props} title='COMMON.RECIPIENTS' />,
      id: 'recipients',
      accessor: 'recipients',
      width: 50,
      Cell: ({cell}: any) => {
        const recipients = cell.row.original.recipients
        return (
          <div className='d-flex flex-wrap align-items-center  '>
            {recipients.length === 0 && <p>--</p>}

            <button
              className={`btn d-flex align-items-center   text-hover-primary  p-0 m-0 mb-2 ${
                mode === 'dark' ? 'text-dark' : 'text-black'
              }`}
              onClick={() =>
                recipients.length > 1 && setReciepiantDialog({show: true, data: recipients})
              }
            >
              {recipients && recipients[0]?.usersName}

              {recipients.length > 1 && <i className='fa-solid fa-caret-down ms-2'></i>}
            </button>
          </div>
        )
      },
    },

    {
      Header: (props: any) => <UserCustomHeader tableProps={props} title='KIDLING.HOME.TITLE' />,
      accessor: 'title',
      id: 'title',
      width: 300,
      Cell: ({cell}: any) => {
        return (
          <div
            className={`${mode === 'dark' ? 'text-dark' : 'text-black'} ${
              cell.row.original.readDateTime ? 'fw-normal' : 'fw-bold'
            }`}
          >
            <p onClick={() => navigate(`details/${cell.row.original.notificationId}`)}>
              {cell.row.original.notificationTitle}
            </p>
          </div>
        )
      },
    },

    {
      Header: (props: any) => <UserCustomHeader tableProps={props} title='KIDLING.HOME.MESSAGE' />,
      accessor: 'message',
      id: 'message',
      width: 300,
      Cell: ({cell}: any) => {
        const htmlNode = document.createElement('div')
        htmlNode.innerHTML = cell.row.original.message

        return (
          <div className='d-flex flex-column link'>
            <p
              className={`${mode === 'dark' ? 'text-dark' : 'text-black'} ${
                cell.row.original.readDateTime ? 'fw-normal' : 'fw-bold'
              }`}
              onClick={() => navigate(`details/${cell.row.original.notificationId}`)}
              dangerouslySetInnerHTML={{
                __html: `${htmlNode.innerText.substring(0, MESSAGE_LENGTH)} ${
                  htmlNode.innerText.length > MESSAGE_LENGTH ? ' ...' : ''
                }`,
              }}
            ></p>
          </div>
        )
      },
    },

    {
      Header: (props: any) => <UserCustomHeader tableProps={props} title='KIDLING.HOME.DATETIME' />,

      id: 'dateTime',
      width: 50,
      accessor: 'dateTime',
      Cell: ({cell}: any) => {
        return (
          <div
            className={`${mode === 'dark' ? 'text-dark' : 'text-black'} ${
              cell.row.original.readDateTime ? 'fw-normal' : 'fw-bold'
            }`}
          >
            <i className='fa-solid fa-clock me-2'></i>
            {moment
              .duration(moment(new Date()).diff(moment(cell.row.original.dateTime)))
              .locale(lang)
              .humanize()}
          </div>
        )
      },
    },
  ]
}

export default _columns

export function _mobileColumns({handleMarkPinned, navigate, setReciepiantDialog}: any) {
  const lang = useLang()

  return [
    {
      id: 'Name',
      accessor: 'name',
      width: 50,
      hideHeader: true,
      Cell: ({cell}: any) => {
        const row = cell.row.original
        const nameArray = row.usersName
          .trim()
          .replace(/\s{2,}/g, ' ')
          .split(' ')
        const htmlNode = document.createElement('div')
        htmlNode.innerHTML = cell.row.original.message
        const pinned = cell.row.original.pinned
        const recipients = cell.row.original.recipients
        return (
          <div className='d-flex align-items-center w-100 shadow-sm p-4'>
            <InboxSelectionCell id={row.notificationId} />
            <div className='d-flex align-items-center link mx-4  d-flex justify-content-center w-100'>
              <AvatarWithLetters
                image={row.imagePath}
                alt={`${nameArray[0].charAt(0).toUpperCase()} ${
                  nameArray.length > 1 ? nameArray[1].charAt(0).toUpperCase() : ''
                } `}
                extrasmall
              />
              <div className='w-100 ms-4'>
                <div
                  className=' d-flex align-items-center justify-content-between w-100'
                  onClick={() => navigate(`details/${cell.row.original.notificationId}`)}
                >
                  <div className='d-flex align-items-center'>
                    <h5
                      className={`fs-7 me-4 my-0 ${
                        cell.row.original.readDateTime && 'fw-normal text-gray'
                      }`}
                    >
                      {row.usersName}
                    </h5>
                    {cell.row.original.attachmentExists && (
                      <i className={`fa-solid fa-paperclip fs-5 m-0 mx-1 `}></i>
                    )}

                    {cell.row.original.readDateTime && (
                      <i className='fa-solid fa-spinner fs-7 text-success mx-1'></i>
                    )}
                    {cell.row.original.highPriority && (
                      <i className='fa-sharp fa-solid fa-circle-exclamation text-danger mx-1'></i>
                    )}
                  </div>

                  <div className=' my-0 '>
                    {/* <i className='fa-solid fa-clock me-2'></i> */}
                    {moment
                      .duration(moment(new Date()).diff(moment(cell.row.original.dateTime)))
                      .locale(lang)
                      .humanize()}
                  </div>
                </div>
                <div className='d-flex flex-wrap align-items-center  '>
                  {recipients.length === 0 && <p>--</p>}
                  <button
                    className='btn d-flex align-items-center text-gray-400 text-hover-primary  p-0 m-0  fs-7'
                    onClick={() =>
                      recipients.length > 1 && setReciepiantDialog({show: true, data: recipients})
                    }
                  >
                    {recipients && recipients[0]?.usersName}

                    {recipients.length > 1 && <i className='fa-solid fa-caret-down ms-2'></i>}
                  </button>
                </div>
                <p
                  className={`my-0  fs-7  ${
                    cell.row.original.readDateTime && 'fw-normal text-gray'
                  }`}
                  onClick={() => navigate(`details/${cell.row.original.notificationId}`)}
                >
                  {cell.row.original.notificationTitle}
                </p>
                <div className='d-flex align-items-center justify-content-between'>
                  <p
                    className='my-0  fs-7'
                    dangerouslySetInnerHTML={{
                      __html: `${htmlNode.innerText.substring(0, MOBILE_MESSAGE_LENGTH)} ${
                        htmlNode.innerText.length > MOBILE_MESSAGE_LENGTH ? ' ...' : ''
                      }`,
                    }}
                    onClick={() => navigate(`details/${cell.row.original.notificationId}`)}
                  ></p>

                  <button
                    className='btn  btn-clear btn-active-light-primary p-0 '
                    type='button'
                    onClick={() => handleMarkPinned(cell.row.original.notificationId, pinned)}
                  >
                    <i className={`fa-solid fa-star fs-5 m-0 ${pinned && 'text-warning'}`}></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        )
      },
    },
  ]
}
