export const storeLinks: any = {
  kidling: {
    android: 'https://play.google.com/store/apps/details?id=com.kidling.parents',
    ios: 'https://apps.apple.com/de/app/eltern-app-kidling/id6446947284',
  },
  bambini: {
    android: 'https://play.google.com/store/apps/details?id=com.kidling.Bambini.de',
    ios: 'https://apps.apple.com/de/app/bambini-kidling/id6480405456',
  },
  kneippeltern: {
    android: 'https://play.google.com/store/apps/details?id=com.kidling.kneipp.de',
    ios: 'https://apps.apple.com/de/app/kneipp-kita-spandau/id6480405237',
  },
  'es.sence': {
    android: 'https://play.google.com/store/apps/details?id=com.kidling.es.sence.de',
    ios: 'https://apps.apple.com/de/app/elly-stoffl/id6480307025',
  },
  taeks: {
    android: 'https://play.google.com/store/apps/details?id=com.kidling.taeks.de',
    ios: 'https://apps.apple.com/de/app/t%C3%A4ks-eltern-app/id6503148706',
  },
  frecherspatz: {
    android: 'https://play.google.com/store/apps/details?id=com.kidling.frecherspatz.de',
    ios: 'https://apps.apple.com/de/app/frecher-spatz/id6503983757',
  },
}
