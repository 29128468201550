import {FC} from 'react'
import {TitleHeader} from '../../../../general-components/TitleHeader'

interface Props {
  title?: string
}
const MedicalsHeader: FC<Props> = ({title}) => {
  return <TitleHeader title={title} />
}

export {MedicalsHeader}
