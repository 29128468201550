import {FC, ReactElement, useMemo, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import clsx from 'clsx'
import {useIntl} from 'react-intl'
import moment from 'moment'
import ResponsiveDatePicker from '../../general-components/responsive-material-datepicker/ResponsiveDatePicker'
import countryList from 'react-select-country-list'
import Select from 'react-select'
import './styles.scss'
interface Props {
  handleNext: any
}
const ChildStep: FC<Props> = ({handleNext}): ReactElement => {
  const intl = useIntl()
  const [initialValues] = useState<any>({})

  const options = useMemo(() => countryList().getData(), [])

  const Schema = Yup.object().shape({})
  const formik: any = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: Schema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      try {
        handleNext({
          [intl.formatMessage({id: 'PARENT.FIRSTNAME'})]: values.firstName,
          [intl.formatMessage({id: 'PARENT.LASTNAME'})]: values.lastName,
          [intl.formatMessage({id: 'KIDLING.COMPANY'})]: values.company,
          [intl.formatMessage({id: 'KIDLING.BIRTHDAY'})]: moment(values.dateOfBirth).format(
            'DD.MM.yyyy'
          ),
          [intl.formatMessage({id: 'KIDLING.CARE_TIME'})]: values.careTime,
          [intl.formatMessage({id: 'KIDLING.ADMISSION_DATE'})]: moment(values.admissionDate).format(
            'DD.MM.yyyy HH:mm'
          ),
          [intl.formatMessage({id: 'KIDLING.Req.Adm.flex'})]: values.raf,
          [intl.formatMessage({id: 'PARENT.GENDER'})]:
            values.genderId === 0
              ? intl.formatMessage({id: 'COMMON.MALE'})
              : intl.formatMessage({id: 'COMMON.FEMALE'}),
          [intl.formatMessage({id: 'KIDLING.SIBLINGS'})]: values.Siblings,
          [intl.formatMessage({id: 'KIDLING.POSTAL_CODE'})]: values.PostalCode,
          [`${intl.formatMessage({id: 'KIDLING.MOTHER_TONGUE'})}1`]: values.Mothertongue1,
          [`${intl.formatMessage({id: 'KIDLING.SECOND_LANGUAGE'})}1`]: values.SecondLanguage1,
          [intl.formatMessage({id: 'KIDLING.FAMILIY_SPOKEN_LANGUAGE'})]:
            values.FamilySpokenLanguage,
          [`${intl.formatMessage({id: 'KIDLING.MOTHER_TONGUE'})}2`]: values.Mothertongue2,
          [`${intl.formatMessage({id: 'KIDLING.SECOND_LANGUAGE'})}2`]: values.SecondLanguage2,
          [intl.formatMessage({id: 'KIDLING.COUNTRY'})]: values.country?.label,
          [intl.formatMessage({id: 'KIDLING.CITY'})]: values.city,
          [intl.formatMessage({id: 'PARENT.StreetName'})]: values.street,
          [intl.formatMessage({id: 'KIDLING.HOUSE_NUMBER'})]: values.houseNumber,
          [intl.formatMessage({id: 'KIDLING.RECOMMENDED_BY'})]: values.RecommendedBy,
          [intl.formatMessage({id: 'KIDLING.OTHER'})]: values.Other,
          [intl.formatMessage({id: 'KIDLING.KID_IS_WITH_SINGLE_PARENT'})]:
            values.KidIsWithSingleParent,
        })
      } catch (ex) {
        console.error(ex)
      } finally {
        setSubmitting(false)
      }
    },
  })

  return (
    <form
      id='kt_modal_add_user_form'
      className='form container p-8 d-flex flex-column align-items-end'
      onSubmit={formik.handleSubmit}
      noValidate
    >
      <div className='row align-items-center'>
        <div className='col-md-6 col-12 my-3'>
          <label className=' required form-label fs-6 fw-semibold text-muted'>
            {intl.formatMessage({id: 'PARENT.FIRSTNAME'})}
          </label>
          <input
            {...formik.getFieldProps('firstName')}
            className={clsx(
              'form-control  mb-3 mb-lg-0',
              {'is-invalid': formik.touched.firstName && formik.errors.firstName},
              {
                'is-valid': formik.touched.firstName && !formik.errors.firstName,
              }
            )}
            name='firstName'
            autoComplete='off'
            disabled={formik.isSubmitting}
            rows={1}
          />
          {formik.touched.firstName && formik.errors.firstName && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span className='alert-danger'>{formik.errors.firstName}</span>
              </div>
            </div>
          )}
        </div>
        <div className='col-md-6 col-12 my-3'>
          <label className=' required form-label fs-6 fw-semibold text-muted'>
            {intl.formatMessage({id: 'PARENT.LASTNAME'})}
          </label>
          <input
            {...formik.getFieldProps('lastName')}
            className={clsx(
              'form-control  mb-3 mb-lg-0',
              {'is-invalid': formik.touched.lastName && formik.errors.lastName},
              {
                'is-valid': formik.touched.lastName && !formik.errors.lastName,
              }
            )}
            name='lastName'
            autoComplete='off'
            disabled={formik.isSubmitting}
            rows={1}
          />
          {formik.touched.lastName && formik.errors.lastName && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span className='alert-danger'>{formik.errors.lastName}</span>
              </div>
            </div>
          )}
        </div>
        <div className='col-md-6 col-12 my-3'>
          <label className='required form-label fs-6 fw-semibold text-muted'>
            {intl.formatMessage({id: 'KIDLING.COMPANY'})}
          </label>
          <input
            {...formik.getFieldProps('company')}
            className={clsx(
              'form-control  mb-3 mb-lg-0',
              {'is-invalid': formik.touched.company && formik.errors.company},
              {
                'is-valid': formik.touched.company && !formik.errors.company,
              }
            )}
            name='company'
            autoComplete='off'
            disabled={formik.isSubmitting}
            rows={1}
          />
          {formik.touched.company && formik.errors.company && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span className='alert-danger'>{formik.errors.company}</span>
              </div>
            </div>
          )}
        </div>
        <div className='col-md-6 col-12 my-3'>
          <label className=' form-label fs-6 fw-semibold text-muted'>
            {intl.formatMessage({id: 'KIDLING.BIRTHDAY'})}
          </label>
          <ResponsiveDatePicker
            onAccept={(val: any) => {
              formik.setFieldValue('dateOfBirth', val)
            }}
            value={formik.values.dateOfBirth}
          />
        </div>
        <div className='col-md-6 col-12 my-3'>
          <label className=' form-label fs-6 fw-semibold text-muted'>
            {intl.formatMessage({id: 'KIDLING.CARE_TIME'})}
          </label>
          <input
            {...formik.getFieldProps('careTime')}
            className={clsx(
              'form-control  mb-3 mb-lg-0',
              {'is-invalid': formik.touched.careTime && formik.errors.careTime},
              {
                'is-valid': formik.touched.careTime && !formik.errors.careTime,
              }
            )}
            name='careTime'
            autoComplete='off'
            disabled={formik.isSubmitting}
            rows={1}
          />
          {formik.touched.careTime && formik.errors.careTime && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span className='alert-danger'>{formik.errors.careTime}</span>
              </div>
            </div>
          )}
        </div>
        <div className='col-md-6 col-12 my-3'>
          <label className=' form-label fs-6 fw-semibold text-muted'>
            {intl.formatMessage({id: 'KIDLING.ADMISSION_DATE'})}
          </label>
          <ResponsiveDatePicker
            onAccept={(val: any) => {
              formik.setFieldValue('admissionDate', val)
            }}
            value={formik.values.admissionDate}
          />
        </div>
        <div className='col-md-6 col-12 my-3'>
          <label className=' form-label fs-6 fw-semibold text-muted'>
            {intl.formatMessage({id: 'KIDLING.Req.Adm.flex'})}
          </label>
          <input
            {...formik.getFieldProps('raf')}
            className={clsx(
              'form-control  mb-3 mb-lg-0',
              {'is-invalid': formik.touched.raf && formik.errors.raf},
              {
                'is-valid': formik.touched.raf && !formik.errors.raf,
              }
            )}
            name='raf'
            autoComplete='off'
            disabled={formik.isSubmitting}
            rows={1}
          />
          {formik.touched.raf && formik.errors.raf && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span className='alert-danger'>{formik.errors.raf}</span>
              </div>
            </div>
          )}
        </div>
        <div className='col-md-6 col-12 my-3'>
          <label className=' form-label fs-6 fw-semibold text-muted'>
            {intl.formatMessage({id: 'PARENT.GENDER'})}
          </label>

          <select
            {...formik.getFieldProps('genderId')}
            className={clsx(
              'form-control  mb-3 mb-lg-0',
              {'is-invalid': formik.touched.gender && formik.errors.gender},
              {
                'is-valid': formik.touched.gender && !formik.errors.gender,
              }
            )}
            name='genderId'
            autoComplete='off'
            disabled={formik.isSubmitting}
          >
            <option value=''>{intl.formatMessage({id: 'COMMON.SELECT'})}..</option>
            <option value={0}>{intl.formatMessage({id: 'COMMON.MALE'})}</option>
            <option value={1}>{intl.formatMessage({id: 'COMMON.FEMALE'})}</option>
          </select>
          {formik.touched.genderId && formik.errors.genderId && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span className='alert-danger'>{formik.errors.genderId}</span>
              </div>
            </div>
          )}
        </div>
        <div className='col-md-6 col-12 my-3'>
          <label className=' form-label fs-6 fw-semibold text-muted'>
            {intl.formatMessage({id: 'KIDLING.SIBLINGS'})}
          </label>
          <input
            {...formik.getFieldProps('Siblings')}
            className={clsx(
              'form-control  mb-3 mb-lg-0',
              {'is-invalid': formik.touched.Siblings && formik.errors.Siblings},
              {
                'is-valid': formik.touched.Siblings && !formik.errors.Siblings,
              }
            )}
            name='Siblings'
            autoComplete='off'
            disabled={formik.isSubmitting}
            rows={1}
          />
          {formik.touched.Siblings && formik.errors.Siblings && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span className='alert-danger'>{formik.errors.Siblings}</span>
              </div>
            </div>
          )}
        </div>
        <div className='col-md-6 col-12 my-3'>
          <label className=' form-label fs-6 fw-semibold text-muted'>
            {intl.formatMessage({id: 'KIDLING.POSTAL_CODE'})}
          </label>
          <input
            {...formik.getFieldProps('PostalCode')}
            className={clsx(
              'form-control  mb-3 mb-lg-0',
              {'is-invalid': formik.touched.PostalCode && formik.errors.PostalCode},
              {
                'is-valid': formik.touched.PostalCode && !formik.errors.PostalCode,
              }
            )}
            name='PostalCode'
            autoComplete='off'
            disabled={formik.isSubmitting}
            rows={1}
          />
          {formik.touched.PostalCode && formik.errors.PostalCode && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span className='alert-danger'>{formik.errors.PostalCode}</span>
              </div>
            </div>
          )}
        </div>
        <div className='col-md-6 col-12 my-3'>
          <label className=' form-label fs-6 fw-semibold text-muted'>
            {intl.formatMessage({id: 'KIDLING.MOTHER_TONGUE'})}1
          </label>
          <input
            {...formik.getFieldProps('Mothertongue1')}
            className={clsx(
              'form-control  mb-3 mb-lg-0',
              {'is-invalid': formik.touched.Mothertongue1 && formik.errors.Mothertongue1},
              {
                'is-valid': formik.touched.Mothertongue1 && !formik.errors.Mothertongue1,
              }
            )}
            name='Mothertongue1'
            autoComplete='off'
            disabled={formik.isSubmitting}
            rows={1}
          />
          {formik.touched.Mothertongue1 && formik.errors.Mothertongue1 && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span className='alert-danger'>{formik.errors.Mothertongue1}</span>
              </div>
            </div>
          )}
        </div>
        <div className='col-md-6 col-12 my-3'>
          <label className=' form-label fs-6 fw-semibold text-muted'>
            {intl.formatMessage({id: 'KIDLING.SECOND_LANGUAGE'})}1
          </label>
          <input
            {...formik.getFieldProps('SecondLanguage1')}
            className={clsx(
              'form-control  mb-3 mb-lg-0',
              {'is-invalid': formik.touched.SecondLanguage1 && formik.errors.SecondLanguage1},
              {
                'is-valid': formik.touched.SecondLanguage1 && !formik.errors.SecondLanguage1,
              }
            )}
            name='SecondLanguage1'
            autoComplete='off'
            disabled={formik.isSubmitting}
            rows={1}
          />
          {formik.touched.SecondLanguage1 && formik.errors.SecondLanguage1 && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span className='alert-danger'>{formik.errors.SecondLanguage1}</span>
              </div>
            </div>
          )}
        </div>
        <div className='col-md-6 col-12 my-3'>
          <label className=' form-label fs-6 fw-semibold text-muted'>
            {intl.formatMessage({id: 'KIDLING.FAMILIY_SPOKEN_LANGUAGE'})}
          </label>
          <input
            {...formik.getFieldProps('FamilySpokenLanguage')}
            className={clsx(
              'form-control  mb-3 mb-lg-0',
              {
                'is-invalid':
                  formik.touched.FamilySpokenLanguage && formik.errors.FamilySpokenLanguage,
              },
              {
                'is-valid':
                  formik.touched.FamilySpokenLanguage && !formik.errors.FamilySpokenLanguage,
              }
            )}
            name='FamilySpokenLanguage'
            autoComplete='off'
            disabled={formik.isSubmitting}
            rows={1}
          />
          {formik.touched.FamilySpokenLanguage && formik.errors.FamilySpokenLanguage && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span className='alert-danger'>{formik.errors.FamilySpokenLanguage}</span>
              </div>
            </div>
          )}
        </div>
        <div className='col-md-6 col-12 my-3'>
          <label className=' form-label fs-6 fw-semibold text-muted'>
            {intl.formatMessage({id: 'KIDLING.MOTHER_TONGUE'})}2
          </label>
          <input
            {...formik.getFieldProps('Mothertongue2')}
            className={clsx(
              'form-control  mb-3 mb-lg-0',
              {'is-invalid': formik.touched.Mothertongue2 && formik.errors.Mothertongue2},
              {
                'is-valid': formik.touched.Mothertongue2 && !formik.errors.Mothertongue2,
              }
            )}
            name='Mothertongue2'
            autoComplete='off'
            disabled={formik.isSubmitting}
            rows={1}
          />
          {formik.touched.Mothertongue2 && formik.errors.Mothertongue2 && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span className='alert-danger'>{formik.errors.Mothertongue2}</span>
              </div>
            </div>
          )}
        </div>
        <div className='col-md-6 col-12 my-3'>
          <label className=' form-label fs-6 fw-semibold text-muted'>
            {intl.formatMessage({id: 'KIDLING.SECOND_LANGUAGE'})}2
          </label>
          <input
            {...formik.getFieldProps('SecondLanguage2')}
            className={clsx(
              'form-control  mb-3 mb-lg-0',
              {'is-invalid': formik.touched.SecondLanguage2 && formik.errors.SecondLanguage2},
              {
                'is-valid': formik.touched.SecondLanguage2 && !formik.errors.SecondLanguage2,
              }
            )}
            name='SecondLanguage2'
            autoComplete='off'
            disabled={formik.isSubmitting}
            rows={1}
          />
          {formik.touched.SecondLanguage2 && formik.errors.SecondLanguage2 && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span className='alert-danger'>{formik.errors.SecondLanguage2}</span>
              </div>
            </div>
          )}
        </div>
        <div className='col-md-6 col-12 my-3'>
          <label className=' form-label fs-6 fw-semibold text-muted'>
            {intl.formatMessage({id: 'KIDLING.COUNTRY'})}
          </label>
          <Select
            name='country'
            options={options}
            value={formik.values.country}
            onChange={(val: any) => {
              formik.setFieldValue('country', val)
            }}
          />
          {/* <select
            options
            {...formik.getFieldProps('Country')}
            className={clsx(
              'form-control  mb-3 mb-lg-0',
              {'is-invalid': formik.touched.Country && formik.errors.Country},
              {
                'is-valid': formik.touched.Country && !formik.errors.Country,
              }
            )}
            name='CountryId'
            autoComplete='off'
            disabled={formik.isSubmitting  }
          >
            <option value=''>{intl.formatMessage({id: 'COMMON.SELECT'})}..</option>
            {religions.map((s: any) => (
              <option value={s.CountryId}>{s.countryName}</option>
            ))}
          </select> */}
          {formik.touched.CountryId && formik.errors.CountryId && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span className='alert-danger'>{formik.errors.CountryId}</span>
              </div>
            </div>
          )}
        </div>
        <div className='col-md-6 col-12 my-3'>
          <label className=' form-label fs-6 fw-semibold text-muted'>
            {intl.formatMessage({id: 'KIDLING.CITY'})}
          </label>
          <input
            {...formik.getFieldProps('city')}
            className={clsx(
              'form-control  mb-3 mb-lg-0',
              {'is-invalid': formik.touched.city && formik.errors.city},
              {
                'is-valid': formik.touched.city && !formik.errors.city,
              }
            )}
            name='city'
            autoComplete='off'
            disabled={formik.isSubmitting}
            rows={1}
          />
          {formik.touched.city && formik.errors.city && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span className='alert-danger'>{formik.errors.city}</span>
              </div>
            </div>
          )}
        </div>
        <div className='col-md-6 col-12 my-3'>
          <label className=' form-label fs-6 fw-semibold text-muted'>
            {intl.formatMessage({id: 'PARENT.StreetName'})}
          </label>
          <input
            {...formik.getFieldProps('street')}
            className={clsx(
              'form-control  mb-3 mb-lg-0',
              {'is-invalid': formik.touched.street && formik.errors.street},
              {
                'is-valid': formik.touched.street && !formik.errors.street,
              }
            )}
            name='street'
            autoComplete='off'
            disabled={formik.isSubmitting}
            rows={1}
          />
          {formik.touched.street && formik.errors.street && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span className='alert-danger'>{formik.errors.street}</span>
              </div>
            </div>
          )}
        </div>
        <div className='col-md-6 col-12 my-3'>
          <label className=' form-label fs-6 fw-semibold text-muted'>
            {intl.formatMessage({id: 'KIDLING.HOUSE_NUMBER'})}
          </label>
          <input
            {...formik.getFieldProps('houseNumber')}
            className={clsx(
              'form-control  mb-3 mb-lg-0',
              {'is-invalid': formik.touched.houseNumber && formik.errors.houseNumber},
              {
                'is-valid': formik.touched.houseNumber && !formik.errors.houseNumber,
              }
            )}
            name='houseNumber'
            autoComplete='off'
            disabled={formik.isSubmitting}
            rows={1}
          />
          {formik.touched.houseNumber && formik.errors.houseNumber && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span className='alert-danger'>{formik.errors.houseNumber}</span>
              </div>
            </div>
          )}
        </div>
        <div className='col-md-6 col-12 my-3'>
          <label className=' form-label fs-6 fw-semibold text-muted'>
            {intl.formatMessage({id: 'KIDLING.RECOMMENDED_BY'})}
          </label>
          <input
            {...formik.getFieldProps('RecommendedBy')}
            className={clsx(
              'form-control  mb-3 mb-lg-0',
              {'is-invalid': formik.touched.RecommendedBy && formik.errors.RecommendedBy},
              {
                'is-valid': formik.touched.RecommendedBy && !formik.errors.RecommendedBy,
              }
            )}
            name='RecommendedBy'
            autoComplete='off'
            disabled={formik.isSubmitting}
            rows={1}
          />
          {formik.touched.RecommendedBy && formik.errors.RecommendedBy && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span className='alert-danger'>{formik.errors.RecommendedBy}</span>
              </div>
            </div>
          )}
        </div>
        <div className='col-md-6 col-12 my-3'>
          <label className=' form-label fs-6 fw-semibold text-muted'>
            {intl.formatMessage({id: 'KIDLING.OTHER'})}
          </label>
          <input
            {...formik.getFieldProps('Other')}
            className={clsx(
              'form-control  mb-3 mb-lg-0',
              {'is-invalid': formik.touched.Other && formik.errors.Other},
              {
                'is-valid': formik.touched.Other && !formik.errors.Other,
              }
            )}
            name='Other'
            autoComplete='off'
            disabled={formik.isSubmitting}
            rows={1}
          />
          {formik.touched.Other && formik.errors.Other && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span className='alert-danger'>{formik.errors.Other}</span>
              </div>
            </div>
          )}
        </div>
        <div className='col-md-6 col-12 my-3'>
          <label className=' form-label fs-6 fw-semibold text-muted'>
            {intl.formatMessage({id: 'KIDLING.KID_IS_WITH_SINGLE_PARENT'})}
          </label>
          <input
            {...formik.getFieldProps('KidIsWithSingleParent')}
            className={clsx(
              'form-control  mb-3 mb-lg-0',
              {
                'is-invalid':
                  formik.touched.KidIsWithSingleParent && formik.errors.KidIsWithSingleParent,
              },
              {
                'is-valid':
                  formik.touched.KidIsWithSingleParent && !formik.errors.KidIsWithSingleParent,
              }
            )}
            name='KidIsWithSingleParent'
            autoComplete='off'
            disabled={formik.isSubmitting}
            rows={1}
          />
          {formik.touched.KidIsWithSingleParent && formik.errors.KidIsWithSingleParent && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span className='alert-danger'>{formik.errors.KidIsWithSingleParent}</span>
              </div>
            </div>
          )}
        </div>
      </div>
      <button type='submit' disabled={formik.isSubmitting} className='btn ms-auto custom__button'>
        {!formik.isSubmitting &&
          intl.formatMessage({
            id: 'KIDLING.NEXT',
          })}
        {formik.isSubmitting && (
          <span className='indicator-progress' style={{display: 'block'}}>
            {intl.formatMessage({id: 'KIDLING.HOME.PLEASE_WAIT'})}...
            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
          </span>
        )}
      </button>
    </form>
  )
}

export default ChildStep
