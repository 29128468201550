/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useMemo} from 'react'
import {useIntl} from 'react-intl'
import _columns from '../_columns'
import {SleepsHeader} from './header/SleepsHeader'
import {useAppContext} from '../../../../_metronic/layout/core/AppContextProvider'
import TableCardsContainer from '../../../general-components/table-cards-container/TableCardsContainer'
import useSleepTimes from '../../../hooks/useSleepTimes'

function Sleeptimes() {
  const {internalPagesDatesFilters} = useAppContext()
  const intl = useIntl()
  const columns: any = useMemo(() => _columns(), [])
  const {readSleeptimes, data} = useSleepTimes()

  useEffect(() => {
    readSleeptimes()
  }, [internalPagesDatesFilters])

  return (
    <div className='container'>
      <SleepsHeader title={intl.formatMessage({id: 'KIDLING.MENU.SLEEPS'})} />
      <div className='mt-4'>
        <TableCardsContainer data={data} columns={columns} />
      </div>
    </div>
  )
}

export default Sleeptimes
