/* eslint-disable react-hooks/exhaustive-deps */
import moment from 'moment'
import {getAllKidsAssessments} from '../../controller/assessment'
import {useEffect, useState} from 'react'
import {useAuth} from '../views/auth'
import {useAppContext} from '../../_metronic/layout/core/AppContextProvider'

const useAssessment = () => {
  const {
    allKidsAssessments,
    setAllKidsAssessments,
    setGlobalLoader,
    internalPagesFilters,
    internalPagesDatesFilters,
    globalLoader,
    setInternalPagesDatesFilters,
    setInternalPagesFilters,
  } = useAppContext()
  const [data, setData] = useState([])
  const {currentUser} = useAuth()
  const [date, setDate] = useState<any>({})
  const [clicked, setClicked] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')

  useEffect(() => {
    filterData()
  }, [searchTerm])

  useEffect(() => {
    if (clicked && !globalLoader) {
      readAssessments()
      setClicked(false)
    }
  }, [clicked])

  useEffect(() => {
    const newFilters: any = {...internalPagesDatesFilters}

    newFilters.assessment = {
      ...newFilters.assessment,
      From: moment(date?.startDate).startOf('day').format('yyyy-MM-DDTHH:mm:ss'),
      To: moment(date?.endDate).endOf('day').format('yyyy-MM-DDTHH:mm:ss'),
    }

    setInternalPagesDatesFilters(newFilters)
  }, [date])

  useEffect(() => {
    if (!globalLoader) readAssessments()
  }, [internalPagesDatesFilters])
  useEffect(() => {
    let temp: any = []
    allKidsAssessments?.forEach((item: any) => {
      let KidsAssessments: any = []
      item.assessments?.map((item: any) =>
        KidsAssessments.push({
          id: item.assessmentItemId,
          title: item.assessmentItemTitle,
          date: `${moment(item.kidAssessmentDate).format('DD.MM.yyyy')} `,
          desc: item.kidAssessmentEducatorNotes,
          tag: item.assessmentItemRateName,
          assessmentName: item.assessmentName,
          assessmentCategoryName: item.assessmentCategoryName,
          assessmentSubCategoryName: item.assessmentSubCategoryName,
        })
      )
      let myClasses: any = []
      item.classes?.forEach((cc: any) => {
        if (cc.classesName) myClasses = [...myClasses, cc.classesName]
      })

      temp.push({
        image: item.imagePath,
        name: `${item.firstName} ${item.lastName}`,
        classes: [...new Set(myClasses)],
        subItems: KidsAssessments,
      })
    })
    setData(temp)
  }, [allKidsAssessments])

  const readAssessments = async () => {
    let filter = '1=1'
    setGlobalLoader(true)

    if (internalPagesFilters.assessment.searchTerm)
      filter = internalPagesFilters.assessment.searchTerm

    const res = await getAllKidsAssessments(
      filter,
      currentUser?.contact?.contactId || 0,
      internalPagesDatesFilters.assessment.From
        ? moment(internalPagesDatesFilters.assessment.From).format('yyyy-MM-DDTHH:mm:ss')
        : moment().startOf('year').format('yyyy-MM-DDTHH:mm:ss'),
      internalPagesDatesFilters.assessment.To
        ? moment(internalPagesDatesFilters.assessment.To).format('yyyy-MM-DDTHH:mm:ss')
        : moment().endOf('year').format('yyyy-MM-DDTHH:mm:ss')
    )

    setAllKidsAssessments(res)
    setGlobalLoader(false)
  }
  const handleDateChange = (selected: any) => {
    setDate(selected[0])
  }
  const filterData = async () => {
    const newFilters: any = {...internalPagesFilters}

    newFilters.assessment = {
      ...newFilters.assessment,
      searchTerm: `kidName.toLower().contains("${searchTerm.toLowerCase().trim()}")`,
    }
    setInternalPagesFilters(newFilters)
  }

  return {data, readAssessments, handleDateChange, clicked, setClicked, searchTerm, setSearchTerm}
}

export default useAssessment
