import React, {FC, useState} from 'react'
import {useIntl} from 'react-intl'

interface Props {
  text: string
  count: number
}
const ReadMore: FC<Props> = ({text, count}) => {
  const [isReadMore, setIsReadMore] = useState(true)
  const intl = useIntl()
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore)
  }
  return (
    <span className='mb-0 '>
      <p
        className='text-gray-400 fw-semibold mb-0'
        dangerouslySetInnerHTML={{__html: isReadMore ? text.slice(0, count) : text}}
      ></p>
      {text.length > count && (
        <span onClick={toggleReadMore} className=' text-primary cursor-pointer'>
          {isReadMore
            ? intl.formatMessage({id: 'KIDLING.READ_MORE'})
            : intl.formatMessage({id: 'KIDLING.SHOW_LESS'})}
        </span>
      )}
    </span>
  )
}

export default ReadMore
