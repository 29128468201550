/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useMemo} from 'react'
import {useIntl} from 'react-intl'
import _columns from '../_columns'
import {useAppContext} from '../../../../_metronic/layout/core/AppContextProvider'
import TableCardsContainer from '../../../general-components/table-cards-container/TableCardsContainer'
import {RequestsHeader} from './header/RequestsHeader'
import useRequests from '../../../hooks/useRequests'

function KidRequest() {
  const intl = useIntl()
  const {globalLoader, internalPagesDatesFilters, delivered} = useAppContext()
  const columns: any = useMemo(() => _columns(), [])
  const {readRequests, data} = useRequests()

  useEffect(() => {
    if (!globalLoader && internalPagesDatesFilters.requests.From) readRequests()
  }, [internalPagesDatesFilters, delivered])
  return (
    <div className='container  '>
      <RequestsHeader title={intl.formatMessage({id: 'KIDLING.MENU.REQUESTS'})} />
      <TableCardsContainer data={data} columns={columns} />
    </div>
  )
}

export default KidRequest
