import React, {ReactElement, FC} from 'react'
import './styles.scss'
import {Link} from 'react-router-dom'
import {useThemeMode} from '../../../_metronic/partials/layout/theme-mode/ThemeModeProvider'
type TimelineItem = {
  icon: string
  title: string
  subtitle: string
  iconColor: string
  backgroundColor: string
  tag?: string
  tagIcon?: string
}
interface Props {
  title: string
  subtitle?: string
  link?: string
  items: TimelineItem[]
}
const TimeLine: FC<Props> = ({title, subtitle, link, items}): ReactElement => {
  const company = window.location.pathname.split('/')[1]
  const {mode} = useThemeMode()
  return (
    <div className='card shadow mb-5 mb-xl-8 h-100'>
      <div className='card-header align-items-center border-0 mt-4'>
        <h3 className='card-title align-items-start flex-column'>
          <Link
            to={company ? `/${company}/${link}` : `/kidling/${link}`}
            className={`  ${mode === 'dark' ? 'text-dark' : 'text-black '}`}
          >
            <span className={`fw-bold mb-2 ${mode === 'dark' && 'text-dark'}`}>{title}</span>
          </Link>

          <span className={`  fw-semibold fs-7  ${mode === 'dark' ? 'text-dark' : 'text-muted '}`}>
            {subtitle}
          </span>
        </h3>
      </div>

      <div className='card-body pt-5'>
        <div className='timeline-label'>
          {items?.map((item: TimelineItem, index: number) => (
            <div className='timeline-item' key={index}>
              <div
                className='timeline-badge w-40px h-40px   '
                style={{marginLeft: '-20px', backgroundColor: item.backgroundColor}}
              >
                <i className={item.icon} style={{color: item.iconColor}}></i>
              </div>
              <div className='d-flex flex-column ps-3 mt-2 w-100'>
                <div className='d-flex align-items-center w-100 justify-content-between'>
                  <h5 className='m-0 p-0  '>{item.title}</h5>
                  {item.tag && (
                    <div className='d-flex align-items-center'>
                      <p className='m-0 p-0 me-2'>{item.tag}</p>
                      <i className={item.tagIcon} style={{color: '#ccc'}}></i>
                    </div>
                  )}
                </div>

                <div
                  className={`fw-mormal timeline-content  ${
                    mode === 'dark' ? 'text-dark' : 'text-muted '
                  }`}
                >
                  {item.subtitle}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default TimeLine
