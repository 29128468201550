import {useEffect} from 'react'
import {Navigate, Routes} from 'react-router-dom'
import {useAuth} from './core/Auth'

export function Logout() {
  const {logout} = useAuth()
  const company = window.location.pathname.split('/')[1]

  useEffect(() => {
    const dologout = async () => {
      logout()
      document.location.reload()
    }
    dologout()
  }, [logout])

  return (
    <Routes>
      <Navigate
        to={company ? `${company}/auth` : 'kidling/auth'}
        state={{company: company ?? 'kidling'}}
        replace={true}
      />
    </Routes>
  )
}
