import React, {FC, ReactElement} from 'react'
import './styles.scss'
import {useIntl} from 'react-intl'
interface Props {
  value: string
  onChange: (v: string) => void
}
const SearchBox: FC<Props> = ({value, onChange}): ReactElement => {
  const intl = useIntl()
  return (
    <div className='d-flex align-items-center form-control form-control-solid search__input__container py-2 mx-auto '>
      <i className='fa-solid fa-magnifying-glass'></i>
      <input
        type='text'
        className=' bg-transparent border-0 outline-0  px-2 flex-grow-1  search__input text-body'
        name='search'
        placeholder={intl.formatMessage({id: 'KIDLING.CHAT_TYPE_TO_SEARCH'})}
        value={value}
        onChange={(e: any) => onChange(e.target.value)}
      />
      {value && value !== '' && (
        <button type='button' className='btn p-0 m-0' onClick={() => onChange('')}>
          <i className='fa-solid fa-xmark'></i>
        </button>
      )}
    </div>
  )
}

export default SearchBox
