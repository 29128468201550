/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useMemo} from 'react'
import _columns from '../_columns'
import {MoodHeader} from './header/MoodHeader'
import {useAppContext} from '../../../../_metronic/layout/core/AppContextProvider'
import TableCardsContainer from '../../../general-components/table-cards-container/TableCardsContainer'
import {useIntl} from 'react-intl'
import useMood from '../../../hooks/useMood'
function Moods() {
  const columns: any = useMemo(() => _columns(), [])
  const {internalPagesDatesFilters} = useAppContext()
  const intl = useIntl()
  const {readMoods, data} = useMood()

  useEffect(() => {
    readMoods()
  }, [internalPagesDatesFilters])

  return (
    <div className='container  '>
      <MoodHeader title={intl.formatMessage({id: 'KIDLING.HOME.MOODS'})} />
      <div className='mt-4'>
        <TableCardsContainer data={data} columns={columns} />
      </div>
    </div>
  )
}

export default Moods
