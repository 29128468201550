import {FC} from 'react'
import {ProtocolsSearchComponent} from './ProtocolsSearchComponent'
import {ProtocolsToolbar} from './ProtocolsToolbar'
import moment from 'moment'
import CustomDateRangePicker from '../../../../general-components/date-range-picker/DateRangePicker'
import useProtocols from '../../../../hooks/useProtocols'

interface Props {
  title?: string
}
const ProtocolsHeader: FC<Props> = ({title}) => {
  const {handleDateChange} = useProtocols()
  return (
    <div className=' border-0    pt-6  w-100'>
      <div className='d-flex align-items-center justify-content-between'>
        {title && <h4 className='m-0 d-flex align-items-center h-100 '>{title}</h4>}
        <div className=''>
          <CustomDateRangePicker
            handleDateChange={handleDateChange}
            initialDate={{
              startDate: moment().startOf('year').toDate(),
              endDate: moment().endOf('year').toDate(),
            }}
          />
        </div>
      </div>

      <div className='d-flex flex-column-reverse flex-sm-row align-items-sm-center justify-content-between w-100 pb-8'>
        <div className='d-flex flex-row  w-100  w-md-auto'>
          <ProtocolsSearchComponent />
          <ProtocolsToolbar />
        </div>
      </div>
    </div>
  )
}

export {ProtocolsHeader}
