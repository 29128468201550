import {ProtocolsFilter} from './ProtocolsFilter'

const ProtocolsToolbar = () => {
  return (
    <div className=' w-md-auto' data-kt-user-table-toolbar='base'>
      <ProtocolsFilter />
    </div>
  )
}

export {ProtocolsToolbar}
