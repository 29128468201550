import React, {FC, ReactElement} from 'react'
import {
  DesktopDatePicker,
  DesktopDateTimePicker,
  DesktopTimePicker,
  MobileDatePicker,
  MobileDateTimePicker,
  MobileTimePicker,
} from '@mui/x-date-pickers'
import './styles.scss'
import {useMediaQuery} from 'react-responsive'
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider'
import {deDE} from '@mui/x-date-pickers/locales'
import {ThemeProvider, createTheme} from '@mui/material'
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs'
import 'dayjs/locale/es'
import 'dayjs/locale/de'
import {useLang} from '../../../_metronic/i18n/Metronici18n'
import {useAppContext} from '../../../_metronic/layout/core/AppContextProvider'
import {useThemeMode} from '../../../_metronic/partials/layout/theme-mode/ThemeModeProvider'
import dayjs from 'dayjs'
interface Props {
  dateTime?: boolean
  onAccept: any
  value?: Date
  timeOnly?: boolean
  disablePast?: boolean
  disabled?: boolean
  minDate?: Date
}
const ResponsiveDatePicker: FC<Props> = ({
  dateTime,
  onAccept,
  value,
  timeOnly,
  disablePast,
  disabled,
  minDate,
}): ReactElement => {
  const {companyMetaData} = useAppContext()
  const {mode} = useThemeMode()
  const locale = useLang()
  const isMobile = useMediaQuery({
    query: '(max-width: 1000px)',
  })
  const theme = createTheme(
    {
      palette: {
        mode: mode === 'dark' ? 'dark' : 'light',
        primary: {
          main:
            mode === 'dark'
              ? '#d1d1dd'
              : companyMetaData?.primaryColor
              ? companyMetaData?.primaryColor
              : '#8d4290',
        },
      },
    },
    deDE
  )

  return (
    <div className='w-100 '>
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale}>
          {dateTime ? (
            isMobile ? (
              <MobileDateTimePicker
                ampm={false}
                format='DD.MM.YYYY HH:mm'
                disabled={disabled}
                disablePast={disablePast}
                className={`datepicker  `}
                onChange={(date) => onAccept(dayjs(date).toDate())}
                value={dayjs(value)}
                minDateTime={minDate ? dayjs(minDate) : null}
              />
            ) : (
              <DesktopDateTimePicker
                ampm={false}
                format='DD.MM.YYYY HH:mm'
                disabled={disabled}
                disablePast={disablePast}
                className={`datepicker  `}
                onChange={(date) => onAccept(dayjs(date).toDate())}
                value={dayjs(value)}
                minDateTime={minDate ? dayjs(minDate) : null}
              />
            )
          ) : timeOnly ? (
            isMobile ? (
              <MobileTimePicker
                ampm={false}
                disabled={disabled}
                disablePast={disablePast}
                className={`datepicker  `}
                onChange={(date) => onAccept(dayjs(date).toDate())}
                value={dayjs(value)}
              />
            ) : (
              <DesktopTimePicker
                ampm={false}
                disabled={disabled}
                disablePast={disablePast}
                className={`datepicker  `}
                onChange={(date) => onAccept(dayjs(date).toDate())}
                value={dayjs(value)}
              />
            )
          ) : isMobile ? (
            <MobileDatePicker
              format='DD.MM.YYYY'
              disabled={disabled}
              disablePast={disablePast}
              className={`datepicker  `}
              onChange={(date) => onAccept(dayjs(date).toDate())}
              value={dayjs(value)}
              minDate={minDate ? dayjs(minDate) : null}
            />
          ) : (
            <DesktopDatePicker
              format='DD.MM.YYYY'
              disabled={disabled}
              disablePast={disablePast}
              className={`datepicker  `}
              onChange={(date) => onAccept(dayjs(date).toDate())}
              value={dayjs(value)}
              minDate={minDate ? dayjs(minDate) : null}
            />
          )}
        </LocalizationProvider>
      </ThemeProvider>
    </div>
  )
}

export default ResponsiveDatePicker
