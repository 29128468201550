import React, {FC, ReactElement, useState} from 'react'

import './styles.scss'
import {useAppContext} from '../../../_metronic/layout/core/AppContextProvider'
import useLongPress from '../../hooks/useLongPress'
import {useMediaQuery} from 'react-responsive'
import CustomImage from '../custom-image/CustomImage'
interface ImageProps {
  img: string
  desc?: string
  activateLongPress?: boolean
  KidMediaFileId?: number
  hideCheckbox?: boolean
}
const ImageWithEnlargeMedia: FC<ImageProps> = ({
  img,
  desc,
  KidMediaFileId,
  activateLongPress,
  hideCheckbox,
}): ReactElement => {
  const isMobile = useMediaQuery({
    query: '(max-width:1000px)',
  })

  const {selectedImages, setSelectedImages} = useAppContext()
  const [enlarge, setEnlarge] = useState(false)
  const handleClick = () => {
    !enlarge && setEnlarge(true)
  }
  const handleLongPress = () => {
    setSelectedImages((prev: any) => [
      ...prev,
      {id: KidMediaFileId, filePath: img?.split('FilePath=')[1]?.split('&OTP=')[0]},
    ])
  }
  const {action, handlers} = useLongPress({
    initialClick: handleClick,
    handleLongPress: activateLongPress ? handleLongPress : () => {},
  })
  const handleUncheck = () => {
    let selected: any[] = []
    const exists = selectedImages?.find((item: any) => item.id === KidMediaFileId)
    if (exists)
      selectedImages?.map((item: any) => {
        if (item.id !== KidMediaFileId) selected.push({id: item.id, filePath: img})
      })
    else {
      selected = [...selectedImages, {id: KidMediaFileId, filePath: img}]
    }
    setSelectedImages(selected)
  }

  return (
    <div className=' '>
      <div
        className={` ${
          enlarge
            ? 'full__image d-flex justify-content-center align-items-center  w-screen h-screen'
            : 'image__container '
        }`}
        // onClick={() => !enlarge && setEnlarge(true)}
        {...handlers}
      >
        {enlarge && (
          <button className='close__btn' onClick={() => setEnlarge(false)}>
            <i className='fa-solid fa-xmark'></i>
          </button>
        )}

        {!enlarge && (
          <button className='enlarge__btn' onClick={() => setEnlarge(true)}>
            <i className='fa-solid fa-expand'></i>
          </button>
        )}
        <div
          className={`${
            enlarge && 'slider__image__animation d-flex justify-content-center align-items-center'
          }`}
        >
          <CustomImage
            id={img}
            className={`${
              enlarge
                ? 'd-flex justify-content-center align-items-center object-fit-contain'
                : 'cursor-pointer rounded object-fit-cover mh-200px h-200px w-100'
            }`}
          />
          {/* <img
            src={img}
            alt={desc}
            className={`${
              enlarge
                ? 'd-flex justify-content-center align-items-center object-fit-contain'
                : 'cursor-pointer rounded object-fit-cover mh-200px h-200px w-100'
            }`}
          /> */}
        </div>
      </div>
      {isMobile && !hideCheckbox && !selectedImages?.some((i: any) => i.id === KidMediaFileId) && (
        <div className='form-check form-check-sm form-check-custom form-check-solid position-absolute image_checkbox_mobile'>
          <input
            className='form-check-input  '
            type='checkbox'
            checked={selectedImages?.some((i: any) => i.id === KidMediaFileId)}
            defaultChecked={true}
            name='notifications'
            onClick={handleUncheck}
          />
        </div>
      )}
      {!hideCheckbox && selectedImages?.some((i: any) => i.id === KidMediaFileId) && (
        <>
          <div className='checkbox__container' onClick={handleUncheck}></div>
          <div className='form-check form-check-lg form-check-custom form-check-solid position-absolute image_checkbox'>
            <input
              className='form-check-input  rounded-circle'
              type='checkbox'
              checked={selectedImages?.some((i: any) => i.id === KidMediaFileId)}
              defaultChecked={true}
              name='notifications'
              onClick={handleUncheck}
            />
          </div>
        </>
      )}
    </div>
  )
}

export default ImageWithEnlargeMedia
