import {AssessmentsFilter} from './AssessmentsFilter'

const AssessmentsToolbar = () => {
  return (
    <div className='d-flex justify-content-end ms-2 w-md-auto' data-kt-user-table-toolbar='base'>
      <AssessmentsFilter />
    </div>
  )
}

export {AssessmentsToolbar}
