import moment from 'moment'
import {getKidsAcivities} from '../../controller/activities'
import {useAuth} from '../views/auth'
import {useEffect, useState} from 'react'
import {useAppContext} from '../../_metronic/layout/core/AppContextProvider'

const useActivities = () => {
  const {
    kidsActivites,
    setKidsActivites,
    setGlobalLoader,
    internalPagesFilters,
    childFilter,
    setInternalPagesDatesFilters,
    internalPagesDatesFilters,
  } = useAppContext()
  const {currentUser} = useAuth()
  const [data, setData] = useState([])
  const [date, setDate] = useState<any>({})
  const [clicked, setClicked] = useState(false)
  const {searchTerm, setSearchTerm, setFilters, globalLoader}: any = useAppContext()

  useEffect(() => {
    filterData()
  }, [searchTerm])
  useEffect(() => {
    if (clicked && !globalLoader) {
      console.log('====================================')
      console.log('in clicked')
      console.log('====================================')
      readActivities()
      setClicked(false)
    }
  }, [clicked])
  useEffect(() => {
    const newFilters: any = {...internalPagesDatesFilters}

    newFilters.activity = {
      ...newFilters.activity,
      From: moment(date?.startDate).startOf('day').format('yyyy-MM-DDTHH:mm:ss'),
      To: moment(date?.endDate).endOf('day').format('yyyy-MM-DDTHH:mm:ss'),
    }

    setInternalPagesDatesFilters(newFilters)
  }, [date])

  useEffect(() => {
    let temp: any = []
    kidsActivites?.map((item: any) => {
      let activities: any = []
      item.kidActivities?.map((activity: any) =>
        activities.push({
          id: activity.kidActivityId,
          title: activity.activityName,
          desc: '',
          subtitle1: moment(activity.dateTime).format('DD.MM.yyyy'),
        })
      )
      temp.push({
        image: item.imagePath,
        name: `${item.firstName} ${item.lastName}`,
        subItems: activities,
      })
    })
    setData(temp)
  }, [kidsActivites])

  const filterData = async () => {
    if (searchTerm.trim() !== '') {
      setFilters(`kidName.toLower().contains("${searchTerm}")`)
      setClicked(false)
    }
  }
  const readActivities = async () => {
    let filter: string = ''
    setGlobalLoader(true)
    if (childFilter) filter += 'kidId=' + childFilter.kidId
    else if (currentUser?.kids && currentUser?.kids.length > 0)
      filter += 'kidId=' + currentUser?.kids[0]

    if (internalPagesFilters.activity.searchTerm) filter = filter + ' AND '
    if (internalPagesFilters.activity.searchTerm)
      filter = filter + internalPagesFilters.activity.searchTerm

    const res = await getKidsAcivities(
      filter,
      currentUser?.contact?.contactId || 0,
      internalPagesDatesFilters.activity.From
        ? moment(internalPagesDatesFilters.activity.From).format('yyyy-MM-DDTHH:mm:ss')
        : moment().startOf('day').format('yyyy-MM-DDTHH:mm:ss'),
      internalPagesDatesFilters.activity.To
        ? moment(internalPagesDatesFilters.activity.To).format('yyyy-MM-DDTHH:mm:ss')
        : moment().endOf('day').format('yyyy-MM-DDTHH:mm:ss')
    )

    setKidsActivites(res)
    setGlobalLoader(false)
  }

  const handleDateChange = (selected: any) => {
    setDate(selected[0])
  }
  return {readActivities, handleDateChange, data, setSearchTerm, searchTerm, setClicked, clicked}
}
export default useActivities
