import {useIntl} from 'react-intl'
import {TitleHeader} from '../../../general-components/TitleHeader'
import {FoodMenu} from './header/FoodMenu'

function Mealtimes() {
  const intl = useIntl()

  return (
    <div className='container '>
      <TitleHeader title={intl.formatMessage({id: 'KIDLING.MENU.FOOD'})} showChildFilter />
      <FoodMenu />
    </div>
  )
}

export default Mealtimes
