/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useRef} from 'react'
import FullCalendar from '@fullcalendar/react'
import listPlugin from '@fullcalendar/list'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import rrulePlugin from '@fullcalendar/rrule'
import interactionPlugin from '@fullcalendar/interaction'
import {useLang} from '../../../../_metronic/i18n/Metronici18n'
import allLocales from '@fullcalendar/core/locales-all'
import {CalenderHeader} from './header/CalenderHeader'
import {useAppContext} from '../../../../_metronic/layout/core/AppContextProvider'
import EventCell from './EventCell'
import {UsersListLoading} from '../../../general-components/loading/UsersListLoading'
import useCalendar from '../../../hooks/useCalendar'

function Calender() {
  const {
    readEvents,
    readHolidayesAndDaysOff,
    triggerDateChanged,
    readEventsCat,
    formattedEvents,
    handleDateSelect,
  } = useCalendar()
  const {calendarFilter, globalLoader} = useAppContext()
  const locale = useLang()
  const calendarRef = useRef<any>()

  useEffect(() => {
    if (calendarFilter && !globalLoader) {
      readEvents()
      readHolidayesAndDaysOff()
    }
  }, [calendarFilter])

  useEffect(() => {
    readEventsCat()
    readHolidayesAndDaysOff()
  }, [calendarFilter])

  useEffect(() => {
    if (calendarRef.current) calendarRef.current.render()
  }, [formattedEvents, calendarRef])

  return (
    <div className='p-4 p-sm-8 p-md-16'>
      <div className='mb-4'>
        <CalenderHeader />
      </div>

      <FullCalendar
        ref={calendarRef}
        plugins={[rrulePlugin, dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin]}
        headerToolbar={{
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek,timeGridDay,listDay',
        }}
        slotMinTime='07:00'
        slotMaxTime='20:00'
        initialView='timeGridWeek'
        allDaySlot={false}
        weekends={true}
        editable={false}
        selectable={true}
        selectMirror={false}
        dayMaxEvents={false}
        initialEvents={formattedEvents}
        events={formattedEvents}
        eventContent={EventCell}
        select={handleDateSelect}
        locale={locale}
        locales={allLocales}
        firstDay={1}
        nowIndicator={true}
        datesSet={triggerDateChanged}
      />

      {globalLoader && <UsersListLoading />}
    </div>
  )
}

export default Calender
