/* eslint-disable react-hooks/exhaustive-deps */
import {Outlet, Route, Routes, useNavigate} from 'react-router-dom'
import Protocol from './components/Protocol'
import {useEffect} from 'react'
import {useAppContext} from '../../../_metronic/layout/core/AppContextProvider'
import {KTCard} from '../../../_metronic/helpers'

const ProtocolPage = () => {
  const {mobileConfiguration} = useAppContext()
  const navigate = useNavigate()
  useEffect(() => {
    if (!mobileConfiguration?.KidProtocolRemark[0]?.showInParentApp) {
      navigate('/')
    }
  }, [mobileConfiguration])
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='*'
          element={
            <>
              <KTCard>
                <Protocol />
              </KTCard>
            </>
          }
        />
      </Route>
    </Routes>
  )
}

export default ProtocolPage
