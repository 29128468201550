/* eslint-disable react-hooks/exhaustive-deps */
import {FC, ReactElement, useEffect, useState} from 'react'
import {Link, useNavigate, useParams} from 'react-router-dom'
import {Avatar, Badge} from '@mui/material'
import moment from 'moment'
import {useLang} from '../../../../_metronic/i18n/Metronici18n'
import {SmallAvatar, StyledBadge} from '../../../general-components/BadgeAvatars/BadgeAvatars'
import {useChatContext} from '../../../../store/context/ChatProvider'
import {useAuth} from '../../auth'
import {useThemeMode} from '../../../../_metronic/partials/layout/theme-mode/ThemeModeProvider'

interface Props {
  chats: any
}
const PrivateChats: FC<Props> = ({chats}): ReactElement => {
  const {mode} = useThemeMode()
  const {onlineUsers, connection} = useChatContext()
  const company = window.location.pathname.split('/')[1]
  const [currentChats, setCurrentChats] = useState<any>([])
  const params = useParams()
  const lang = useLang()
  const {currentUser} = useAuth()
  const navigate = useNavigate()
  useEffect(() => {
    if (connection)
      connection.on('CreatedNewChat', function (response: any) {
        const newChat: any = {
          id: response?.chatGroupId,
          name: response?.chatGroupName,
          image: '/media/avatars/300-5.jpg',
          subtitle:
            response?.messages?.length > 0
              ? `${response?.messages[
                  response?.messages?.length - 1
                ]?.chatGroupMessageText?.substring(0, 15)} ${
                  response?.messages[response?.messages?.length - 1]?.chatGroupMessageText.length >
                  15
                    ? ' ...'
                    : ''
                }`
              : '',
          date: moment
            .duration(
              moment(moment().format('yyyy-MM-DDTHH:mm:ss')).diff(
                moment(response?.messages[response?.messages?.length - 1]?.dateTime)
              )
            )
            .locale(lang)
            .humanize(),
          unReadCount: response?.countOfNewMessages,
          users: response?.users,
        }

        const temp = [...currentChats]

        const exists = temp?.find((i: any) => Number(i?.id) === Number(response?.chatGroupId))

        if (!exists) {
          setCurrentChats([newChat, ...temp])
          navigate(
            company
              ? `/${company}/chat/private/${response?.chatGroupId}`
              : `/kidling/chat/private/${response?.chatGroupId}`
          )
        }
      })
  }, [currentChats, connection])

  useEffect(() => {
    let temp: any = []
    if (chats?.length > 0) {
      chats?.forEach((item: any) => {
        temp.push({
          id: item?.chatGroupId,
          name: item?.chatGroupName,
          image: '/media/avatars/300-5.jpg',
          subtitle:
            item?.messages?.length > 0
              ? `${item?.messages[item?.messages?.length - 1]?.chatGroupMessageText?.substring(
                  0,
                  15
                )} ${
                  item?.messages[item?.messages?.length - 1]?.chatGroupMessageText.length > 15
                    ? ' ...'
                    : ''
                }`
              : '',
          date:
            item?.messages?.length > 0
              ? moment
                  .duration(
                    moment(moment().format('yyyy-MM-DDTHH:mm:ss')).diff(
                      moment(item?.messages[item?.messages?.length - 1]?.dateTime)
                    )
                  )
                  .locale(lang)
                  .humanize()
              : null,
          unReadCount: item?.countOfNewMessages,
          users: item?.users,
        })
      })
      setCurrentChats(temp)
    }
  }, [chats])

  return (
    <div className=' pt-4'>
      <div className='menu menu-column menu-rounded menu-state-bg menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary mb-10  '>
        {currentChats?.map((item: any, index: number) => {
          const otherUser = item.users?.find((i: any) => i.usersId !== currentUser?.usersId)

          const otherUserId = otherUser?.usersId
          return (
            <Link
              to={
                company ? `/${company}/chat/private/${item.id}` : `/kidling/chat/private/${item.id}`
              }
              className={`fs-5 fw-bolder text-gray-900 text-hover-primary mb-2 pe-4 ps-8 py-0 chat__header__contact ${
                item.id === Number(params['*']?.split('/')[1] ?? -1) && 'selected__chat'
              }`}
              key={index}
            >
              <div className='d-flex flex-stack py-4'>
                <div className='d-flex align-items-center'>
                  {item.users?.length > 2 ? (
                    <Badge
                      overlap='circular'
                      anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                      badgeContent={<SmallAvatar alt='group1' />}
                    >
                      <Badge
                        overlap='circular'
                        anchorOrigin={{vertical: 'top', horizontal: 'left'}}
                        badgeContent={
                          <span
                            className={`badge badge-light-primary rounded-pill fs-7 ${
                              mode === 'dark' && 'bg-white text-black'
                            }`}
                          >
                            {item?.users?.length}
                          </span>
                        }
                      >
                        <Avatar alt='group2' />
                      </Badge>
                    </Badge>
                  ) : (
                    // <BadgeAvatars image1={item.users[0].imageUrl} image2={item.users[1].imageUrl} />
                    <StyledBadge
                      overlap='circular'
                      anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                      variant='dot'
                      color={onlineUsers?.includes(Number(otherUserId ?? -1)) ? 'success' : 'error'}
                    >
                      <Avatar alt={item.name.charAt(0).toUpperCase()} src={otherUser?.imageUrl} />
                    </StyledBadge>
                  )}

                  <div className='ms-5 w-75'>
                    <div className='d-flex align-items-start'>
                      <span className=' '>
                        {item?.users?.length > 2 ? item.name : otherUser?.usersName}
                      </span>
                      {/* {item?.users?.length > 2 && (
                        <Badge
                          overlap='circular'
                          anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                          badgeContent={
                            <span className='   badge badge-light-primary rounded-pill  '>
                              <i className='fa-solid fa-user fs-7  '></i>
                            </span>
                          }
                        >
                          <span className='   badge badge-light-primary rounded-pill fs-2  '>
                            {item?.users?.length}
                          </span>
                        </Badge>
                        
                      )} */}
                    </div>
                    <div className='fw-normal text-body fs-6 subtitle'>
                      {item?.users?.length > 2 ? item.subtitle : item?.name}
                    </div>
                  </div>
                </div>

                <div className='d-flex flex-column align-items-end ms-2'>
                  <span className='fw-normal text-body fs-7 mb-1 date'>{item.date}</span>
                  {item.unReadCount > 0 && (
                    <span
                      className={`badge badge-sm badge-circle badge-primary fs-7 ${
                        mode === 'dark' && 'bg-white text-black'
                      }`}
                    >
                      {item.unReadCount}
                    </span>
                  )}
                </div>
              </div>
            </Link>
          )
        })}
      </div>
    </div>
  )
}

export default PrivateChats
