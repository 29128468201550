/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import {FC, useEffect} from 'react'
import {List} from '../views/documents/List'
import {KTCard} from '../../_metronic/helpers'
import {useAppContext} from '../../_metronic/layout/core/AppContextProvider'
import {useNavigate} from 'react-router-dom'

const Documents: FC = () => {
  const {mobileConfiguration} = useAppContext()
  const navigate = useNavigate()
  useEffect(() => {
    if (!mobileConfiguration?.Attachment[0]?.showInParentApp) {
      navigate('/')
    }
  }, [mobileConfiguration])

  return (
    <KTCard>
      <List />
    </KTCard>
  )
}

export {Documents}
