import {useIntl} from 'react-intl'
import {AssessmentsHeader} from './header/AssessmentsHeader'
import AssessmentAccordions from './AssessmentAccordions'
import useAssessment from '../../../hooks/useAssessment'

function Assessments() {
  const intl = useIntl()
  const {data} = useAssessment()
  return (
    <div className='container  '>
      <AssessmentsHeader title={intl.formatMessage({id: 'KIDLING.HOME.ASSESSMENT'})} />
      <div className='mt-4'>
        <AssessmentAccordions data={data} />
      </div>
    </div>
  )
}

export default Assessments
