import moment from 'moment'
import {getMobileGallary} from '../../controller/kids'
import {groupBy} from '../utils/common'
import {useAppContext} from '../../_metronic/layout/core/AppContextProvider'
import {useAuth} from '../views/auth'
import {useEffect, useState} from 'react'
import {useSearchParams} from 'react-router-dom'

const useMedia = () => {
  const [data, setData] = useState<any>({})

  const {
    globalLoader,
    setGlobalLoader,
    selectedImages,
    internalPagesFilters,
    internalPagesDatesFilters,
    setInternalPagesFilters,
  } = useAppContext()
  const {currentUser}: any = useAuth()
  const kids = currentUser?.kids || []
  const [searchParams] = useSearchParams()
  const [clicked, setClicked] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')

  useEffect(() => {
    filterData()
  }, [searchTerm])

  useEffect(() => {
    if (clicked) {
      readData()
      setClicked(false)
    }
  }, [clicked])

  const readData = async () => {
    setGlobalLoader(true)
    let filter = ''
    for (const kid of kids) {
      if (filter !== '') filter += ' OR '
      filter += 'kidId=' + kid.kidId
    }
    if (filter === '') filter = 'kidId=-1'
    filter = '( ' + filter + ' )'
    // allowed images: "png", "webp", "jpg", "jpeg"
    filter += ' AND confidential=false'

    const tags = searchParams.getAll('tag')
    let params = ''

    tags?.map((i: any) => {
      params += ` AND  hashtags.any(x => x.hashtagName="${i}")`
    })
    if (tags) filter += params

    if (internalPagesFilters?.media?.searchTerm)
      filter += ` AND ${internalPagesFilters?.media?.searchTerm}`
    if (internalPagesDatesFilters?.media.From && internalPagesDatesFilters?.media?.To) {
      filter += ` AND createdDate>="${moment(internalPagesDatesFilters?.media?.From).format(
        'yyyy-MM-DDTHH:mm:ss'
      )}" AND createdDate<="${moment(internalPagesDatesFilters?.media?.To).format(
        'yyyy-MM-DDTHH:mm:ss'
      )}"`
    }
    const resp = await getMobileGallary(filter, true, false)

    if (!!resp) {
      const grouped = groupBy(resp, 'createdDate')

      setData(grouped)
    }

    setGlobalLoader(false)
  }
  const filterData = async () => {
    const newFilters: any = {...internalPagesFilters}

    newFilters.media = {
      ...newFilters.media,
      searchTerm: `kidName.toLower().contains("${searchTerm.toLowerCase().trim()}")`,
    }

    setInternalPagesFilters(newFilters)
  }
  return {readData, data, setClicked, clicked, searchTerm, setSearchTerm}
}

export default useMedia
