import {FC, ReactElement} from 'react'
import {useIntl} from 'react-intl'
import {Modal, ModalHeader, ModalBody} from 'reactstrap'
import AvatarWithLetters from '../../../../general-components/avatar-with-letters'

interface Props {
  modal: {show: boolean; data: any}
  setModal: any
}
const ReciepiantsModal: FC<Props> = ({modal, setModal}): ReactElement => {
  const intl = useIntl()

  const close = () => {
    setModal({show: false, data: null})
  }

  return (
    <Modal isOpen={modal.show} toggle={close} centered>
      <ModalHeader toggle={close}>{intl.formatMessage({id: 'COMMON.RECIPIENTS'})}</ModalHeader>
      <ModalBody>
        <div className='row'>
          {modal?.data?.map((teacher: any, index: number) => (
            <div className='col-6' key={index}>
              <div className='d-flex flex-column align-items-center border m-1 p-2'>
                <AvatarWithLetters
                  verysmall
                  rounded
                  alt={teacher?.usersName?.charAt(0).toUpperCase()}
                  image={teacher?.imagePath}
                />
                <h6 className='mt-1'>{teacher?.usersName}</h6>
              </div>
            </div>
          ))}
        </div>
      </ModalBody>
    </Modal>
  )
}

export default ReciepiantsModal
