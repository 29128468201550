/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {useAppContext} from '../../../../../../_metronic/layout/core/AppContextProvider'
import SweetAlert from 'react-bootstrap-sweetalert'
import ErrorText from '../../../../../general-components/error-text/ErrorText'
import {useMediaQuery} from 'react-responsive'
import {useNavigate} from 'react-router-dom'
import SearchBox from '../../../../../general-components/search-textbox/SearchBox'

function DeleteTableHeader({getAllMessages, data}: any) {
  const navigate = useNavigate()
  const company = window.location.pathname.split('/')[1]
  const isMobile = useMediaQuery({
    query: '(max-width:776px)',
  })
  const intl = useIntl()
  const {messagesFilters, setMessagesFilters} = useAppContext()
  const [showDelete, setShowDelete] = useState(false)
  const [filterCheck] = useState('')
  const [searchTerm, setSearchTerm] = useState('')
  const [clicked, setClicked] = useState(false)

  const filterData = async () => {
    const newFilters: any = {...messagesFilters}

    newFilters.deleted = {
      ...newFilters.deleted,
      searchTerm: `notificationTitle.toLower().contains("${searchTerm.toLowerCase().trim()}")`,
    }

    setClicked(false)

    setMessagesFilters(newFilters)
  }

  useEffect(() => {
    filterData()
    if (searchTerm === '') setClicked(true)
  }, [searchTerm])

  useEffect(() => {
    if (clicked || filterCheck) {
      let filter: string = ''
      if (!filterCheck) filter = '1=1'
      if (filterCheck) {
        switch (filterCheck) {
          case 'read':
            filter = `readDateTime!=null`
            break
          case 'unread':
            filter = `readDateTime=null`
            break
          case 'starred':
            filter = `pinned=true`
            break
          case 'unstarred':
            filter = `pinned=false`
            break
          default:
            filter = '1=1'
            break
        }
      }
      const temp = `${filter}&${messagesFilters.deleted.searchTerm ?? '1=1'}`

      getAllMessages(temp)
      setClicked(false)
    }
  }, [clicked, filterCheck])

  useEffect(() => {
    const handleSubmit = (event: any) => {
      if (event.keyCode === 13) {
        setClicked(true)
      }
    }
    window.addEventListener('keydown', handleSubmit)

    return () => {
      window.removeEventListener('keydown', handleSubmit)
      setClicked(false)
    }
  }, [])

  const handleDelete = async () => {
    // const data = {ids: selected, notificationStatusId: 3}
    // const resp = await updateMessages(data)
    // if (resp) {
    //   readMessages()
    //   toast.success(intl.formatMessage({id: 'COMMON.SUCCESS'}))
    // } else {
    //   toast.error(intl.formatMessage({id: 'COMMON.FAIL'}))
    // }
  }

  return (
    <div className='d-flex align-items-center py-5 gap-2 gap-md-5 '>
      {isMobile ? (
        <div className='w-100'>
          <div className='d-flex align-items-center justify-content-between flex-wrap gap-2 w-100'>
            <div
              className='d-flex align-items-center position-relative form-control form-control-sm form-control-solid py-0 '
              style={{width: '85%'}}
            >
              <i className='fa-solid fa-magnifying-glass text-muted'></i>
              <input
                type='text'
                data-kt-inbox-listing-filter='search'
                className='form-control form-control-sm form-control-solid mw-100 border-0  ps-4'
                placeholder={intl.formatMessage({id: 'COMMON.SEARCH'})}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
            <a
              href='#!'
              className='btn btn-sm btn-icon btn-color-primary btn-light btn-active-light-primary d-lg-none'
              data-bs-toggle='tooltip'
              data-bs-dismiss='click'
              data-bs-placement='top'
              title='Toggle inbox menu'
              id='kt_inbox_aside_toggle'
            >
              <i className='fa-solid fa-bars fs-3 m-0'></i>
            </a>
          </div>

          <button
            className='btn btn-primary btn-active-light-primary mt-4 px-2 py-2 w-100 '
            onClick={() =>
              navigate(company ? `/${company}/messages/compose` : '/kidling//messages/compose')
            }
          >
            {intl.formatMessage({id: 'KIDLING.HOME.NEW_MESSAGE'})}
          </button>
        </div>
      ) : (
        <div className='d-flex align-items-center justify-content-between'>
          <div className='d-flex align-items-center gap-2  min-w-200px min-w-lg-250px min-w-xxl-500px'>
            <SearchBox value={searchTerm} onChange={setSearchTerm} />
            <a
              href='#!'
              className='btn btn-sm btn-icon btn-color-primary btn-light btn-active-light-primary d-lg-none'
              data-bs-toggle='tooltip'
              data-bs-dismiss='click'
              data-bs-placement='top'
              title='Toggle inbox menu'
              id='kt_inbox_aside_toggle'
            >
              <i className='fa-solid fa-bars fs-3 m-0'></i>
            </a>
          </div>
        </div>
      )}
      <SweetAlert
        show={showDelete}
        warning
        showCancel
        confirmBtnText={intl.formatMessage({id: 'COMMON.YES'})}
        cancelBtnText={intl.formatMessage({id: 'COMMON.CANCEL'})}
        confirmBtnBsStyle='danger'
        title={<ErrorText text={intl.formatMessage({id: 'KIDLING.MENU.ARE_YOU_SURE?'})} />}
        focusCancelBtn
        onConfirm={(e) => {
          setShowDelete(false)
          handleDelete()
        }}
        onCancel={() => setShowDelete(false)}
        style={{
          backgroundColor: 'var(--bs-body-bg)',
          boxShadow: '0 0.4rem 0.8rem -0.1rem rgb(0 32 128 / 10%), 0 0 0 1px var(--bs-body-bg)',
        }}
      />
    </div>
  )
}

export default DeleteTableHeader
