/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect} from 'react'
import {useParams} from 'react-router-dom'
import {InboxTable} from '../components/inbox-table/InboxTable'
import {SentTable} from '../components/sent-table/SentTable'
import {MarkedTable} from '../components/marked-table/MarkedTable'
import {TrashTable} from '../components/trash-table/TrashTable'
import {useListView} from '../core/ListViewProvider'

function TabsContent() {
  const {clearSelected} = useListView()
  const {tab} = useParams()

  useEffect(() => {
    clearSelected()
  }, [tab])

  return (
    <div className='px-4'>
      {tab === 'inbox' && <InboxTable />}
      {tab === 'marked' && <MarkedTable />}
      {/* {tab === 'draft' && <DraftTable />} */}
      {tab === 'sent' && <SentTable />}
      {tab === 'trash' && <TrashTable />}
    </div>
  )
}

export default TabsContent
