/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'

import moment from 'moment'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import SweetAlert from 'react-bootstrap-sweetalert'
import {useIntl} from 'react-intl'
import ErrorText from '../../../general-components/error-text/ErrorText'
import {useAuth} from '../../auth'
import useDocument from '../../../hooks/useDocuments'

interface Props {
  id: string
  icon: string
  title: string
  description: string
  userName: string
  date: string
  ownerId: string
  filePath: string
  kidMediaFileName: string
}

const DocumentCard: FC<Props> = ({
  id,
  icon,
  title,
  description,
  userName,
  date,
  ownerId,
  filePath,
  kidMediaFileName,
}) => {
  const intl = useIntl()
  const [showDelete, setShowDelete] = useState(false)
  const {currentUrl, openFile, isReading, file, handleDelete} = useDocument()
  const {currentUser} = useAuth()

  useEffect(() => {
    if (currentUrl) window.open(currentUrl)
  }, [currentUrl])

  return (
    <div className='card h-100  border-bottom'>
      <div className='card-body d-flex justify-content-center align-items-center text-center flex-column p-8 position-relative '>
        <button
          className='btn text-gray-800 text-hover-primary d-flex flex-column align-items-center  '
          onClick={() => openFile(filePath, kidMediaFileName)}
        >
          <div className='symbol symbol-75px mb-6 '>
            {isReading && (
              <span
                className='spinner-border spinner-border-sm align-middle ms-2 position-absolute  '
                style={{top: '-1rem', left: '-1rem'}}
              ></span>
            )}
            <img src={toAbsoluteUrl(icon)} alt='attachment' />
          </div>
          <h3 className=' mb-2'>{title}</h3>
        </button>
        <p className='text-muted mb-1'>{description}</p>
        <p className='text-muted mb-1 fw-bold'>{userName}</p>
        <p className='text-muted mb-1'>{moment(date).format('DD.MM.yyyy HH:mm')}</p>
        <div className='d-flex align-items-center'>
          {moment.duration(moment().diff(moment(date))).asDays() < 8 &&
            currentUser?.usersId === Number(ownerId) && (
              <button className='btn   w-50px' onClick={() => setShowDelete(true)}>
                <i className='fa-solid fa-trash text-danger fs-3'></i>
              </button>
            )}
          {file && (
            <a href={file} className='btn  w-50px' target='_blank' rel='noopener noreferrer'>
              <i className='fa-solid fa-download fs-3'></i>
            </a>
          )}
        </div>
      </div>
      <SweetAlert
        show={showDelete}
        warning
        showCancel
        confirmBtnText={intl.formatMessage({id: 'COMMON.YES'})}
        cancelBtnText={intl.formatMessage({id: 'COMMON.CANCEL'})}
        confirmBtnBsStyle='danger'
        title={<ErrorText text={intl.formatMessage({id: 'KIDLING.MENU.ARE_YOU_SURE?'})} />}
        focusCancelBtn
        onConfirm={(e) => {
          setShowDelete(false)
          handleDelete(filePath)
        }}
        onCancel={() => setShowDelete(false)}
      />
    </div>
  )
}

export default DocumentCard
