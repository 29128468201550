import {useMemo} from 'react'
import {useIntl} from 'react-intl'
import TableCardsContainer from '../../general-components/table-cards-container/TableCardsContainer'
import _columns from './_columns'
import {ActivityHeader} from '../../general-components/ActivityHeader/ActivityHeader'
import useActivities from '../../hooks/useActivities'

function Activities() {
  const intl = useIntl()
  const columns: any = useMemo(() => _columns(), [])
  const {data} = useActivities()

  return (
    <div className='container '>
      <ActivityHeader title={intl.formatMessage({id: 'KIDLING.MENU.ACTIVITIES'})} />
      <TableCardsContainer data={data} columns={columns} />
    </div>
  )
}

export default Activities
