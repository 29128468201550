import React, {FC, ReactElement} from 'react'
import {PropsFromToggle} from 'react-bootstrap/esm/DropdownToggle'

interface Props {
  text: string
}
const ErrorText: FC<Props> = ({text}): ReactElement => {
  return <p className='text-muted fs-4'>{text}</p>
}

export default ErrorText
