/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react'
import AvatarWithLetters from '../../general-components/avatar-with-letters'
import moment from 'moment'
import ReadMore from '../../general-components/ReadMore'

type Props = {
  notification: any
  isReply?: boolean
  setShowReply: any
  setParentId?: any
}

const NotificationCard: React.FC<Props> = ({notification, isReply, setShowReply, setParentId}) => {
  return (
    <div className={`${isReply ? 'p-8 bg-light-primary my-2 shadow' : ''}`}>
      <div className='d-flex align-items-center mb-5'>
        {/* begin::User */}
        <div className='d-flex align-items-center flex-grow-1'>
          {/* begin::Avatar */}
          <div className='symbol symbol-45px me-5'>
            <AvatarWithLetters
              image={notification.imagePath}
              alt={notification.usersName.charAt(0)}
              small={!isReply}
              verysmall={isReply}
            />
          </div>

          <div className='d-flex flex-column w-100'>
            <div className='d-flex align-items-center justify-content-between w-100'>
              <div className='text-gray-800 text-hover-primary fs-6 fw-bold'>
                {notification.usersName}
              </div>
              <p className='text-muted fs-7'>
                {moment.duration(moment(new Date()).diff(notification.dateTime)).humanize()}
              </p>
            </div>

            <span className='text-gray-400 fw-semibold'>{notification.notificationTitle}</span>
          </div>
        </div>
      </div>

      <div className='mb-5'>
        {/* <p
          className='text-gray-800 fw-normal mb-5'
          dangerouslySetInnerHTML={{__html: notification.message}}
        ></p> */}
        <ReadMore text={notification.message} count={250} />
        {setShowReply && (
          <div className='d-flex align-items-center my-5'>
            <button
              className='btn btn-sm btn-light btn-color-muted btn-active-light-danger px-4 py-2 ms-4 w-100 mw-sm-40px'
              onClick={() => {
                setShowReply((prev: boolean) => !prev)
                setParentId()
              }}
            >
              <i className='fa-solid fa-reply'></i>
            </button>
          </div>
        )}
      </div>
    </div>
  )
}

export {NotificationCard}
