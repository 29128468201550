import {FC, ReactElement} from 'react'
import {CompanyFilter} from './CompanyFilter'

interface Props {
  setFilter: any
}
const CompanyHeader: FC<Props> = ({setFilter}): ReactElement => {
  return (
    <div className='card-header border-0 pt-6 d-flex flex-column align-items-end ps-0 pb-8'>
      <CompanyFilter setFilter={setFilter} />
    </div>
  )
}

export {CompanyHeader}
