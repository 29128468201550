import {FC} from 'react'

import CustomDateRangePicker from '../../../../general-components/date-range-picker/DateRangePicker'
import useEatingHabits from '../../../../hooks/useEatingHabits'

interface Props {
  title?: string
}
const MealsHeader: FC<Props> = ({title}) => {
  const {handleDateChange} = useEatingHabits()
  return (
    <div className='d-flex align-items-center justify-content-between border-0 pt-6 d-flex flex-columnt  w-100'>
      {title && <h4 className='m-0'>{title}</h4>}

      <CustomDateRangePicker handleDateChange={handleDateChange} />
    </div>
  )
}

export {MealsHeader}
