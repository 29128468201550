import axios from 'axios'
import * as endpoints from '../config/endpoints'

export const getAllKidsAssessments = async (
  filter: string,
  parentId: number,
  From: string,
  To: string
) => {
  return axios
    .get(`${endpoints.ALL_KID_ASSESSEMTS}/${filter}`, {
      params: {parentId, From: From && To ? From : null, To: From && To ? To : null},
    })
    .then((response) => response.data.data)
    .catch((error) => {
      console.log(error)
    })
}
