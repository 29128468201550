import moment from 'moment'
import React, {FC, ReactElement, useState} from 'react'
import {useIntl} from 'react-intl'
import './styles.scss'
import {AddToCalendarButton} from 'add-to-calendar-button-react'
import {useAuth} from '../../views/auth'
import {
  getAllEventsWithRecurrents,
  updateContactAttendanceStatus,
  updateKidAttendanceStatus,
} from '../../../controller/events'
import {useAppContext} from '../../../_metronic/layout/core/AppContextProvider'
import {toast} from 'react-toastify'
import AvatarWithLetters from '../avatar-with-letters'

interface Props {
  data: any
}
const EventDetailsView: FC<Props> = ({data}): ReactElement => {
  const intl = useIntl()
  const {currentUser} = useAuth()
  const [isLoading, setIsLoading] = useState(false)
  const {setAllEvents, calendarFilter, calenderDateFilter} = useAppContext()

  const handleAttendaceUpdate = async (agree: boolean, isKid?: boolean) => {
    setIsLoading(true)
    if (isKid) {
      await updateKidAttendanceStatus(
        `eventId=${data.eventsId}&attending=${agree}&kidId=${calendarFilter.id}`
      )
    } else {
      await updateContactAttendanceStatus(`eventId=${data.eventsId}&attending=${agree}`)
    }

    setIsLoading(false)
    readEvents()
  }

  const readEvents = async () => {
    try {
      if (!isLoading) {
        const resp = await getAllEventsWithRecurrents(
          calendarFilter.filter,
          calenderDateFilter?.start
            ? moment(calenderDateFilter?.startStr).format('yyyy-MM-DDTHH:mm:ss')
            : moment().startOf('week').format('yyyy-MM-DDTHH:mm:ss'),
          calenderDateFilter?.end
            ? moment(calenderDateFilter?.endStr).format('yyyy-MM-DDTHH:mm:ss')
            : moment().endOf('week').format('yyyy-MM-DDTHH:mm:ss')
        )
        if (resp) {
          setAllEvents(resp)
          toast.success(intl.formatMessage({id: 'COMMON.SUCCESS'}))
        }
      }
    } catch (e) {
      console.log('error', e)
    }
  }

  return (
    <div className=' w-100 h-100'>
      {!data?.holidayName && !data?.dayOffName && (
        <div className='d-flex align-items-center justify-content-between w-100  border-bottom'>
          <AvatarWithLetters
            image={data.imagePath}
            alt={data.eventsName.charAt(0).toUpperCase()}
            small
          />
          <AddToCalendarButton
            name={data.eventsName}
            options={['Apple', 'Google', 'Outlook.com']}
            location={data.locationName}
            startDate={moment(data.startsOn).format('yyyy-MM-DD')}
            endDate={moment(data.endsOn).format('yyyy-MM-DD')}
            startTime={moment(data.startsOn).format('HH:mm')}
            endTime={moment(data.endsOn).format('HH:mm')}
            description={data.description}
            timeZone='Europe/Berlin'
            hideTextLabelButton
            buttonsList
            trigger='click'
            styleLight='border-0 outline-0'
            lightMode='system'
          ></AddToCalendarButton>
        </div>
      )}
      <div className='d-flex flex-column mt-4 w-100 p-4'>
        <div className='row w-100'>
          <i className='fa-solid fa-circle fs-3   mt-1 col-1' style={{color: data.colorCode}}></i>

          <div className='col-10 ms-4 ms-4'>
            <h4 className='my-0'>{data?.dayOffName ?? data?.holidayName ?? data.eventsName}</h4>
            <div className='my-0 text-muted d-flex align-items-center text-nowrap'>
              {moment(data?.dayOffDate ?? data?.holidayDate ?? data.startsOn).format(
                'dddd, MMMM DD '
              )}{' '}
              {data.startsOn && (
                <>
                  <span className='w-6px h-6px rounded-circle mx-2  bg-gray-500 d-inline-block'></span>
                  {moment(data.startsOn).format('HH:mm')}
                  {' - '}
                  {moment(data.endsOn).format('HH:mm')}
                </>
              )}
            </div>
          </div>
        </div>
        {data?.holidayName && (
          <div className='row w-100 mt-4'>
            <i className='fa-solid fa-hashtag fs-3 col-1'></i>
            <div className='col-10 ms-4'>
              <p className='my-0'>{data?.numberOfDays}</p>
            </div>
          </div>
        )}
        {!data?.holidayName && !data?.dayOffName && (
          <>
            {data?.locationName && (
              <div className='row w-100 mt-4'>
                <i className='fa-solid fa-location-dot fs-3 col-1'></i>
                <div className='col-10 ms-4'>
                  <p className='my-0'>{data?.locationName}</p>
                </div>
              </div>
            )}
            {data?.roomName && (
              <div className='row w-100 mt-4'>
                <i className='fa-solid fa-building fs-3 col-1'></i>
                <div className='col-10 ms-4'>
                  <p className='my-0'>{data?.roomName}</p>
                </div>
              </div>
            )}

            {data?.description && (
              <div className='row w-100 mt-4'>
                <i className='fa-solid fa-paragraph fs-3 col-1'></i>
                <div className='col-10 ms-4'>
                  <p className='my-0'>{data?.description}</p>
                </div>
              </div>
            )}

            {data?.employeeName && (
              <div className='row w-100 mt-4'>
                <i className='fa-solid fa-user fs-3 col-1'></i>
                <div className='col-10 ms-4'>
                  <p className='my-0'>{data?.employeeName}</p>
                </div>
              </div>
            )}
            {/* <div className='row w-100 mt-4'>
              <i className='fa-solid fa-user-group fs-3 col-1'></i>
              <div className='col-10 ms-4'>
                <div className='d-flex flex-column'>
                  <p className='my-0'>
                    {data?.eventsKid?.length +
                      data?.contactEvent?.length +
                      data?.employeeEvent?.length}{' '}
                    {intl.formatMessage({id: 'COMMON.RECIPIENTS'})}
                  </p>
                  <div className='my-0 text-muted'>
                    {data?.contactEventAttendingCount +
                      data?.employeeEventAttendingCount +
                      data?.eventsKidAttendingCount}
                    <span className='ms-1'>{intl.formatMessage({id: 'COMMON.YES'})}</span>

                    <span className='ms-1'>
                      ( {data?.contactEventAttendingCount}{' '}
                      {intl.formatMessage({id: 'COMMON.CONTACTS'})},{' '}
                      {data?.employeeEventAttendingCount}{' '}
                      {intl.formatMessage({id: 'KIDLING.EMPLOYEE'})},{' '}
                      {data?.eventsKidAttendingCount} {intl.formatMessage({id: 'KIDLING.EMPLOYEE'})}{' '}
                      )
                    </span>
                  </div>
                  <div className='my-0 text-muted'>
                    {data?.eventsKid?.length +
                      data?.contactEvent?.length +
                      data.employeeEvent?.length -
                      (data?.contactEventAttendingCount +
                        data?.employeeEventAttendingCount +
                        data?.eventsKidAttendingCount)}
                    <span className='ms-1'> {intl.formatMessage({id: 'COMMON.NO'})}</span>
                  </div>
                </div>
              </div>
            </div> */}
          </>
        )}
        {/* <div className='border-top d-flex align-items-center justify-content-between mt-4'>
          <p className='p-0 m-0 fs-5'> {intl.formatMessage({id: 'KIDLING.GOING'})}</p>
          <div className='d-flex align-items-center mt-2'>
            <button
              className={`btn btn-outline btn-active-lignt-primary mx-1 py-1 px-2 ${
                data?.contactEvent?.find(
                  (item: any) => item.contactId === currentUser?.contact?.contactId
                )?.eventsContactAttending ||
                data?.eventsKid?.find((item: any) => item.kidId === calendarFilter.id)
                  ?.eventsKidAttending
                  ? 'bg-success'
                  : ''
              }`}
              type='button'
              onClick={() =>
                handleAttendaceUpdate(
                  true,
                  data?.eventsKid?.find((item: any) => item.kidId === calendarFilter.id)
                    ? true
                    : false
                )
              }
                 disabled={
                data?.employeeEvent?.find(
                  (item: any) => item.employeeId === currentUser?.employee?.employeeId
                )?.eventsEmployeeAttending
                  ? true
                  : false
              }
            >
              {intl.formatMessage({id: 'COMMON.YES'})}
            </button>
            <button
              className={`btn btn-outline btn-active-lignt-primary mx-1 py-1 px-2 ${
                data?.contactEvent?.find(
                  (item: any) => item.contactId === currentUser?.contact?.contactId
                )?.eventsContactAttending ||
                data?.eventsKid?.find((item: any) => item.kidId === calendarFilter.id)
                  ?.eventsKidAttending
                  ? ''
                  : 'bg-danger'
              }`}
              type='button'
              onClick={() =>
                handleAttendaceUpdate(
                  false,
                  data?.eventsKid?.find((item: any) => item.kidId === calendarFilter.id)
                    ? true
                    : false
                )
              }
                 disabled={
                data?.employeeEvent?.find(
                  (item: any) => item.employeeId === currentUser?.employee?.employeeId
                )?.eventsEmployeeAttending
                  ? false
                  : true
              }
            >
              {intl.formatMessage({id: 'COMMON.NO'})}
            </button>
          </div>
        </div> */}
      </div>
    </div>
  )
}

export default EventDetailsView
