import {useEffect, useState} from 'react'
import {useAuth} from '../views/auth'
import {useAppContext} from '../../_metronic/layout/core/AppContextProvider'
import {deleteKidMediaFile, getMobileGallary} from '../../controller/kids'
import {useIntl} from 'react-intl'
import {toast} from 'react-toastify'
import {readFileFrom} from '../../controller/image'
import {decodeBase64} from '../utils/getBase64'

const useDocument = () => {
  const {setGlobalLoader, globalLoader, kidMediaFiles, setKidMediaFiles, childFilter}: any =
    useAppContext()
  const intl = useIntl()
  const {currentUser} = useAuth()
  const [kidIds, setkidIds] = useState<number[]>([])
  const [isReading, setIsReading] = useState(false)
  const [file, setFile] = useState('')
  const [currentUrl, setCurrentUrl] = useState<any>(null)

  useEffect(() => {
    if (currentUser?.kids && currentUser?.kids?.length > 0) {
      let temp: number[] = []
      currentUser?.kids?.map((kid: any) => temp.push(kid?.kidId))
      setkidIds(temp)
    }
  }, [currentUser])

  const readKidsFiles = async () => {
    setGlobalLoader(true)
    let filter = ''

    filter = `kidId=${childFilter.kidId} AND confidential=false `

    const resp = await getMobileGallary(filter, false, true)

    setKidMediaFiles(resp)
    setGlobalLoader(false)
  }
  const openFile = async (filePath: string, kidMediaFileName: string) => {
    try {
      setIsReading(true)
      const resp = await readFileFrom(filePath)
      if (!!resp) {
        const typeArray = kidMediaFileName.split('.')
        const type = typeArray[typeArray.length - 1]

        const blob = await decodeBase64({base64: resp, name: kidMediaFileName, type: type})
        const url = URL.createObjectURL(blob)
        setCurrentUrl(url)
        setIsReading(false)
        setFile(url)
        if ((window as any)['ReactNativeWebView'])
          (window as any)['ReactNativeWebView'].postMessage(
            JSON.stringify({file: {id: filePath, base64: resp}})
          )
      } else {
        toast.error(intl.formatMessage({id: 'KIDLING.FILE_NOT_FOUND'}))
        setIsReading(false)
      }
    } catch (e) {
      setIsReading(false)
      console.log(e)
    }
  }

  const handleDelete = async (id: string) => {
    const resp = await deleteKidMediaFile(id)
    if (!!resp) {
      await readKidsFiles()
      toast.success(intl.formatMessage({id: 'COMMON.SUCCESS'}))
    } else {
      toast.error(intl.formatMessage({id: 'COMMON.FAIL'}))
    }
  }
  return {readKidsFiles, handleDelete, openFile, isReading, currentUrl, file, kidIds}
}

export default useDocument
