/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useMemo} from 'react'
import {useIntl} from 'react-intl'
import {DrinksHeader} from './header/DrinksHeader'
import {useAppContext} from '../../../../_metronic/layout/core/AppContextProvider'
import TableCardsContainer from '../../../general-components/table-cards-container/TableCardsContainer'
import _columns from '../_columns'
import useDrinks from '../../../hooks/useDrinks'

function Drinks() {
  const {internalPagesDatesFilters} = useAppContext()
  const intl = useIntl()
  const {readDrinks, data} = useDrinks()
  const columns: any = useMemo(() => _columns(), [])
  useEffect(() => {
    readDrinks()
  }, [internalPagesDatesFilters])

  return (
    <div className='container'>
      <DrinksHeader title={intl.formatMessage({id: 'KIDLING.MENU.DRINKS'})} />
      <div className='mt-4'>
        <TableCardsContainer data={data} columns={columns} />
      </div>
    </div>
  )
}

export default Drinks
