import {useEffect, useState} from 'react'
import {getKidsMedicalRecords} from '../../controller/medical'
import {useAuth} from '../views/auth'
import {useAppContext} from '../../_metronic/layout/core/AppContextProvider'

const useMedicalRecords = () => {
  const {
    kidsMedicalRecords,
    setKidsMedicalRecords,
    setGlobalLoader,
    internalPagesFilters,
    searchTerm,
    setSearchTerm,
    setInternalPagesFilters,
  } = useAppContext()
  const [data, setData] = useState([])
  const {currentUser} = useAuth()
  const kids = currentUser?.kids || []
  const [clicked, setClicked] = useState(false)

  useEffect(() => {
    filterData()
  }, [searchTerm])

  useEffect(() => {
    if (clicked) {
      readMedicalRecords()
      setClicked(false)
    }
  }, [clicked])

  useEffect(() => {
    let temp: any = []
    kidsMedicalRecords?.map((item: any) => {
      let medicals: any = []
      item.medicalRecors?.map((med: any) =>
        medicals.push({
          id: med.kidMedicalRecordId,
          title: med.medicalRecordTypeName,
          subtitle: med.medicineName,
          tag: med.temperatureCelcius ? `${med.temperatureCelcius} C` : '',
          date: med.recordedAtDateTime,
          desc: med.remarks,
          covid: med.covid19Case,
        })
      )
      temp.push({
        image: item.imagePath,
        name: `${item.firstName} ${item.lastName}`,
        subItems: medicals,
      })
    })
    setData(temp)
  }, [kidsMedicalRecords])

  const readMedicalRecords = async () => {
    let filter: string = ''
    setGlobalLoader(true)
    for (const kid of kids) {
      if (filter != '') filter += ' OR '
      filter += 'kidId=' + kid.kidId
    }

    if (filter != '') {
      const res = await getKidsMedicalRecords(filter)
      setKidsMedicalRecords(res)
    } else {
      setKidsMedicalRecords([])
    }
    setGlobalLoader(false)
  }
  const filterData = async () => {
    const newFilters: any = {...internalPagesFilters}

    newFilters.medical = {
      ...newFilters.medical,
      searchTerm: `kidName.toLower().contains("${searchTerm.toLowerCase().trim()}")`,
    }

    setInternalPagesFilters(newFilters)
  }
  return {data, readMedicalRecords, setClicked, clicked}
}

export default useMedicalRecords
