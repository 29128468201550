import React, {FC, ReactElement, useEffect, useState} from 'react'
import Select, {StylesConfig} from 'react-select'
import {setLanguage, useLang} from '../../../i18n/Metronici18n'
import {useAppContext} from '../../../layout/core/AppContextProvider'

const formatOptionLabel = ({value, label, flag}: any) => (
  <div className='d-flex align-items-center'>
    <img src={flag} alt='flag' className='w-15px h-15px rounded-1 ms-2 me-2' />
    <div>{label}</div>
  </div>
)

interface Props {
  setCurrentLang?: any
  value?: any
}
const LanguageSelect: FC<Props> = ({setCurrentLang, value}): ReactElement => {
  const lang = useLang()
  const {languages}: any = useAppContext()
  const [currentLanguage, setCurrentLanguage] = useState(null)

  useEffect(() => {
    if (languages?.length > 0) {
      const temp = languages?.find((x: any) => x.value === lang)
      setCurrentLanguage(temp)
    }
  }, [languages])

  const changeUserLang = (val: string) => {
    const langId = languages?.findIndex((x: any) => x.value === val)

    setCurrentLang(langId + 1)
  }

  const darkStyles: StylesConfig<any> = {
    control: (styles) => ({...styles, backgroundColor: 'black', color: '#fff'}),
    option: (styles, {data, isDisabled, isFocused, isSelected}) => {
      return {
        ...styles,
        color: '#fff',
        backgroundColor: isSelected ? '#000' : '#000',
        ':active': {
          ...styles[':active'],
          backgroundColor: !isDisabled ? (isSelected ? '#fff' : 'black') : '#fff',
          color: '#fff',
        },

        ':hover': {
          ...styles[':hover'],
          backgroundColor: '#fff',
          color: '#777',
        },
      }
    },
  }
  return (
    <div className='d-flex justify-content-end w-100 m-2'>
      <div className='theme-dark-show w-100 '>
        <Select
          value={setCurrentLang ? value : currentLanguage}
          name='Language'
          options={languages}
          className='basic-multi-select'
          classNamePrefix='select'
          formatOptionLabel={formatOptionLabel}
          onChange={(val: any) =>
            setCurrentLang ? changeUserLang(val.value) : setLanguage(val.value)
          }
          styles={darkStyles}
          isLoading={languages && languages.length === 0}
        />
      </div>

      <div className='theme-light-show w-100'>
        {' '}
        <Select
          value={setCurrentLang ? value : currentLanguage}
          name='Language'
          options={languages}
          className='basic-multi-select'
          classNamePrefix='select'
          formatOptionLabel={formatOptionLabel}
          onChange={(val: any) =>
            setCurrentLang ? changeUserLang(val.value) : setLanguage(val.value)
          }
          isLoading={languages && languages.length === 0}
        />
      </div>
    </div>
  )
}

export default LanguageSelect
