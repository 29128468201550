/* eslint-disable react-hooks/exhaustive-deps */
import React, {FC, ReactElement, useEffect, useState} from 'react'
import AvatarWithLetters from '../avatar-with-letters'
import {useMediaQuery} from 'react-responsive'
import {Link} from 'react-router-dom'
import {KTSVG} from '../../../_metronic/helpers'
import {useIntl} from 'react-intl'
import moment from 'moment'
import {KidDeRegisterationModal} from '../../views/dashboard/kid-deregistration-modal/KidDeRegisterationModal'
import {useAppContext} from '../../../_metronic/layout/core/AppContextProvider'
import {getAlldeRegisterMeal} from '../../../controller/kids'
import {useThemeMode} from '../../../_metronic/partials/layout/theme-mode/ThemeModeProvider'
import TeacherGroupsModal from './TeacherGroupsModal'
import {groupBy} from '../../utils/common'
import {KidAbsenseModal} from '../../views/kid-absenses/kid-absense-modal/KidAbsenseModal'
import IconButton from '@mui/material/IconButton'
interface Props {
  kid: any
}
const ParentKidCard: FC<Props> = ({kid}): ReactElement => {
  const [showAbsenceModel, setShowAbsenceModel] = useState(false)
  const {mobileConfiguration} = useAppContext()
  const company = window.location.pathname.split('/')[1]
  const [showDeregisteration, setShowDeregisteration] = useState(false)
  const [kidDeregistrations, setKidDeregistrations] = useState([])
  const {mode} = useThemeMode()
  const [showGroupModal, setShowGroupModal] = useState({show: false, data: null})
  const [kidClasses, setKidClasses] = useState<string[]>([])
  const intl = useIntl()
  const isMobile = useMediaQuery({
    query: '(max-width:700px)',
  })

  useEffect(() => {
    if (kid.classrooms.length > 0) {
      let temp: string[] = []
      const grouped = groupBy(kid.classrooms, 'name')
      Object.keys(grouped)?.map((key) => {
        temp.push(key)
      })

      setKidClasses(temp)
    }
  }, [kid])

  useEffect(() => {
    readKidDeregistrations()
  }, [showDeregisteration])

  const readKidDeregistrations = async () => {
    const resp = await getAlldeRegisterMeal(
      `kidId=${kid.id}`,
      moment().startOf('day').format('yyyy-MM-DDTHH:mm:ss'),
      moment().endOf('day').format('yyyy-MM-DDTHH:mm:ss')
    )

    if (resp?.length > 0 && resp[0]?.deregistrations.length > 0) {
      setKidDeregistrations(resp[0].deregistrations)
    }
  }

  return (
    <div className='shadow-lg rounded d-flex flex-column align-items-center p-8 h-100  position-relative  '>
      <div className='position-relative'>
        <AvatarWithLetters
          alt={`${kid.firstName.charAt(0).toUpperCase()} ${kid.lastName.charAt(0).toUpperCase()}`}
          image={kid.imagePath}
          rounded
        />
        <div
          className={`position-absolute w-10px h-10px p-20px rounded-circle  ${
            kid.attendanceHistory.length > 0 &&
            kid.attendanceHistory[kid.attendanceHistory.length - 1].checkOut &&
            (moment(kid.attendanceHistory[kid.attendanceHistory.length - 1].checkOut).year() > 1900
              ? 'bg-info'
              : 'bg-success')
          } ${kid.absent && 'bg-danger'} ${kid.attendanceHistory.length === 0 && 'bg-secondary'}`}
          style={{top: isMobile ? '8%' : '13%', right: '5%'}}
        ></div>
      </div>

      <div className='d-flex  flex-column align-items-center mb-2'>
        <Link
          to={
            company
              ? `/${company}/kidprofile?kidId=${kid.id}`
              : `/kidling/kidprofile?kidId=${kid.id}`
          }
          className='text-gray-800 text-hover-primary fs-2 fw-bolder me-1'
        >
          {kid.firstName + ' ' + kid.lastName}
        </Link>
        <Link
          to={
            company
              ? `/${company}/kidprofile?kidId=${kid.id}`
              : `/kidling/kidprofile?kidId=${kid.id}`
          }
        ></Link>

        <Link
          to={
            company
              ? `/${company}/kidprofile?kidId=${kid.id}`
              : `/kidling/kidprofile?kidId=${kid.id}`
          }
          className='fw-bolder fs-8 py-1 px-3 svg-icon-primary text-muted'
        >
          {kid?.plan?.startDate
            ? `${moment(kid?.plan.startDate).format('DD.MM.yyyy')}  -  ${moment(
                kid?.plan?.endDate
              ).format('DD.MM.yyyy')}`
            : 'Ganztägig verlängert (> 9 Stunden)'}
        </Link>
        <Link
          to={
            company
              ? `/${company}/kidprofile?kidId=${kid.id}`
              : `/kidling/kidprofile?kidId=${kid.id}`
          }
          className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2 position-absolute top-0 start-0 m-4'
        >
          <KTSVG path='/media/icons/duotune/general/gen018.svg' className='svg-icon-4 me-1' />
          {kid?.attendanceTime.location}
        </Link>
      </div>
      <div className='d-flex flex-column align-items-center fs-6 mb-4 pe-2'>
        {kid.classrooms.length > 0 && (
          <button
            className='btn d-flex align-items-center text-gray-400 text-hover-primary  p-0 m-0 mb-2'
            onClick={() => setShowGroupModal({show: true, data: kid.classrooms})}
          >
            <i className='fa-solid fa-people-roof me-2'></i>
            {/* {kid.classrooms && kid.classrooms[0]?.name} */}

            <p className='m-0 text-muted d-flex align-items-center'>
              {kidClasses?.map((cc: any, index: number) =>
                cc && index < 2 ? `${cc} ${kidClasses.length - 1 > index ? ',' : ' '} ` : ''
              )}
            </p>

            <i className='fa-solid fa-caret-down ms-2'></i>
          </button>
        )}

        <Link
          to={
            company
              ? `/${company}/kidprofile?kidId=${kid.id}`
              : `/kidling/kidprofilekidId=${kid.id}`
          }
          className='d-flex align-items-center text-gray-400 text-hover-primary '
        >
          <i className='fa-solid fa-cake-candles me-2'></i>
          {moment(kid?.dateOfBirth).format('DD.MM.YYYY')}
        </Link>
      </div>
      <div className='d-flex flex-column mt-2 w-100 align-items-center '>
        <div className='flex-grow-1'>
          <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
            <div className='d-flex flex-column w-100'>
              {kid.attendanceHistory &&
                mobileConfiguration?.KidAttendance &&
                mobileConfiguration?.KidAttendance[0]?.showInParentApp &&
                kid.attendanceHistory?.map((history: any, index: number) => (
                  <div
                    className='d-flex align-items-center mb-2 justify-content-between w-100'
                    key={index}
                  >
                    <Link
                      to={
                        company
                          ? `/${company}/attendance?kidId=${kid.id}`
                          : `/kidling/attendancekidId=${kid.id}`
                      }
                      className='d-flex align-items-center text-gray-400 text-hover-primary  mb-2'
                    >
                      <i className='bi bi-clock-fill  me-2 text-primary'></i>
                      {history.checkIn ? moment(history.checkIn).format('HH:mm') : ''}{' '}
                      <span className='mx-3 '>
                        <i className='fa-solid fa-angles-right'></i>
                      </span>
                      {history.checkOut && moment(history.checkOut).year() > 1900
                        ? moment(history.checkOut).format('HH:mm')
                        : '--'}
                    </Link>
                    <Link
                      to={
                        company
                          ? `/${company}/attendance?kidId=${kid.id}`
                          : `/kidling/attendance?kidId=${kid.id}`
                      }
                      className='d-flex align-items-center text-gray-400 text-hover-primary ms-1 mb-2'
                    >
                      {history?.location}
                      <KTSVG
                        path='/media/icons/duotune/general/gen018.svg'
                        className='svg-icon-4 ms-1'
                      />
                    </Link>
                  </div>
                ))}

              {kid.absenceHistory &&
                mobileConfiguration?.KidAbsent &&
                mobileConfiguration?.KidAbsent[0]?.showInParentApp &&
                kid.absenceHistory?.map((history: any, index1: number) => (
                  <div
                    className='d-flex align-items-center mb-2 justify-content-between w-100'
                    key={index1}
                  >
                    <Link
                      to={
                        company
                          ? `/${company}/absense?kidId=${kid.id}`
                          : `/kidling/absense?kidId=${kid.id}`
                      }
                      className='d-flex align-items-center text-gray-400 text-hover-primary  mb-2'
                    >
                      <i className='fa-solid fa-person-circle-minus me-2 text-danger'></i>
                      {history.absentFromDate
                        ? moment(history.absentFromDate).format('DD.MM.yyyy')
                        : ''}{' '}
                      <span className='mx-3 '>
                        <i className='fa-solid fa-angles-right'></i>
                      </span>
                      {history.absentToDate && moment(history.absentToDate).year() > 1900
                        ? moment(history.absentToDate).format('DD.MM.yyyy')
                        : '--'}
                    </Link>
                    <Link
                      to={
                        company
                          ? `/${company}/absense?kidId=${kid.id}`
                          : `/kidling/absense?kidId=${kid.id}`
                      }
                      className='d-flex align-items-center  text-hover-primary ms-1 mb-2 badge badge-danger  '
                    >
                      {history?.absentReasonName || 'Nan'}
                    </Link>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>

      {/* {kid.classrooms.length > 0 && kid.classrooms[0]?.teacherName && (
        <div className='d-flex mb-4 w-100'>
          <div className='d-flex align-items-center'>
            <AvatarWithLetters
              alt={`${kid.classrooms[0]?.teacherName.charAt(0).toUpperCase()} ${kid.lastName
                .charAt(0)
                .toUpperCase()}`}
              image={kid.classrooms[0]?.teacherImagePath}
              rounded
              verysmall
            />
            <div className='d-flex align-items-center flex-wrap  mb-md-0 ms-3'>
              <Link
                to={company ? `/${company}/calender` : '/kidling/calender'}
                className='text-gray-800 text-hover-primary fw-bold me-2'
              >
                {kid.classrooms[0]?.teacherName}
              </Link>
            </div>
          </div>

          {kid.classrooms && kid.classrooms[0]?.schedule && (
            <div className='d-flex align-items-center flex-wrap mb-1'>
              <Link
                to={company ? `/${company}/calender` : '/kidling/calender'}
                className='fw-bolder fs-8 py-1 px-3 svg-icon-primary text-decoration-underline'
                data-bs-toggle='modal'
              >
                {kid.classrooms && kid.classrooms[0]?.schedule}
              </Link>
            </div>
          )}
        </div>
      )} */}

      <div className='d-flex justify-content-center  w-100 mt-auto mt-12 gap-4'>
        <button
          type='button'
          className='btn btn-icon  btn-sm btn-light-primary  w-100   d-flex align-items-center justify-content-center'
          onClick={() => setShowAbsenceModel(true)}
        >
          <i
            className={` fa-solid   fa-person-circle-minus fs-3 ${mode === 'dark' && 'text-white'}`}
          ></i>
        </button>

        {mobileConfiguration?.MealSubscription &&
          mobileConfiguration?.MealSubscription[0]?.showInParentApp && (
            <button
              type='button'
              className='btn btn-icon  btn-sm btn-light-primary w-100    d-flex align-items-center justify-content-center'
              onClick={() => setShowDeregisteration(true)}
            >
              <i className={`fa-solid fa-square-minus fs-3 ${mode === 'dark' && 'text-white'}`}></i>
            </button>
          )}
        {mobileConfiguration?.Messaging && mobileConfiguration?.Messaging[0]?.showInParentApp && (
          <Link
            to={company ? `/${company}/messages/inbox` : '/kidling/messages/inbox'}
            className='btn btn-icon  btn-sm btn-light-primary w-100   d-flex align-items-center justify-content-center'
          >
            <i className={`fas fa-envelope fs-1 ${mode === 'dark' && 'text-white'} `}></i>
          </Link>
        )}
      </div>

      {showAbsenceModel && (
        <KidAbsenseModal
          setShowAbsenceModel={setShowAbsenceModel}
          kidId={kid.id}
          companyId={kid.companyId}
        />
      )}
      {showDeregisteration && (
        <KidDeRegisterationModal
          kid={kid}
          setShowDeregisteration={setShowDeregisteration}
          kidDeregistrations={kidDeregistrations}
        />
      )}
      <TeacherGroupsModal modal={showGroupModal} setModal={setShowGroupModal} />
    </div>
  )
}

export default ParentKidCard
