import {useState} from 'react'
import {toast} from 'react-toastify'
import {deleteAttendance, getAttendanceByFilter, updateAttendance} from '../../controller/attendace'
import moment from 'moment'
import {useAppContext} from '../../_metronic/layout/core/AppContextProvider'
import {useIntl} from 'react-intl'

const useKidAttendance = () => {
  const [totalOverTime, setTotalOverTime] = useState<any>(0)
  const [child, setChild] = useState(0)
  const [showDelete, setShowDelete] = useState(false)
  const [currentId, setCurrentId] = useState('')
  const [data, setData] = useState<any>([])
  const intl = useIntl()
  const {setGlobalLoader, childFilter, dateFilter, setDateFilter} = useAppContext()

  const load = async () => {
    await readAttendace(childFilter?.kidId ?? -1)
  }

  const handleDeleteAttendance = async (id: string) => {
    setCurrentId(id)
    setShowDelete(true)
  }
  const doDelete = async () => {
    setShowDelete(false)
    if (currentId !== '') {
      setGlobalLoader(true)
      const resp = await deleteAttendance(currentId)
      if (resp) {
        await readAttendace(childFilter.kidId)
        setGlobalLoader(false)
        toast.success(intl.formatMessage({id: 'COMMON.SUCCESS'}))
      } else {
        setGlobalLoader(false)
        toast.error(intl.formatMessage({id: 'COMMON.FAIL'}))
      }
    }
  }
  const editCell = async (row: any, col: string, value: any) => {
    setGlobalLoader(true)
    const data = {
      ...row,
      ...row.kid,
      [col]: value,
    }

    const resp = await updateAttendance(data)
    if (resp) {
      await readAttendace(childFilter.kidId)
      setGlobalLoader(false)
      toast.success(intl.formatMessage({id: 'COMMON.SUCCESS'}))
    } else {
      setGlobalLoader(false)
      toast.error(intl.formatMessage({id: 'COMMON.FAIL'}))
    }
  }

  const readAttendace = async (id: number) => {
    setGlobalLoader(true)

    const res = await getAttendanceByFilter(
      id,
      moment(dateFilter?.startDate).startOf('day').format('yyyy-MM-DDTHH:mm:ss'),
      moment(dateFilter?.endDate).endOf('day').format('yyyy-MM-DDTHH:mm:ss')
    )

    setData(res)
    setGlobalLoader(false)
  }
  const handleDateChange = (selected: any) => {
    setDateFilter(selected[0])
  }

  return {
    child,
    setChild,
    totalOverTime,
    setTotalOverTime,
    dateFilter,
    editCell,
    doDelete,
    readAttendace,
    handleDeleteAttendance,
    showDelete,
    setShowDelete,
    data,
    load,
    handleDateChange,
  }
}

export default useKidAttendance
