/* eslint-disable react-hooks/exhaustive-deps */
import {FC, ReactElement, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {useAuth} from '../../auth'
import {updateKidImage} from '../../../../controller/kids'
import {toast} from 'react-toastify'
import {useLang} from '../../../../_metronic/i18n/Metronici18n'

interface Props {
  kid: any
  setKid: any
  currentTab: number
}

const UserTopProfile: FC<Props> = ({kid, setKid, currentTab}): ReactElement => {
  const intl = useIntl()
  const [preview, setPreview] = useState<any>(null)
  const [selectedFile, setSelectedFile] = useState<any>(null)
  const [base64Image, setBase64Image] = useState<any>(null)

  const [isLoading, setIsLoading] = useState(false)
  const {currentUser, setCurrentUser} = useAuth()
  const lang = useLang()
  useEffect(() => {
    if (currentUser && currentUser?.kids) {
      const kidT = currentUser?.kids[currentTab]

      setKid(kidT)
      if (kid?.imagePath) {
        setPreview(kid?.imagePath)
      } else {
        setPreview(undefined)
      }
    }
  }, [kid])

  useEffect(() => {
    if (!!base64Image) updateImage(base64Image)
  }, [base64Image])

  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined)
      return
    }
    const objectUrl = URL.createObjectURL(selectedFile)
    setPreview(objectUrl)
    getBase64(selectedFile)

    // free memory when ever this component is unmounted
    return () => {
      URL.revokeObjectURL(objectUrl)
    }
  }, [selectedFile])

  const getBase64 = async (file: any) => {
    const reader = new FileReader()

    reader.addEventListener('load', () => {
      return setBase64Image(reader.result)
    })

    reader.readAsDataURL(file)
  }
  const updateImage = async (image: string) => {
    setIsLoading(true)
    const resp = await updateKidImage({id: kid.kidId, imagePath: image})
    setIsLoading(false)
    if (resp) {
      kid.imagePath = resp.imagePath
      kid.image = image
      setKid(kid)
      let temp = currentUser
      if (temp) {
        if (temp.kids) {
          temp.kids[currentTab] = kid
          setCurrentUser(temp)
        }
      }

      toast.success(intl.formatMessage({id: 'COMMON.SUCCESS'}))
    }
  }

  return (
    <div className='card'>
      <div className='card-body'>
        <div className='d-flex align-items-center'>
          <div className=' '>
            <div
              className='image-input image-input-outline'
              data-kt-image-input='true'
              style={{
                backgroundImage: preview
                  ? `url(${preview})`
                  : 'url("/media/svg/avatars/blank.svg")',
              }}
            >
              <div
                className='image-input-wrapper w-125px h-125px'
                style={{
                  backgroundImage: preview
                    ? `url(${preview})`
                    : 'url("/media/svg/avatars/blank.svg")',
                }}
              ></div>

              <label
                className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                data-kt-image-input-action='change'
                data-bs-toggle='tooltip'
                title='Change avatar'
              >
                {isLoading ? (
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                ) : (
                  <i className='fa-solid fa-pencil'>
                    <span className='path1'></span>
                    <span className='path2'></span>
                  </i>
                )}

                <input
                  type='file'
                  name='avatar'
                  accept='.png, .jpg, .jpeg'
                  onChange={(e: any) =>
                    setSelectedFile(e.target?.files.length > 0 ? e.target?.files[0] : null)
                  }
                  lang={lang}
                />
                <input type='hidden' name='avatar_remove' lang={lang} />
              </label>

              <span
                className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                data-kt-image-input-action='cancel'
                data-bs-toggle='tooltip'
                title='Cancel avatar'
              >
                <i className='fa-solid fa-xmark'>
                  <span className='path1'></span>
                  <span className='path2'></span>
                </i>
              </span>

              <span
                className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                data-kt-image-input-action='remove'
                data-bs-toggle='tooltip'
                title='Remove avatar'
                onClick={() => {
                  updateImage('')
                  setPreview(null)
                }}
              >
                <i className='fa-solid fa-xmark'>
                  <span className='path1'></span>
                  <span className='path2'></span>
                </i>
              </span>
            </div>
          </div>
          <div className='mx-8 d-flex flex-column'>
            <h3 className='  '>
              {kid?.firstName} {kid?.lastName}
            </h3>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UserTopProfile
