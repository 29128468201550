import {FC} from 'react'
import {ChildFilter} from '../../../general-components/ChildFilter'
import CustomDateRangePicker from '../../../general-components/date-range-picker/DateRangePicker'
import moment from 'moment'
import {useAppContext} from '../../../../_metronic/layout/core/AppContextProvider'
import {MediaSearchComponent} from './MediaSearchComponent'
import {useThemeMode} from '../../../../_metronic/partials/layout/theme-mode/ThemeModeProvider'
import {useAuth} from '../../auth'

interface Props {
  title?: string
  showChildFilter?: boolean | false
  showButton?: any
}

const TitleHeader: FC<Props> = ({title, showChildFilter, showButton}) => {
  const {internalPagesDatesFilters, setInternalPagesDatesFilters} = useAppContext()
  const {currentUser} = useAuth()
  const {mode} = useThemeMode()

  const handleDateChange = (selected: any) => {
    const date = selected[0]
    const newFilters: any = {...internalPagesDatesFilters}

    newFilters.media = {
      ...newFilters.mealtimes,
      From: date?.startDate
        ? moment(date?.startDate).startOf('day').format('yyyy-MM-DDTHH:mm:ss')
        : moment().startOf('day').format('yyyy-MM-DDTHH:mm:ss'),
      To: date?.endDate
        ? moment(date?.endDate).endOf('day').format('yyyy-MM-DDTHH:mm:ss')
        : moment().endOf('day').format('yyyy-MM-DDTHH:mm:ss'),
    }

    setInternalPagesDatesFilters(newFilters)
  }

  const printPage = () => {
    window.print()
  }
  return (
    <div className='d-flex justify-content-center border-0 pt-6  px-0 pb-8 w-100'>
      <div className='float-start row w-100'>
        <div className='col-6 col-sm-9'> {title && <h4>{title}</h4>}</div>
        <div className='col-6 col-sm-3  d-flex align-items-center justify-content-end'>
          {currentUser?.username !== 'apptest@kidling.email' && (
            <button
              className='btn btn-icon btn-light-primary  mx-1 d-flex align-items-center justify-content-center  print__btn   px-1'
              onClick={() => printPage()}
              style={{cursor: 'pointer'}}
              type='button'
            >
              <i
                className={`fa-solid fa-print fs-2 fs-sm-3 ${mode === 'dark' && 'text-dark'} m-0`}
              ></i>
            </button>
          )}
          {showButton && (
            <button
              className='btn btn-icon btn-light-primary mx-1 d-flex align-items-center justify-content-centerbprint__btn   px-1'
              type='button'
              onClick={showButton}
            >
              <i
                className={`fa-solid fa-paperclip fs-2 fs-sm-3  m-0 ${
                  mode === 'dark' && 'text-dark'
                }`}
              ></i>
            </button>
          )}
          <CustomDateRangePicker
            handleDateChange={handleDateChange}
            initialDate={{
              startDate: moment().startOf('year').toDate(),
              endDate: moment().endOf('year').toDate(),
            }}
          />
        </div>
        <div className='d-flex flex-column-reverse flex-sm-row align-items-start justify-content-between w-100'>
          <div className='d-flex align-items-center flex-column flex-wrap flex-sm-row  w-100 '>
            <MediaSearchComponent />
          </div>
        </div>
        {showChildFilter && (
          <div className='col-12'>
            <ChildFilter />
          </div>
        )}
      </div>
    </div>
  )
}

export {TitleHeader}
