import axios from 'axios'
import * as endpoints from '../config/endpoints'
import moment from 'moment'

export const sendNewMessage = async (data: any) => {
  return axios
    .post(`${endpoints.SEND_MESSAGE}`, data)
    .then((response) => response)
    .catch((error) => {
      console.log(error)
    })
}

export const getAllMessages = async (userId: number, filter: string, query: string | null) => {
  return axios
    .get(`${endpoints.GET_MESSAGES}/UsersId/${filter}?UsersId=${userId}&${query}`)
    .then((response) => {
      const received: any = response.data.data?.received.sort((a: any, b: any) =>
        moment(b?.dateTime).diff(a?.dateTime) > 0 ? 1 : -1
      )

      const sent: any = response?.data?.data?.sent.sort((a: any, b: any) =>
        moment(b?.dateTime).diff(a?.dateTime) > 0 ? 1 : -1
      )
      const deletedSent: any = response?.data.data?.deletedSent.sort((a: any, b: any) =>
        moment(b?.dateTime).diff(a?.dateTime) > 0 ? 1 : -1
      )
      const deletedReceived: any = response?.data.data?.deletedReceived.sort((a: any, b: any) =>
        moment(b?.dateTime).diff(a?.dateTime) > 0 ? 1 : -1
      )

      const draft: any = response?.data?.data?.draft.sort((a: any, b: any) =>
        moment(b?.dateTime).diff(a?.dateTime) > 0 ? 1 : -1
      )
      const pinnedReceived: any = response?.data?.data?.pinnedReceived.sort((a: any, b: any) =>
        moment(b?.dateTime).diff(a?.dateTime) > 0 ? 1 : -1
      )
      const pinnedSent: any = response?.data?.data?.pinnedSent.sort((a: any, b: any) =>
        moment(b?.dateTime).diff(a?.dateTime) > 0 ? 1 : -1
      )

      return {
        ...response.data.data,
        received,
        sent,
        deletedSent,
        deletedReceived,
        draft,
        pinnedReceived,
        pinnedSent,
      }
    })
    .catch((error) => {
      console.log(error)
    })
}

export const updateReceivedMessages = async (data: any) => {
  return axios
    .put(`${endpoints.UPDATE_RECEIVED_MESSAGE}`, data)
    .then((response) => response.data)
    .catch((error) => {
      console.log(error)
    })
}

export const updateSentMessages = async (data: any) => {
  return axios
    .put(`${endpoints.UPDATE_SENT_MESSAGE}`, data)
    .then((response) => response.data)
    .catch((error) => {
      console.log(error)
    })
}

export const getSubMessages = async ({userId, parentId}: any) => {
  return axios
    .get(`${endpoints.GET_SUB_MESSAGES}?userId=${userId}&parentId=${parentId}`)
    .then((response) => response.data.data)
    .catch((error) => {
      console.log(error)
    })
}

export const getMessage = async (notificationId: string) => {
  return axios
    .get(`${endpoints.GET_MESSAGE}?NotificationId=${notificationId}`)
    .then((response) => response.data)
    .catch((error) => {
      console.log(error)
    })
}

export const getAllMessagesCount = async (userId: number) => {
  return axios
    .get(`${endpoints.NEW_MESSAGES_COUNT}`)
    .then((response) => response.data.data)
    .catch((error) => {
      console.log(error)
    })
}

export const getAllMessagesV2 = async (
  UsersId: number,
  filter: string,
  PageNumber: number,
  PageSize: number,
  NotificationFolder: number,
  NotificationTypes: number
) => {
  return axios
    .get(`${endpoints.GET_MESSAGES_V2}/${filter}`, {
      params: {UsersId, PageNumber, PageSize, NotificationFolder, NotificationTypes},
    })
    .then((response) => {
      return {
        ...response.data.data,
      }
    })
    .catch((error) => {
      console.log(error)
    })
}

export const getAllMessagesCounts = async (filter: any) => {
  return axios
    .get(`${endpoints.GET_ALL_MESSAGES_COUNTS}/1=1?UsersId=${filter}`)
    .then((response) => response.data)
    .catch((error) => {
      console.log(error)
    })
}
