import {FC, ReactElement} from 'react'
import {CalenderFilter} from '../filter/CalenderFilter'

const CalenderHeader: FC = (): ReactElement => {
  return (
    <div className=' border-0 d-flex flex-column align-items-end  '>
      <CalenderFilter />
    </div>
  )
}

export {CalenderHeader}
