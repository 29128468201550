/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useState} from 'react'
import {UncontrolledAccordion, AccordionBody, AccordionHeader, AccordionItem} from 'reactstrap'
import moment from 'moment'
import {useIntl} from 'react-intl'
import {readDaysOff, readHolidays} from '../../../../controller/events'
import {getCompanyInfo} from '../../../../controller/company'
import {UsersListLoading} from '../../../general-components/loading/UsersListLoading'
import {CompanyHeader} from './CompanyHeader'
import {useMediaQuery} from 'react-responsive'

function CompanyInformation() {
  const intl = useIntl()
  const [holidays, setHolidays] = useState<any>([])
  const [daysOff, setDaysOff] = useState<any>([])
  const [companyInfo, setcompanyInfo] = useState<any>([])
  const [filter, setFilter] = useState<any>(null)
  const [isLoading, setIsLoading] = useState(false)
  const isMobile = useMediaQuery({
    query: '(max-width:768px)',
  })
  useEffect(() => {
    if (filter && !isLoading) {
      setIsLoading(true)
      setHolidays(null)
      setDaysOff(null)
      setcompanyInfo(null)
      readHolidayesAndDaysOff()
    }
  }, [filter])

  const readHolidayesAndDaysOff = async () => {
    if (filter?.companyId)
      Promise.all([
        readHolidays(filter?.companyId ? filter?.companyId : -1),
        readDaysOff(filter?.companyId ? filter?.companyId : -1),
        getCompanyInfo(filter?.companyId ? filter?.companyId : -1),
      ])
        .then(function ([resp1, resp2, resp3]) {
          setHolidays(resp1)
          setDaysOff(resp2)
          setcompanyInfo(resp3)
        })
        .catch(console.log)
        .finally(() => setIsLoading(false))
  }

  return (
    <div className='p-8'>
      <CompanyHeader setFilter={setFilter} />
      <UncontrolledAccordion toggle={() => {}}>
        {holidays && (
          <AccordionItem>
            <AccordionHeader targetId={`0`}>
              <h6 className='ms-4'>{intl.formatMessage({id: 'KIDLING.HOLIDAYS'})}</h6>
            </AccordionHeader>
            <AccordionBody accordionId={`0`}>
              {holidays?.map((item: any, index: number) => (
                <div
                  key={index}
                  className='d-flex justify-content-between shadow rounded m-2 py-2 px-4 align-items-center'
                >
                  <div className='m-0 p-0 fs-4 text-bold'>{item.holidayName}</div>
                  <div className='d-flex  flex-column align-items-end'>
                    {/* <p className=' badge badge-primary mb-0'>
                      <span className=' me-1'> {item.numberOfDays}</span>

                      {intl.formatMessage({id: 'KIDLING.DAY'})}
                    </p> */}

                    <p className='d-flex flex-column flex-md-row align-items-center m-0 p-0 text-muted mb-1'>
                      <span className='badge badge-primary mb-0'>
                        {moment(item.holidayDate).format('DD.MM.yyyy')}
                      </span>
                      {item.numberOfDays > 1 && (
                        <>
                          <span className='mx-2 '>
                            <i
                              className={`fa-solid fa-angles-${
                                isMobile ? 'down' : 'right'
                              } text-muted`}
                            ></i>
                          </span>
                          <span className='badge badge-primary  mb-0 mt-1'>
                            {moment(
                              moment(item.holidayDate).add(Number(item.numberOfDays) - 1, 'days')
                            ).format('DD.MM.yyyy')}
                          </span>
                        </>
                      )}
                    </p>
                  </div>
                </div>
              ))}
            </AccordionBody>
          </AccordionItem>
        )}
        {daysOff && (
          <AccordionItem>
            <AccordionHeader targetId={`1`}>
              <h6 className='ms-4'>{intl.formatMessage({id: 'KIDLING.DAYS_OFF'})}</h6>
            </AccordionHeader>
            <AccordionBody accordionId={`1`}>
              {daysOff?.map((item: any, index: number) => (
                <div
                  key={index}
                  className='d-flex justify-content-between shadow rounded m-2 py-2 px-4 align-items-center'
                >
                  <div className='m-0 p-0 fs-4 text-bold'>{item.dayOffName}</div>
                  <div className='d-flex  flex-column align-items-end'>
                    <span className='badge badge-primary mb-0'>
                      {moment(item.dayOffDate).format('DD.MM.yyyy')}
                    </span>
                  </div>
                </div>
              ))}
            </AccordionBody>
          </AccordionItem>
        )}
        {companyInfo && (
          <AccordionItem>
            <AccordionHeader targetId={`2`}>
              <h6 className='ms-4'>{companyInfo?.companyInfoTitle}</h6>
            </AccordionHeader>
            <AccordionBody accordionId={`2`}>
              <p
                className='text-muted'
                dangerouslySetInnerHTML={{__html: companyInfo?.companyInfoDescription}}
              ></p>
            </AccordionBody>
          </AccordionItem>
        )}
      </UncontrolledAccordion>
      {isLoading && <UsersListLoading />}
    </div>
  )
}

export default CompanyInformation
