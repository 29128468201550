import React, {useEffect, useRef, useState} from 'react'
import {useIntl} from 'react-intl'
import {useAppContext} from '../../../_metronic/layout/core/AppContextProvider'

const InstallWpaButton = () => {
  const [supportsPWA, setSupportsPWA] = useState(false)
  const [openInstall, setOpenInstall] = useState(false)
  const [promptInstall, setPromptInstall] = useState<any>(null)
  const {companyMetaData, globalLoader} = useAppContext()
  const [appIcon, setAppIcon] = useState('')
  const modalRef = useRef<any>()
  const intl = useIntl()
  const company = window.location.pathname.split('/')[1]

  useEffect(() => {
    const handler = (e: any) => {
      e.preventDefault()
      console.log('we are being triggered :D')
      setSupportsPWA(true)
      setPromptInstall(e)
    }
    window.addEventListener('beforeinstallprompt', handler)
    window.addEventListener('appinstalled', (event) => {
      console.log('👍', 'appinstalled', event)

      window.location.reload()
    })

    return () => window.removeEventListener('transitionend', handler)
  }, [])

  useEffect(() => {
    if (!!companyMetaData?.imagePath && !globalLoader) {
      setAppIcon(companyMetaData?.imagePath)
    } else {
      setAppIcon(`${window.location.origin}/icon.png`)
    }
  }, [companyMetaData, globalLoader])

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setOpenInstall(false)
      }
    }
    if (modalRef) document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [modalRef])

  const onClick = (evt: any) => {
    evt.preventDefault()
    if (!promptInstall) {
      return
    }
    promptInstall.prompt()
  }
  if (!supportsPWA) {
    return null
  }

  return (
    <div
      className='position-fixed d-flex justify-content-center buttons__container'
      style={{bottom: '4rem', left: '4rem', zIndex: 10000}}
      ref={modalRef}
    >
      <button
        className='btn rounded-circle btn-primary w-60px h-60px  d-flex justify-content-center align-items-center shadow  '
        onClick={() => setOpenInstall(true)}
      >
        <i className='fa-solid fa-download text-white fs-2 mb-1 ms-1'></i>
      </button>
      {openInstall && (
        <div
          className='position-absolute   bg-white shadow rounded d-flex flex-column  p-4 '
          style={{width: '20rem', height: '12rem', bottom: '5rem', left: '0rem'}}
        >
          <button
            className='btn position-absolute end-0 top-0 m-1'
            onClick={() => setOpenInstall(false)}
          >
            <i className='fa-solid fa-xmark text-muted'></i>
          </button>
          <div className='d-flex align-items-center mb-4'>
            <img src={appIcon} alt='icon' className='w-40px contain ' id='main__app__icon' />
            <div className='ms-4'>
              <p className='text-muted fs-5 mb-0'>
                {companyMetaData?.parentAppTitle ?? 'Kidling Eltern'}
              </p>
              <p className='text-muted fs-7 mb-0'>
                {' '}
                {window.location.host}/{company}
              </p>
            </div>
          </div>

          <button
            className='btn-primary btn   py-2 w-100 rounded-pill '
            id='setup_button'
            aria-label='Install app'
            title='Install app'
            onClick={onClick}
            type='button'
          >
            {intl.formatMessage({id: 'KIDLING.INSTALL_APP'})}
          </button>
        </div>
      )}
    </div>
  )
}

export default InstallWpaButton
