import {Outlet, Route, Routes} from 'react-router-dom'
import {KTCard} from '../../../_metronic/helpers'
import Profile from './Profile'

const ProfilePage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='*'
          element={
            <>
              <KTCard>
                <Profile />
              </KTCard>
            </>
          }
        />
      </Route>
    </Routes>
  )
}

export default ProfilePage
