/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useState} from 'react'
import ProfileSidebar from './components/ProfileSidebar'
import {useIntl} from 'react-intl'
import UserTopProfile from './components/UserTopProfile'
import UserInfo from './components/UserInfo'
import {useAuth} from '../auth'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import {UncontrolledAccordion, AccordionBody, AccordionHeader, AccordionItem} from 'reactstrap'
import {UsersListLoading} from '../../general-components/loading/UsersListLoading'
import ContactDialog from './components/ContactDialog'
import {useSearchParams} from 'react-router-dom'
import {GetKidContacts} from '../../../controller/contact'
import {Avatar} from '@mui/material'
function Profile() {
  const [searchParams] = useSearchParams()
  const [currentTab, setCurrentTab] = useState(0)
  const [kidContacts, setKidContacts] = useState([])
  const [loading, setLoading] = useState(false)
  const {currentUser}: any = useAuth()
  const intl = useIntl()
  const [kid, setKid] = useState<any>({})
  const [showModal, setShowModal] = useState(false)
  const [key, setKey] = useState('info')

  useEffect(() => {
    getKidInfo(0)
  }, [])

  useEffect(() => {
    const kidId = searchParams.get('kidId')
    if (kidId)
      currentUser?.kids?.forEach((item: any, index: number) => {
        if (item.kidId === Number(kidId)) {
          setCurrentTab(index)
          setKid(item)
          setKey('info')
        }
      })
    else {
      setCurrentTab(currentTab)
      setKid(currentUser?.kids[currentTab])
    }
  }, [searchParams.get('kidId'), currentUser])

  useEffect(() => {
    if (currentUser?.kids.length > 0) {
      readContacts()
    }
  }, [currentTab, currentUser])

  const getKidInfo = async (tab: number) => {
    setKid({})
    setCurrentTab(tab)
    setKid(currentUser?.kids[tab])
    /*const resp = await getKidInfoById(currentUser?.usersId || 0)
    if (resp) setEmployeeInfo(resp)*/
  }

  const readContacts = async () => {
    setLoading(true)
    const resp = await GetKidContacts(`kidId=${currentUser?.kids[currentTab].kidId}`)

    if (!!resp) setKidContacts(resp)
    setLoading(false)
  }

  return (
    <div id='kt_app_content' className='app-content flex-column-fluid'>
      <div id='kt_app_content_container' className='app-container container-xxl'>
        <div className='d-flex flex-column flex-lg-row'>
          <div className='  d-flex flex-column flex-lg-row-auto w-100 w-lg-275px'>
            <div className='card card-flush mb-0'>
              <ProfileSidebar setCurrentTab={getKidInfo} currentTab={currentTab} />
            </div>
          </div>
          <div className='flex-lg-row-fluid ms-lg-7 ms-xl-10'>
            <div className='card'>
              <Tabs activeKey={key} onSelect={(k: any) => setKey(k)} className='my-3'>
                <Tab eventKey='info' title={<h5>{intl.formatMessage({id: 'COMMON.INFO'})}</h5>}>
                  <div className='container-xxl mb-7'>
                    <UserTopProfile kid={kid} setKid={setKid} currentTab={currentTab} />
                  </div>

                  <div className='p-sm-8 '>
                    <UserInfo kid={kid} setKid={setKid} currentTab={currentTab} />
                  </div>
                </Tab>
                <Tab
                  eventKey='contact'
                  title={<h5>{intl.formatMessage({id: 'COMMON.CONTACTS'})}</h5>}
                >
                  <UncontrolledAccordion defaultOpen='0' toggle={() => {}}>
                    {kidContacts?.map((item: any, index: number) => (
                      <AccordionItem>
                        <AccordionHeader targetId={`${index}`}>
                          <Avatar
                            src={item?.contactImagePath}
                            alt={item?.contactName?.charAt(0).toUpperCase()}
                          />

                          <h6 className='ms-4'>{item?.contactName}</h6>
                        </AccordionHeader>
                        <AccordionBody accordionId={`${index}`}>
                          <p className='m-0 text-muted fs-7'>{item?.contactRelationTypeName}</p>
                          <p className='m-0 text-muted fs-7'>{item?.email}</p>
                          <p className='m-0 text-muted fs-7'>{item?.cellPhoneNumber}</p>
                          <div className='d-flex align-items-center justify-content-between mt-8'>
                            <p> {intl.formatMessage({id: 'KIDLING.ACTIVE'})} </p>
                            <span
                              className={` badge   ${
                                item.active ? 'badge-success' : 'badge-danger'
                              } `}
                            >
                              {intl.formatMessage({
                                id: item.active ? 'COMMON.YES' : 'COMMON.NO',
                              })}
                            </span>
                            {/* <label className='form-check form-switch form-check-custom form-check-solid mb-2'>
                              <input
                                className='form-check-input w-30px h-20px'
                                type='checkbox'
                                name='active'
                                checked={item?.active}
                                // onChange={() => {
                                //   updateOption('active', item, !item?.active)
                                // }}
                              />
                            </label> */}
                          </div>
                          <div className='d-flex align-items-center justify-content-between '>
                            <p> {intl.formatMessage({id: 'KIDLING.ALLOW_ACCESS_KID_DATA'})} </p>
                            <span
                              className={` badge   ${
                                item.allowAccessKidData ? 'badge-success' : 'badge-danger'
                              } `}
                            >
                              {intl.formatMessage({
                                id: item.allowAccessKidData ? 'COMMON.YES' : 'COMMON.NO',
                              })}
                            </span>
                            {/* <label className='form-check form-switch form-check-custom form-check-solid mb-2'>
                              <input
                                className='form-check-input w-30px h-20px'
                                type='checkbox'
                                name='allowAccessKidData'
                                checked={item.allowAccessKidData}
                                // onChange={() => handleMealPortion(item)}
                              />
                            </label> */}
                          </div>
                          <div className='d-flex align-items-center justify-content-between'>
                            <p> {intl.formatMessage({id: 'KIDLING.AUTHORIZED_PERSON'})} </p>
                            <span
                              className={` badge   ${
                                item.authorizedPerson ? 'badge-success' : 'badge-danger'
                              } `}
                            >
                              {intl.formatMessage({
                                id: item.authorizedPerson ? 'COMMON.YES' : 'COMMON.NO',
                              })}
                            </span>
                            {/* <label className='form-check form-switch form-check-custom form-check-solid mb-2'>
                              <input
                                className='form-check-input w-30px h-20px'
                                type='checkbox'
                                name='authorizedPerson'
                                checked={item.authorizedPerson}
                                // onChange={() => handleMealPortion(item)}
                              />
                            </label> */}
                          </div>
                          <div className='d-flex align-items-center justify-content-between'>
                            <p> {intl.formatMessage({id: 'KIDLING.CAN_PICK_CHILD'})}</p>
                            <span
                              className={` badge   ${
                                item.canPickUpChild ? 'badge-success' : 'badge-danger'
                              } `}
                            >
                              {intl.formatMessage({
                                id: item.canPickUpChild ? 'COMMON.YES' : 'COMMON.NO',
                              })}
                            </span>
                            {/* <label className='form-check form-switch form-check-custom form-check-solid mb-2'>
                              <input
                                className='form-check-input w-30px h-20px'
                                type='checkbox'
                                name='canPickUpChild'
                                checked={item.canPickUpChild}
                                // onChange={() => {
                                //   updateOption('canPickUpChild', item, !item?.active)
                                // }}
                              />
                            </label> */}
                          </div>
                          {/* <div className='d-flex align-items-center justify-content-between'>
                            <p> {intl.formatMessage({id: 'KIDLING.CAN_PICK_UP_CHILD_DATE'})} </p>
                            <label className='form-check form-switch form-check-custom form-check-solid mb-2'>
                              <input
                                className='form-check-input w-30px h-20px'
                                type='checkbox'
                                name='canPickUpChildUpdatedDate'
                                checked={item.canPickUpChildUpdatedDate}
                                // onChange={() => handleMealPortion(item)}
                              />
                            </label>
                          </div> */}
                        </AccordionBody>
                      </AccordionItem>
                    ))}
                  </UncontrolledAccordion>
                  <button
                    type='button'
                    className='btn btn-primary   w-100 w-sm-auto my-8 mx-sm-6'
                    onClick={() => setShowModal(true)}
                  >
                    {intl.formatMessage({id: 'KIDLING.ADD_CONTACT'})}
                  </button>
                </Tab>
              </Tabs>
              {loading && <UsersListLoading />}
              <ContactDialog
                setShowModal={setShowModal}
                showModal={showModal}
                kid={kid}
                readContacts={readContacts}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Profile
