/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect} from 'react'
import {AbsenseListWrapper} from './absenses-list/AbsenseList'
import {useAppContext} from '../../../_metronic/layout/core/AppContextProvider'
import {useNavigate} from 'react-router-dom'

function KidAbsense() {
  const {mobileConfiguration} = useAppContext()
  const navigate = useNavigate()
  useEffect(() => {
    if (!mobileConfiguration?.KidAbsent[0]?.showInParentApp) {
      navigate('/')
    }
  }, [mobileConfiguration])

  return (
    <>
      <AbsenseListWrapper />
    </>
  )
}

export default KidAbsense
