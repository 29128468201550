import {UserCustomHeader} from '../../general-components/table-custom-header/UserCustomHeader'

function _columns() {
  return [
    {
      Header: (props: any) => <UserCustomHeader tableProps={props} title='COMMON.NAME' />,
      id: 'Name',
      accessor: 'firstName',
      sortable: true,
      width: 20,
      Cell: ({cell}: any) => {
        const row = cell.row.original
        return (
          <div className='d-flex align-items-center'>
            <div className='symbol  me-4'>
              <img src={row.imagePath !== '' ? row.imagePath : '/media/avatars/blank.png'} alt='' />
            </div>
            <div className='ml-4  d-flex flex-column justify-content-center'>
              <h5>{row.firstName + ' ' + row.lastName}</h5>
              {row.classes.length > 0 && (
                <p className='m-0 text-muted d-flex align-items-center'>
                  <i className='fa-solid fa-people-roof me-2'></i>
                  {row.classes?.map((cc: any, index: number) =>
                    cc.classesName
                      ? `${cc.classesName} ${row.classes.length - 1 > index ? ',' : ' '} `
                      : ''
                  )}
                </p>
              )}
            </div>
          </div>
        )
      },
    },
  ]
}

export default _columns
