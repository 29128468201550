import React, {useEffect, useState} from 'react'
import {HubConnectionBuilder, LogLevel} from '@microsoft/signalr'
import {useChatContext} from '../../../store/context/ChatProvider'
import {useAuth} from '../../views/auth'
function ChatInitializer() {
  const {mobileConfiguration, setConnection, setOnlineUsers, connection} = useChatContext()
  const [isConnecting, setIsConnecting] = useState(false)
  const {auth, socketUrl} = useAuth()

  useEffect(() => {
    if (
      ((connection?._connectionState !== 'Connected' &&
        connection?._connectionState !== 'Connecting') ||
        !connection) &&
      !isConnecting &&
      auth &&
      mobileConfiguration?.ChatGroup &&
      mobileConfiguration?.ChatGroup[0]?.showInParentApp &&
      !!socketUrl
    )
      startConnection()
  }, [connection, auth, mobileConfiguration, socketUrl])
  const startConnection = async () => {
    try {
      setIsConnecting(true)
      if (!!socketUrl) {
        const connection: any = new HubConnectionBuilder()
          .withUrl(socketUrl + 'chatHub', {
            accessTokenFactory: () => `${auth?.token}`,
          })
          .configureLogging(LogLevel.Error)
          .build()
        setConnection(connection)
        await connection.start()

        connection.on('OnlineUsers', function (response: any) {
          setOnlineUsers(response)
        })
      }
      setIsConnecting(false)
    } catch (e) {
      setIsConnecting(false)
    }
  }
  return <></>
}

export default ChatInitializer
