/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import {FC, useEffect, useRef, useState} from 'react'
import clsx from 'clsx'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Popover from 'react-bootstrap/Popover'
import data from '@emoji-mart/data'
import Picker from '@emoji-mart/react'
import {useIntl} from 'react-intl'
import {MessageAttachModal} from '../../messages/components/message-attachement/MessageAttachModal'
import '../styles.scss'
import {shadeColor} from '../../../utils/common'
import {useAppContext} from '../../../../_metronic/layout/core/AppContextProvider'
import GroupMembersDialog from './GroupMembersDialog'
import {useAuth} from '../../auth'
import {useChatContext} from '../../../../store/context/ChatProvider'
import {useParams} from 'react-router'
import useChatScroll from '../../../hooks/useChatScroll'
import '../styles.scss'
import ChatMessage from './message-chat-message'
import moment from 'moment'
import {UsersListLoading} from '../../../general-components/loading/UsersListLoading'
import {getBase64} from '../../../utils/getBase64'

import {
  getAllUserChats,
  getChatCount,
  getChatDetailsWithPagination,
  markMessagesAsRead,
  markSingleMessageAsReaded,
  uploadFileToGroup,
} from '../../../../controller/chat'
import {useMediaQuery} from 'react-responsive'
import {useThemeMode} from '../../../../_metronic/partials/layout/theme-mode/ThemeModeProvider'

import {HubConnectionBuilder, LogLevel} from '@microsoft/signalr'
type Props = {
  isDrawer?: boolean
}

const PrivateChatInner: FC<Props> = ({isDrawer = false}) => {
  const {mode} = useThemeMode()
  const firstMessageRef = useRef<any>(null)
  const params = useParams()
  const {currentUser, auth, socketUrl} = useAuth()
  const intl = useIntl()
  const {chatDetails, setAllChats, connection, setOnlineUsers, setConnection, setChatCount} =
    useChatContext()
  const {companyMetaData, globalLoader, mobileConfiguration} = useAppContext()
  const [inputData, setInputData] = useState('')
  const [selectedFiles, setSelectedFiles] = useState<any>([])
  const [showAttachModal, setShowAttachModal] = useState(false)
  const [generatedColor, setGeneratedColor] = useState('')
  const [showMembersDialog, setShowMembersDialog] = useState(false)
  const [allMessages, setAllMessages] = useState<any>([])
  const [newMessage, setNewMessage] = useState<any>(null)
  const [base64Files, setBase64Files] = useState<any>([])
  const [uploadingImages, setUploadingImages] = useState(false)
  const [loadMore, setLoadMore] = useState(false)
  const ref = useChatScroll(allMessages[allMessages?.length - 1])
  const [isConnecting, setIsConnecting] = useState(false)
  const isPhone = useMediaQuery({
    query: '(max-width: 762px)',
  })

  const inputRef = useRef<any>(null)

  useEffect(() => {
    if (allMessages?.length >= 20 && loadMore) loadMoreMessages()
  }, [loadMore])

  useEffect(() => {
    if (
      allMessages?.length > 0 &&
      Number(params?.id) >= 0 &&
      allMessages[0]?.chatGrouptId === Number(params?.id)
    )
      doMarkAsRead()
  }, [params, allMessages])

  useEffect(() => {
    if (chatDetails?.messages) setAllMessages(chatDetails?.messages)
  }, [chatDetails])

  useEffect(() => {
    const temp = shadeColor(companyMetaData?.primaryColor ?? '#8D4290', 10)
    setGeneratedColor(temp)
  }, [companyMetaData])

  useEffect(() => {
    if (connection) {
      connection.on('ReceiveMessage', function (response: any) {
        readAllChats()
        if (Number(response?.chatGrouptId) === Number(params?.id)) setNewMessage(response)
      })
      connection.on('MessageRemoved', function (response: any) {
        readAllChats()
        if (Number(response?.chatGrouptId) === Number(params?.id)) {
          let temp: any = [...allMessages]

          temp = temp.filter((i: any) => i.chatGroupMessageId !== response.chatGroupMessageId)

          setAllMessages(temp)
        }
      })
      connection.on('MarkedCollectionAsRead', function (response: any) {
        readAllChats()

        if (Number(response?.chatGrouptId) === Number(params?.id)) {
          let temp: any = [...allMessages]
          response?.readMessages?.forEach((resp: any) => {
            if (resp?.usersId !== currentUser?.usersId) {
              temp?.forEach((message: any) => {
                if (resp?.messageId === message.chatGroupMessageId) {
                  const user = chatDetails?.users.find((i: any) => i.usersId === resp?.usersId)
                  message['statusDto'] = [
                    {
                      chatGroupMessageId: message.chatGroupMessageId,
                      chatGroupMessageStatusReadDatetime: new Date(),
                      userId: user?.usersId,
                      usersName: user?.usersName,
                    },
                  ]
                }
              })
            }
          })
          setAllMessages(temp)
        }
        readAllChats()
      })
      connection.on('MarkedAsRead', function (response: any) {
        readAllChats()
        if (Number(response?.chatGrouptId) === Number(params?.id)) {
          if (response?.readMessage?.usersId !== currentUser?.usersId) {
            let temp: any = [...allMessages]
            temp?.forEach((message: any) => {
              if (response?.readMessage?.messageId === message.chatGroupMessageId) {
                const user = chatDetails?.users.find(
                  (i: any) => i.usersId === response?.readMessage?.usersId
                )
                message['statusDto'] = [
                  {
                    chatGroupMessageId: message.chatGroupMessageId,

                    chatGroupMessageStatusReadDatetime: new Date(),
                    userId: user?.usersId,
                    usersName: user?.usersName,
                  },
                ]
              }
            })
            setAllMessages(temp)
          }
        }
      })
    }
  }, [connection, allMessages])

  useEffect(() => {
    if (
      ((connection?._connectionState !== 'Connected' &&
        connection?._connectionState !== 'Connecting') ||
        !connection) &&
      auth &&
      mobileConfiguration?.ChatGroup &&
      mobileConfiguration?.ChatGroup[0]?.showInParentApp &&
      !isConnecting &&
      !!socketUrl
    )
      startConnection()
  }, [connection, auth, mobileConfiguration, socketUrl])

  useEffect(() => {
    if (newMessage) {
      if (Number(newMessage?.chatGrouptId) === Number(params?.id)) {
        if (newMessage?.usersId !== currentUser?.usersId)
          markSingleMessageAsRead(newMessage?.chatGroupMessageId)

        setAllMessages([...allMessages, newMessage])
        setNewMessage(null)
      }
    }
  }, [newMessage])

  useEffect(() => {
    if (selectedFiles?.length > 0) convertToBase64()
  }, [selectedFiles])

  useEffect(() => {
    if (base64Files?.length > 0) {
      sendFiles(base64Files)
    }
  }, [base64Files])

  const onScroll = () => {
    if (ref?.current) {
      const {scrollTop} = ref?.current

      if (scrollTop <= 200) {
        setLoadMore(true)
      } else {
        setLoadMore(false)
      }
    }
  }

  useEffect(() => {
    const listInnerElement = ref?.current

    if (!!listInnerElement) {
      listInnerElement.addEventListener('scroll', onScroll)

      // Clean-up
      return () => {
        listInnerElement.removeEventListener('scroll', onScroll)
      }
    }
  }, [ref?.current])

  const readAllChats = async () => {
    const resp = await getAllUserChats()
    setAllChats(resp)
    getAllChatCount()
  }
  const getAllChatCount = async () => {
    const resp = await getChatCount()

    setChatCount(resp)
  }
  const markSingleMessageAsRead = async (id: number) => {
    await markSingleMessageAsReaded(id)
  }

  const doMarkAsRead = async () => {
    if (params?.id) {
      const data = {
        chatGroupId: Number(params?.id ?? -1),
        messageFromDate: allMessages[0]?.dateTime,
        messageToDate: allMessages[allMessages.length - 1]?.dateTime,
      }
      await markMessagesAsRead(data)
    }
  }

  const convertToBase64 = async () => {
    try {
      let temp: any = []

      selectedFiles?.map(async (singleFile: any, index: number) => {
        const nameWithExt = singleFile.name.split('.')
        const ext = nameWithExt[nameWithExt.length - 1]

        await getBase64(singleFile, (result: string) => {
          temp = [...temp, {fileName: singleFile.name, strBase64: result, extension: ext}]

          if (selectedFiles?.length === temp?.length) setBase64Files(temp)
        })
      })
    } catch (e) {}
  }
  const sendFiles = async (files: any) => {
    try {
      setUploadingImages(true)
      await uploadFileToGroup(Number(params?.id ?? -1), files)
      setBase64Files([])
      setSelectedFiles([])
      setUploadingImages(false)
    } catch (e) {
      setUploadingImages(false)
    }
  }

  const sendMessage = async () => {
    if (inputData !== '' && inputData.trim() !== '') {
      if (
        ((connection?._connectionState !== 'Connected' &&
          connection?._connectionState !== 'Connecting') ||
          !connection) &&
        !isConnecting &&
        !!socketUrl
      ) {
        const con = await startConnection()
        await con
          .invoke(
            'SendMessage',
            JSON.stringify({
              ChatGroupMessageText: inputData,
              ChatGrouptId: params?.id,
              FilePath: '',
            })
          )
          .then((resp: any) => {
            setInputData('')
          })
          .catch(function (err: any) {
            return console.log(err)
          })
      } else
        await connection
          .invoke(
            'SendMessage',
            JSON.stringify({
              ChatGroupMessageText: inputData,
              ChatGrouptId: params?.id,
              FilePath: '',
            })
          )
          .then((resp: any) => {
            setInputData('')
          })
          .catch(function (err: any) {
            return console.log(err)
          })
    }
  }
  const startConnection = async () => {
    try {
      setIsConnecting(true)
      if (!!socketUrl) {
        const connection: any = new HubConnectionBuilder()
          .withUrl(socketUrl + 'chatHub', {
            accessTokenFactory: () => `${auth?.token}`,
          })
          .configureLogging(LogLevel.Error)
          .build()
        setConnection(connection)
        await connection.start()

        connection.on('OnlineUsers', function (response: any) {
          setOnlineUsers(response)
        })
      }
      setIsConnecting(false)
      return connection
    } catch (e) {
      setIsConnecting(false)
    }
  }
  const onEnterPress = (e: any) => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault()
      sendMessage()
    }
  }

  const loadMoreMessages = async () => {
    const resp = await getChatDetailsWithPagination(
      Number(params?.id),
      50,
      allMessages?.length > 0 ? allMessages[0]?.dateTime : moment().toDate()
    )
    setAllMessages([...resp, ...allMessages])
    setLoadMore(false)
  }

  return globalLoader ? (
    <UsersListLoading />
  ) : (
    <div className='card-body  m-0 mb-4 h-100 overflow-hidden chat__background position-relative px-4 '>
      <div
        className={clsx(' scroll-y h-100   scrollthin inner__chat')}
        data-kt-element='messages'
        data-kt-scroll='true'
        data-kt-scroll-activate='{default: true}'
        data-kt-scroll-max-height='100%'
        ref={ref}
      >
        {loadMore && (
          <div className='w-100 d-flex align-items-center justify-content-center'>
            <i className='fa-solid fa-spinner fa-spin fs-2'></i>
          </div>
        )}

        {allMessages?.map((message: any, index: number) => {
          const isMine = message?.usersId !== currentUser?.usersId ? false : true
          const sp: any = message?.filePath?.split('/')[2]?.split('.')
          const ext = sp ? sp[sp?.length - 1] : ''
          return (
            <div ref={index === 0 ? firstMessageRef : null} key={index}>
              <ChatMessage
                id={message.chatGroupMessageId}
                isMine={isMine}
                text={message.chatGroupMessageText}
                name={message.usersName}
                isSent={true}
                isRead={true}
                date={message.dateTime}
                img=''
                userId={message?.usersId ?? 0}
                key={index}
                mineColor={generatedColor}
                filePath={message.filePath}
                showAvatar={
                  index > 0
                    ? allMessages[index - 1]?.usersId !== message?.usersId
                      ? true
                      : false
                    : true
                }
                fileExtension={message?.filePath ? ext : ''}
                seenByUsers={message?.statusDto}
              />
            </div>
            // <div
            //   key={`message${index}`}
            //   className={clsx('d-flex', contentClass, 'mb-10', {'d-none': message.template})}
            //   {...templateAttr}
            // >
            //   <div
            //     className={clsx(
            //       'd-flex flex-column align-items',
            //       `align-items-${type === 'in' ? 'start' : 'end'}`
            //     )}
            //   >
            //     <div className='d-flex align-items-center mb-2'>
            //       {type === 'in' ? (
            //         <>
            //           <div className='symbol  symbol-35px symbol-circle '>
            //             <Avatar alt={message.usersName.charAt(0).toUpperCase()} />
            //           </div>
            //           <div className='ms-3 d-flex flex-column'>
            //             <a href='#' className='fs-5 fw-bold text-gray-900 text-hover-primary me-1'>
            //               {message.usersName}
            //             </a>
            //             <span className='text-muted fs-7 mb-1'>{message.dateTime}</span>
            //           </div>
            //         </>
            //       ) : (
            //         <>
            //           <div className='me-3 d-flex flex-column'>
            //             <span className='text-muted fs-7 mb-1'>{message.dateTime}</span>
            //             <a href='#' className='fs-5 fw-bold text-gray-900 text-hover-primary ms-1'>
            //               You
            //             </a>
            //           </div>
            //           <div className='symbol  symbol-35px symbol-circle '>
            //             <Avatar
            //               alt={currentUser?.first_name?.charAt(0).toUpperCase()}
            //               src={currentUser?.imagePath}
            //             />
            //           </div>
            //         </>
            //       )}
            //     </div>
            //     <div
            //       style={{backgroundColor: state}}
            //       className={clsx(
            //         'position-relative p-4 pb-8 rounded boxShadow mb-1',
            //         '  fw-semibold mw-lg-400px',
            //         `text-${type === 'in' ? 'start' : 'end'}`
            //       )}
            //     >
            //       <div
            //         className={clsx(
            //           ' fw-semibold mw-lg-400px ',
            //           `${type !== 'in' ? ' text-white' : 'text-dark'}`,
            //           `text-${type === 'in' ? 'start' : 'end'}`
            //         )}
            //         data-kt-element='message-text'
            //         dangerouslySetInnerHTML={{__html: message.chatGroupMessageText}}
            //       ></div>
            //       {type !== 'in' && (
            //         <i
            //           className='bi bi-check2-all fs-3 position-absolute bottom-0 start-0 '
            //           style={{color: 'skyblue'}}
            //         ></i>
            //       )}
            //     </div>
            //   </div>
            // </div>
          )
        })}
      </div>
      <div
        className='w-100 py-2 px-4 px-md-8  bg-body mx-auto '
        style={{
          position: isPhone ? 'fixed' : 'absolute',
          bottom: isPhone ? '4rem' : 0,
          height: '5rem',
          left: 0,

          overflow: 'hidden',
        }}
      >
        <div
          className='d-flex align-items-center w-100 ps-4 '
          style={{
            borderRadius: '10rem',
            border: '1px solid #ccc',
            width: '100%',
            backgroundColor: mode === 'dark' ? '#000' : '#fff',
          }}
        >
          <input
            type='text'
            className='flex-grow-1 px-4 py-2 fs-4  input__noborders bg-transparent text-body'
            name='inputData'
            onChange={(e) => setInputData(e.target.value)}
            value={inputData}
            ref={inputRef}
            onKeyDown={onEnterPress}
            placeholder={intl.formatMessage({id: 'KIDLING.TYPE_HERE'})}
          />
          <div className='d-flex flex-stack flex-wrap gap-2 p-2 border-top'>
            <div className='d-flex align-items-center me-3'>
              <OverlayTrigger
                placement='top'
                trigger='click'
                rootClose
                overlay={
                  <Popover>
                    <Picker
                      data={data}
                      onEmojiSelect={(data: any) => {
                        setInputData((prev) => prev + data.native)
                      }}
                    />
                  </Popover>
                }
              >
                <button className='btn p-1 ' type='button'>
                  <i className='fa-regular fa-face-smile fs-2 text-primary'></i>
                </button>
              </OverlayTrigger>
              <button
                className='btn px-2 p-1'
                type='button'
                onClick={() => setShowAttachModal(!showAttachModal)}
              >
                <i className='fa-solid fa-paperclip fs-3 m-0'></i>
              </button>
              <div className='btn-group '>
                <button
                  type='submit'
                  className='btn p-1 d-flex aling-items-center'
                  data-kt-users-modal-action='submit'
                  // disabled={formik.isSubmitting}
                  onClick={sendMessage}
                >
                  <span className='indicator-label '>
                    <i className='fa-solid fa-paper-plane text-primary fs-3'></i>
                  </span>

                  {/* {isSending && (
                <span className=''>
                  {intl.formatMessage({id: 'KIDLING.HOME.PLEASE_WAIT'})}...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )} */}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <GroupMembersDialog
        showModal={showMembersDialog}
        setShowModal={setShowMembersDialog}
        title={intl.formatMessage({id: 'KIDLING.CHAT_SEEN_BY'})}
      />
      {showAttachModal && (
        <MessageAttachModal
          setShowAttachModal={setShowAttachModal}
          setSelectedFiles={setSelectedFiles}
        />
      )}
      {uploadingImages && <UsersListLoading />}
    </div>
  )
}

export {PrivateChatInner}
