/* eslint-disable react-hooks/exhaustive-deps */
import {FC, useEffect, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import clsx from 'clsx'
import {useIntl} from 'react-intl'
import {toast} from 'react-toastify'
import moment from 'moment'
import {useAppContext} from '../../../../_metronic/layout/core/AppContextProvider'
import {useAuth} from '../../auth'
import {KidDeRegistration} from '../../../../controller/bulk-actions'
import {getMealTimes} from '../../../../controller/meal'
import {UsersListLoading} from '../../../general-components/loading/UsersListLoading'
import {handleAddCheckBox} from '../../../utils/common'
import ResponsiveDatePicker from '../../../general-components/responsive-material-datepicker/ResponsiveDatePicker'

type Props = {
  kid: any
  setShowDeregisteration: (state: boolean) => void
  kidDeregistrations?: any
}

const KidDeRegisterationModalForm: FC<Props> = ({
  kid,
  setShowDeregisteration,
  kidDeregistrations,
}) => {
  const {setMealtime, mealtime} = useAppContext()
  const intl = useIntl()
  const {currentUser} = useAuth()
  const [userForEdit] = useState({
    notes: '',
    date: new Date(),
    meal: [],
  })

  useEffect(() => {
    if (!mealtime) readMealTimes()
  }, [])

  const kidSchema = Yup.object().shape({
    notes: Yup.string(),
    date: Yup.date().typeError(intl.formatMessage({id: 'KIDLING.INVALID_DATETIME'})),
    meal: Yup.mixed(),
  })

  const cancel = (withRefresh?: boolean) => {
    setShowDeregisteration(false)
  }

  const formik: any = useFormik({
    initialValues: userForEdit,
    validationSchema: kidSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)

      try {
        values.meal?.map(async (item: number, index: number) => {
          const data = {
            kidId: kid.id,
            contactId: currentUser?.contact?.contactId,
            mealTimeId: item,
            recordDateTime: moment().format('yyyy-MM-DDTHH:mm:ss'),
            deRegisteredForDate: moment(values.date).format('yyyy-MM-DDTHH:mm:ss'),
            remarks: values.notes,
          }
          const resp = await KidDeRegistration(data)
          if (index === values.meal.length - 1) {
            if (!!resp) {
              toast.success(intl.formatMessage({id: 'COMMON.SUCCESS'}))
              cancel(true)
            } else {
              toast.error(intl.formatMessage({id: 'COMMON.FAIL'}))
            }
          }
        })
      } catch (ex) {
        console.error(ex)
      } finally {
        setSubmitting(false)
      }
    },
  })
  const readMealTimes = async () => {
    const resp = await getMealTimes()
    if (resp) setMealtime(resp)
  }

  return (
    <>
      <form id='kt_modal_add_user_form' className='form ' onSubmit={formik.handleSubmit} noValidate>
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column   me-n7 pe-7  '
          id='kt_modal_add_user_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_user_header'
          data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
          data-kt-scroll-offset='300px'
        >
          <div className='symbol-group symbol-hover'>
            <div className='d-flex flex-column justify-content-center align-items-center'>
              <div className={`symbol w-60px h-60px mb-8 mb-sm-0 rounded `}>
                {kid.imagePath ? (
                  <img
                    src={kid.imagePath !== '' ? kid.imagePath : '/media/avatars/blank.png'}
                    alt={`${kid.firstName.charAt(0).toUpperCase()} ${kid.lastName
                      .charAt(0)
                      .toUpperCase()}`}
                    className={`w-60px h-60px   
                      cover mb-8 mb-md-0 d-flex justify-content-center align-items-center fs-2 shadow  rounded-circle object-cover 
                     `}
                  />
                ) : (
                  <div
                    className={`symbol-label w-60px h-60px    
                     cover mb-8 mb-md-0 d-flex justify-content-center align-items-center fs-2 shadow text-black  rounded-circle object-cover 
                      `}
                    style={{
                      backgroundColor: `#F2E5E5`,
                    }}
                  >
                    {`${kid.firstName.charAt(0).toUpperCase()} ${kid.lastName
                      .charAt(0)
                      .toUpperCase()}`}
                  </div>
                )}
              </div>
              <div className='dispaly-6'>{kid.firstName}</div>
            </div>
          </div>

          <div className='fv-row my-7'>
            <label className='label  form-label fs-6 fw-semibold text-muted'>
              {intl.formatMessage({id: 'KIDLING.UNSUBSCRIPTION_FOR'})}
            </label>
            <div
              className={clsx(
                {'is-invalid': formik.touched.date && formik.errors.date},
                {
                  'is-valid': formik.touched.date && !formik.errors.date,
                }
              )}
            >
              <ResponsiveDatePicker
                onAccept={(val: any) => {
                  formik.setFieldValue('date', val)
                }}
                value={formik.values.date}
                minDate={moment().set('hour', 23).set('minute', 59).toDate()}
              />
            </div>
          </div>

          <div className='fv-row my-7'>
            <div className='row'>
              <div className='col-6'>
                <label className='form-label fs-6 fw-semibold text-muted'>
                  {intl.formatMessage({id: 'KIDLING.HOME.MEAL_TIME'})}
                </label>
              </div>
            </div>
            <div className='d-flex   flex-md-row flex-column gap-md-4'>
              {mealtime?.map((time: any) => {
                let timeDate = moment(time.dailyRegisterBeforeTime)
                  .date(moment().date())
                  .month(moment().month())
                  .year(moment().year())
                const temp = kidDeregistrations.find(
                  (item: any) => item.mealTimeId === time.mealTimeId
                )

                let isAreadyDeregisterd = false
                if (temp) {
                  isAreadyDeregisterd =
                    Math.trunc(
                      moment
                        .duration(
                          moment(moment(formik.values.date).format('yyyy-MM-DDTHH:mm:ss')).diff(
                            moment(moment(temp.deRegisteredForDate).format('yyyy-MM-DDTHH:mm:ss'))
                          )
                        )
                        .asDays()
                    ) === 0
                }
                const isDisabled =
                  (moment.duration(moment(timeDate).diff(new Date())).asMinutes() < 0 &&
                    Math.trunc(
                      moment.duration(moment(timeDate).diff(formik.values.date)).asDays()
                    ) === 0) ||
                  isAreadyDeregisterd

                const isChecked = formik.values.meal?.find((i: number) => i === time.mealTimeId)

                return (
                  <div className='d-flex flex-column justify-content-start w-100'>
                    <label
                      className={`btn btn-outline w-100 btn-outline-dashed btn-active-light-primary  text-start px-2 pb-5 my-2  d-flex align-items-center position-relative  ${
                        isChecked && 'active'
                      } ${isDisabled && 'disabled__btn'}
                      `}
                      data-kt-button='true'
                      key={time.mealTimeId}
                    >
                      <span className='form-check form-check-custom form-check-solid form-check-sm align-items-start  '>
                        <input
                          className={`form-check-input  `}
                          type='checkbox'
                          name='meal'
                          checked={isChecked}
                          onChange={() =>
                            formik.setFieldValue(
                              'meal',
                              handleAddCheckBox(formik.values.meal, time.mealTimeId)
                            )
                          }
                          disabled={isDisabled}
                        />
                      </span>

                      <span className='ms-5'>
                        <span className='fs-4 fw-bold text-gray-800 d-block'>
                          {time.mealTimeName}
                        </span>
                      </span>
                      <span className='fs-7   text-muted d-block position-absolute bottom-0 end-0 py-0 px-2'>
                        {moment(time.dailyRegisterBeforeTime).format('HH:mm')}
                      </span>
                    </label>
                    {
                      <p className='text-danger fs-7'>
                        {isDisabled
                          ? isAreadyDeregisterd
                            ? intl.formatMessage({
                                id: 'KIDLING.UNSUBSCRIBE_ALREADY',
                              })
                            : intl.formatMessage({
                                id: 'KIDLING.UNSUBSCRIPTION_FINISHED',
                              })
                          : ''}
                      </p>
                    }
                  </div>
                )
              })}
            </div>
          </div>
          <div className='fv-row mb-7'>
            <label className='  form-label fs-6 fw-semibold text-muted'>
              {intl.formatMessage({id: 'KIDLING.HOME.NOTES'})}
            </label>
            <textarea
              {...formik.getFieldProps('notes')}
              className={clsx(
                'form-control  mb-3 mb-lg-0',
                {'is-invalid': formik.touched.notes && formik.errors.notes},
                {
                  'is-valid': formik.touched.notes && !formik.errors.notes,
                }
              )}
              name='notes'
              autoComplete='off'
              disabled={formik.isSubmitting}
              rows={1}
            />
            {formik.touched.notes && formik.errors.notes && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span className='alert-danger'>{formik.errors.notes}</span>
                </div>
              </div>
            )}
          </div>
        </div>
        {/* end::Scroll */}

        {/* begin::Actions */}
        <div className='text-center pt-15'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            data-kt-users-modal-action='cancel'
            disabled={formik.isSubmitting}
          >
            {intl.formatMessage({id: 'KIDLING.HOME.DISCARD'})}
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-users-modal-action='submit'
            disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
          >
            <span className='indicator-label'>
              {' '}
              {intl.formatMessage({id: 'AUTH.GENERAL.SUBMIT_BUTTON'})}
            </span>
            {formik.isSubmitting && (
              <span className='indicator-progress'>
                {intl.formatMessage({id: 'KIDLING.HOME.PLEASE_WAIT'})}...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Actions */}
      </form>
      {formik.isSubmitting && <UsersListLoading />}
    </>
  )
}

export {KidDeRegisterationModalForm}
