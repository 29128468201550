import axios from 'axios'
import {PARENT_DASHBOARD} from '../config/endpoints'

export const getDashboard = async (query: any) => {
  return axios
    .get(PARENT_DASHBOARD + query)
    .then((response) => response.data)
    .catch((error) => {
      console.log(error)
    })
}
