/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect} from 'react'
import {AttendanceListWrapper} from './attendance-list/AttendanceList'
import {useAppContext} from '../../../_metronic/layout/core/AppContextProvider'
import {useNavigate} from 'react-router-dom'

function KidAttendance() {
  const {mobileConfiguration} = useAppContext()
  const navigate = useNavigate()
  useEffect(() => {
    if (!mobileConfiguration?.KidAttendance[0]?.showInParentApp) {
      navigate('/')
    }
  }, [mobileConfiguration])

  return (
    <>
      <AttendanceListWrapper />
    </>
  )
}

export default KidAttendance
