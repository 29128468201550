import {useEffect, useState} from 'react'
import {useAppContext} from '../../_metronic/layout/core/AppContextProvider'
import {
  getAllEventsWithRecurrents,
  readDaysOff,
  readEventsCategory,
  readHolidays,
} from '../../controller/events'
import moment from 'moment'
import {useSearchParams} from 'react-router-dom'
import {useAuth} from '../views/auth'
import {useIntl} from 'react-intl'

const useCalendar = () => {
  const intl = useIntl()
  const {
    allEvents,
    setAllEvents,
    eventsCategories,
    setEventsCategories,
    calenderDateFilter,
    setCalenderDateFilter,
    calendarFilter,
    translations,
    setCalendarFilter,
    setGlobalLoader,
    globalLoader,
  } = useAppContext()

  const [formattedEvents, setformattedEvents] = useState<any>([])
  const [holidays, setHolidays] = useState([])
  const [daysOff, setDaysOff] = useState([])
  const [filters, setFilters] = useState([])
  const [searchParams, setSearchParams] = useSearchParams()
  const {currentUser} = useAuth()

  useEffect(() => {
    let temp: any = [
      {
        id: -1,
        type: intl.formatMessage({id: 'COMMON.MINE'}),
        icon: 'fa-user',
        companyId: '',
        filter: `parentId=${currentUser?.contact?.contactId}`,
        imagePath: currentUser?.imagePath,
      },
    ]
    currentUser?.kids.map(
      (item: any, index: number) =>
        item.kidName &&
        temp.push({
          id: item.kidId,
          companyId: item.companyId,
          type: item.firstName,
          icon: 'fa-user',
          filter: `kidIds=${item.kidId}`,
          imagePath: item.imagePath,
        })
    )

    setFilters(temp)
  }, [currentUser, translations])

  useEffect(() => {
    formatEvents()
  }, [allEvents, eventsCategories, holidays, daysOff])

  const readHolidayesAndDaysOff = async () => {
    if (calendarFilter?.companyId) {
      const resp1: any = await readHolidays(
        calendarFilter?.companyId ? calendarFilter?.companyId : -1
      )
      const resp2: any = await readDaysOff(
        calendarFilter?.companyId ? calendarFilter?.companyId : -1
      )
      setDaysOff(resp2)
      setHolidays(resp1)
    }
  }

  const readEvents = async (dateFilter?: any) => {
    try {
      if (!globalLoader) {
        setGlobalLoader(true)
        setAllEvents([])
        const resp = await getAllEventsWithRecurrents(
          calendarFilter.filter,

          dateFilter?.start
            ? moment(dateFilter?.startStr).format('yyyy-MM-DDTHH:mm:ss')
            : calenderDateFilter?.start
            ? moment(calenderDateFilter?.startStr).format('yyyy-MM-DDTHH:mm:ss')
            : moment().startOf('week').format('yyyy-MM-DDTHH:mm:ss'),
          dateFilter?.end
            ? moment(dateFilter?.endStr).format('yyyy-MM-DDTHH:mm:ss')
            : calenderDateFilter?.end
            ? moment(calenderDateFilter?.endStr).format('yyyy-MM-DDTHH:mm:ss')
            : moment().endOf('week').format('yyyy-MM-DDTHH:mm:ss')
        )

        setAllEvents(resp)
      }
    } catch (e) {
      console.log('error', e)
    }
  }

  const formatEvents = async () => {
    try {
      let tempEvents: any = []
      if (holidays?.length > 0) {
        holidays?.map((event: any) => {
          let tempObj: any = {
            title: event.holidayName,
            start: moment(event.holidayDate)
              .set('hours', 0)
              .set('minutes', 0)
              .format('yyyy-MM-DDTHH:mm:ss'),
            end: moment(event.holidayDate)
              .add(event.numberOfDays - 1, 'days')
              .set('hours', 23)
              .set('minutes', 59)
              .format('yyyy-MM-DDTHH:mm:ss'),
            duration: {
              days: moment
                .duration(
                  moment(moment(event.holidayDate).add(event.numberOfDays - 1, 'days')).diff(
                    moment(event.holidayDate)
                  )
                )
                .asDays(),
            },
            allDay: true,
            extendedProps: {...event},
            colorCode: '#ff0000',
            display: 'background',
          }
          if (event.recurrent)
            tempObj['rrule'] = {
              freq: 'yearly',
              interval: 1,
              dtstart: event.holidayDate,
            }

          tempEvents.push(tempObj)
        })
      }

      if (daysOff?.length > 0) {
        daysOff?.map((event: any) => {
          let tempObj: any = {
            title: event.dayOffName,
            start: moment(event.dayOffDate)
              .set('hours', 0)
              .set('minutes', 0)
              .format('yyyy-MM-DDTHH:mm:ss'),
            end: moment(event.dayOffDate)
              .add(event.numberOfDays, 'days')
              .set('hours', 23)
              .set('minutes', 59)
              .format('yyyy-MM-DDTHH:mm:ss'),
            allDay: true,
            extendedProps: {...event},
            colorCode: '#ff0000',
            display: 'background',
          }

          tempEvents.push(tempObj)
        })
      }

      allEvents?.map((event: any) => {
        let eventRecurrent: any = []

        if (event?.recurrings?.length > 0) {
          event?.recurrings?.map((item: any) =>
            eventRecurrent.push(item.weekdayId === 0 ? 6 : item.weekdayId - 1)
          )
        }
        const category = eventsCategories?.find(
          (item: any) => item.eventsCategoryId === event.eventsCategoryId
        )

        let tempObj: any = {
          title: event.eventsName,
          start: event.startsOn,
          end: moment(event.startsOn)
            .set('hours', moment(event.endsOn).hours())
            .set('minutes', moment(event.endsOn).minutes())
            .format('yyyy-MM-DDTHH:mm:ss'),
          duration: {
            hours: moment
              .duration(
                moment(
                  moment(event.startsOn)
                    .set('hours', moment(event.endsOn).hours())
                    .set('minutes', moment(event.endsOn).minutes())
                    .format('yyyy-MM-DDTHH:mm:ss')
                ).diff(event.startsOn)
              )
              .asHours(),
          },
          colorCode: category?.colorCode || '#fff',
          allDay: false,
          extendedProps: {...event},
        }
        if (eventRecurrent.length === 0) {
          tempObj = {
            ...tempObj,
            rrule: {
              freq: 'daily',
              interval: 1,
              dtstart: event.startsOn,
              until: event.endsOn,
            },
          }
        }
        if (eventRecurrent.length > 0) {
          tempObj = {
            ...tempObj,
            rrule: {
              freq: 'weekly',
              interval: 1,
              byweekday: eventRecurrent,
              dtstart: event.startsOn,
              until: event.endsOn,
            },
          }
        }

        tempEvents.push(tempObj)
      })

      setformattedEvents(tempEvents)
      setGlobalLoader(false)
    } catch (e) {
      console.log(e)
    }
  }

  const readEventsCat = async () => {
    if (calendarFilter?.companyId) {
      const resp = await readEventsCategory(
        `${calendarFilter?.companyId ? `companyId=${calendarFilter?.companyId}` : 'companyId=-1'}`
      )

      if (resp) setEventsCategories(resp)
    }
  }

  const triggerDateChanged = async (dateObj: any) => {
    setCalenderDateFilter(dateObj)
    readEvents(dateObj)
  }
  const handleDateSelect = (selectInfo: any) => {
    // console.log('selectInfo', selectInfo)
    // if (selectInfo) setModal({showModal: true, data: selectInfo})
  }

  return {
    daysOff,
    holidays,
    readEvents,
    readHolidayesAndDaysOff,
    triggerDateChanged,
    readEventsCat,
    formattedEvents,
    handleDateSelect,
    filters,
    setFilters,
    searchParams,
    setSearchParams,
    calendarFilter,
  }
}
export default useCalendar
