import moment from 'moment'
import {UserCustomHeader} from '../../../../../general-components/table-custom-header/UserCustomHeader'

function _columns({totalOverTime, setTotalOverTime}: any) {
  return [
    {
      Header: (props: any) => <UserCustomHeader tableProps={props} title='KIDLING.HOME.CHECKIN' />,
      accessor: 'dateTimeIn',
      id: 'in',
      sortable: true,
      Cell: ({cell}: any) => {
        return (
          <div>
            <i className='fa-solid fa-calendar-days me-2 '></i>

            {cell.row.original.dateTimeIn &&
              moment(cell.row.original.dateTimeIn).format('DD.MM.yyyy HH:mm')}
          </div>
        )
      },
    },
    {
      Header: (props: any) => <UserCustomHeader tableProps={props} title='KIDLING.HOME.CHECKOUT' />,

      id: 'out',
      sortable: true,
      accessor: 'dateTimeOut',
      Cell: ({cell}: any) => {
        return (
          <div>
            <i className='fa-solid fa-calendar-days me-2 '></i>

            {cell.row.original.dateTimeOut
              ? moment(cell.row.original.dateTimeOut).format('DD.MM.yyyy HH:mm')
              : '--'}
          </div>
        )
      },
    },
    {
      Header: (props: any) => (
        <UserCustomHeader tableProps={props} title='KIDLING.HOME.TIMESPENT' />
      ),
      accessor: 'timespan',
      id: 'timespan',
      sortable: true,

      Cell: ({cell}: any) => {
        let timeOut = cell.row.original.dateTimeOut
        const duration = moment.duration(moment(timeOut).diff(cell.row.original.dateTimeIn))
        return (
          <div>
            <i className='fa-solid fa-clock me-2'></i>
            {timeOut ? moment.utc(duration.as('milliseconds')).format('HH:mm') : '--:--'}
          </div>
        )
      },
    },
  ]
}

export default _columns
