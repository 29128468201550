import clsx from 'clsx'
import React, {FC, ReactElement, useEffect, useState} from 'react'
import {useMediaQuery} from 'react-responsive'
import {
  TableInstance,
  usePagination,
  UsePaginationInstanceProps,
  UsePaginationState,
  useSortBy,
  UseSortByInstanceProps,
  useTable,
  useGlobalFilter,
  useRowSelect,
} from 'react-table'
import CustomPagination from '../custom-pagination/CustomPagination'
import TableMobileView from '../mobile-table'

import './styles.scss'
export type TableInstanceWithHooks<T extends object> = TableInstance<T> &
  UsePaginationInstanceProps<T> &
  UseSortByInstanceProps<T> & {
    state: UsePaginationState<T>
  }
interface TableProps {
  columns: any
  data: any
  handleDelete?: any
  editCell?: any
  hideCards?: boolean
  pageSizeItems?: number
  hidePagination?: boolean
  useExpand?: boolean
  shrinkedView?: boolean
  _mobileColumns?: any
}
const TableContainer: FC<TableProps> = ({
  columns,
  data,
  handleDelete,
  editCell,
  hideCards,
  pageSizeItems,
  hidePagination,
  useExpand,
  _mobileColumns,
  shrinkedView,
}): ReactElement => {
  const isMobile = useMediaQuery({
    query: '(max-width: 767px)',
  })

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: {pageIndex, pageSize = pageSizeItems || 50},
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,

    (hooks: any) => {
      hooks.allColumns.push((_columns: any) => [..._columns])
    }
  ) as TableInstanceWithHooks<any>
  useEffect(() => {
    setPageSize(pageSizeItems || 50)
  }, [])

  useEffect(() => {
    window.scrollTo({top: 100, left: 100, behavior: 'smooth'})
  }, [pageIndex])

  return (
    <>
      {(!isMobile || hideCards) && (
        <div className='table-responsive'>
          <table
            id='kt_table_users'
            className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer w-100 mw-100'
            {...getTableProps()}
          >
            <thead>
              {headerGroups?.map(
                (headerGroup) =>
                  headerGroup.headers.length > 0 && (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup?.headers?.map(
                        (column: any) =>
                          !column.hideHeader && (
                            <th
                              className='cell'
                              {...(!column.canSort
                                ? column.getHeaderProps()
                                : column.getHeaderProps(column.getSortByToggleProps()))}
                            >
                              {column.render('Header')}
                            </th>
                          )
                      )}
                    </tr>
                  )
              )}
            </thead>
            <tbody className='text-gray-600 fw-semibold' {...getTableProps()}>
              {page?.map((row, i) => {
                prepareRow(row)
                return (
                  <tr {...row.getRowProps()} className='truncate cursor-pointer '>
                    {row.cells?.map((cell) => {
                      return (
                        <td {...cell.getCellProps()} className='p-0 p-md-2'>
                          {cell.render('Cell')}
                        </td>
                      )
                    })}
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      )}
      {isMobile && !hideCards && !shrinkedView && !useExpand && (
        <TableMobileView
          data={page}
          columns={columns}
          headerGroups={headerGroups}
          prepareRow={prepareRow}
          getTableProps={getTableProps}
        />
      )}

      {!hidePagination && (
        <CustomPagination
          gotoPage={gotoPage}
          canPreviousPage={canPreviousPage}
          previousPage={previousPage}
          pageCount={pageCount}
          nextPage={nextPage}
          canNextPage={canNextPage}
          setPageSize={setPageSize}
          pageIndex={pageIndex}
          pageOptions={pageOptions}
          pageSize={pageSize}
          show={data.length > pageSize}
          total={data.length}
        />
      )}
    </>
  )
}

export default TableContainer
