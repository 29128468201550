/* eslint-disable react-hooks/exhaustive-deps */
import {useMemo} from 'react'
import {KTCardBody} from '../../../../../_metronic/helpers'
import {useIntl} from 'react-intl'
import {UsersListLoading} from '../../../../general-components/loading/UsersListLoading'
import SweetAlert from 'react-bootstrap-sweetalert'
import TableContainer from '../../../../general-components/table-container/TableContainer'
import {useAppContext} from '../../../../../_metronic/layout/core/AppContextProvider'
import _columns from './columns/_columns'
import {NoRecords} from '../../../../general-components/loading/NoRecords'
import {useAuth} from '../../../auth'
import useAbsence from '../../../../hooks/useAbsence'

const KidsTable = () => {
  const {currentUser} = useAuth()
  const contactName = currentUser?.contact?.contactName || ''
  const contactId = currentUser?.contact?.contactId || ''
  const {allAbsences, globalLoader} = useAppContext()
  const {handleDeleteAbsense, editCell, doDelete, setShowDelete, showDelete} = useAbsence()
  const intl = useIntl()

  const columns: any = useMemo(
    () => _columns({handleDeleteAbsense, editCell, contactId, contactName}),
    []
  )

  return (
    <>
      {allAbsences && allAbsences?.length !== 0 && (
        <KTCardBody className='py-4'>
          <TableContainer
            columns={columns}
            data={allAbsences}
            handleDelete={handleDeleteAbsense}
            editCell={editCell}
          />
        </KTCardBody>
      )}
      {!globalLoader && allAbsences?.length === 0 && <NoRecords />}
      {(globalLoader || !columns) && <UsersListLoading />}
      <SweetAlert
        show={showDelete}
        warning
        showCancel
        confirmBtnText={intl.formatMessage({id: 'COMMON.YES'})}
        cancelBtnText={intl.formatMessage({id: 'COMMON.CANCEL'})}
        confirmBtnBsStyle='danger'
        title={intl.formatMessage({id: 'KIDLING.MENU.ARE_YOU_SURE?'})}
        focusCancelBtn
        onConfirm={() => doDelete()}
        onCancel={() => setShowDelete(false)}
      />
    </>
  )
}

export {KidsTable}
