import {FC} from 'react'
import {MoodSearchComponent} from './MoodSearchComponent'
import {MoodToolbar} from './MoodToolbar'
import CustomDateRangePicker from '../../../../general-components/date-range-picker/DateRangePicker'
import useMood from '../../../../hooks/useMood'

interface Props {
  title?: string
}
const MoodHeader: FC<Props> = ({title}) => {
  const {handleDateChange} = useMood()
  return (
    <div className=' border-0 pt-6  w-100'>
      <div className='d-flex align-items-center justify-content-between'>
        {title && <h4 className='d-flex align-items-center h-100'>{title}</h4>}
        <CustomDateRangePicker handleDateChange={handleDateChange} />
      </div>

      <div className='d-flex  align-items-center w-100  w-md-auto'>
        <MoodSearchComponent />
        <MoodToolbar />
      </div>
    </div>
  )
}

export {MoodHeader}
