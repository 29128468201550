import {FC, useEffect} from 'react'
import {KidDeRegisterationModalHeader} from './KidDeRegisterationModalHeader'
import {KidDeRegisterationModalForm} from './KidDeRegisterationModalForm'

type KidProps = {
  kid: any
  setShowDeregisteration: (state: boolean) => void
  kidDeregistrations?: any
}
const KidDeRegisterationModal: FC<KidProps> = ({
  kid,
  setShowDeregisteration,
  kidDeregistrations,
}) => {
  useEffect(() => {
    document.body.classList.add('modal-open')
    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [])

  return (
    <>
      <div
        className='modal modalfade show d-block '
        id='kt_modal_add_user'
        role='dialog'
        tabIndex={-1}
        aria-modal='true'
      >
        {/* begin::Modal dialog */}
        <div className='modal-dialog modal-dialog-centered mw-750px'>
          {/* begin::Modal content */}
          <div className='modal-content'>
            <KidDeRegisterationModalHeader setShowDeregisteration={setShowDeregisteration} />
            {/* begin::Modal body */}
            <div className='modal-body overflow-auto mx-5 mx-xl-15 my-7'>
              <KidDeRegisterationModalForm
                kid={kid}
                setShowDeregisteration={setShowDeregisteration}
                kidDeregistrations={kidDeregistrations}
              />
            </div>
            {/* end::Modal body */}
          </div>
          {/* end::Modal content */}
        </div>
        {/* end::Modal dialog */}
      </div>
      {/* begin::Modal Backdrop */}
      <div className='modal-backdrop fade show'></div>
      {/* end::Modal Backdrop */}
    </>
  )
}

export {KidDeRegisterationModal}
