import {DASHBOARD_NOTIFICATIONS} from '../config/endpoints'
import axios from 'axios'
export const getAllDashboardNotifications = async (
  filter: any,
  From: string,
  To: string,
  PageNumber: number,
  PageSize: number
) => {
  return axios
    .get(
      `${DASHBOARD_NOTIFICATIONS}?${filter}&PageNumber=${PageNumber}&PageSize=${PageSize}&From=${
        From && To ? From : null
      }&To=${From && To ? To : null}`
    )
    .then((response) => response?.data?.data)
    .catch((error) => {
      console.log(error)
    })
}
