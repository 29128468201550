import axios from 'axios'
import {READFILE} from '../config/endpoints'

export const readFileFrom = async (FilePath: string) => {
  return axios
    .get(`${READFILE}`, {
      params: {
        FilePath,
      },
    })
    .then((response) => response.data)
    .catch((error) => {
      console.log(error)
    })
}
