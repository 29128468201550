import clsx from 'clsx'
import React, {FC, ReactElement, useEffect, useState} from 'react'
import {useMediaQuery} from 'react-responsive'
import {
  TableInstance,
  usePagination,
  UsePaginationInstanceProps,
  UsePaginationState,
  useSortBy,
  UseSortByInstanceProps,
  useTable,
  useGlobalFilter,
  useRowSelect,
} from 'react-table'

import CustomPagination from '../custom-pagination/CustomPagination'
import ActivityCard from '../ActivityCard/ActivityCard'

import {UsersListLoading} from '../loading/UsersListLoading'
import {useAppContext} from '../../../_metronic/layout/core/AppContextProvider'
import {Loading} from '../loading/General'
import {NoRecords} from '../loading/NoRecords'

export type TableInstanceWithHooks<T extends object> = TableInstance<T> &
  UsePaginationInstanceProps<T> &
  UseSortByInstanceProps<T> & {
    state: UsePaginationState<T>
  }
interface TableProps {
  columns: any
  data: any
}
const TableCardsContainer: FC<TableProps> = ({columns, data}): ReactElement => {
  const {globalLoader} = useAppContext()
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: {pageIndex, pageSize = 50},
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,

    (hooks: any) => {
      hooks.allColumns.push((_columns: any) => [..._columns])
    }
  ) as TableInstanceWithHooks<any>
  useEffect(() => {
    setPageSize(50)
  }, [])

  return (
    <div>
      <div className='row gx-5 gy-5 mb-8'>
        {page?.length > 0 &&
          page?.map((item: any, index: number) => (
            <div className='col-12 col-xs-6 col-md-4' key={index}>
              <ActivityCard
                image={item.original.image}
                name={`${item.original.name}  `}
                activities={item.original.subItems}
                setShowEditModal={
                  item.original.setShowEditModal
                    ? () => item.original.setShowEditModal(item.id)
                    : null
                }
                handleDelete={(id: string) =>
                  item.original.handleDelete ? item.original.handleDelete(id) : null
                }
              />
            </div>
          ))}

        {globalLoader && <UsersListLoading />}
        {!globalLoader && data.length === 0 && <NoRecords />}
      </div>
      <CustomPagination
        gotoPage={gotoPage}
        canPreviousPage={canPreviousPage}
        previousPage={previousPage}
        pageCount={pageCount}
        nextPage={nextPage}
        canNextPage={canNextPage}
        setPageSize={setPageSize}
        pageIndex={pageIndex}
        pageOptions={pageOptions}
        pageSize={pageSize}
        show={data.length > 50}
        total={data.length}
      />
    </div>
  )
}

export default TableCardsContainer
