import moment from 'moment'
import {readFileFrom} from '../../controller/image'
import {groupBy} from '../utils/common'
import {decodeBase64} from '../utils/getBase64'
import {getFOODMenu} from '../../controller/meal'
import {useEffect, useState} from 'react'
import {useAppContext} from '../../_metronic/layout/core/AppContextProvider'

const useFoodMenu = () => {
  const [start, setStart] = useState(new Date())
  const {setGlobalLoader, childFilter} = useAppContext()
  const [currentTab, setCurrentTab] = useState(0)
  const [childClasses, setChildClasses] = useState<any>({})
  const [FoodItems, setFoodItems] = useState<any>([])
  const [isReading, setIsReading] = useState('')
  const [currentDownload, setCurrentDownload] = useState<any>({})
  const [clicked, setClicked] = useState(false)
  const {searchTerm, setSearchTerm, setFilters}: any = useAppContext()

  useEffect(() => {
    if (clicked) filterData()
  }, [clicked])
  useEffect(() => {
    if (childFilter) {
      setCurrentTab(0)
      const groupped = groupBy(childFilter.classes, 'classesId')

      let classesNames: any = []
      Object.keys(groupped)?.map((key, index) => {
        classesNames.push({classId: key, name: groupped[key][0].classesName})
      })
      setChildClasses(classesNames)
    }
  }, [childFilter])

  const readFoodMenu = async () => {
    try {
      setGlobalLoader(true)

      let temp: any = {}
      const length = Object.keys(childClasses).length

      childClasses?.map(async (item: any, index: any) => {
        const resp: any = await getFOODMenu({
          classesId: item.classId,
          companyId: childFilter.companyId,
          date: moment(start).format('MM/DD/yyy'),
        })

        const sub = item.name
        let val: any = []
        if (resp?.length > 0) {
          val = groupBy(resp, 'mealTimeName')
        }
        temp = {...temp, [sub]: val}
        if (index === length - 1) {
          setFoodItems(temp)

          setGlobalLoader(false)
        }
      })
    } catch (e) {
      console.log('error', e)
    }
  }

  const triggerDateChanged = async (dateObj: any) => {
    setStart(dateObj.start)
  }

  const openFile = async (item: any) => {
    try {
      setIsReading(item.filePath)
      const resp = await readFileFrom(item.filePath)
      const type = item.filePath.split('/')[2].split('.')[1]

      const blob = await decodeBase64({base64: resp, name: item.foodItemName, type: type})

      const url = URL.createObjectURL(blob)

      window.open(url)
      setIsReading('')
      let temp = {...currentDownload}

      temp[item.filePath] = url
      setCurrentDownload(temp)
      if ((window as any)['ReactNativeWebView'])
        (window as any)['ReactNativeWebView'].postMessage(
          JSON.stringify({file: {id: item.filePath, base64: resp}})
        )
    } catch (e) {
      setIsReading('')
      console.log(e)
    }
  }
  const filterData = async () => {
    if (searchTerm.trim() !== '') {
      setFilters(`kidName.toLower().contains("${searchTerm}")`)
      setClicked(false)
    }
  }

  return {
    openFile,
    triggerDateChanged,
    readFoodMenu,
    isReading,
    currentTab,
    currentDownload,
    setCurrentTab,
    setFoodItems,
    childClasses,
    start,
    FoodItems,
    setClicked,
    setSearchTerm,
    searchTerm,
  }
}
export default useFoodMenu
