import React, {useState} from 'react'
import {KTCard, KTCardBody} from '../../../_metronic/helpers'
import {styled} from '@mui/material/styles'
import Stack from '@mui/material/Stack'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import StepConnector, {stepConnectorClasses} from '@mui/material/StepConnector'
import {StepIconProps} from '@mui/material/StepIcon'
import './styles.scss'
import ChildStep from './ChildStep'
import ContactStep from './ContactStep'
import SummaryStep from './SummaryStep'

const ColorlibConnector = styled(StepConnector)(({theme}) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderRadius: 1,
  },
}))

const ColorlibStepIconRoot = styled('div')<{
  ownerState: {completed?: boolean; active?: boolean}
}>(({theme, ownerState}) => ({
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
  zIndex: 1,
  color: '#fff',
  width: 50,
  height: 50,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.active && {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  }),
  ...(ownerState.completed && {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
  }),
}))

function ColorlibStepIcon(props: StepIconProps) {
  const {active, completed, className} = props

  const icons: {[index: string]: React.ReactElement} = {
    1: <i className='fa-solid fa-child-reaching text-white fs-3'></i>,
    2: <i className='fa-solid fa-hands-holding-child text-white fs-3'></i>,
    3: <i className='fa-solid fa-arrow-up-9-1  text-white fs-3'></i>,
  }

  return (
    <ColorlibStepIconRoot ownerState={{completed, active}} className={className}>
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  )
}

function Registration() {
  const steps = ['Create Child', 'Contact', 'Summary']
  const [allValues, setAllValues] = useState({})
  const [activeStep, setActiveStep] = useState(0)
  const handleSubmit = () => {}

  return (
    <KTCard>
      <KTCardBody>
        <div className='page__container mx-auto d-flex flex-column justify-content-center'>
          <Stack spacing={4}>
            <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
              {steps?.map((label) => (
                <Step key={label}>
                  <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </Stack>
          <div className=''>
            {activeStep === 0 && (
              <ChildStep
                handleNext={(val: any) => {
                  setAllValues((prev) => ({...prev, child: val}))
                  setActiveStep((prev) => prev + 1)
                }}
              />
            )}
            {activeStep === 1 && (
              <ContactStep
                handleNext={(val: any) => {
                  setAllValues((prev) => ({...prev, parent: val}))
                  setActiveStep((prev) => prev + 1)
                }}
                handleBack={() => setActiveStep((prev) => prev - 1)}
              />
            )}
            {activeStep === 2 && (
              <SummaryStep
                handleSubmit={handleSubmit}
                handleBack={() => setActiveStep((prev) => prev - 1)}
                allValues={allValues}
              />
            )}
          </div>
        </div>
      </KTCardBody>
    </KTCard>
  )
}

export default Registration
