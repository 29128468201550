import {FC, ReactElement, useMemo, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import ResponsiveDatePicker from '../../general-components/responsive-material-datepicker/ResponsiveDatePicker'
import {useIntl} from 'react-intl'
import Select from 'react-select'
import clsx from 'clsx'
import countryList from 'react-select-country-list'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import de from 'react-phone-input-2/lang/de.json'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import './styles.scss'
interface Props {
  handleNext: any
  handleBack: any
}
const ContactStep: FC<Props> = ({handleNext, handleBack}): ReactElement => {
  const intl = useIntl()
  const [eventInitialValues] = useState<any>({
    firstName: '',
    lastName: '',
    origincountry: '',
    dateOfBirth: new Date(),
    phone: '',
    email: '',
    country: '',
    city: '',
    PostalCode: '',
    street: '',
    houseNumber: '',
    Mothertongue: '',
    employer: '',
    profession: '',
  })
  const options = useMemo(() => countryList().getData(), [])
  const [Guardians, setGuardians] = useState([eventInitialValues])
  const [open, setOpen] = useState('0')

  const kidSchema = Yup.object().shape({})
  const formik: any = useFormik({
    initialValues: eventInitialValues,
    enableReinitialize: true,
    validationSchema: kidSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      try {
        handleNext(Guardians)
      } catch (ex) {
        console.log(ex)
      } finally {
        setSubmitting(true)
      }
    },
  })

  return (
    <form
      id='kt_modal_add_user_form'
      className='form container p-8 d-flex flex-column align-items-end'
      onSubmit={formik.handleSubmit}
      noValidate
    >
      {Guardians?.map((item, i) => (
        <Accordion expanded={`${i}` === open}>
          <AccordionSummary
            aria-controls='panel1-content'
            id='panel1-header'
            onClick={() => setOpen((prev) => (prev === `${i}` ? '-1' : `${i}`))}
          >
            <div className='w-100 d-flex align-items-center justify-content-between'>
              <h4>
                {intl.formatMessage({id: 'KIDLING.GUARDIAN'})} {i + 1}
              </h4>
              {Guardians.length > 1 && (
                <button
                  type='button'
                  className='btn'
                  onClick={() => {
                    let temp = Guardians.filter((g, ii) => ii !== i)
                    setGuardians(temp)
                  }}
                >
                  <i className='fa-solid fa-trash fs-3 text-danger'></i>
                </button>
              )}
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div className='row align-items-center'>
              <div className='col-md-6 col-12 my-3'>
                <label className=' required form-label fs-6 fw-semibold text-muted'>
                  {intl.formatMessage({id: 'PARENT.FIRSTNAME'})}
                </label>
                <input
                  {...formik.getFieldProps('firstName')}
                  className={clsx(
                    'form-control  mb-3 mb-lg-0',
                    {'is-invalid': formik.touched.firstName && formik.errors.firstName},
                    {
                      'is-valid': formik.touched.firstName && !formik.errors.firstName,
                    }
                  )}
                  name='firstName'
                  autoComplete='off'
                  value={Guardians[i].firstName}
                  onChange={(e: any) => {
                    const newArray = [...Guardians]
                    Guardians[i].firstName = e.target.value
                    setGuardians(newArray)
                  }}
                />
              </div>
              <div className='col-md-6 col-12 my-3'>
                <label className=' required form-label fs-6 fw-semibold text-muted'>
                  {intl.formatMessage({id: 'PARENT.LASTNAME'})}
                </label>
                <input
                  {...formik.getFieldProps('lastName')}
                  className={clsx(
                    'form-control  mb-3 mb-lg-0',
                    {'is-invalid': formik.touched.lastName && formik.errors.lastName},
                    {
                      'is-valid': formik.touched.lastName && !formik.errors.lastName,
                    }
                  )}
                  name='lastName'
                  autoComplete='off'
                  disabled={formik.isSubmitting}
                  value={Guardians[i].lastName}
                  onChange={(e: any) => {
                    const newArray = [...Guardians]
                    Guardians[i].lastName = e.target.value
                    setGuardians(newArray)
                  }}
                />
              </div>
              <div className='col-md-6 col-12 my-3'>
                <label className=' form-label fs-6 fw-semibold text-muted'>
                  {intl.formatMessage({id: 'KIDLING.ORIGIN_COUNTRY'})}
                </label>
                <Select
                  options={options}
                  value={Guardians[i].origincountry}
                  onChange={(val: any) => {
                    const newArray = [...Guardians]
                    Guardians[i].origincountry = val
                    setGuardians(newArray)
                  }}
                />
              </div>
              <div className='col-md-6 col-12 my-3'>
                <label className=' form-label fs-6 fw-semibold text-muted'>
                  {intl.formatMessage({id: 'KIDLING.BIRTHDAY'})}
                </label>
                <ResponsiveDatePicker
                  value={Guardians[i].dateOfBirth}
                  onAccept={(val: any) => {
                    const newArray = [...Guardians]
                    Guardians[i].dateOfBirth = val
                    setGuardians(newArray)
                  }}
                />
              </div>

              <div className='col-md-6 col-12 my-3'>
                <label className=' form-label fs-6 fw-semibold text-muted'>
                  {intl.formatMessage({id: 'PARENT.phone'})}
                </label>

                <PhoneInput
                  inputProps={{
                    name: 'phone',
                  }}
                  enableSearch
                  country={'de'}
                  value={Guardians[i].phone}
                  onChange={(value: any, data: any, event: any, formattedValue: any) => {
                    const newArray = [...Guardians]
                    Guardians[i].phone = formattedValue
                    setGuardians(newArray)
                  }}
                  localization={de}
                  containerClass='w-100'
                  inputClass='w-100 py-6'
                />
              </div>

              <div className='col-md-6 col-12 my-3'>
                <label className=' form-label fs-6 fw-semibold text-muted'>
                  {intl.formatMessage({id: 'KIDLING.EMAIL'})}
                </label>
                <input
                  {...formik.getFieldProps('email')}
                  className={clsx(
                    'form-control  mb-3 mb-lg-0',
                    {'is-invalid': formik.touched.email && formik.errors.email},
                    {
                      'is-valid': formik.touched.email && !formik.errors.email,
                    }
                  )}
                  name='email'
                  autoComplete='off'
                  disabled={formik.isSubmitting}
                  value={Guardians[i].email}
                  onChange={(e: any) => {
                    const newArray = [...Guardians]
                    Guardians[i].email = e.target.value
                    setGuardians(newArray)
                  }}
                />
              </div>
              <div className='col-md-6 col-12 my-3'>
                <label className=' form-label fs-6 fw-semibold text-muted'>
                  {intl.formatMessage({id: 'KIDLING.COUNTRY'})}
                </label>
                <Select
                  options={options}
                  value={Guardians[i].country}
                  onChange={(val: any) => {
                    const newArray = [...Guardians]
                    Guardians[i].country = val
                    setGuardians(newArray)
                  }}
                />
              </div>

              <div className='col-md-6 col-12 my-3'>
                <label className=' form-label fs-6 fw-semibold text-muted'>
                  {intl.formatMessage({id: 'KIDLING.CITY'})}
                </label>
                <input
                  {...formik.getFieldProps('city')}
                  className={clsx(
                    'form-control  mb-3 mb-lg-0',
                    {'is-invalid': formik.touched.city && formik.errors.city},
                    {
                      'is-valid': formik.touched.city && !formik.errors.city,
                    }
                  )}
                  name='city'
                  autoComplete='off'
                  disabled={formik.isSubmitting}
                  value={Guardians[i].city}
                  onChange={(e: any) => {
                    const newArray = [...Guardians]
                    Guardians[i].city = e.target.value
                    setGuardians(newArray)
                  }}
                />
              </div>
              <div className='col-md-6 col-12 my-3'>
                <label className=' form-label fs-6 fw-semibold text-muted'>
                  {intl.formatMessage({id: 'KIDLING.POSTAL_CODE'})}
                </label>
                <input
                  {...formik.getFieldProps('PostalCode')}
                  className={clsx(
                    'form-control  mb-3 mb-lg-0',
                    {'is-invalid': formik.touched.PostalCode && formik.errors.PostalCode},
                    {
                      'is-valid': formik.touched.PostalCode && !formik.errors.PostalCode,
                    }
                  )}
                  name='PostalCode'
                  autoComplete='off'
                  disabled={formik.isSubmitting}
                  value={Guardians[i].PostalCode}
                  onChange={(e: any) => {
                    const newArray = [...Guardians]
                    Guardians[i].PostalCode = e.target.value
                    setGuardians(newArray)
                  }}
                />
              </div>
              <div className='col-md-6 col-12 my-3'>
                <label className=' form-label fs-6 fw-semibold text-muted'>
                  {intl.formatMessage({id: 'PARENT.StreetName'})}
                </label>
                <input
                  {...formik.getFieldProps('street')}
                  className={clsx(
                    'form-control  mb-3 mb-lg-0',
                    {'is-invalid': formik.touched.street && formik.errors.street},
                    {
                      'is-valid': formik.touched.street && !formik.errors.street,
                    }
                  )}
                  name='street'
                  autoComplete='off'
                  disabled={formik.isSubmitting}
                  value={Guardians[i].street}
                  onChange={(e: any) => {
                    const newArray = [...Guardians]
                    Guardians[i].street = e.target.value
                    setGuardians(newArray)
                  }}
                />
              </div>
              <div className='col-md-6 col-12 my-3'>
                <label className=' form-label fs-6 fw-semibold text-muted'>
                  {intl.formatMessage({id: 'KIDLING.HOUSE_NUMBER'})}
                </label>
                <input
                  {...formik.getFieldProps('houseNumber')}
                  className={clsx(
                    'form-control  mb-3 mb-lg-0',
                    {'is-invalid': formik.touched.houseNumber && formik.errors.houseNumber},
                    {
                      'is-valid': formik.touched.houseNumber && !formik.errors.houseNumber,
                    }
                  )}
                  name='houseNumber'
                  autoComplete='off'
                  disabled={formik.isSubmitting}
                  value={Guardians[i].houseNumber}
                  onChange={(e: any) => {
                    const newArray = [...Guardians]
                    Guardians[i].houseNumber = e.target.value
                    setGuardians(newArray)
                  }}
                />
              </div>
              <div className='col-md-6 col-12 my-3'>
                <label className=' form-label fs-6 fw-semibold text-muted'>
                  {intl.formatMessage({id: 'KIDLING.MOTHER_TONGUE'})}
                </label>
                <input
                  {...formik.getFieldProps('Mothertongue')}
                  className={clsx(
                    'form-control  mb-3 mb-lg-0',
                    {'is-invalid': formik.touched.Mothertongue && formik.errors.Mothertongue},
                    {
                      'is-valid': formik.touched.Mothertongue && !formik.errors.Mothertongue,
                    }
                  )}
                  name='Mothertongue1'
                  autoComplete='off'
                  disabled={formik.isSubmitting}
                  value={Guardians[i].Mothertongue}
                  onChange={(e: any) => {
                    const newArray = [...Guardians]
                    Guardians[i].Mothertongue = e.target.value
                    setGuardians(newArray)
                  }}
                />
              </div>
              <div className='col-md-6 col-12 my-3'>
                <label className=' form-label fs-6 fw-semibold text-muted'>
                  {intl.formatMessage({id: 'KIDLING.EMPLOYER'})}
                </label>
                <input
                  {...formik.getFieldProps('employer')}
                  className={clsx(
                    'form-control  mb-3 mb-lg-0',
                    {'is-invalid': formik.touched.employer && formik.errors.employer},
                    {
                      'is-valid': formik.touched.employer && !formik.errors.employer,
                    }
                  )}
                  name='employer'
                  autoComplete='off'
                  disabled={formik.isSubmitting}
                  value={Guardians[i].employer}
                  onChange={(e: any) => {
                    const newArray = [...Guardians]
                    Guardians[i].employer = e.target.value
                    setGuardians(newArray)
                  }}
                />
              </div>
              <div className='col-md-6 col-12 my-3'>
                <label className=' form-label fs-6 fw-semibold text-muted'>
                  {intl.formatMessage({id: 'KIDLING.PROFISSION'})}
                </label>
                <input
                  {...formik.getFieldProps('profession')}
                  className={clsx(
                    'form-control  mb-3 mb-lg-0',
                    {
                      'is-invalid': formik.touched.profession && formik.errors.profession,
                    },
                    {
                      'is-valid': formik.touched.profession && !formik.errors.profession,
                    }
                  )}
                  name='profession'
                  autoComplete='off'
                  disabled={formik.isSubmitting}
                  value={Guardians[i].profession}
                  onChange={(e: any) => {
                    const newArray = [...Guardians]
                    Guardians[i].profession = e.target.value
                    setGuardians(newArray)
                  }}
                />
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
      ))}
      <button
        type='button'
        disabled={formik.isSubmitting}
        className='btn mb-16 mt-8 custom__button'
        onClick={() => {
          setGuardians((prev) => [
            ...prev,
            {
              firstName: '',
              lastName: '',
              origincountry: '',
              dateOfBirth: new Date(),
              phone: '',
              email: '',
              country: '',
              city: '',
              PostalCode: '',
              street: '',
              houseNumber: '',
              Mothertongue: '',
              employer: '',
              profession: '',
            },
          ])
          setOpen(`${Guardians.length}`)
        }}
      >
        {intl.formatMessage({
          id: 'KIDLING.ADD_ANOTHER_CONTACT',
        })}
      </button>
      <div className='d-flex align-items-center ms-auto '>
        <button type='button' className='btn me-2 bg-secondary ' onClick={() => handleBack()}>
          {intl.formatMessage({
            id: 'KIDLING.BACK',
          })}
        </button>
        <button type='submit' disabled={formik.isSubmitting} className='btn   custom__button'>
          {!formik.isSubmitting &&
            intl.formatMessage({
              id: 'KIDLING.NEXT',
            })}
          {formik.isSubmitting && (
            <span className='indicator-progress' style={{display: 'block'}}>
              {intl.formatMessage({id: 'KIDLING.HOME.PLEASE_WAIT'})}...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
    </form>
  )
}

export default ContactStep
