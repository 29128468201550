/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-template-curly-in-string */
import {FC, ReactElement, useEffect, useState} from 'react'
import {Button} from 'react-bootstrap'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import clsx from 'clsx'
import {useIntl} from 'react-intl'
import {toast} from 'react-toastify'
import {getBloodTypes, getReligions, getGenders} from '../../../../controller/common'
import {updateKidInfo} from '../../../../controller/kids'
import {useAuth} from '../../auth'
import {readFileFrom} from '../../../../controller/image'
import moment from 'moment'
import ResponsiveDatePicker from '../../../general-components/responsive-material-datepicker/ResponsiveDatePicker'

interface Props {
  kid: any
  setKid: any
  currentTab: number
}
const UserInfo: FC<Props> = ({kid, setKid, currentTab}): ReactElement => {
  const [isLoading, setIsLoading] = useState(false)
  const {currentUser, setCurrentUser} = useAuth()
  const intl = useIntl()

  const [genders, setGenders] = useState([])
  const [bloodTypes, setBloodTypes] = useState([])
  const [religions, setReligions] = useState([])
  const [initialValues, setInitialValues] = useState<any>({})
  const [currentKid, setCurrentKid] = useState(kid)

  const Schema = Yup.object().shape({
    firstName: Yup.string().required(intl.formatMessage({id: 'KIDDLING.REQUIRED'})),
    lastName: Yup.string().required(intl.formatMessage({id: 'KIDDLING.REQUIRED'})),
    dateOfBirth: Yup.date().typeError('Expected a value of type ${type} but got: ${value}'),
    genderId: Yup.string().required(intl.formatMessage({id: 'KIDDLING.REQUIRED'})),
    religionId: Yup.string().required(intl.formatMessage({id: 'KIDDLING.REQUIRED'})),
  })

  useEffect(() => {
    setCurrentKid(kid)
  }, [kid])

  const load = async () => {
    setBloodTypes(await getBloodTypes())
    setReligions(await getReligions())
    setGenders(await getGenders())
    //formik.setValues({...initialValues}, {})
  }

  const resetKid = async () => {
    setInitialValues({
      firstName: '',
      lastName: '',
      dateOfBirth: '',
      genderId: '',
      religionId: '',
      bloodTypeId: '',
      insuranceCompany: '',
      medicine: '',
      medicalIssues: '',
      fears: '',
      paediatrician: '',
      otherHabits: '',
    })
  }

  const triggerKid = async () => {
    setInitialValues({
      firstName: currentKid?.firstName || '',
      lastName: currentKid?.lastName || '',
      dateOfBirth: currentKid.dateOfBirth ? new Date(currentKid.dateOfBirth) : '',
      genderId: currentKid?.genderId,
      religionId: currentKid.religionId,
      bloodTypeId: currentKid.bloodTypeId,
      insuranceCompany: currentKid.insuranceCompany || '',
      medicine: currentKid.medicine || '',
      medicalIssues: currentKid.medicalIssues || '',
      fears: currentKid.fears || '',
      paediatrician: currentKid.paediatrician || '',
      otherHabits: currentKid.otherHabits || '',
      imagePath: currentKid.imagePath,
    })
  }

  useEffect(() => {
    resetKid()
  }, [currentTab])

  useEffect(() => {
    setIsLoading(true)
    triggerKid()
    setIsLoading(false)
  }, [currentKid])

  useEffect(() => {
    load()
  }, [])

  const formik: any = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: Schema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      try {
        const data = {
          ...currentKid,
          kidName: values.firstName + ' ' + values.lastName,
          firstName: values.firstName,
          lastName: values.lastName,
          dateOfBirth: moment(values.dateOfBirth).format('yyyy-MM-DDTHH:mm:ss'),
          genderId: values.genderId,
          religionId: values.religionId,
          bloodTypeId: values.bloodTypeId,
          insuranceCompany: values.insuranceCompany,
          medicine: values.medicine,
          medicalIssues: values.medicalIssues,
          fears: values.fears,
          paediatrician: values.paediatrician,
          otherHabits: values.otherHabits,
          imagePath: kid.image,
          statusId: kid.statusId,
          statusReasonId: kid.statusReasonId,
          statusReasonNotes: kid.statusReasonNotes,
          remarks: kid.remarks,
          allowTakePhoto: kid.allowTakePhoto,
        }

        const resp = await updateKidInfo(data)
        if (resp) {
          toast.success(intl.formatMessage({id: 'COMMON.SUCCESS'}))
          let temp = currentUser
          if (temp) {
            if (temp.kids) {
              resp.image = resp.imagePath ? await readFileFrom(resp.imagePath) : ''
              temp.kids[currentTab] = resp
              setCurrentUser(temp)
            }
          }
          //setKid(resp)
        } else {
          toast.error(intl.formatMessage({id: 'COMMON.FAIL'}))
        }
      } catch (ex) {
        console.error(ex)
      } finally {
        setSubmitting(false)
      }
    },
  })

  return (
    <form
      id='kt_modal_add_user_form'
      className='form container p-8 d-flex flex-column align-items-end'
      onSubmit={formik.handleSubmit}
      noValidate
    >
      <div className='row align-items-center'>
        <div className='col-md-6 col-12 my-3'>
          <label className='required form-label fs-6 fw-semibold text-muted'>
            {intl.formatMessage({id: 'PARENT.FIRSTNAME'})}
          </label>
          <input
            {...formik.getFieldProps('firstName')}
            className={clsx(
              'form-control  mb-3 mb-lg-0',
              {'is-invalid': formik.touched.firstName && formik.errors.firstName},
              {
                'is-valid': formik.touched.firstName && !formik.errors.firstName,
              }
            )}
            name='firstName'
            autoComplete='off'
            disabled={formik.isSubmitting || isLoading}
            rows={1}
            value={formik.values.firstName}
          />
          {formik.touched.firstName && formik.errors.firstName && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span className='alert-danger'>{formik.errors.firstName}</span>
              </div>
            </div>
          )}
        </div>
        <div className='col-md-6 col-12 my-3'>
          <label className='required form-label fs-6 fw-semibold text-muted'>
            {intl.formatMessage({id: 'PARENT.LASTNAME'})}
          </label>
          <input
            {...formik.getFieldProps('lastName')}
            className={clsx(
              'form-control  mb-3 mb-lg-0',
              {'is-invalid': formik.touched.lastName && formik.errors.lastName},
              {
                'is-valid': formik.touched.lastName && !formik.errors.lastName,
              }
            )}
            name='lastName'
            autoComplete='off'
            disabled={formik.isSubmitting || isLoading}
            rows={1}
            value={formik.values.lastName}
          />
          {formik.touched.lastName && formik.errors.lastName && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span className='alert-danger'>{formik.errors.lastName}</span>
              </div>
            </div>
          )}
        </div>
        <div className='col-md-6 col-12 my-3'>
          <label className='required form-label fs-6 fw-semibold text-muted'>
            {intl.formatMessage({id: 'PARENT.DOB'})}
          </label>
          <ResponsiveDatePicker
            onAccept={(val: any) => {
              formik.setFieldValue('dateOfBirth', val)
            }}
            value={formik.values.dateOfBirth}
          />
        </div>

        <div className='col-md-6 col-12 my-3'>
          <label className='required form-label fs-6 fw-semibold text-muted'>
            {intl.formatMessage({id: 'PARENT.GENDER'})}
          </label>

          <select
            {...formik.getFieldProps('genderId')}
            className={clsx(
              'form-control  mb-3 mb-lg-0',
              {'is-invalid': formik.touched.gender && formik.errors.gender},
              {
                'is-valid': formik.touched.gender && !formik.errors.gender,
              }
            )}
            name='genderId'
            autoComplete='off'
            disabled={formik.isSubmitting || isLoading}
            value={formik.values.genderId}
          >
            <option value=''>{intl.formatMessage({id: 'COMMON.SELECT'})}..</option>
            {genders &&
              Array.isArray(genders) &&
              genders?.map((s: any) => <option value={s.genderId}>{s.genderName}</option>)}
          </select>
          {formik.touched.genderId && formik.errors.genderId && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span className='alert-danger'>{formik.errors.genderId}</span>
              </div>
            </div>
          )}
        </div>
        <div className='col-md-6 col-12 my-3'>
          <label className='required form-label fs-6 fw-semibold text-muted'>
            {intl.formatMessage({id: 'PARENT.RELIGION'})}
          </label>

          <select
            {...formik.getFieldProps('religionId')}
            className={clsx(
              'form-control  mb-3 mb-lg-0',
              {'is-invalid': formik.touched.religion && formik.errors.religion},
              {
                'is-valid': formik.touched.religion && !formik.errors.religion,
              }
            )}
            name='religionId'
            autoComplete='off'
            disabled={formik.isSubmitting || isLoading}
            value={formik.values.religionId}
          >
            <option value=''>{intl.formatMessage({id: 'COMMON.SELECT'})}..</option>
            {religions &&
              Array.isArray(religions) &&
              religions?.map((s: any) => <option value={s.religionId}>{s.religionName}</option>)}
          </select>
          {formik.touched.religionId && formik.errors.religionId && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span className='alert-danger'>{formik.errors.religionId}</span>
              </div>
            </div>
          )}
        </div>
        <div className='col-md-6 col-12 my-3'>
          <label className='form-label fs-6 fw-semibold text-muted'>
            {intl.formatMessage({id: 'PARENT.BloodType'})}
          </label>

          <select
            {...formik.getFieldProps('bloodTypeId')}
            className={clsx(
              'form-control  mb-3 mb-lg-0',
              {'is-invalid': formik.touched.religion && formik.errors.bloodType},
              {
                'is-valid': formik.touched.religion && !formik.errors.bloodType,
              }
            )}
            name='bloodTypeId'
            autoComplete='off'
            disabled={formik.isSubmitting || isLoading}
            value={formik.values.bloodTypeId}
          >
            <option value=''>{intl.formatMessage({id: 'COMMON.SELECT'})}..</option>
            {bloodTypes &&
              Array.isArray(bloodTypes) &&
              bloodTypes?.map((s: any) => <option value={s.bloodTypeId}>{s.bloodTypeName}</option>)}
          </select>
        </div>
        <div className='col-md-6 col-12 my-3'>
          <label className='form-label fs-6 fw-semibold text-muted'>
            {intl.formatMessage({id: 'PARENT.INSURANCECOMPANY'})}
          </label>

          <input
            {...formik.getFieldProps('insuranceCompany')}
            className={clsx(
              'form-control  mb-3 mb-lg-0',
              {'is-invalid': formik.touched.insuranceCompany && formik.errors.insuranceCompany},
              {
                'is-valid': formik.touched.insuranceCompany && !formik.errors.insuranceCompany,
              }
            )}
            name='insuranceCompany'
            autoComplete='off'
            disabled={formik.isSubmitting || isLoading}
            value={formik.values.insuranceCompany}
          />
        </div>

        <div className='col-md-6 col-12 my-3'>
          <label className='form-label fs-6 fw-semibold text-muted'>
            {intl.formatMessage({id: 'PARENT.MEDICINE'})}
          </label>
          <input
            {...formik.getFieldProps('medicine')}
            className={clsx(
              'form-control  mb-3 mb-lg-0',
              {'is-invalid': formik.touched.medicine && formik.errors.medicine},
              {
                'is-valid': formik.touched.medicine && !formik.errors.medicine,
              }
            )}
            name='medicine'
            autoComplete='off'
            disabled={formik.isSubmitting || isLoading}
            rows={1}
            value={formik.values.medicine}
          />
        </div>

        <div className='col-md-6 col-12 my-3'>
          <label className='form-label fs-6 fw-semibold text-muted'>
            {intl.formatMessage({id: 'PARENT.MedicalIssues'})}
          </label>
          <input
            {...formik.getFieldProps('medicalIssues')}
            className={clsx(
              'form-control  mb-3 mb-lg-0',
              {'is-invalid': formik.touched.medicalIssues && formik.errors.medicalIssues},
              {
                'is-valid': formik.touched.medicalIssues && !formik.errors.medicalIssues,
              }
            )}
            name='medicalIssues'
            autoComplete='off'
            disabled={formik.isSubmitting || isLoading}
            rows={1}
            value={formik.values.medicalIssues}
          />
        </div>

        <div className='col-md-6 col-12 my-3'>
          <label className='form-label fs-6 fw-semibold text-muted'>
            {intl.formatMessage({id: 'PARENT.Fears'})}
          </label>
          <input
            {...formik.getFieldProps('fears')}
            className={clsx(
              'form-control  mb-3 mb-lg-0',
              {'is-invalid': formik.touched.fears && formik.errors.fears},
              {
                'is-valid': formik.touched.fears && !formik.errors.fears,
              }
            )}
            name='fears'
            autoComplete='off'
            disabled={formik.isSubmitting || isLoading}
            rows={1}
            value={formik.values.fears}
          />
        </div>

        <div className='col-md-6 col-12 my-3'>
          <label className='form-label fs-6 fw-semibold text-muted'>
            {intl.formatMessage({id: 'PARENT.Pediatrician'})}
          </label>
          <input
            {...(formik.getFieldProps('paediatrician') || '')}
            className={clsx(
              'form-control  mb-3 mb-lg-0',
              {'is-invalid': formik.touched.paediatrician && formik.errors.paediatrician},
              {
                'is-valid': formik.touched.paediatrician && !formik.errors.paediatrician,
              }
            )}
            name='paediatrician'
            autoComplete='off'
            disabled={formik.isSubmitting || isLoading}
            rows={1}
            value={formik.values.paediatrician}
          />
        </div>

        <div className='col-md-6 col-12 my-3'>
          <label className='form-label fs-6 fw-semibold text-muted'>
            {intl.formatMessage({id: 'PARENT.OtherHabits'})}
          </label>
          <input
            {...formik.getFieldProps('otherHabits')}
            className={clsx(
              'form-control  mb-3 mb-lg-0',
              {'is-invalid': formik.touched.otherHabits && formik.errors.otherHabits},
              {
                'is-valid': formik.touched.otherHabits && !formik.errors.otherHabits,
              }
            )}
            name='otherHabits'
            autoComplete='off'
            disabled={formik.isSubmitting || isLoading}
            rows={1}
            value={formik.values.otherHabits}
          />
        </div>
      </div>
      <Button
        color='primary'
        type='submit'
        disabled={formik.isSubmitting || isLoading}
        className='ms-auto'
      >
        {!formik.isSubmitting &&
          intl.formatMessage({
            id: 'AUTH.GENERAL.SUBMIT_BUTTON',
          })}
        {formik.isSubmitting && (
          <span className='indicator-progress' style={{display: 'block'}}>
            {intl.formatMessage({id: 'KIDLING.HOME.PLEASE_WAIT'})}...
            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
          </span>
        )}
      </Button>
    </form>
  )
}

export default UserInfo
