import {Outlet, Route, Routes} from 'react-router-dom'
import CompanyInformation from './components/CompanyInformation'
import {KTCard} from '../../../_metronic/helpers'

const CompanyInfo = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='*'
          element={
            <>
              <KTCard>
                <CompanyInformation />
              </KTCard>
            </>
          }
        />
      </Route>
    </Routes>
  )
}

export default CompanyInfo
