import {Outlet, Route, Routes} from 'react-router-dom'
import {KTCard} from '../../../_metronic/helpers'
import Activities from './components/Activities'

const ActivitiesPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='*'
          element={
            <>
              <KTCard>
                <Activities />
              </KTCard>
            </>
          }
        />
      </Route>
    </Routes>
  )
}

export default ActivitiesPage
