import {
  TextBlock,
  MediaBlock,
  TextRow,
  RectShape,
  RoundShape,
} from 'react-placeholder/lib/placeholders'

import {FC} from 'react'
interface Props {
  colNum: number
  rows: number
}
export const TableLoader: FC<Props> = ({colNum, rows}) => (
  <div className='show-loading-animation'>
    {[...Array(rows)].map((_, index) => (
      <div className='my-awesome-placeholder d-flex align-items-center mx-2' key={index}>
        <div className='row w-100 my-2'>
          <RoundShape color='#ccc' className='w-30px h-30px mx-2 col-2' />
          {[...Array(colNum)].map((_, index1) => (
            <div className={`col-${Math.floor(10 / colNum)} `} key={index1}>
              <TextRow color='#ccc' className='h-25px w-100 mt-0' />
            </div>
          ))}
        </div>{' '}
      </div>
    ))}
  </div>
)
