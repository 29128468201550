/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import {getUserInfo, login, loginAdmin} from '../core/_requests'
import {useAuth} from '../core/Auth'
import {FormattedMessage, useIntl} from 'react-intl'
import {useAppContext} from '../../../../_metronic/layout/core/AppContextProvider'
import {getUserData} from '../../../../controller/common'
import {setupAxios} from '../core/AuthHelpers'
import axios from 'axios'

export function Login() {
  const company = window.location.pathname.split('/')[1]
  const [loading, setLoading] = useState(false)
  const {companyMetaData}: any = useAppContext()
  const {saveAuth, setCurrentUser, setSocketUrl} = useAuth()
  const intl = useIntl()
  const loginSchema = Yup.object().shape({
    username: Yup.string()
      .min(3, intl.formatMessage({id: 'KIDLING.MIN_3_SYMBOLES'}))
      .required(intl.formatMessage({id: 'KIDLING.USERNAME_REQUIRED'})),
    password: Yup.string()
      .min(3, intl.formatMessage({id: 'KIDLING.MIN_3_SYMBOLES'}))
      .required(intl.formatMessage({id: 'KIDLING.PASSWORD_REQUIRED'})),
    agree: Yup.bool().oneOf([true], intl.formatMessage({id: 'KIDLING.REQUIRED'})),
  })

  const initialValues = {
    username: '',
    password: '',
    agree: false,
  }
  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        const resp = await loginAdmin(values.username, values.password)

        const url = resp[0].apiUrl
        localStorage.setItem('apiUrl', url)
        setupAxios(axios, url)
        const auth = await login(values.username, values.password, url)

        saveAuth(auth)

        const {data: user} = await getUserInfo(auth.userId, auth.token, url)
        setSocketUrl(`${url.split('/api')[0]}/`)
        setCurrentUser(await getUserData(auth.userId, user, auth.token, url))
      } catch (error) {
        console.error(error)
        saveAuth(undefined)
        setStatus(intl.formatMessage({id: 'KIDLING.LOGIN_DETAIL_INCORRECT'}))
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Heading */}
      <div className='text-center mb-10'>
        <h1 className='text-dark mb-3'>
          {companyMetaData?.parentAppTitle
            ? `${intl.formatMessage({
                id: 'KIDLING.SIGNIN',
              })} ${intl.formatMessage({id: 'KIDLING.MESSAGE.TO'})} ${
                companyMetaData?.parentAppTitle
              }`
            : intl.formatMessage({id: 'KIDLING.SIGNIN_TO_KIDLING'})}
        </h1>
        {/* <div className='text-gray-400 fw-semibold fs-4'>
          {intl.formatMessage({id: 'KIDLING.NEWHERE'})} ?{' '}
          <Link to='/auth/registration' className='link-primary fw-bold'>
            {intl.formatMessage({id: 'KIDLING.CREATE_ACCOUNT'})}
          </Link>
        </div> */}
      </div>
      {/* begin::Heading */}

      {formik.status && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      )}
      {/* : (
        <div className='mb-10 bg-light-info p-8 rounded'>
          <div className='text-info'>
            Use account <strong>admin@demo.com</strong> and password <strong>demo</strong> to
            continue.
          </div>
        </div>
      )} */}

      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <label className='form-label fs-6 fw-bold text-dark'>
          {intl.formatMessage({id: 'KIDLING.USERNAME'})}
        </label>
        <input
          placeholder={intl.formatMessage({id: 'KIDLING.USERNAME'})}
          {...formik.getFieldProps('username')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {'is-invalid': formik.touched.username && formik.errors.username},
            {
              'is-valid': formik.touched.username && !formik.errors.username,
            }
          )}
          type='text'
          name='username'
        />
        {formik.touched.username && formik.errors.username && (
          <div className='fv-plugins-message-container'>
            <span className='alert-danger'>{formik.errors.username}</span>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <div className='d-flex justify-content-between mt-n5'>
          <div className='d-flex flex-stack mb-2'>
            {/* begin::Label */}
            <label className='form-label fw-bold text-dark fs-6 mb-0'>
              {intl.formatMessage({id: 'KIDLING.PASSWORD'})}
            </label>
            {/* end::Label */}
            {/* begin::Link */}
            <Link
              to={company ? `/${company}/auth/forgot-password` : '/kidling/auth/forgot-password'}
              className='text-secondary fs-6 fw-bold'
              style={{marginLeft: '5px'}}
            >
              {intl.formatMessage({id: 'KIDLING.FORGOT_PASSWORD'})}
            </Link>
            {/* end::Link */}
          </div>
        </div>
        <input
          type='password'
          autoComplete='off'
          placeholder={intl.formatMessage({id: 'KIDLING.PASSWORD'})}
          {...formik.getFieldProps('password')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {
              'is-invalid': formik.touched.password && formik.errors.password,
            },
            {
              'is-valid': formik.touched.password && !formik.errors.password,
            }
          )}
        />
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span className='alert-danger'>{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      <div className='pb-6 border-bottom'>
        <label className='form-check   form-check-custom form-check-solid  '>
          <input
            className='form-check-input'
            type='checkbox'
            onChange={(e) => {
              formik.setFieldValue('agree', !formik.values.agree)
            }}
            name='agree'
          />
          <span className='form-check-label text-gray-700 fs-6 fw-semibold ms-2 me-2'>
            {/* {intl.formatMessage({id: 'KIDLING.READ_AND_AGREE_PRIVACY'})} */}
            <FormattedMessage
              id='AGREE_DATA_PRIVACY'
              values={{
                privacy: (
                  <a
                    href='https://kidling.de/datenschutz-app/'
                    target='_blank'
                    className='menu-link ps-0 pe-2 text-secondary'
                    rel='noreferrer'
                  >
                    <FormattedMessage id='KIDLING.HOME.DATA_PRIVACY' />
                  </a>
                ),
              }}
            />
          </span>
        </label>
        {formik.errors.agree && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span className='alert-danger'>{formik.errors.agree}</span>
            </div>
          </div>
        )}
      </div>

      {/* begin::Action */}
      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-lg btn-primary w-100 mb-5'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && (
            <span className='indicator-label'> {intl.formatMessage({id: 'KIDLING.CONTINUE'})}</span>
          )}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              {intl.formatMessage({id: 'KIDLING.HOME.PLEASE_WAIT'})}...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
        <img src='/media/logos/sectigo_trust_seal.png' alt='seal' />
        {/* begin::Separator */}
        {/* <div className='text-center text-muted text-uppercase fw-bold mb-5'>or</div> */}
        {/* end::Separator */}

        {/* begin::Google link */}
        {/* <a href='#' className='btn btn-flex flex-center btn-light btn-lg w-100 mb-5'>
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/svg/brand-logos/google-icon.svg')}
            className='h-20px me-3'
          />
          Continue with Google *
        </a>/}
        {/* end::Google link */}

        {/* begin::Google link */}
        {/* <a href='#' className='btn btn-flex flex-center btn-light btn-lg w-100 mb-5'>
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/svg/brand-logos/facebook-4.svg')}
            className='h-20px me-3'
          />
          Continue with Facebook
        </a> */}
        {/* end::Google link */}

        {/* begin::Google link */}
        {/* <a href='#' className='btn btn-flex flex-center btn-light btn-lg w-100'>
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/svg/brand-logos/apple-black.svg')}
            className='h-20px me-3'
          />
          Continue with Apple
        </a> */}
        {/* end::Google link */}
      </div>
      {/* end::Action */}
    </form>
  )
}
