import {FC, ReactElement} from 'react'
import {useIntl} from 'react-intl'
import {Modal, ModalHeader, ModalBody} from 'reactstrap'
import {toAbsoluteUrl} from '../../../../../_metronic/helpers'

const data = [
  {name: 'Melody Macy', avatar: '/media/avatars/300-5.jpg'},
  {name: 'Max Smith', avatar: '/media/avatars/300-25.jpg'},
  {name: 'Sean Bean', avatar: '/media/avatars/300-9.jpg'},
  {name: 'Brian Cox', avatar: '/media/avatars/300-23.jpg'},
]
interface Props {
  showModal: boolean
  setShowModal: any
}
const GroupMembersDialog: FC<Props> = ({showModal, setShowModal}): ReactElement => {
  const intl = useIntl()

  const close = () => {
    setShowModal(false)
  }

  return (
    <Modal isOpen={showModal} toggle={close} centered>
      <ModalHeader toggle={close}>{intl.formatMessage({id: 'KIDLING.GROUP_MEMBERS'})}</ModalHeader>

      <ModalBody>
        {data?.map((item: any, index: number) => {
          return (
            <div className='d-flex align-items-center justify-content-between m-2 shadow p-2 rounded'>
              <div className='d-flex align-items-center '>
                <div className='symbol symbol-35px symbol-circle'>
                  <img alt='Pic' src={toAbsoluteUrl(item.avatar)} />
                </div>{' '}
                <h4 className='mx-2'>{item.name}</h4>
              </div>
              <button className='btn'>
                <i className='fa-solid fa-trash text-danger' />
              </button>
            </div>
          )
        })}
      </ModalBody>
    </Modal>
  )
}

export default GroupMembersDialog
