/* eslint-disable react-hooks/exhaustive-deps */
import {KidsTable} from './table/KidsTable'
import {KTCard} from '../../../../_metronic/helpers'
import {TitleHeader} from '../../../general-components/TitleHeader'
import {useIntl} from 'react-intl'
import CustomDateRangePicker from '../../../general-components/date-range-picker/DateRangePicker'
import useAbsence from '../../../hooks/useAbsence'
import {useAppContext} from '../../../../_metronic/layout/core/AppContextProvider'
import {useEffect} from 'react'

const AbsenseList = () => {
  const intl = useIntl()
  const {handleDateChange, readAbsense, date} = useAbsence()
  const {childFilter} = useAppContext()

  useEffect(() => {
    readAbsense()
  }, [date, childFilter])

  return (
    <>
      <KTCard>
        <div className='border-0  d-flex flex-column align-items-start w-100 '>
          <TitleHeader
            title={intl.formatMessage({id: 'KIDLING.MENU.KIDSABSENSES'})}
            showChildFilter={true}
            filter={<CustomDateRangePicker handleDateChange={handleDateChange} />}
          />
        </div>
        <KidsTable />
      </KTCard>
    </>
  )
}

const AbsenseListWrapper = () => <AbsenseList />

export {AbsenseListWrapper}
