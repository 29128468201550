export const selectStyle = {
  control: (base: any) => ({
    ...base,
    width: '100%',
    minWidth: '150px',
    borderRaduis: '0.5rem',
    padding: '2px',
    boxShadow: 'none',
    backgroundColor: 'transparent',
    '&:hover': {
      borderColor: 'var(--bs-gray-100)',
    },
  }),
  menu: (provided: any) => ({...provided, zIndex: 9999}),
}

export const selectStyleNoBorder = {
  control: (base: any) => ({
    ...base,
    width: '100%',
    minWidth: '150px',
    borderRaduis: '0.5rem',
    padding: '2px',
    boxShadow: 'none',
    border: 'none',
    backgroundColor: 'transparent',
    '&:hover': {
      borderColor: 'var(--bs-gray-100)',
    },
  }),
  menu: (provided: any) => ({...provided, zIndex: 9999}),
}
