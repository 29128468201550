/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useLayout} from '../core'
import {useIntl} from 'react-intl'
import {Link} from 'react-router-dom'
import {storeLinks} from '../../../app/general-components/Store-download/storeLinks'
import {useAuth} from '../../../app/views/auth'

const Footer: FC = () => {
  const {classes} = useLayout()
  const intl = useIntl()
  const company = window.location.pathname.split('/')[1]
  const {currentUser} = useAuth()
  return (
    <div className='footer py-4 d-flex flex-lg-column w-100' id='kt_footer'>
      {/* begin::Container */}
      <div
        className={`${classes.footerContainer} d-flex flex-column flex-md-row align-items-center justify-content-between w-100`}
      >
        {/* begin::Copyright */}
        <div className='text-dark order-2 order-md-1'>
          <span className='text-muted fw-semibold me-2'>{new Date().getFullYear()} &copy;</span>
          <a href='#' className='text-gray-800 text-hover-primary'>
            Kidling
          </a>
        </div>

        {/* end::Copyright */}

        {/* begin::Nav */}
        <div className='d-flex align-items-center'>
          {currentUser?.username !== 'apptest@kidling.email' && (
            <div className='d-flex flex-column flex-sm-row align-items-start me-2'>
              <Link
                to={
                  storeLinks[company.toLocaleLowerCase()]
                    ? storeLinks[company.toLocaleLowerCase()].ios
                    : storeLinks['kidling'].ios
                }
                target='_blank'
                className='h-35px me-2 '
              >
                <img
                  src='/apple-small.png'
                  alt='appstore'
                  className='h-35px   object-fit-contain '
                />
              </Link>
              <Link
                to={
                  storeLinks[company.toLocaleLowerCase()]
                    ? storeLinks[company.toLocaleLowerCase()].android
                    : storeLinks['kidling'].android
                }
                target='_blank'
                className='h-35px   '
              >
                <img
                  src='/google-small.png'
                  alt='appstore'
                  className='h-35px  object-fit-contain '
                />
              </Link>
            </div>
          )}
          <ul className='menu menu-gray-600 menu-hover-primary fw-semibold order-1'>
            <li className='menu-item'>
              <a
                href='http://kidling.de/kontakt/'
                target='_blank'
                className='menu-link ps-0 pe-2'
                rel='noreferrer'
              >
                {intl.formatMessage({
                  id: 'KIDLING.HOME.SUPPORT',
                })}
              </a>
            </li>
            <li className='menu-item'>
              <a
                href='http://kidling.de/kontakt/'
                target='_blank'
                className='menu-link ps-0 pe-2'
                rel='noreferrer'
              >
                {'  |  '}{' '}
                {intl.formatMessage({
                  id: 'KIDLING.HOME.CONTACT',
                })}
              </a>
            </li>
            <li className='menu-item'>
              <a
                href='https://kidling.de/datenschutz-app/'
                target='_blank'
                className='menu-link ps-0 pe-2'
                rel='noreferrer'
              >
                {'  |  '}{' '}
                {intl.formatMessage({
                  id: 'KIDLING.HOME.DATA_PRIVACY',
                })}
              </a>
            </li>
          </ul>
        </div>

        {/* end::Nav */}
      </div>
      {/* end::Container */}
    </div>
  )
}

export {Footer}
