import {FC} from 'preact/compat'
import {ReactElement, useState} from 'react'
import SweetAlert from 'react-bootstrap-sweetalert'
import {useIntl} from 'react-intl'
import {useNavigate} from 'react-router-dom'
import {toast} from 'react-toastify'
import ErrorText from '../../../general-components/error-text/ErrorText'
import {getAllMessages, updateReceivedMessages} from '../../../../controller/messages'
import {useAppContext} from '../../../../_metronic/layout/core/AppContextProvider'
import {useAuth} from '../../auth'

interface Props {
  markUnread: () => void

  id: string
}
const MessageDetailsHeader: FC<Props> = ({markUnread, id}): ReactElement => {
  const navigate = useNavigate()
  const intl = useIntl()
  const {currentUser} = useAuth()
  const {setGlobalLoader2, setTabMessages, messagesFilters} = useAppContext()
  const [showDelete, setShowDelete] = useState(false)
  const deteleCurrentMessage = async () => {
    const data = {ids: [id], notificationStatusId: 3}
    const resp = await updateReceivedMessages(data)

    if (resp) {
      readMessages()

      toast.success(intl.formatMessage({id: 'COMMON.SUCCESS'}))
    } else {
      toast.error(intl.formatMessage({id: 'COMMON.FAIL'}))
    }
  }

  const readMessages = async () => {
    const filter = '1=1'
    setGlobalLoader2(true)
    const res = await getAllMessages(
      currentUser?.usersId ?? 0,
      filter,
      `ReceivedExpression=${messagesFilters.inbox.searchTerm}`
    )
    setTabMessages(res)
    navigate(-1)

    setGlobalLoader2(false)
  }
  const handleRead = () => {
    markUnread()
    navigate(-1)
  }
  return (
    <div className='card-header align-items-center justify-content-between  py-5 gap-5'>
      <div className='d-flex '>
        <a
          href='#!'
          onClick={() => navigate(-1)}
          className='btn btn-sm btn-icon btn-clear btn-active-light-primary me-3'
          data-bs-toggle='tooltip'
          data-bs-placement='top'
          title='Back'
        >
          <i className='fa-solid fa-arrow-left fs-5 m-0'></i>
        </a>
        <a
          href='#!'
          className='btn btn-sm btn-icon btn-light btn-active-light-primary me-2'
          data-bs-toggle='tooltip'
          data-bs-placement='top'
          title={intl.formatMessage({id: 'KIDLING.MARK_AS_UNREAD'})}
          onClick={() => handleRead()}
        >
          <i className='fa-solid fa-envelope fs-5 m-0'></i>
        </a>
        <a
          href='#!'
          className='btn btn-sm btn-icon btn-light btn-active-light-primary me-2'
          data-bs-toggle='tooltip'
          data-bs-placement='top'
          title={intl.formatMessage({id: 'COMMON.DELETE'})}
          onClick={() => setShowDelete(true)}
        >
          <i className='fa-solid fa-trash fs-5 m-0'></i>
        </a>
      </div>
      <SweetAlert
        show={showDelete}
        warning
        showCancel
        confirmBtnText={intl.formatMessage({id: 'COMMON.YES'})}
        cancelBtnText={intl.formatMessage({id: 'COMMON.CANCEL'})}
        confirmBtnBsStyle='danger'
        title={<ErrorText text={intl.formatMessage({id: 'KIDLING.MENU.ARE_YOU_SURE?'})} />}
        focusCancelBtn
        onConfirm={(e) => {
          setShowDelete(false)
          deteleCurrentMessage()
          navigate(-1)
        }}
        onCancel={() => setShowDelete(false)}
        style={{
          backgroundColor: 'var(--bs-body-bg)',
          boxShadow: '0 0.4rem 0.8rem -0.1rem rgb(0 32 128 / 10%), 0 0 0 1px var(--bs-body-bg)',
        }}
      />
    </div>
  )
}

export default MessageDetailsHeader
