import {FC, ReactElement, useEffect, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import clsx from 'clsx'
import {useIntl} from 'react-intl'
import Select from 'react-select'
import {Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap'
import {toast} from 'react-toastify'
import {getContactRelationType, getGenders, getSalutations} from '../../../../../controller/common'
import {AddNewContact} from '../../../../../controller/contact'
import ResponsiveDatePicker from '../../../../general-components/responsive-material-datepicker/ResponsiveDatePicker'
import {useThemeMode} from '../../../../../_metronic/partials/layout/theme-mode/ThemeModeProvider'
import {selectStyle, selectStyleDarkMode} from '../../../../utils/select-style'

interface Props {
  setShowModal: any
  showModal: boolean
  kid: any
  readContacts: any
}
const ContactDialog: FC<Props> = ({setShowModal, showModal, kid, readContacts}): ReactElement => {
  const {mode} = useThemeMode()
  const intl = useIntl()
  const [isLoading, setIsLoading] = useState(false)
  const [salutation, setSalutation] = useState([])
  const [genders, setGenders] = useState([])
  const [contactRelationTypes, setContactRelationTypes] = useState([])
  const [eventInitialValues, setEventInitialValues] = useState<any>({
    contactName: '',
    Facility: '',
    canPickUpChild: true,
    authorizedPerson: false,
    active: true,
    canPickUpChildUpdatedDate: new Date(),
    contactRelationTypeId: 1,
    cellPhoneNumber: '',
    emailAddress: '',
    dateOfBirth: new Date(),
    salutation: null,
  })

  const kidSchema = Yup.object().shape({
    contactName: Yup.string().required(intl.formatMessage({id: 'KIDLING.REQUIRED'})),
    emailAddress: Yup.string()
      .email(intl.formatMessage({id: 'KIDDLING.INVALID_EMAIL'}))
      .nullable(true),
    cellPhoneNumber: Yup.string().required(intl.formatMessage({id: 'KIDLING.REQUIRED'})),
    gender: Yup.mixed(),
    Facility: Yup.string(),
    canPickUpChild: Yup.boolean(),
    authorizedPerson: Yup.boolean(),
    active: Yup.boolean(),
    canPickUpChildUpdatedDate: Yup.date().required(intl.formatMessage({id: 'KIDLING.REQUIRED'})),
    dateOfBirth: Yup.date().required(intl.formatMessage({id: 'KIDLING.REQUIRED'})),
    contactRelationTypeId: Yup.mixed(),
    salutation: Yup.mixed().required(intl.formatMessage({id: 'KIDLING.REQUIRED'})),
  })

  useEffect(() => {
    const relation: any = contactRelationTypes.find(
      (item: any) => item.contactRelationTypeId === 16
    )

    setEventInitialValues({
      contactName: '',
      Facility: '',
      canPickUpChild: true,
      authorizedPerson: false,
      active: true,
      canPickUpChildUpdatedDate: new Date(),
      contactRelationTypeId: {
        value: relation?.contactRelationTypeId,
        label: relation?.contactRelationTypeName,
      },
      cellPhoneNumber: '',
      emailAddress: '',
      dateOfBirth: new Date(),
      salutation: null,
    })
  }, [contactRelationTypes])

  useEffect(() => {
    const read = async () => {
      setIsLoading(true)
      const resp = await getSalutations()
      setSalutation(resp)
      const resp1 = await getGenders()
      setGenders(resp1)
      const resp2 = await getContactRelationType()
      setContactRelationTypes(resp2)
      setIsLoading(false)
    }
    read()
  }, [showModal])

  const handleClose = () => {
    setShowModal(false)

    setEventInitialValues({
      contactName: '',
      Facility: '',
      canPickUpChild: true,
      authorizedPerson: false,
      // allowAccessKidData: false,
      active: true,
      canPickUpChildUpdatedDate: new Date(),
      contactRelationTypeId: null,
      cellPhoneNumber: '',
      emailAddress: '',
      dateOfBirth: null,
      salutation: null,
    })
  }

  const formik: any = useFormik({
    initialValues: eventInitialValues,
    enableReinitialize: true,
    validationSchema: kidSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      try {
        const data = {
          contactName: values.contactName,
          salutationId: values.salutation.value,
          firstName: values.contactName.split(' ')[0],
          lastName:
            values.contactName.split(' ').length > 1 ? values.contactName.split(' ')[1] : ' ',
          genderId: values.gender.value,
          dateOfBirth: values.dateOfBirth,
          nationalityId: 0,
          countryId: 0,
          cellPhoneNumber: values.cellPhoneNumber,
          phoneNumber: values.cellPhoneNumber,
          faxNumber: null,
          emailAddress: values.emailAddress,
          profession: '',
          personLanguageId: null,
          personLanguageSecondaryId: null,
          migrationBackground: true,
          remarks: '',
          totalAnnualIncome: 0,
          annualIncomeApplicableSince: '2023-03-14T00:00:00',
          taxNumber: '',
          salesTaxID: '',
          usersId: -1,
          getNotifiedInApp: true,
          getNotifiedByEmail: true,
          getNotifiedBySMS: true,
          contactDebtorNumber: null,
          billingInfoOrganisationName: null,
          billingInfoBillingPercentage: null,
          paymentMethodId: null,
          mandateReferenceNumber: null,
          mandateAccountOwnerName: null,
          iban: null,
          bankName: null,
          swift: null,
          mandateSignatureDate: null,
          mandateRecurringPayments: false,
          mandateValidUntilDate: null,
          mandateSigned: false,
          mandateActive: false,
          streetName: null,
          houseNumber: null,
          postalCode: null,
          stateId: null,
          cityId: null,
          billingInfoRemarks: null,
          contractPartner: false,
          kidContact: {
            kidId: kid.kidId,
            contactRelationTypeId: values.contactRelationTypeId.value,
            canPickUpChild: values.canPickUpChild,
            authorizedPerson: values.authorizedPerson,
            canPickUpChildUpdatedDate: new Date(),
            active: values.active,
            companyId: kid.companyId,
          },
        }

        const resp = await AddNewContact(data)
        if (!!resp) {
          await readContacts()
          toast.success(intl.formatMessage({id: 'COMMON.SUCCESS'}))
          setShowModal(false)
        }
      } catch (ex) {
        console.log(ex)
      } finally {
        setSubmitting(true)
      }
    },
  })

  return (
    <Modal isOpen={showModal} toggle={handleClose} zIndex={1000} onClosed={handleClose} size='lg'>
      <ModalHeader toggle={handleClose}>
        {intl.formatMessage({id: 'KIDLING.ADD_CONTACT'})}
      </ModalHeader>

      <form
        id='kt_modal_add_user_form'
        className='form container'
        onSubmit={formik.handleSubmit}
        noValidate
      >
        <ModalBody>
          <div className='row align-items-center'>
            <div className='mb-lg-15 alert alert-danger'>
              <div className='alert-text font-weight-bold'>
                {intl.formatMessage({id: 'KIDLING.WARNING_ADD_KID_CONTACT_NO_DELETE_INACTIVE'})}
              </div>
            </div>

            <div className='col-sm-6   col-12 my-2'>
              <Select
                openMenuOnFocus={true}
                value={formik.values.salutation}
                onChange={(val: any) => formik.setFieldValue('salutation', val)}
                options={salutation?.map((item: any) => ({
                  value: item.salutationId,
                  label: item.salutationTitle,
                }))}
                isLoading={isLoading}
                formatOptionLabel={(item: any) => <p className='p-0 m-0'>{item.label}</p>}
                isDisabled={formik.isSubmitting || isLoading}
                placeholder={intl.formatMessage({id: 'PARENT.SALUTATION'})}
                styles={mode === 'dark' ? selectStyleDarkMode : selectStyle}
              />
              {formik.errors.salutation && (
                <div className='fv-help-block'>
                  <span className='alert-danger'>{formik.errors.salutation}</span>
                </div>
              )}
            </div>
            <div className='col-sm-6 col-12 my-2'>
              <input
                {...formik.getFieldProps('contactName')}
                className={clsx(
                  'form-control  mb-3 mb-lg-0',
                  {'is-invalid': formik.touched.contactName && formik.errors.contactName},
                  {
                    'is-valid': formik.touched.contactName && !formik.errors.contactName,
                  }
                )}
                name='contactName'
                autoComplete='off'
                disabled={formik.isSubmitting || isLoading}
                rows={1}
                placeholder={intl.formatMessage({id: 'KIDLING.CONTACT_NAME'})}
              />
              {formik.touched.contactName && formik.errors.contactName && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span className='alert-danger'>{formik.errors.contactName}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='col-sm-6 col-12 my-2'>
              <input
                {...formik.getFieldProps('emailAddress')}
                className={clsx(
                  'form-control  mb-3 mb-lg-0',
                  {'is-invalid': formik.touched.emailAddress && formik.errors.emailAddress},
                  {
                    'is-valid': formik.touched.emailAddress && !formik.errors.emailAddress,
                  }
                )}
                name='emailAddress'
                autoComplete='off'
                disabled={formik.isSubmitting || isLoading}
                rows={1}
                placeholder={intl.formatMessage({id: 'KIDLING.EMAIL'})}
              />
              {formik.touched.emailAddress && formik.errors.emailAddress && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span className='alert-danger'>{formik.errors.emailAddress}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='col-sm-6 col-12 my-2'>
              <input
                {...formik.getFieldProps('cellPhoneNumber')}
                className={clsx(
                  'form-control  mb-3 mb-lg-0',
                  {'is-invalid': formik.touched.cellPhoneNumber && formik.errors.cellPhoneNumber},
                  {
                    'is-valid': formik.touched.cellPhoneNumber && !formik.errors.cellPhoneNumber,
                  }
                )}
                name='cellPhoneNumber'
                autoComplete='off'
                disabled={formik.isSubmitting || isLoading}
                rows={1}
                placeholder={intl.formatMessage({id: 'KIDLING.PHONE'})}
              />
              {formik.touched.cellPhoneNumber && formik.errors.cellPhoneNumber && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span className='alert-danger'>{formik.errors.cellPhoneNumber}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='col-sm-12   col-12 my-2'>
              <Select
                openMenuOnFocus={true}
                value={formik.values.gender}
                onChange={(val: any) => formik.setFieldValue('gender', val)}
                options={genders?.map((item: any) => ({
                  value: item.genderId,
                  label: item.genderName,
                }))}
                isLoading={isLoading}
                formatOptionLabel={(item: any) => <p className='p-0 m-0'>{item.label}</p>}
                isDisabled={formik.isSubmitting || isLoading}
                placeholder={intl.formatMessage({id: 'PARENT.GENDER'})}
                styles={mode === 'dark' ? selectStyleDarkMode : selectStyle}
              />
              {formik.errors.gender && (
                <div className='fv-help-block'>
                  <span className='alert-danger'>{formik.errors.gender}</span>
                </div>
              )}
            </div>

            <div className='col-sm-6  col-12'>
              <div className='fv-row my-2'>
                <label className=' required label   form-label fs-6 fw-semibold text-muted'>
                  {intl.formatMessage({id: 'KIDLING.BIRTHDAY'})}
                </label>
                <div
                  className={clsx(
                    {
                      'is-invalid': formik.touched.dateOfBirth && formik.errors.dateOfBirth,
                    },
                    {
                      'is-valid': formik.touched.dateOfBirth && !formik.errors.dateOfBirth,
                    }
                  )}
                >
                  <ResponsiveDatePicker
                    onAccept={(val: any) => {
                      formik.setFieldValue('dateOfBirth', val)
                    }}
                    value={formik.values.dateOfBirth}
                    disabled={formik.isSubmitting || isLoading}
                  />
                </div>
                {formik.errors.endsOn && (
                  <div className='fv-help-block'>
                    <span className='alert-danger'>{formik.errors.canPickUpChildUpdatedDate}</span>
                  </div>
                )}
              </div>
            </div>
            {/* <div className='col-sm-6  col-12'>
              <label className='  form-label fs-6 fw-semibold text-muted'>
                {intl.formatMessage({id: 'KIDLING.FACILITY'})}
              </label>
              <input
                {...formik.getFieldProps('Facility')}
                className={clsx(
                  'form-control  mb-3 mb-lg-0',
                  {'is-invalid': formik.touched.Facility && formik.errors.Facility},
                  {
                    'is-valid': formik.touched.Facility && !formik.errors.Facility,
                  }
                )}
                name='Facility'
                autoComplete='off'
                disabled={formik.isSubmitting || isLoading}
                rows={1}
              />
              {formik.touched.Facility && formik.errors.Facility && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span className='alert-danger'>{formik.errors.Facility}</span>
                  </div>
                </div>
              )}
            </div> */}

            <div className='col-sm-6  col-12 mt-4'>
              <label className='  label  form-label fs-6 fw-semibold text-muted'>
                {intl.formatMessage({id: 'KIDLING.CONTACT_RELATION'})}
              </label>

              <Select
                openMenuOnFocus={true}
                value={formik.values.contactRelationTypeId}
                onChange={(val: any) => formik.setFieldValue('contactRelationTypeId', val)}
                options={contactRelationTypes?.map((item: any) => ({
                  value: item.contactRelationTypeId,
                  label: item.contactRelationTypeName,
                }))}
                isLoading={isLoading}
                formatOptionLabel={(item: any) => <p className='p-0 m-0'>{item.label}</p>}
                isDisabled={true}
                styles={mode === 'dark' ? selectStyleDarkMode : selectStyle}
              />

              <div className='fv-help-block'>
                <span className='alert-danger'>
                  {' '}
                  {intl.formatMessage({id: 'KIDLING.CHANGE_ONLY_BY_KITA'})}
                </span>
              </div>
            </div>

            <div className='mt-8 form-check form-switch form-switch-sm form-check-custom form-check-solid  col-12 my-2 d-flex align-items-center justify-content-between'>
              <label className='  form-check-label'>
                {intl.formatMessage({id: 'KIDLING.ACTIVE'})}
              </label>
              <input
                className='form-check-input'
                type='checkbox'
                checked={formik.values.active}
                name='notifications'
                onChange={(e: any) => {
                  formik.setFieldValue('active', e.target.checked)
                }}
                disabled={formik.isSubmitting || isLoading}
              />
            </div>
            {/* <div className='form-check form-switch form-switch-sm form-check-custom form-check-solid  col-12 my-2 d-flex align-items-center justify-content-between'>
              <label className='  form-check-label'>
                {intl.formatMessage({id: 'KIDLING.ALLOW_ACCESS_KID_DATA'})}
              </label>
              <input
                className='form-check-input'
                type='checkbox'
                checked={formik.values.allowAccessKidData}
                name='notifications'
                onChange={(e: any) => {
                  formik.setFieldValue('allowAccessKidData', e.target.checked)
                }}
                disabled={formik.isSubmitting || isLoading}
              />
            </div> */}
            <div className='form-check form-switch form-switch-sm form-check-custom form-check-solid  col-12 my-2 d-flex align-items-center justify-content-between'>
              <label className='  form-check-label'>
                {intl.formatMessage({id: 'KIDLING.AUTHORIZED_PERSON'})}
              </label>
              <input
                className='form-check-input'
                type='checkbox'
                checked={formik.values.authorizedPerson}
                name='notifications'
                onChange={(e: any) => {
                  formik.setFieldValue('authorizedPerson', e.target.checked)
                }}
                disabled={formik.isSubmitting || isLoading}
              />
            </div>
            <div className='form-check form-switch form-switch-sm form-check-custom form-check-solid  col-12 my-2 d-flex align-items-center justify-content-between'>
              <label className='  form-check-label'>
                {intl.formatMessage({id: 'KIDLING.CAN_PICK_CHILD'})}
              </label>
              <input
                className='form-check-input'
                type='checkbox'
                checked={formik.values.canPickUpChild}
                name='notifications'
                onChange={(e: any) => {
                  formik.setFieldValue('canPickUpChild', e.target.checked)
                }}
                disabled={formik.isSubmitting || isLoading}
              />
            </div>
            {/* end::Scroll */}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color='secondary' onClick={handleClose}>
            {intl.formatMessage({id: 'KIDLING.HOME.DISCARD'})}{' '}
          </Button>

          <Button color='primary' type='submit' disabled={formik.isSubmitting || isLoading}>
            {intl.formatMessage({id: 'AUTH.GENERAL.SUBMIT_BUTTON'})}
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  )
}

export default ContactDialog
