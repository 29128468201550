/* eslint-disable react-hooks/exhaustive-deps */
import React, {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {TitleHeader} from '../../general-components/TitleHeader'
import {
  getAllMessages,
  getAllMessagesCount,
  getSubMessages,
  updateReceivedMessages,
} from '../../../controller/messages'
import {useAppContext} from '../../../_metronic/layout/core/AppContextProvider'
import {NotificationMessageCard} from './NotificationMessageCard'
import {useAuth} from '../auth'
import {RectShape} from 'react-placeholder/lib/placeholders'
import {Link} from 'react-router-dom'
import {NoRecords} from '../../general-components/loading/NoRecords'
import moment from 'moment'
import {getAllDashboardNotifications} from '../../../controller/dashboard'
import TimeLine from '../../general-components/Timeline/TimeLine'
import {DashboardKidsFilter} from './DashboardKidsFilter'
import NotificationActivityCard from '../../general-components/NotificationActivityCard/NotificationActivityCard'
import DashboardImageCard from '../../general-components/DashboardImageCard/DashboardImageCard'
import ImagesSlider from '../../general-components/Images-slider/ImagesSlider'
import {groupBy} from '../../utils/common'
import DownloadImagesButton from '../../general-components/download-images/DownloadImagesButton'

const Notifications: FC = () => {
  const company = window.location.pathname.split('/')[1]
  const {
    setGlobalLoader2,
    globalLoader2,
    setDashboardNotifications,
    dashboardNotifications,
    setMessagesCounts,
    setTabMessages,
    dashboardKidsFilter,
    setGlobalLoader,
    globalLoader,
    mobileConfiguration,
    selectedImages,
  } = useAppContext()

  const {currentUser} = useAuth()
  const intl = useIntl()
  const [attendanceTimeline, setAttendanceTimeline] = useState<any>({})
  const [absenceTimeline, setAbsenceTimeline] = useState<any>({})
  const [allActivites, setAllActivites] = useState<any>([])
  const [mediaData, setMediaData] = useState<any>({})

  useEffect(() => {
    if (dashboardKidsFilter && dashboardKidsFilter?.id) {
      setGlobalLoader(true)
      readAllTodayNotifications()
    }
  }, [dashboardKidsFilter, mobileConfiguration])
  useEffect(() => {
    if (currentUser && dashboardKidsFilter?.id) {
      setGlobalLoader2(true)
      readAllTodayNotifications()
    }
  }, [currentUser, dashboardKidsFilter])

  const updateReadMessages = async (parent: number) => {
    const ids: any = []
    const subMessages: any = await getSubMessages({
      userId: currentUser?.usersId,
      parentId: parent,
    })
    subMessages?.map((item: any) => {
      if (item?.usersId !== currentUser?.usersId) ids.push(item.notificationId)
      return item
    })
    const data = {
      ids: ids,
      isRead: true,
    }
    await updateReceivedMessages(data)
    const count = await getAllMessagesCount(currentUser?.usersId || 0)
    if (count) setMessagesCounts(count)
    const resp = await getAllMessages(currentUser?.usersId ?? 0, '1=1', null)

    setTabMessages(resp)
  }

  const readAllTodayNotifications = async () => {
    let filter: string = ''

    filter = `kidId= ${dashboardKidsFilter?.id}`
    const resp = await getAllDashboardNotifications(
      filter,

      moment().startOf('day').format('yyyy-MM-DDTHH:mm:ss'),
      moment().endOf('day').format('yyyy-MM-DDTHH:mm:ss'),
      1,
      100
    )
    if (resp) {
      let messages: any = []
      resp.messages?.map((item: any) =>
        messages.push({
          ...item.message,
          subMessages: item.subMessages.data?.sort((a: any, b: any) =>
            moment(a?.dateTime).diff(b?.dateTime)
          ),
        })
      )
      setDashboardNotifications(messages)

      if (
        mobileConfiguration?.KidMedia &&
        mobileConfiguration?.KidMedia[0]?.showInParentApp === true
      ) {
        const grouped = groupBy(resp?.activities?.kidsMedia, 'createdDate')

        setMediaData(grouped)
      }

      let attendanceItems: any = []
      const sortedAtt = resp.activities?.attendances?.sort((a: any, b: any) =>
        moment(a?.dateTimeIn).diff(b?.dateTimeIn)
      )

      sortedAtt?.map((item: any) => {
        attendanceItems.push({
          icon: 'fa-solid fa-turn-down ',
          title: `${moment(item.dateTimeIn).format('HH:mm')} `,
          subtitle: item.employeeRemarks,
          backgroundColor: '#A1EBE7',
          iconColor: '#1BC5BD',
          tag: item.attendanceLocationName,
          tagIcon: 'fa-solid fa-location-dot',
        })
        if (item.dateTimeOut !== null)
          attendanceItems.push({
            icon: 'fa-solid fa-turn-up ',
            title: ` ${moment(item.dateTimeOut).format('HH:mm')} `,
            subtitle: item.employeeRemarks,
            backgroundColor: '#EEE5FF',
            iconColor: '#8950FC',
            tag: item.attendanceLocationName,
            tagIcon: 'fa-solid fa-location-dot',
          })
        return item
      })

      ////absences
      let absencesItems: any = []
      resp.activities?.absences?.map((item: any) => {
        absencesItems.push({
          icon: 'fa-solid fa-arrow-left',
          title: `${moment(item.fromDate).format('DD.MM.yyyy')} `,
          subtitle: item.employeeRemarks,
          backgroundColor: '#ebc5a1',
          iconColor: '#c5211b',
          tag: item.attendanceLocationName,
          tagIcon: 'fa-solid fa-location-dot',
        })
        absencesItems.push({
          icon: 'fa-solid fa-arrow-right',
          title: `${moment(item.toDate).format('DD.MM.yyyy')} `,
          subtitle: item.employeeRemarks,
          backgroundColor: '#A1EBE7',
          iconColor: '#1BC5BD',
          tag: item.attendanceLocationName,
          tagIcon: 'fa-solid fa-location-dot',
        })
        return item
      })

      //rest of activities
      let drinks: any = {
        title: intl.formatMessage({id: 'KIDLING.DASHBOARD.DRINKS'}),
        items: [],
        link: 'drinks',
      }
      let events: any = {
        title: intl.formatMessage({id: 'KIDLING.DASHBOARD.EVENTS'}),
        items: [],
        link: 'calender',
      }
      let protocols: any = {
        title: intl.formatMessage({id: 'KIDLING.DASHBOARD.PROTOCOLS'}),
        items: [],
        link: 'protocols',
      }
      let activities: any = {
        title: intl.formatMessage({id: 'KIDLING.DASHBOARD.ACTIVITIES'}),
        items: [],
        link: 'activities',
      }
      let requests: any = {
        title: intl.formatMessage({id: 'KIDLING.DASHBOARD.REQUESTS'}),
        items: [],
        link: 'requests',
      }
      let windingProtocols: any = {
        title: intl.formatMessage({id: 'KIDLING.DASHBOARD.DIAPERS'}),
        link: 'diapers',
        items: [],
      }
      let mealTime: any = {
        title: intl.formatMessage({id: 'KIDLING.DASHBOARD.EATING_HABIT'}),
        items: [],
        link: 'eating-habit',
      }
      let sleeps: any = {
        title: intl.formatMessage({id: 'KIDLING.DASHBOARD.SLEEPS'}),
        items: [],
        link: 'sleeptimes',
      }
      if (resp.activities?.drinks?.length > 0) {
        resp.activities?.drinks[0].kidDrinks?.map((item: any) => {
          drinks.items.push({
            image: item.imagePath ?? '/media/dashboard/Drinks.jfif',
            defaultImage: '/media/dashboard/Drinks.jfif',
            title: item.kidDrinkName,
            subtitle: `Ml:${item.kidDrinkMl}`,
            date: item.dateTime,
            link: 'drinks',
          })
          return item
        })
      }
      if (resp.activities?.events?.length > 0) {
        resp.activities?.events?.map((item: any) => {
          events.items.push({
            image: item.imagePath ?? '/media/dashboard/Events.jfif',
            defaultImage: '/media/dashboard/Events.jfif',
            title: item.eventsName,
            subtitle: item.locationName,
            date: item.startsOn,
            endDate: item.endsOn,
            link: 'calender',
          })
          return item
        })
      }
      if (resp.activities?.kidsProtocols?.length > 0) {
        resp.activities?.kidsProtocols[0]?.kidProtocols?.map((item: any) => {
          protocols.items.push({
            image: '/media/dashboard/Protocols.jfif',
            defaultImage: '/media/dashboard/Protocols.jfif',
            title: item.title,
            subtitle: item.remarks,
            date: item.dateTime,
            link: 'protocols',
          })
          return item
        })
      }
      if (resp.activities?.kidsActivities?.length > 0) {
        resp.activities?.kidsActivities[0]?.kidActivities?.map((item: any) => {
          activities.items.push({
            image: '/media/dashboard/Activities.jfif',
            defaultImage: '/media/dashboard/Activities.jfif',
            title: item.activityName,
            subtitle: '',
            date: item.dateTime,
            link: 'activities',
          })
          return item
        })
      }

      if (resp.activities?.requestsKid?.length > 0) {
        resp.activities?.requestsKid[0]?.requests?.map((item: any) => {
          requests.items.push({
            image: '/media/dashboard/Request.jpg',
            defaultImage: '/media/dashboard/Request.jpg',
            title: item.requestItemName,
            subtitle: item.note,
            date: item.dueDate,
            link: 'requests',
          })
          return item
        })
      }

      if (resp.activities?.protocols?.length > 0) {
        resp.activities?.protocols[0]?.windingProtocols?.map((item: any) => {
          windingProtocols.items.push({
            image: '/media/dashboard/WindingProtocols.jfif',
            defaultImage: '/media/dashboard/WindingProtocols.jfif',
            title: item.diaperContentName,
            subtitle: item.notes,
            date: item.dateTime,
            link: 'diapers',
          })
          return item
        })
      }

      if (resp.activities?.kidsMealTime?.length > 0) {
        resp.activities?.kidsMealTime[0]?.kidMealTimes.map((item: any) => {
          mealTime.items.push({
            image: '/media/dashboard/Meal.jfif',
            defaultImage: '/media/dashboard/Meal.jfif',
            title: `${item.foodItemName}/${item.mealTimeName}/${item.mealPortionName}`,
            subtitle: item.notes,
            date: item.dateTime,
            link: 'eating-habit',
          })
          return item
        })
      }
      if (resp.activities?.kidsSleeps?.length > 0) {
        resp.activities?.kidsSleeps?.map((item: any) => {
          sleeps.items.push({
            image: '/media/dashboard/Sleeps.jfif',
            defaultImage: '/media/dashboard/Sleeps.jfif',
            title: item?.sleepHabit?.sleepHabitName,
            subtitle: item?.sleepHabit?.description,
            subtitle1: `${moment(item.date).format('DD.MM.yyyy')} ${
              item.fromTime ? moment(item.fromTime).format('HH:mm') : '--'
            } - ${item.toTime ? moment(item.toTime).format('HH:mm') : '--'}`,
            link: 'sleeptimes',
          })
          return item
        })
      }
      if (
        mobileConfiguration?.KidAttendance &&
        mobileConfiguration?.KidAttendance[0]?.showInParentApp
      ) {
        setAttendanceTimeline({
          title: intl.formatMessage({id: 'KIDLING.DASHBOARD.ATTENDANCE'}),
          subtitle: '',
          items: attendanceItems,
          link: 'attendance',
        })
        setAbsenceTimeline({
          title: intl.formatMessage({id: 'KIDLING.DASHBOARD.ABSENCE'}),
          subtitle: '',
          items: absencesItems,
          link: 'absense',
        })
      }
      let finatlOutput = []
      if (mobileConfiguration?.KidDrink && mobileConfiguration?.KidDrink[0]?.showInParentApp) {
        finatlOutput.push(drinks)
      }
      if (mobileConfiguration?.Events && mobileConfiguration?.Events[0]?.showInParentApp) {
        finatlOutput.push(events)
      }
      if (
        mobileConfiguration?.KidProtocolRemark &&
        mobileConfiguration?.KidProtocolRemark[0]?.showInParentApp
      ) {
        finatlOutput.push(protocols)
      }
      // if (
      //   mobileConfiguration?.KidActivity &&
      //   mobileConfiguration?.KidActivity[0]?.showInParentApp
      // ) {
      finatlOutput.push(activities)
      // }
      if (mobileConfiguration?.RequestKid && mobileConfiguration?.RequestKid[0]?.showInParentApp) {
        finatlOutput.push(requests)
      }
      if (
        mobileConfiguration?.KidWindingProtocol &&
        mobileConfiguration?.KidWindingProtocol[0]?.showInParentApp
      ) {
        finatlOutput.push(windingProtocols)
      }
      if (
        mobileConfiguration?.KidMealTime &&
        mobileConfiguration?.KidMealTime[0]?.showInParentApp
      ) {
        finatlOutput.push(mealTime)
      }
      if (
        mobileConfiguration?.KidSleepTime &&
        mobileConfiguration?.KidSleepTime[0]?.showInParentApp
      ) {
        finatlOutput.push(sleeps)
      }
      setAllActivites(finatlOutput)
    }

    setGlobalLoader(false)
    setGlobalLoader2(false)
  }

  return (
    <div className=' card  border-0 mt-6 pt-6 d-flex flex-column align-items-center w-100'>
      <TitleHeader title={intl.formatMessage({id: 'KIDLING.MENU.NOTIFICATIONS'})} hidePrint />
      {/* begin::Col */}
      <div className='row w-100 g-8'>
        <div className=' col-12'>
          <div className='  rounded d-flex flex-column align-items-center h-100 '>
            {globalLoader2 ? (
              <RectShape color='#ccc' className=' h-200px' />
            ) : (
              <>
                {dashboardNotifications?.map(
                  (item: any, index2: number) =>
                    item.notificationTypeId === 1 && (
                      <React.Fragment key={index2}>
                        <NotificationMessageCard
                          notification={item}
                          readMessages={readAllTodayNotifications}
                          updateReadMessages={updateReadMessages}
                        />
                      </React.Fragment>
                    )
                )}
                {dashboardNotifications?.length > 0 && (
                  <div className={`card w-100 my-4 `}>
                    {/* begin::Body */}
                    <div className='card-body p-8 d-flex justify-content-center align-items-center'>
                      <Link
                        to={company ? `/${company}/messages` : '/kidling/messages'}
                        className='btn btn-primary mw-sm-200px w-100 '
                      >
                        <i className='fas fa-envelope fs-1 me-2'></i>
                        {intl.formatMessage({id: 'KIDLING.SEE_MORE'})}
                      </Link>
                    </div>
                  </div>
                )}
                {!globalLoader2 && dashboardNotifications?.length === 0 && <NoRecords />}
              </>
            )}
          </div>
        </div>
        <div className='border-1 mt-16 pt-8 border-primary border-top'>
          <DashboardKidsFilter />
        </div>
        <div className='col-12 '>
          {globalLoader ? (
            <RectShape color='#ccc' className=' h-200px' />
          ) : (
            Object.keys(mediaData).length > 0 && (
              <div className='card mb-5 mb-xl-8 h-100 mt-2 '>
                <h3 className='card-title align-items-start flex-column p-4'>
                  <Link to={company ? `/${company}/media` : `/kidling/media`}>
                    <span className='fw-bold mb-2 text-dark '>
                      {intl.formatMessage({id: 'KIDLING.DASHBOARD.MEDIA'})}
                    </span>
                  </Link>
                </h3>
                <div className='row g-4 justify-content-start'>
                  {Object.keys(mediaData)?.map(
                    (key: any, index: number) =>
                      mediaData[key].length > 0 && (
                        <div className={`${'col-12 col-sm-6 col-md-4'}  `} key={index}>
                          {mediaData[key].length > 1 ? (
                            <div className='shadow rounded h-100 w-100'>
                              <ImagesSlider data={mediaData[key]} />
                            </div>
                          ) : (
                            mediaData[key].length === 1 && (
                              <DashboardImageCard
                                date={mediaData[key][0].createdDate}
                                image={mediaData[key][0].filePath}
                                userName={mediaData[key][0].userName}
                                userImagePath={mediaData[key][0].userImagePath}
                                details={mediaData[key][0].kidMediaFileDescription}
                                title={mediaData[key][0].title}
                                kidName={mediaData[key][0].kidName}
                                KidMediaFileName={mediaData[key][0].kidMediaFileName}
                                locked={mediaData[key][0].Confidential}
                                Hashtags={mediaData[key][0]?.hashtags}
                                KidMediaFileId={mediaData[key][0]?.kidMediaFileId}
                                readData={readAllTodayNotifications}
                                hideCheckbox={true}
                              />
                            )
                          )}
                        </div>
                      )
                  )}
                </div>

                <div className={`card w-100 my-4 `}>
                  {/* begin::Body */}
                  <div className='card-body p-8 d-flex justify-content-center align-items-center'>
                    <Link
                      to={company ? `/${company}/media` : '/kidling/media'}
                      className='btn btn-primary mw-sm-200px w-100 '
                    >
                      <i className='fa-solid fa-image fs-1 me-2'></i>
                      {intl.formatMessage({id: 'KIDLING.SEE_MORE'})}
                    </Link>
                  </div>
                </div>
              </div>
            )
          )}
        </div>
        {attendanceTimeline?.items?.length > 0 && (
          <div className='col-12 col-sm-6 col-md-4'>
            {globalLoader ? (
              <RectShape color='#ccc' className=' h-200px' />
            ) : (
              <TimeLine {...attendanceTimeline} />
            )}
          </div>
        )}
        {absenceTimeline?.items?.length > 0 && (
          <div className='col-12 col-sm-6 col-md-4'>
            {globalLoader ? (
              <RectShape color='#ccc' className=' h-200px' />
            ) : (
              <TimeLine {...absenceTimeline} />
            )}
          </div>
        )}
        {allActivites?.map(
          (item: any, index1: number) =>
            item.items?.length > 0 && (
              <div className='col-12 col-sm-6 col-md-4' key={index1}>
                {globalLoader ? (
                  <RectShape color='#ccc' className=' h-200px' />
                ) : (
                  item.items?.length > 0 && <NotificationActivityCard {...item} />
                )}
              </div>
            )
        )}
      </div>
      {selectedImages?.length > 0 && <DownloadImagesButton />}
      {/* end::Col */}
    </div>
  )
}

export {Notifications}
