import moment from 'moment'
import {getKidsProtocols} from '../../controller/protocol'
import {useAppContext} from '../../_metronic/layout/core/AppContextProvider'
import {useEffect, useState} from 'react'
import {useAuth} from '../views/auth'

const useProtocols = () => {
  const {
    kidsProtocol,
    setKidsProtocol,
    setGlobalLoader,
    internalPagesFilters,
    internalPagesDatesFilters,
    globalLoader,
    setInternalPagesDatesFilters,
    setInternalPagesFilters,
  } = useAppContext()
  const [data, setData] = useState([])
  const {currentUser} = useAuth()
  const [date, setDate] = useState<any>({})
  const [clicked, setClicked] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')

  useEffect(() => {
    filterData()
  }, [searchTerm])

  useEffect(() => {
    if (clicked && !globalLoader) {
      readProtocol()
      setClicked(false)
    }
  }, [clicked])

  useEffect(() => {
    const newFilters: any = {...internalPagesDatesFilters}

    newFilters.protocols = {
      ...newFilters.protocols,
      From: moment(date?.startDate).startOf('day').format('yyyy-MM-DDTHH:mm:ss'),
      To: moment(date?.endDate).endOf('day').format('yyyy-MM-DDTHH:mm:ss'),
    }

    setInternalPagesDatesFilters(newFilters)
  }, [date])

  useEffect(() => {
    let temp: any = []

    kidsProtocol?.map((item: any) => {
      let protocols: any = []

      item.kidProtocols?.map((protocol: any, index: number) => {
        if (
          internalPagesDatesFilters.protocols.From ===
            moment().startOf('year').format('yyyy-MM-DDTHH:mm:ss') &&
          internalPagesDatesFilters.protocols.To ===
            moment().endOf('year').format('yyyy-MM-DDTHH:mm:ss')
        ) {
          if (index < 10)
            protocols.push({
              id: protocol.kidProtocolRemarkId,
              title: protocol.title,
              desc: protocol.remarks,
              date: protocol.dateTime,
              // setShowEditModal: () =>
              //   setEditModal((prev: any) => ({
              //     ...prev,
              //     show: !prev.show,
              //     data: {kid: item, item: protocol},
              //   })),
            })
        } else
          protocols.push({
            id: protocol.kidProtocolRemarkId,
            title: protocol.title,
            desc: protocol.remarks,
            date: protocol.dateTime,
            // setShowEditModal: () =>
            //   setEditModal((prev: any) => ({
            //     ...prev,
            //     show: !prev.show,
            //     data: {kid: item, item: protocol},
            //   })),
          })
      })
      temp.push({
        image: item.imagePath,
        name: `${item.firstName} ${item.lastName}`,
        subItems: protocols,
        // handleDelete: (id: string) => doDelete(id),
      })
    })

    setData(temp)
  }, [kidsProtocol])

  const readProtocol = async () => {
    let filter: string = ''
    setGlobalLoader(true)

    currentUser?.kids?.map((kid: any, index: number) => {
      if (index === 0) filter += '('
      filter += 'kidId=' + kid.kidId
      if (index !== currentUser?.kids?.length - 1) filter += ' OR '
      else filter += ')'
    })
    if (filter === '') filter = 'kidId=-1'
    if (internalPagesFilters.protocols.searchTerm) filter = filter + ' AND '
    if (internalPagesFilters.protocols.searchTerm)
      filter = filter + internalPagesFilters.protocols?.searchTerm
    const res = await getKidsProtocols(
      filter,
      currentUser?.contact?.contactId || 0,
      internalPagesDatesFilters?.protocols?.From
        ? moment(internalPagesDatesFilters?.protocols?.From).format('yyyy-MM-DDTHH:mm:ss')
        : moment().startOf('year').format('yyyy-MM-DDTHH:mm:ss'),
      internalPagesDatesFilters?.protocols?.To
        ? moment(internalPagesDatesFilters?.protocols?.To).format('yyyy-MM-DDTHH:mm:ss')
        : moment().endOf('year').format('yyyy-MM-DDTHH:mm:ss')
    )

    setKidsProtocol(res)
    setGlobalLoader(false)
  }
  const handleDateChange = (selected: any) => {
    setDate(selected[0])
  }
  const filterData = async () => {
    const newFilters: any = {...internalPagesFilters}

    newFilters.protocols = {
      ...newFilters.protocols,
      searchTerm: `kidName.toLower().contains("${searchTerm.toLowerCase().trim()}")`,
    }

    setInternalPagesFilters(newFilters)
  }
  return {readProtocol, data, handleDateChange, searchTerm, setSearchTerm, setClicked}
}
export default useProtocols
