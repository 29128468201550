import {Divider} from '@mui/material'
import React, {FC, ReactElement} from 'react'
import {useIntl} from 'react-intl'
import fieldToLableMapper from '../../../helpers/fieldToLabelMapper'
import moment from 'moment'

interface Props {
  handleBack: any
  handleSubmit: any
  allValues: any
}
const SummaryStep: FC<Props> = ({handleBack, handleSubmit, allValues}): ReactElement => {
  const intl = useIntl()
  const childData = allValues.child
  const parents = allValues.parent

  return (
    <form
      id='kt_modal_add_user_form'
      className='form container p-8 d-flex flex-column align-items-end w-100'
      // onSubmit={formik.handleSubmit}
      noValidate
    >
      <Divider orientation='horizontal' style={{width: '100%'}}>
        <h4>{intl.formatMessage({id: 'KIDLING.KID'})}</h4>
      </Divider>
      <div className='row align-items-center'>
        {Object.keys(childData)?.map((key: string, index: number) => {
          return (
            <div className='col-md-6 col-12'>
              <div className=' my-3 text-center shadow-sm p-4 col-md-offset-2' key={index}>
                <label className='  form-label fs-6 fw-bold text-muted me-2'>{key}:</label>

                <label className='  form-label fs-6 fw-semibold text-muted'>{childData[key]}</label>
              </div>
            </div>
          )
        })}
      </div>

      <div className='row align-items-center justify-content-center my-8'>
        {parents?.map((item: any, index: number) => {
          return (
            <div className='row align-items-center g-5' key={index}>
              <Divider orientation='horizontal' style={{width: '100%'}}>
                <h4>
                  {intl.formatMessage({id: 'KIDLING.GUARDIAN'})} {index + 1}
                </h4>
              </Divider>
              {Object.keys(item)?.map((key: string, index1: number) => {
                return (
                  <div className='col-md-6 col-12'>
                    <div className='text-center shadow-sm p-4 col-md-offset-2' key={index1}>
                      <label className='  form-label fs-6 fw-bold text-muted me-2 mb-0'>
                        {fieldToLableMapper(intl, key)}:
                      </label>

                      <label className='  form-label fs-6 fw-semibold text-muted mb-0'>
                        {key === 'dateOfBirth'
                          ? moment(item[key]).format('MM.DD.yyyy')
                          : key === 'country' || key === 'origincountry'
                          ? item[key].label
                          : item[key]}
                      </label>
                    </div>
                  </div>
                )
              })}
              <Divider orientation='horizontal' style={{width: '100%'}}></Divider>
            </div>
          )
        })}
      </div>
      <div className='d-flex align-items-center ms-auto '>
        <button type='button' className='btn me-2 bg-secondary ' onClick={() => handleBack()}>
          {intl.formatMessage({
            id: 'KIDLING.BACK',
          })}
        </button>
        <button type='submit' onClick={handleSubmit} className='btn   custom__button'>
          {intl.formatMessage({
            id: 'AUTH.GENERAL.SUBMIT_BUTTON',
          })}
        </button>
      </div>
    </form>
  )
}

export default SummaryStep
