import {useEffect, useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link, useNavigate} from 'react-router-dom'
import {useFormik} from 'formik'
import {useIntl} from 'react-intl'
import ReCAPTCHA from 'react-google-recaptcha'
import {toast} from 'react-toastify'
import {useLang} from '../../../../_metronic/i18n/Metronici18n'
import OtpInput from 'react-otp-input'
import {changePassowrdByOtp, sendOtpByEmail} from '../../../../controller/users'

export function ForgotPassword() {
  const initialMinutes = 10
  const initialSeconds = 0
  const intl = useIntl()
  const lang = useLang()
  const [hasErrors] = useState<boolean | undefined>(undefined)
  const company = window.location.pathname.split('/')[1]
  const [step, setStep] = useState(0)
  const [captchaToken, setCaptchaToken] = useState('')
  const [captchaError, setCaptchaError] = useState(false)
  const [minutes, setMinutes] = useState(initialMinutes)
  const [seconds, setSeconds] = useState(initialSeconds)
  const navigate = useNavigate()
  const [initialValues] = useState<any>({
    email: '',
    otp: '',
    password: '',
    confirmPassword: '',
  })
  const Step1Schema = Yup.object().shape({
    email: Yup.string()
      .email(intl.formatMessage({id: 'KIDDLING.INVALID_EMAIL'}))
      .required(intl.formatMessage({id: 'KIDLING.REQUIRED'})),
  })

  useEffect(() => {
    setMinutes(initialMinutes)
    setSeconds(initialSeconds)
  }, [step])

  useEffect(() => {
    const interval: any = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1)
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval)
        } else {
          setMinutes(minutes - 1)
          setSeconds(59)
        }
      }
    }, 1000)

    return () => {
      clearInterval(interval)
    }
  })

  useEffect(() => {
    setMinutes(initialMinutes)
    setSeconds(initialSeconds)
  }, [step])

  const forgotPasswordSchema = Yup.object().shape({
    otp: Yup.string().required(intl.formatMessage({id: 'KIDLING.REQUIRED'})),
    password: Yup.string()
      .required(intl.formatMessage({id: 'KIDLING.REQUIRED'}))
      .min(8, intl.formatMessage({id: 'KIDLING.SHORT_PASSWORD'}))
      .test(
        'isValidPass',
        intl.formatMessage({id: 'KIDLING.NOT_COMPLEX_PASSWORD'}),
        (value: any, context: any) => {
          const hasUpperCase = /[A-Z]/.test(value)
          const hasNumber = /[0-9]/.test(value)
          const hasLowerCase = /[a-z]/.test(value)
          const hasSymbole = /["!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~"]/.test(value)
          let validConditions = 0
          const numberOfMustBeValidConditions = 3
          const conditions = [hasUpperCase, hasLowerCase, hasNumber, hasSymbole]
          conditions.forEach((condition) => (condition ? validConditions++ : null))
          if (validConditions >= numberOfMustBeValidConditions) {
            return true
          }
          return false
        }
      ),
    confirmPassword: Yup.string()
      .required(intl.formatMessage({id: 'KIDLING.REQUIRED'}))
      .oneOf([Yup.ref('password'), null], intl.formatMessage({id: 'KIDLING.PASSWORDS_DONT_MATCH'})),
  })

  const formik: any = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setSubmitting(true)
      const resp = await changePassowrdByOtp({
        otp: values.otp,
        password: values.password,
        email: formik1.values.email,
      })
      setSubmitting(false)

      if (!!resp?.data) {
        logoutMobile()
        toast.success(intl.formatMessage({id: 'COMMON.SUCCESS'}))
        navigate(company ? `/${company}/auth` : '/kidling/auth')
      } else {
        toast.error(intl.formatMessage({id: 'COMMON.FAIL'}))
      }
    },
  })
  const formik1: any = useFormik({
    initialValues,
    validationSchema: Step1Schema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      try {
        if (captchaToken !== '') {
          setSubmitting(true)
          const resp = await sendOtpByEmail({email: values.email})
          if (!!resp) {
            setStep(1)
            toast.success(intl.formatMessage({id: 'KIDLING.OTP_SENT_CHECK_EMAIL'}))
          } else {
            toast.error(intl.formatMessage({id: 'COMMON.FAIL'}))
          }
        } else {
          setCaptchaError(true)
        }
        setSubmitting(false)
      } catch (e) {
        setSubmitting(false)
      }
    },
  })
  const onCaptchaChange = (val: string) => {
    setCaptchaToken(val)
    setCaptchaError(false)
  }

  const resendOtp = async () => {
    formik1.setSubmitting(true)
    const resp = await sendOtpByEmail({email: formik1.values.email})
    if (!!resp) {
      setStep(1)
      setMinutes(initialMinutes)
      setSeconds(initialSeconds)
      toast.success(intl.formatMessage({id: 'KIDLING.OTP_SENT_CHECK_EMAIL'}))
    } else {
      toast.error(intl.formatMessage({id: 'COMMON.FAIL'}))
    }
    formik1.setSubmitting(false)
  }

  const logoutMobile = () => {
    if ((window as any)['ReactNativeWebView'])
      (window as any)['ReactNativeWebView'].postMessage(JSON.stringify({logout: true}))
  }
  return (
    <>
      {step === 0 && (
        <form
          className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
          noValidate
          id='kt_login_password_reset_form'
          onSubmit={formik1.handleSubmit}
        >
          <div className='text-center mb-10'>
            {/* begin::Title */}
            <h1 className='text-dark mb-3'>
              {intl.formatMessage({id: 'KIDLING.FORGOT_PASSWORD'})}?
            </h1>
            {/* end::Title */}

            {/* begin::Link */}
            <div className='text-gray-400 fw-semibold fs-4'>
              {intl.formatMessage({id: 'KIDLING.ENTER_EMAIL_TO_RESET_PASSWORD'})}
            </div>
            {/* end::Link */}
          </div>

          {/* begin::Title */}
          {hasErrors === true && (
            <div className='mb-lg-15 alert alert-danger'>
              <div className='alert-text font-weight-bold'>
                {intl.formatMessage({id: 'KIDLING.ERROR_TRY_AGAIN'})}
              </div>
            </div>
          )}

          {/* end::Title */}

          {/* begin::Form group */}
          <div className='fv-row mb-10'>
            <label className='form-label fw-bold text-gray-900 fs-6'>
              {intl.formatMessage({id: 'KIDLING.EMAIL'})}
            </label>
            <input
              type='email'
              placeholder=''
              autoComplete='off'
              {...formik1.getFieldProps('email')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {'is-invalid': formik1.touched.email && formik1.errors.email},
                {
                  'is-valid': formik1.touched.email && !formik1.errors.email,
                }
              )}
            />
            {formik1.touched.email && formik1.errors.email && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik1.errors.email}</span>
                </div>
              </div>
            )}
          </div>
          <div className='my-4'>
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_CAPTCHA_KEY}
              onChange={onCaptchaChange}
              hl={lang}
            />
            {captchaError && (
              <div className='text-danger'>
                {intl.formatMessage({id: 'KIDLING.INCORRECT_CAPTCHA'})}
              </div>
            )}
          </div>
          {/* end::Form group */}

          {/* begin::Form group */}
          <div className='d-flex flex-wrap justify-content-center pb-lg-0 mt-4'>
            <button
              type='submit'
              className='btn btn-primary mx-2'
              data-kt-users-modal-action='submit'
              disabled={formik1.isSubmitting || !formik1.isValid || !formik1.touched}
            >
              {!formik1.isSubmitting && (
                <span className='indicator-label'>
                  {intl.formatMessage({id: 'AUTH.GENERAL.SUBMIT_BUTTON'})}
                </span>
              )}
              {formik1.isSubmitting && (
                <span className=''>
                  {intl.formatMessage({id: 'KIDLING.HOME.PLEASE_WAIT'})}...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
            <Link to='/auth/login' onClick={logoutMobile}>
              <button
                type='button'
                id='kt_login_password_reset_form_cancel_button'
                className='btn btn-lg btn-light-primary fw-bold'
                disabled={formik1.isSubmitting || !formik1.isValid}
              >
                {intl.formatMessage({id: 'COMMON.CANCEL'})}
              </button>
            </Link>{' '}
          </div>
          {/* end::Form group */}
        </form>
      )}
      {step === 1 && (
        <form
          id='kt_modal_add_user_form'
          className='form container p-0   d-flex flex-column align-items-start'
          onSubmit={formik.handleSubmit}
          noValidate
        >
          <div className='alert alert-warning mt-8    w-100' role='alert'>
            <h6>
              <span className='me-2'>
                <i className='fs-2 text-warning fa-solid fa-circle-exclamation '></i>
              </span>
              {intl.formatMessage({id: 'KIDLING.OTP_SENT_CHECK_EMAIL'})}
            </h6>
            <p className='mt-8 mb-0 text-muted'>
              {intl.formatMessage({id: 'KIDLING.DID_NOT_RECEIVE_OTP'})}
            </p>

            <h2 className='resend'>
              <button
                onClick={() => {
                  resendOtp()
                }}
                type='button'
                disabled={minutes > 0 || seconds > 0}
                className={`btn  text-black w-100 mt-4 ${
                  minutes > 0 || seconds > 0 ? 'btn-light curser-na' : 'btn-warning '
                }`}
              >
                {!formik1.isSubmitting && (
                  <span className='indicator-label'>
                    {intl.formatMessage({id: 'KIDLING.RESEND_OTP'})}{' '}
                    {(minutes > 0 || seconds > 0) && (
                      <>
                        <span>{' in '}</span>
                        <span>
                          {' '}
                          {String(minutes).padStart(2, '0')}:{String(seconds).padStart(2, '0')}
                        </span>
                      </>
                    )}
                  </span>
                )}
                {formik1.isSubmitting && (
                  <span className=''>
                    {intl.formatMessage({id: 'KIDLING.HOME.PLEASE_WAIT'})}...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </h2>
            {/* <p className='text-black'>Ihre verfolgte Zeit beträgt 7h 34m.</p>
            <button className='btn btn-warning text-black w-100 mt-4'>
              Bestätigen Sie die geschätzte
            </button> */}
          </div>
          <div className='row align-items-center'>
            <div className=' col-12 my-3'>
              <label className='required form-label fs-6 fw-semibold text-muted'>
                {intl.formatMessage({id: 'KIDLING.OTP_CODE'})}
              </label>
              <OtpInput
                value={formik.values.otp}
                onChange={(val: string) => formik.setFieldValue('otp', val)}
                numInputs={4}
                renderInput={(props) => <input {...props} />}
                containerStyle={{width: '100%'}}
                inputStyle={{width: '100%', margin: '5px', height: '3rem'}}
              />
              {/* <input
                type='text'
                {...formik.getFieldProps('otp')}
                className={clsx(
                  'form-control  mb-3 mb-lg-0',
                  {'is-invalid': formik.touched.otp && formik.errors.otp},
                  {
                    'is-valid': formik.touched.otp && !formik.errors.otp,
                  }
                )}
                name='otp'
                autoComplete='off'
                value={formik.values.otp}
              /> */}
              {formik.touched.otp && formik.errors.otp && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span className='alert-danger'>{formik.errors.otp}</span>
                  </div>
                </div>
              )}
            </div>
            <div className=' col-12 my-3'>
              <label className='required form-label fs-6 fw-semibold text-muted'>
                {intl.formatMessage({id: 'KIDLING.NEW_PASSWORD'})}
              </label>
              <input
                type='password'
                {...formik.getFieldProps('password')}
                className={clsx(
                  'form-control  mb-3 mb-lg-0',
                  {'is-invalid': formik.touched.password && formik.errors.password},
                  {
                    'is-valid': formik.touched.password && !formik.errors.password,
                  }
                )}
                name='password'
                autocomplete='one-time-code'
                value={formik.values.password}
              />
              {formik.touched.password && formik.errors.password && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span className='alert-danger'>{formik.errors.password}</span>
                  </div>
                </div>
              )}
            </div>

            <div className=' col-12 my-3'>
              <label className='required form-label fs-6 fw-semibold text-muted'>
                {intl.formatMessage({id: 'KIDLING.CONFIRM_PASSWORD'})}
              </label>
              <input
                type='password'
                {...formik.getFieldProps('confirmPassword')}
                className={clsx(
                  'form-control  mb-3 mb-lg-0',
                  {'is-invalid': formik.touched.confirmPassword && formik.errors.confirmPassword},
                  {
                    'is-valid': formik.touched.confirmPassword && !formik.errors.confirmPassword,
                  }
                )}
                name='confirmPassword'
                autocomplete='one-time-code'
                value={formik.values.confirmPassword}
              />
              {formik.errors.confirmPassword && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span className='alert-danger'>{formik.errors.confirmPassword}</span>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className='d-flex flex-wrap justify-content-center pb-lg-0 mt-4'>
            <button
              type='submit'
              className='btn btn-primary me-4'
              data-kt-users-modal-action='submit'
              disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
            >
              <span className='indicator-label'>
                {intl.formatMessage({id: 'AUTH.GENERAL.SUBMIT_BUTTON'})}
              </span>

              {!formik.isSubmitting && (
                <span className='indicator-progress'>
                  {intl.formatMessage({id: 'KIDLING.HOME.PLEASE_WAIT'})}...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
              {formik.isSubmitting && (
                <span className=''>
                  {intl.formatMessage({id: 'KIDLING.HOME.PLEASE_WAIT'})}...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
            <Link to='/auth/login' onClick={logoutMobile}>
              <button
                type='button'
                id='kt_login_password_reset_form_cancel_button'
                className='btn btn-lg btn-light-primary fw-bold'
                disabled={formik.isSubmitting || !formik.isValid}
              >
                {intl.formatMessage({id: 'COMMON.CANCEL'})}
              </button>
            </Link>{' '}
          </div>
        </form>
      )}
    </>
  )
}
