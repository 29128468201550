const fieldToLableMapper = (intl: any, key: string) => {
  const map: any = {
    firstName: intl.formatMessage({id: 'PARENT.FIRSTNAME'}),
    lastName: intl.formatMessage({id: 'PARENT.LASTNAME'}),
    origincountry: intl.formatMessage({id: 'KIDLING.ORIGIN_COUNTRY'}),
    dateOfBirth: intl.formatMessage({id: 'KIDLING.BIRTHDAY'}),
    phone: intl.formatMessage({id: 'PARENT.phone'}),
    email: intl.formatMessage({id: 'KIDLING.EMAIL'}),
    country: intl.formatMessage({id: 'KIDLING.COUNTRY'}),
    city: intl.formatMessage({id: 'KIDLING.CITY'}),
    PostalCode: intl.formatMessage({id: 'KIDLING.POSTAL_CODE'}),
    street: intl.formatMessage({id: 'PARENT.StreetName'}),
    houseNumber: intl.formatMessage({id: 'KIDLING.HOUSE_NUMBER'}),
    Mothertongue: intl.formatMessage({id: 'KIDLING.MOTHER_TONGUE'}),
    employer: intl.formatMessage({id: 'KIDLING.EMPLOYER'}),
    profession: intl.formatMessage({id: 'KIDLING.PROFISSION'}),
  }
  return map[key]
}

export default fieldToLableMapper
