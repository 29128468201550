/* eslint-disable react-hooks/exhaustive-deps */
import clsx from 'clsx'
import React, {useEffect, useState} from 'react'
import {Link, useParams} from 'react-router-dom'
import {KTSVG} from '../../../helpers'
import {useLayout} from '../../core'
import {Header} from './Header'
import {DefaultTitle} from './page-title/DefaultTitle'
import {Topbar} from './Topbar'
import {useAppContext} from '../../core/AppContextProvider'
import {useThemeMode} from '../../../partials/layout/theme-mode/ThemeModeProvider'
import {useMediaQuery} from 'react-responsive'

export function HeaderWrapper() {
  const {config, classes, attributes} = useLayout()
  const {header, aside} = config
  const company = window.location.pathname.split('/')[1]
  const {companyMetaData, mobileConfiguration, tabMessagesCounts} = useAppContext()
  const [countOfNewChats, setCountOfNewChats] = useState(0)
  const {mode} = useThemeMode()
  const isMobile = useMediaQuery({
    query: '(max-width:762px)',
  })
  return (
    <div
      id='kt_header'
      className={clsx('header px-4 px-sm-0', classes.header.join(' '), 'align-items-stretch')}
      data-kt-sticky='true'
      data-kt-sticky-name='header'
      data-kt-sticky-offset="{default: '200px', lg: '300px'}"
      {...attributes.headerMenu}
      style={{
        backgroundColor:
          mode !== 'dark' && companyMetaData.topBarColor ? companyMetaData.topBarColor : '',
      }}
    >
      <div
        className={clsx(
          classes.headerContainer.join(' '),
          'd-flex align-items-center justify-content-center'
        )}
      >
        <div className='d-flex align-items-center justify-content-center w-100'>
          {/* begin::Aside mobile toggle */}
          {aside.display && (
            <div className='d-flex align-items-center d-lg-none ms-n3 me-1' title='Show aside menu'>
              <div
                className='btn btn-icon btn-active-light-primary btn-custom w-30px h-30px w-md-40px h-md-40px'
                id='kt_aside_mobile_toggle'
              >
                <KTSVG
                  path='/media/icons/duotune/abstract/abs015.svg'
                  className='svg-icon-2x mt-1'
                  color={
                    mode !== 'dark' && companyMetaData.iconColor ? companyMetaData.iconColor : ''
                  }
                />
              </div>
            </div>
          )}
          {/* end::Aside mobile toggle */}

          <div className='d-flex topbar align-items-center   ms-n2 me-3' title='Show aside menu'>
            <div
              className='btn btn-icon btn-active-light-primary btn-custom w-30px h-30px w-md-40px h-md-40px'
              id='kt_header_menu_mobile_toggle'
            >
              <KTSVG
                path='/media/icons/duotune/abstract/abs015.svg'
                className='svg-icon-2x'
                color={
                  mode !== 'dark' && companyMetaData.iconColor ? companyMetaData.iconColor : ''
                }
              />
            </div>
          </div>

          <div className='header-logo me-5 me-md-10 flex-grow-1  '>
            <Link to={company ? `/${company}/dashboard` : '/kidling/dashboard'}>
              <img
                alt='Logo'
                src={
                  mode === 'dark'
                    ? companyMetaData?.imagePathLogoDark
                      ? companyMetaData?.imagePathLogoDark
                      : '/logo.png'
                    : companyMetaData?.imagePathLogoLight
                    ? companyMetaData?.imagePathLogoLight
                    : '/logo.png'
                }
                className={`logo-default mw-100 object-fit-contain ${
                  company.toLocaleLowerCase() === 'taeks'
                    ? 'h-40px'
                    : company.toLocaleLowerCase() === 'es.sence' && isMobile
                    ? 'h-20px'
                    : 'h-25px'
                }`}
              />
              <img
                alt='Logo'
                src={
                  mode === 'dark'
                    ? companyMetaData?.imagePathLogoDark
                      ? companyMetaData?.imagePathLogoDark
                      : '/logo.png'
                    : companyMetaData?.imagePathLogoLight
                    ? companyMetaData?.imagePathLogoLight
                    : '/logo.png'
                }
                className={`logo-sticky mw-100 object-fit-contain ${
                  company.toLocaleLowerCase() === 'taeks'
                    ? 'h-40px'
                    : company.toLocaleLowerCase() === 'es.sence' && isMobile
                    ? 'h-20px'
                    : 'h-25px'
                }`}
              />
            </Link>
          </div>

          {/* begin::Wrapper */}
          <div className='d-flex align-items-stretch justify-content-between  '>
            {/* begin::Navbar */}
            {header.left === 'menu' && (
              <div className='d-flex align-items-stretch' id='kt_header_nav'>
                <Header />
              </div>
            )}

            {header.left === 'page-title' && (
              <div className='d-flex align-items-center' id='kt_header_nav'>
                <DefaultTitle />
              </div>
            )}

            <div className='d-flex align-items-stretch flex-shrink-0'>
              <Topbar countOfNewChats={countOfNewChats} />
            </div>
          </div>
          {/* end::Wrapper */}
        </div>
      </div>
    </div>
  )
}
