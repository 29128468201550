/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useMemo} from 'react'
import {useIntl} from 'react-intl'
import _columns from '../_columns'
import {ProtocolsHeader} from './header/ProtocolsHeader'
import {useAppContext} from '../../../../_metronic/layout/core/AppContextProvider'
import TableCardsContainer from '../../../general-components/table-cards-container/TableCardsContainer'
import useProtocols from '../../../hooks/useProtocols'

function Protocol() {
  const {globalLoader, internalPagesDatesFilters} = useAppContext()
  const intl = useIntl()
  const {readProtocol, data} = useProtocols()
  const columns: any = useMemo(() => _columns(), [])

  useEffect(() => {
    if (!globalLoader) readProtocol()
  }, [internalPagesDatesFilters])

  return (
    <div className='container'>
      <ProtocolsHeader title={intl.formatMessage({id: 'KIDLING.MENU.PROTOCOLS'})} />
      <TableCardsContainer data={data} columns={columns} />
    </div>
  )
}

export default Protocol
