import {useIntl} from 'react-intl'
import {Button} from 'reactstrap'
import {useEffect, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {toast} from 'react-toastify'
import {useAuth} from '../../auth'
import {updateContactInfo} from '../../../../controller/users'

function NotificationSettings() {
  const intl = useIntl()

  const {currentUser, setCurrentUser} = useAuth()
  const account = currentUser?.contact

  const [initialValues, setInitialValues] = useState<any>({
    getNotifiedByEmail: account?.getNotifiedByEmail,
    getNotifiedInApp: account?.getNotifiedInApp,
  })
  useEffect(() => {
    if (account)
      setInitialValues({
        getNotifiedByEmail: account?.getNotifiedByEmail,
        getNotifiedInApp: account?.getNotifiedInApp,
      })
  }, [account])

  const profileDetailsSchema = Yup.object().shape({})

  const formik: any = useFormik({
    initialValues: initialValues,
    validationSchema: profileDetailsSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      try {
        const data = {
          ...account,
          getNotifiedByEmail: values.getNotifiedByEmail,
          getNotifiedInApp: values?.getNotifiedInApp,
        }

        const resp = await updateContactInfo(data)
        if (resp) {
          toast.success(intl.formatMessage({id: 'COMMON.SUCCESS'}))
          let temp = currentUser
          if (temp) {
            temp.contact = resp
            setCurrentUser(temp)
          }
        } else {
          toast.error(intl.formatMessage({id: 'COMMON.FAIL'}))
        }
      } catch (ex) {
        console.error(ex)
      } finally {
        setSubmitting(false)
      }
    },
  })

  return (
    <div className='card mb-5 mb-xl-10'>
      <div id='kt_account_notifications' className='collapse show'>
        <form className='form' onSubmit={formik.handleSubmit} noValidate>
          <div className='card-body border-top px-9 pt-3 pb-4'>
            <div className='card mb-5 mb-xl-10 p-8'>
              <div className='row  mt-8 w-100'>
                <p className='col-6  '> {intl.formatMessage({id: 'KIDLING.NOTIFY_BY_EMAIL'})} </p>
                <label className='form-check form-switch form-check-custom form-check-solid mb-2 col-6 justify-content-end'>
                  <input
                    className='form-check-input w-30px h-20px  '
                    type='checkbox'
                    name='allowAccessKidData'
                    checked={formik.values.getNotifiedByEmail}
                    onChange={() =>
                      formik.setFieldValue('getNotifiedByEmail', !formik.values.getNotifiedByEmail)
                    }
                  />
                </label>
              </div>
              <div className='row  mt-4 w-100'>
                <p className='col-6  '> {intl.formatMessage({id: 'KIDLING.NOTIFY_IN_APP'})} </p>
                <label className='form-check form-switch form-check-custom form-check-solid mb-2 col-6 justify-content-end'>
                  <input
                    className='form-check-input w-30px h-20px  '
                    type='checkbox'
                    name='allowAccessKidData'
                    checked={formik.values.getNotifiedInApp}
                    onChange={() =>
                      formik.setFieldValue('getNotifiedInApp', !formik.values.getNotifiedInApp)
                    }
                  />
                </label>
              </div>
            </div>
          </div>

          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <Button color='primary' type='submit' disabled={formik.isSubmitting} className=' '>
              {!formik.isSubmitting &&
                intl.formatMessage({
                  id: 'AUTH.GENERAL.SUBMIT_BUTTON',
                })}
              {formik.isSubmitting && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  {intl.formatMessage({id: 'KIDLING.HOME.PLEASE_WAIT'})}...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </Button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default NotificationSettings
