/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useState} from 'react'
import ChatSidebar from './components/ChatSidebar'
import {Outlet, Route, Routes, useNavigate} from 'react-router-dom'
import GroupDetailsMessages from './components/GroupDetailsMessages'
import './styles.scss'
import {useMediaQuery} from 'react-responsive'
import PrivateDetailsMessages from './components/PrivateDetailsMessages'
import useIsChatDetailsPath from '../../hooks/useCurrentPath'
import {useAppContext} from '../../../_metronic/layout/core/AppContextProvider'
import {useChatContext} from '../../../store/context/ChatProvider'
import {useAuth} from '../auth'
import {HubConnectionBuilder, LogLevel} from '@microsoft/signalr'
function Chat() {
  const {connection, setOnlineUsers, setConnection} = useChatContext()
  const [isConnecting, setIsConnecting] = useState(false)
  const {auth, socketUrl} = useAuth()
  const isDetailsPage = useIsChatDetailsPath()
  const isMobile = useMediaQuery({
    query: '(max-width: 1000px)',
  })

  const {mobileConfiguration} = useAppContext()
  const navigate = useNavigate()
  useEffect(() => {
    if (!mobileConfiguration?.ChatGroup[0]?.showInParentApp) {
      navigate('/')
    }
  }, [mobileConfiguration])
  useEffect(() => {
    if (
      ((connection?._connectionState !== 'Connected' &&
        connection?._connectionState !== 'Connecting') ||
        !connection) &&
      auth &&
      mobileConfiguration?.ChatGroup &&
      mobileConfiguration?.ChatGroup[0]?.showInParentApp &&
      !isConnecting &&
      !!socketUrl
    )
      startConnection()
  }, [connection, auth, mobileConfiguration, socketUrl])
  const startConnection = async () => {
    try {
      setIsConnecting(true)
      if (!!socketUrl) {
        const connection: any = new HubConnectionBuilder()
          .withUrl(socketUrl + 'chatHub', {
            accessTokenFactory: () => `${auth?.token}`,
          })
          .configureLogging(LogLevel.Error)
          .build()
        setConnection(connection)
        await connection.start()

        connection.on('OnlineUsers', function (response: any) {
          setOnlineUsers(response)
        })
      }
      setIsConnecting(false)
    } catch (e) {
      setIsConnecting(false)
    }
  }
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'start',
        height: '100vh',
        width: '100vw',
        position: 'fixed',
        top: '5rem',
        left: 0,
        right: 0,
        bottom: '2rem',

        overflow: 'hidden',
      }}
    >
      <div
        id='kt_app_content'
        className='app-content flex-column-fluid'
        style={{
          display: 'flex',
          justifyContent: 'center',
          // paddingBottom: '3rem',
          height: '87%',
          width: '80%',
          overflow: 'hidden',
          borderBottom: '1px solid #ccc',
        }}
      >
        <div
          id='kt_app_content_container'
          className='h-100 app-container card card-flush container-xxl p-0'
        >
          <div className='h-100 d-flex flex-column flex-lg-row gap-4 '>
            {(!(isMobile && isDetailsPage) || !isMobile) && (
              <div className='h-100 d-flex flex-column  w-100 mw-100 w-100 w-lg-350px  '>
                <div className='h-100  mb-0'>
                  <ChatSidebar />
                </div>
              </div>
            )}

            <div className='flex-lg-row-fluid p-0 m-0 h-100 chat__background'>
              <div className='  h-100 p-0 '>
                {/* <GroupDetailsMessages /> */}
                <Routes>
                  <Route element={<Outlet />}>
                    <Route path='group/:id' element={<GroupDetailsMessages />} />
                    <Route path='private/:id' element={<PrivateDetailsMessages />} />
                    {!isMobile && (
                      <Route
                        path='*'
                        element={
                          <div className='h-100 w-100 d-flex align-items-center justify-content-center'>
                            <i
                              className='fa-sharp fa-solid fa-comments  '
                              style={{fontSize: '6rem'}}
                            ></i>
                          </div>
                        }
                      />
                    )}
                  </Route>
                </Routes>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Chat
