import React, {FC, ReactElement, useEffect, useState} from 'react'
import './styles.scss'
import Slider from 'react-slick'
import SliderArrowNext from '../slider-arrow-next'
import SliderArrowBack from '../slider-arrow-back'
import DashboardSliderImageCard from '../DashboardSliderImageCard/DashboardSliderImageCard'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import AvatarWithLetters from '../avatar-with-letters'
import moment from 'moment'
import {useIntl} from 'react-intl'
import ImageGallery from 'react-image-gallery'
import 'react-image-gallery/styles/css/image-gallery.css'
import SliderImageWithEnlarge from '../slider-image-with-enlarge'
import {Avatar} from '@mui/material'

interface Props {
  data: any
  readData?: any
  hideCheckbox?: boolean
}
const ImagesSlider: FC<Props> = ({data, readData, hideCheckbox}): ReactElement => {
  const intl = useIntl()
  const [enlargeImage, setEnlargeImage] = useState('')
  const [showAddHashtagDialog, setShowAddHashtagDialog] = useState(false)
  const company = window.location.pathname.split('/')[1]
  const [showDelete, setShowDelete] = useState({show: false, id: -1})
  const [showImageDelete, setShowImageDelete] = useState(false)
  const [taggedKids, setTaggedKids] = useState([])
  const [showTaggedKids, setShowTaggedKids] = useState(false)
  const [sliderData, setSliderData] = useState([])
  const [currentIndex, setCurrentIndex] = useState(0)
  useEffect(() => {
    let temp: any = []
    data?.map((item: any, index: number) =>
      temp.push({
        original: item.FilePath ?? item.filePath,
        thumbnail: item.FilePath ?? item.filePath,
        ...item,
        index: index,
      })
    )
    setSliderData(temp)
  }, [data])

  // const handleDelete = async (id: number) => {
  //   let hashTagsIds: any = []
  //   data[0]?.Hashtags.map((item: any) => {
  //     if (item.HashtagId !== id) hashTagsIds = [...hashTagsIds, item.HashtagId]
  //   })
  //   const data = {KidMediaFileId, hashTagsIds}
  //   const resp = await addHashtagToImage(data)
  //   if (!!resp) {
  //     if (readData) readData()
  //     toast.success(intl.formatMessage({id: 'COMMON.SUCCESS'}))
  //   }
  // }

  // const handleDeleteFile = async () => {
  //   const resp: any = await deleteMediaFile(KidMediaFileId ?? -1)
  //   if (resp.status === 200) {
  //     if (readData) readData()
  //     toast.success(intl.formatMessage({id: 'COMMON.SUCCESS'}))
  //   }
  // }

  return (
    <div className='slider_card card h-100 '>
      <div className='slider__section'>
        {data?.length > 0 && (
          <div className='d-flex flex-column   rounded p-3 h-100 '>
            <div className='d-flex align-items-center justify-content-between mt-4 mb-2'>
              <Avatar
                src={data[0]?.userImagePath ?? data[0]?.UserImagePath}
                alt={
                  data[0]?.userName
                    ? data[0]?.userName?.charAt(0)?.toUpperCase()
                    : data[0]?.UserName?.charAt(0)?.toUpperCase()
                }
              />

              <div className='ms-2 w-100 d-flex justify-content-between'>
                <div>
                  <h5 className='m-0 p-0'>{data[0]?.userName ?? data[0]?.UserName}</h5>
                  <p className='fs-7 text-muted m-0 p-0'>
                    {moment(data[0]?.date ?? data[0]?.createdDate ?? data[0]?.CreatedDate).format(
                      'DD.MM.yyyy'
                    )}
                  </p>
                </div>
              </div>
            </div>

            {/* <div className='d-flex flex-wrap mt-2'>
              {data[0].Hashtags.map((item: any, index: number) => (
                <span
                  className='badge badge-info fw-bold m-1 p-0 d-flex align-content-center justify-content-center tag__container'
                  key={index}
                >
                  <Link
                    to={
                      company
                        ? `/${company}/media?tag=${item.HashtagName}`
                        : `/kidling/media?tag=${item.HashtagName}`
                    }
                    className='text-white p-1 px-2'
                  >
                    {item.HashtagName}
                  </Link>
                  <button
                    type='button'
                    className='btn rounded-start-0  d-flex align-content-center justify-content-center m-0 p-0 tag__btn'
                    onClick={() => setShowDelete({show: true, id: item.HashtagId})}
                  >
                    <i className='fa-solid fa-xmark p-0 m-0 text-white icon'></i>
                  </button>
                </span>
              ))}
              <button
                className='btn border border-1 border-success border-dashed py-1 my-0 ms-1 px-2 d-flex align-items-center justify-content-center add_btn'
                onClick={() => setShowAddHashtagDialog(true)}
              >
                <i className='fa-solid fa-plus p-0 m-0'></i>
              </button>
            </div> */}
            <ImageGallery
              items={sliderData}
              showPlayButton={false}
              useBrowserFullscreen={false}
              onSlide={(currentIndex) => {
                setCurrentIndex(currentIndex)
              }}
              renderLeftNav={(onClick, disabled) => (
                <SliderArrowBack onClick={onClick} disabled={disabled} />
              )}
              renderRightNav={(onClick, disabled) => (
                <SliderArrowNext onClick={onClick} disabled={disabled} />
              )}
              renderFullscreenButton={(onClick, isFullscreen) => (
                <button className='enlarge__btn' onClick={onClick}>
                  {isFullscreen ? (
                    <i className='fas fa-compress '></i>
                  ) : (
                    <i className='fa-solid fa-expand'></i>
                  )}
                </button>
              )}
              renderItem={(item: any) => {
                return (
                  <div className='slide h-100 mh-100 position-relative'>
                    <SliderImageWithEnlarge
                      {...item}
                      KidMediaFileId={item.kidMediaFileId}
                      img={item.filePath}
                      setEnlargeImage={setEnlargeImage}
                      enlargeImage={enlargeImage}
                      activateLongPress
                      hideCheckbox={hideCheckbox}
                      desc={item?.description}
                      currentIndex={currentIndex}
                    />
                    {item?.confidential && (
                      <div className='position-absolute ' style={{right: '3rem', top: '-5px'}}>
                        <i className='fa-solid fa-lock fs-3 text-info'></i>
                      </div>
                    )}
                  </div>
                )
              }}
            />
            <p className='mb-4 p-0 text-muted fs-4'>
              {data[0]?.kidMediaFileDescription ?? data[0]?.KidMediaFileDescription}
            </p>
            {/* <Slider {...settings}>
              {data.map((item: any, index: number) => (
                <div className='slide h-100 mh-100 position-relative' key={index}>
                  <DashboardSliderImageCard
                    {...item}
                    filePath={item.FilePath ?? item.filePath}
                    setEnlargeImage={setEnlargeImage}
                    enlargeImage={enlargeImage}
                    activateLongPress
                    hideCheckbox={hideCheckbox}
                  />
                  {item?.confidential && (
                    <div className='position-absolute ' style={{right: '3rem', top: '-5px'}}>
                      <i className='fa-solid fa-lock fs-3 text-info'></i>
                    </div>
                  )}
                </div>
              ))}
            </Slider> */}
          </div>
        )}

        {enlargeImage !== '' && (
          <div className='position-relative '>
            <div
              className={`  full__image  d-flex justify-content-center align-items-center  w-screen h-screen`}
            >
              <button className='close__btn' onClick={() => setEnlargeImage('')}>
                <i className='fa-solid fa-xmark'></i>
              </button>

              <div
                className={`  d-flex justify-content-center align-items-center  slider__image__animation`}
              >
                <img src={enlargeImage} className='h-screen w-screen object-fit-contain' />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default ImagesSlider
