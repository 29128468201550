import {FC} from 'react'
import moment from 'moment'
import {AssessmentsSearchComponent} from './AssessmentsSearchComponent'
import {AssessmentsToolbar} from './AssessmentsToolbar'
import CustomDateRangePicker from '../../../../general-components/date-range-picker/DateRangePicker'
import useAssessment from '../../../../hooks/useAssessment'

interface Props {
  title?: string
}
const AssessmentsHeader: FC<Props> = ({title}) => {
  const {handleDateChange} = useAssessment()
  return (
    <div className='  border-0 pt-6  w-100'>
      <div className='d-flex align-items-center justify-content-between w-100'>
        {title && <h4>{title}</h4>}
        <CustomDateRangePicker
          handleDateChange={handleDateChange}
          initialDate={{
            startDate: moment().startOf('year').toDate(),
            endDate: moment().endOf('year').toDate(),
          }}
        />
      </div>

      <div className='d-flex align-items-center w-100  w-md-auto'>
        <AssessmentsSearchComponent />
        <AssessmentsToolbar />
      </div>
    </div>
  )
}

export {AssessmentsHeader}
