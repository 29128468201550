/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect} from 'react'
import {Navigate, Route, Routes, Outlet} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {Overview} from './components/Overview'
import {Settings} from './components/settings/Settings'
import {ProfileAddress} from './components/settings/cards/ProfileAddress'
import {AccountHeader} from './AccountHeader'
import {useIntl} from 'react-intl'
import ChangePassword from './components/settings/ChangePassword'
import {useAppContext} from '../../../_metronic/layout/core/AppContextProvider'
import {useAuth} from '../auth'
import {
  getAllCities,
  getAllCountries,
  getGenders,
  getSalutations,
  getStates,
} from '../../../controller/common'
import NotificationSettings from './components/NotificationSettings'

const accountBreadCrumbs: Array<PageLink> = [
  {
    title: 'Account',
    path: '/parent/account/overview',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const AccountPage: React.FC = () => {
  const intl = useIntl()
  const company = window.location.pathname.split('/')[1]
  const {
    setSalutations,
    salutations,
    setGenders,
    setGlobalLoader,
    setCountries,
    setCities,
    setStates,
  } = useAppContext()
  const {currentUser} = useAuth()

  useEffect(() => {
    salutations?.map((s: any) => <option value={s.salutationId}>{s.salutationTitle}</option>)
  }, [])

  useEffect(() => {
    load()
  }, [currentUser?.contact])

  const load = async () => {
    setGlobalLoader(true)
    setSalutations(await getSalutations())
    setGenders(await getGenders())

    setGlobalLoader(false)
  }
  useEffect(() => {
    const readCountries = async () => {
      const resp = await getAllCountries()
      const sorted = resp.sort((a: any, b: any) =>
        a.countryName.toLowerCase() === b.countryName.toLowerCase()
          ? 0
          : a.countryName.toLowerCase() > b.countryName.toLowerCase()
          ? 1
          : -1
      )
      setCountries(sorted)
    }
    const readCities = async () => {
      const resp = await getAllCities('1=1')

      const sorted = resp.sort((a: any, b: any) =>
        a.cityName.toLowerCase() === b.cityName.toLowerCase()
          ? 0
          : a.cityName.toLowerCase() > b.cityName.toLowerCase()
          ? 1
          : -1
      )

      setCities(sorted)
    }
    const readStates = async () => {
      const resp = await getStates()

      const sorted = resp.sort((a: any, b: any) =>
        a.stateName.toLowerCase() === b.stateName.toLowerCase()
          ? 0
          : a.stateName.toLowerCase() > b.stateName.toLowerCase()
          ? 1
          : -1
      )
      setStates(sorted)
    }
    readCountries()
    readCities()
    readStates()
  }, [])

  return (
    <Routes>
      <Route
        element={
          <>
            <AccountHeader />
            <Outlet />
          </>
        }
      >
        <Route
          path='overview'
          element={
            <>
              <PageTitle breadcrumbs={accountBreadCrumbs}>
                {intl.formatMessage({id: 'COMMON.Overview'})}
              </PageTitle>
              <Overview />
            </>
          }
        />
        <Route
          path='settings'
          element={
            <>
              <PageTitle breadcrumbs={accountBreadCrumbs}>
                {intl.formatMessage({id: 'COMMON.Settings'})}
              </PageTitle>
              <Settings />
            </>
          }
        />
        <Route
          path='changePassword'
          element={
            <>
              <PageTitle breadcrumbs={accountBreadCrumbs}>
                {intl.formatMessage({id: 'KIDLING.CHANGE_PASSWORD'})}
              </PageTitle>
              <ChangePassword />
            </>
          }
        />

        <Route
          path='address'
          element={
            <>
              <PageTitle breadcrumbs={accountBreadCrumbs}>
                {intl.formatMessage({id: 'COMMON.Address'})}
              </PageTitle>
              <ProfileAddress />
            </>
          }
        />
        <Route
          path='notifications'
          element={
            <>
              <PageTitle breadcrumbs={accountBreadCrumbs}>
                {intl.formatMessage({id: 'KIDLING.PROFILE.NOTIFICATIONS'})}
              </PageTitle>
              <NotificationSettings />
            </>
          }
        />
        <Route
          index
          element={
            <Navigate to={company ? `/${company}/profile/overview` : '/kidling/profile/overview'} />
          }
        />
      </Route>
    </Routes>
  )
}

export default AccountPage
