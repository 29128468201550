/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {useAuth} from '../../../views/auth'
import moment from 'moment'
import {useAppContext} from '../../../../_metronic/layout/core/AppContextProvider'

export function Overview() {
  const {currentUser} = useAuth()
  const account = currentUser?.contact
  const {salutations, genders, languages} = useAppContext()
  const [currentLang, setCurrentLang] = useState<any>({})
  const intl = useIntl()
  const [salution, setSalution] = useState(null)
  const [gender, setGender] = useState(null)

  useEffect(() => {
    if (account) {
      salutations?.forEach((s: any) => {
        if (s.salutationId === account?.salutationId) setSalution(s.salutationTitle)
      })
      genders?.forEach((s: any) => {
        if (s.genderId === account?.genderId) setGender(s.genderName)
      })
      setCurrentLang(
        languages?.find((x: any, index: number) => x.languageId === account?.personLanguageId)
      )
    }
  }, [account, currentUser, languages, genders, salutations])

  return (
    <>
      <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
        <div className='card-header cursor-pointer'>
          <div className='card-title m-0'>
            <h3 className='fw-bold m-0'>{intl.formatMessage({id: 'PARENT.PROFILEDETAILS'})}</h3>
          </div>
        </div>

        <div className='card-body p-9'>
          <div className='row mb-7'>
            <label className='col-lg-4 fw-semibold text-muted'>
              {intl.formatMessage({id: 'PARENT.SALUTATION'})}
            </label>

            <div className='col-lg-8'>
              <span className='fw-bold fs-6 text-dark'>{salution}</span>
            </div>
          </div>
          <div className='row mb-7'>
            <label className='col-lg-4 fw-semibold text-muted'>
              {intl.formatMessage({id: 'PARENT.FULLNAME'})}
            </label>

            <div className='col-lg-8'>
              <span className='fw-bold fs-6 text-dark'> {account?.contactName}</span>
            </div>
          </div>

          <div className='row mb-7'>
            <label className='col-lg-4 fw-semibold text-muted'>
              {intl.formatMessage({id: 'PARENT.EMAIL'})}
              <i
                className='fas fa-exclamation-circle ms-1 fs-7'
                data-bs-toggle='tooltip'
                title={intl.formatMessage({id: 'PARENT.EMAILHINT'})}
              ></i>
            </label>

            <div className='col-lg-8 fv-row'>
              <span className='fw-semibold fs-6'>{account?.emailAddress}</span>
            </div>
          </div>

          <div className='row mb-7'>
            <label className='col-lg-4 fw-semibold text-muted'>
              {intl.formatMessage({id: 'PARENT.USERNAME'})}
            </label>

            <div className='col-lg-8 fv-row'>
              <span className='fw-semibold fs-6'>{account?.emailAddress}</span>
            </div>
          </div>

          <div className='row mb-7'>
            <label className='col-lg-4 fw-semibold text-muted'>
              {intl.formatMessage({id: 'PARENT.PHONE'})}
            </label>

            <div className='col-lg-8 d-flex align-items-center'>
              <span className='fw-bold fs-6 me-2'>{account?.cellPhoneNumber}</span>

              {/* <span className='badge badge-success'>
                {intl.formatMessage({id: 'PARENT.PHONEVERIFIED'})}
              </span> */}
            </div>
          </div>

          <div className='row mb-7'>
            <label className='col-lg-4 fw-semibold text-muted'>
              {intl.formatMessage({id: 'PARENT.LANGUAGE'})}
            </label>

            <div className='col-lg-8'>
              <span className='fw-bold fs-6 text-dark'>
                {currentLang && (
                  <div className='d-flex align-items-center'>
                    <img
                      src={currentLang?.flag}
                      alt='flag'
                      className='w-15px h-15px rounded-1 ms-2 me-2'
                    />
                    <div>{currentLang?.label}</div>
                  </div>
                )}
              </span>
            </div>
          </div>

          <div className='row mb-7'>
            <label className='col-lg-4 fw-semibold text-muted'>
              {intl.formatMessage({id: 'PARENT.GENDER'})}
            </label>

            <div className='col-lg-8'>
              <span className='fw-bold fs-6 text-dark'>{gender}</span>
            </div>
          </div>
          {account?.dateOfBirth && (
            <div className='row mb-10'>
              <label className='col-lg-4 fw-semibold text-muted'>
                {intl.formatMessage({id: 'PARENT.DOB'})}
              </label>

              <div className='col-lg-8'>
                <span className='fw-semibold fs-6'>
                  {moment(account.dateOfBirth).format('DD.MM.yyyy')}
                </span>
              </div>
            </div>
          )}
          {account?.address && (
            <div className='row mb-7'>
              <label className='col-lg-4 fw-semibold text-muted'>
                {intl.formatMessage({id: 'PARENT.ADDRESS'})}
              </label>

              <div className='col-lg-8'>
                <span className='fw-bold fs-6 text-dark'>{account?.address}</span>
              </div>
            </div>
          )}

          {/*<div className='notice d-flex bg-light-warning rounded border-warning border border-dashed p-6'>
            <KTSVG
              path='icons/duotune/general/gen044.svg'
              className='svg-icon-2tx svg-icon-warning me-4'
            />
            <div className='d-flex flex-stack flex-grow-1'>
              <div className='fw-semibold'>
                <h4 className='text-gray-800 fw-bold'>We need your attention!</h4>
                <div className='fs-6 text-gray-600'>
                  Your payment was declined. To start using tools, please
                  <Link className='fw-bold' to='/profile/settings'>
                     
                    Add Payment Method
                  </Link>
                  .
                </div>
              </div>
            </div>
          </div>*/}
        </div>
      </div>
    </>
  )
}
