/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect} from 'react'
import FullCalendar from '@fullcalendar/react'
import listPlugin from '@fullcalendar/list'
import {useLang} from '../../../../../_metronic/i18n/Metronici18n'
import allLocales from '@fullcalendar/core/locales-all'
import {useAppContext} from '../../../../../_metronic/layout/core/AppContextProvider'
import AvatarWithLetters from '../../../../general-components/avatar-with-letters'
import moment from 'moment'
import {UsersListLoading} from '../../../../general-components/loading/UsersListLoading'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import {useIntl} from 'react-intl'
import {useThemeMode} from '../../../../../_metronic/partials/layout/theme-mode/ThemeModeProvider'
import useFoodMenu from '../../../../hooks/useFoodMenu'

function FoodMenu() {
  const {mode} = useThemeMode()
  const {globalLoader} = useAppContext()
  const intl = useIntl()
  const locale = useLang()
  const {
    triggerDateChanged,
    openFile,
    currentDownload,
    currentTab,
    setCurrentTab,
    setFoodItems,
    readFoodMenu,
    childClasses,
    start,
    isReading,
    FoodItems,
  } = useFoodMenu()

  useEffect(() => {
    setCurrentTab(0)
    setFoodItems([])
    readFoodMenu()
  }, [start, childClasses])

  return (
    <div className='col-12 col-sm-6 col-md-4 mb-4 w-100'>
      <FullCalendar
        plugins={[listPlugin]}
        headerToolbar={{
          left: 'title',
          center: '',
          right: 'prev,next',
        }}
        contentHeight='0'
        initialView='listDay'
        locale={locale}
        locales={allLocales}
        datesSet={triggerDateChanged}
        noEventsText=''
      />
      <Tabs
        defaultActiveKey={0}
        activeKey={currentTab}
        id='uncontrolled-tab-example'
        className='my-3'
        onSelect={(key: any) => setCurrentTab(key)}
      >
        {Object.keys(FoodItems)?.map((mainkey: any, index: number) => {
          return (
            <Tab eventKey={index} title={<h5>{mainkey}</h5>}>
              {Object.keys(FoodItems[mainkey]).length === 0 && (
                <div className=' mt-8 w-100 d-flex flex-column align-items-center justify-content-center'>
                  <i className='fa-solid fa-database  ' style={{fontSize: '5rem'}}></i>
                  <p className='mt-4 text-muted fs-3'>
                    {intl.formatMessage({id: 'KIDLING.MESSAGING.NO_RECORDS_YET'})}
                  </p>
                </div>
              )}
              {Object.keys(FoodItems[mainkey])?.map((key) => (
                <div
                  className={`card w-100 border  ${mode === 'dark' && 'bg-body text-white'} `}
                  style={{backgroundColor: '#F3F6F9'}}
                >
                  <div
                    className='card-header align-items-center fw-bold fs-4'
                    style={{minHeight: '3rem'}}
                  >
                    {key}
                  </div>
                  <ul className='list-group list-group-flush ps-8  bg-body'>
                    {FoodItems[mainkey][key]?.map((item: any) => (
                      <li className='list-group-item d-flex align-items-center mb-4 position-relative bg-body'>
                        <AvatarWithLetters
                          image={item.imagePath}
                          alt={item.foodItemName.charAt(0).toUpperCase()}
                          small
                        />

                        <div className='ms-4'>
                          {!!item.filePath ? (
                            <div className=' d-flex align-items-center'>
                              <h5
                                className='m-0 p-0 text-primary cursor-pointer'
                                onClick={() => openFile(item)}
                              >
                                {item.foodItemName}{' '}
                              </h5>
                              {isReading === item.filePath && (
                                <span className='fs-5 text-warning ms-2 '>
                                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                              )}

                              {currentDownload[item.filePath] && (
                                <a
                                  className='btn btn-link text-warning fs-5 ms-2'
                                  target='_blank'
                                  href={currentDownload[item.filePath]}
                                  rel='noreferrer'
                                >
                                  <i className='fa-solid fa-download'></i>
                                </a>
                              )}
                            </div>
                          ) : (
                            // <Link to={item.filePath} target='_blank'>
                            //   <h5 className='m-0 p-0 text-primary'>{item.foodItemName} </h5>
                            // </Link>

                            <h5 className='m-0 p-0'>{item.foodItemName}</h5>
                          )}
                          <p className='text-muted s-7 my-0'>{item.remarks}</p>
                        </div>
                        <p className='text-muted fs-7 position-absolute bottom-0 end-0 me-2 mb-1'>
                          {moment(item.startDate).format('DD.MMM')} -{' '}
                          {moment(item.endDate).format('DD.MMM')}
                        </p>
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </Tab>
          )
        })}
      </Tabs>
      {globalLoader && <UsersListLoading />}
    </div>
  )
}
export {FoodMenu}
