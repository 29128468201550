/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import {FC, useEffect} from 'react'
import MediaGallery from '../views/media/MediaGallery'
import {KTCard} from '../../_metronic/helpers'
import {useAppContext} from '../../_metronic/layout/core/AppContextProvider'
import {useNavigate} from 'react-router-dom'

const Media: FC = () => {
  const {mobileConfiguration} = useAppContext()
  const navigate = useNavigate()

  useEffect(() => {
    if (!mobileConfiguration?.KidMedia[0]?.showInParentApp) {
      navigate('/')
    }
  }, [mobileConfiguration])

  return (
    <KTCard>
      <MediaGallery />
    </KTCard>
  )
}

export {Media}
