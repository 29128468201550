export const handleAddCheckBox = (arr: any, id: any) => {
  let temp: any = [...arr]
  const exists = temp.find((item: any) => item === id)

  if (exists === id) {
    temp = temp.filter((i: any) => i !== id)
  } else {
    temp.push(id)
  }
  return temp
}

export const groupBy = (items: any, key: any) =>
  items.reduce(
    (result: any, item: any) => ({
      ...result,
      [item[key]]: [...(result[item[key]] || []), item],
    }),
    {}
  )

export const shadeColor = (color: string, percent: number) => {
  let R: any = parseInt(color.substring(1, 3), 16)
  let G: any = parseInt(color.substring(3, 5), 16)
  let B: any = parseInt(color.substring(5, 7), 16)

  R = (R * (100 + percent)) / 100
  G = (G * (100 + percent)) / 100
  B = (B * (100 + percent)) / 100

  R = R < 255 ? R : 255
  G = G < 255 ? G : 255
  B = B < 255 ? B : 255

  R = Math.round(R)
  G = Math.round(G)
  B = Math.round(B)

  let RR = R.toString(16).length === 1 ? '0' + R.toString(16) : R.toString(16)
  let GG = G.toString(16).length === 1 ? '0' + G.toString(16) : G.toString(16)
  let BB = B.toString(16).length === 1 ? '0' + B.toString(16) : B.toString(16)

  return '#' + RR + GG + BB
}

export const getOperatingSystem = (window: any) => {
  let os = 'unknow'
  const userAgent = window.navigator.userAgent
  const platform = window.navigator?.userAgentData?.platform || window.navigator.platform
  const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K']
  const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE']
  const iosPlatforms = ['iPhone', 'iPad', 'iPod']

  if (macosPlatforms.indexOf(platform) !== -1) {
    os = 'Mac OS'
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    os = 'iOS'
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = 'Windows'
  } else if (/Android/.test(userAgent)) {
    os = 'Android'
  } else if (/Linux/.test(platform)) {
    os = 'Linux'
  }

  return os
}
