/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import {FC, useEffect} from 'react'
import {KTCard} from '../../_metronic/helpers'
import KidEatingHabits from '../views/eating-habit'
import {useAppContext} from '../../_metronic/layout/core/AppContextProvider'
import {useNavigate} from 'react-router-dom'

const EatingHabit: FC = () => {
  const {mobileConfiguration} = useAppContext()
  const navigate = useNavigate()
  useEffect(() => {
    if (!mobileConfiguration?.KidMealTime[0]?.showInParentApp) {
      navigate('/')
    }
  }, [mobileConfiguration])
  return (
    <KTCard>
      <KidEatingHabits />
    </KTCard>
  )
}

export {EatingHabit}
