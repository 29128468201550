import axios from 'axios'
import * as endpoints from '../config/endpoints'

export const getAllEmployeesByKita = async (CompanyId: string) => {
  const params = {CompanyId, EmployeeReceiveCommunicationFromParents: true}
  return axios
    .get(`${endpoints.GET_EMPLOYEES_BY_KITA}`, {params})
    .then((response) => response?.data)
    .catch((error) => {
      console.log(error)
    })
}

export const getEmployeeInfoByUserId = async (UsersId: number) => {
  const params = {UsersId}
  return axios
    .get(`${endpoints.GET_EMPLOYEES_BY_USER}`, {params})
    .then((response) => (response && response?.data.length > 0 ? response.data[0] : null))
    .catch((error) => {
      console.log(error)
    })
}
