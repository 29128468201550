/* eslint-disable react-hooks/exhaustive-deps */
import {FC, useEffect, useMemo, useState} from 'react'
import {KTCardBody} from '../../../../../_metronic/helpers'
import TableContainer from '../../../../general-components/table-container/TableContainer'
import _columns, {_mobileColumns} from './columns/_columns'
import {
  getAllMessagesCounts,
  getAllMessagesV2,
  updateReceivedMessages,
} from '../../../../../controller/messages'
import {toast} from 'react-toastify'
import {useIntl} from 'react-intl'
import {useAuth} from '../../../auth'
import InboxTableHeader from './columns/InboxTableHeader'
import {useNavigate} from 'react-router-dom'
import {TableLoader} from '../../../../general-components/table-loader/TableLoader'
import {useMediaQuery} from 'react-responsive'
import {MESSAGE_FOLDER_ID, MESSAGE_TYPE} from '../../../../../config/constants'
import PaginationComponent from '../../../../general-components/pagination/Pagination'
import {useAppContext} from '../../../../../_metronic/layout/core/AppContextProvider'

const InboxTable: FC = () => {
  const [pageSize, setPageSize] = useState(15)
  const intl = useIntl()
  const [currentPage, setCurrentPage] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const {currentUser} = useAuth()
  const {setTabMessagesCounts, inboxData, setInboxData} = useAppContext()
  const navigate = useNavigate()

  const isMobile = useMediaQuery({
    query: '(max-width:700px)',
  })
  useEffect(() => {
    readMessages()
  }, [currentPage, pageSize])

  const handleMarkPinned = async (id: string, pinned: boolean) => {
    const data = {ids: [id], isPinned: !pinned}
    const resp = await updateReceivedMessages(data)

    if (resp) {
      readMessages()
      toast.success(intl.formatMessage({id: 'COMMON.SUCCESS'}))
    } else {
      toast.error(intl.formatMessage({id: 'COMMON.FAIL'}))
    }
  }

  const readMessages = async (filter?: string) => {
    setIsLoading(true)
    const resp = await getAllMessagesV2(
      currentUser?.usersId ?? 0,
      filter ?? '1=1',
      currentPage + 1,
      pageSize,
      MESSAGE_FOLDER_ID.Received,
      MESSAGE_TYPE.Message
    )

    setInboxData(resp)
    const resp1 = await getAllMessagesCounts(currentUser?.usersId ?? 0)
    setTabMessagesCounts(resp1)
    setIsLoading(false)
  }

  const columns: any = useMemo(
    () =>
      isMobile
        ? _mobileColumns({handleMarkPinned, navigate})
        : _columns({handleMarkPinned, navigate}),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isMobile]
  )

  return (
    <KTCardBody className='py-1 px-0'>
      <div className='  py-1 px-0'>
        <InboxTableHeader getAllMessages={readMessages} data={inboxData?.items} />

        <div className='  py-1'>
          {isLoading ? (
            <TableLoader colNum={4} rows={5} />
          ) : inboxData?.items?.length > 0 ? (
            <TableContainer columns={columns} data={inboxData?.items} hideCards hidePagination />
          ) : (
            <div className=' mt-8 w-100 d-flex flex-column align-items-center justify-content-center'>
              <i className='fa-sharp fa-solid fa-comments  ' style={{fontSize: '5rem'}}></i>
              <p className='mt-4 text-muted fs-3'>
                {intl.formatMessage({id: 'KIDLING.MESSAGING.NO_MESSAGES_YET'})}
              </p>
            </div>
          )}
        </div>
        {inboxData?.totalPages > 1 && !isLoading && (
          <PaginationComponent
            pageCount={inboxData?.totalPages}
            page={currentPage}
            setPage={setCurrentPage}
            setPageSize={setPageSize}
            pageSize={pageSize}
            total={inboxData?.totalCount}
          />
        )}
      </div>
    </KTCardBody>
  )
}

export {InboxTable}
