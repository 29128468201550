import React, {FC, useEffect, useState} from 'react'
import {readFileFrom} from '../../../controller/image'
import {decodeBase64} from '../../utils/getBase64'
interface Props {
  id: string
  className?: string
  currentIndex?: number
  index?: number
}
const CustomImage: FC<Props> = ({id, index, currentIndex, className}) => {
  // const [imageUrl, setImageUrl] = useState('/empty-image.png')
  // const [isLoading, setIsLoading] = useState(false)
  // useEffect(() => {
  //   if ((currentIndex && index && currentIndex === index) || (!index && !currentIndex)) getImage()
  // }, [index, currentIndex, id])

  // const getImage = async () => {
  //   setIsLoading(true)
  //   const resp = await readFileFrom(id)
  //   setIsLoading(false)
  //   const typeArray = id.split('.')
  //   const type = typeArray[typeArray.length - 1]
  //   const blob = await decodeBase64({
  //     base64: resp,
  //     name: id,
  //     type: type,
  //   })

  //   const url = URL.createObjectURL(blob)

  //   setImageUrl(url)
  // }
  // useEffect(() => {
  //   // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
  //   return () => URL.revokeObjectURL(imageUrl)
  // }, [])

  return (
    <div className='position-relative'>
      <img src={id} alt={id} loading='lazy' className={className} />
      {/* {isLoading && (
        <span
          className='spinner-border spinner-border  align-middle ms-2 position-absolute  '
          style={{top: '45%', left: '45%'}}
        ></span>
      )} */}
    </div>
  )
}

export default CustomImage
