import {FC} from 'react'
import {SleepsSearchComponent} from './SleepsSearchComponent'
import {SleepsToolbar} from './SleepsToolbar'
import CustomDateRangePicker from '../../../../general-components/date-range-picker/DateRangePicker'
import useSleepTimes from '../../../../hooks/useSleepTimes'

interface Props {
  title?: string
}
const SleepsHeader: FC<Props> = ({title}) => {
  const {handleDateChange} = useSleepTimes()
  return (
    <div className=' border-0 pt-6  w-100'>
      <div className='d-flex align-items-center justify-content-between'>
        {title && <h4 className='m-0 h-100 d-flex align-items-center '>{title}</h4>}
        <CustomDateRangePicker handleDateChange={handleDateChange} />
      </div>

      <div className='d-flex align-items-center  w-100  w-md-auto'>
        <SleepsSearchComponent />
        <SleepsToolbar />
      </div>
    </div>
  )
}

export {SleepsHeader}
