import {IconButton} from '@mui/material'
import React, {FC, ReactElement, useState} from 'react'
import {useIntl} from 'react-intl'

interface Props {
  value: string
  onChange: (v: string) => void
  setShowSearch: (v: boolean) => void
  showSearch: boolean
}
const SearchBoxIconToOpen: FC<Props> = ({
  value,
  onChange,
  setShowSearch,
  showSearch,
}): ReactElement => {
  const intl = useIntl()

  return (
    <>
      {!showSearch && (
        <IconButton onClick={() => setShowSearch(!showSearch)}>
          <i className='fa-solid fa-magnifying-glass'></i>
        </IconButton>
      )}
      {showSearch && (
        <div className='d-flex align-items-center form-control form-control-solid search__input__container py-2 mx-auto posisiton-absolute top-0 left-0 w-100'>
          <i className='fa-solid fa-magnifying-glass me-4'></i>
          <input
            type='text'
            className=' bg-transparent border-0 outline-0  px-2 flex-grow-1  search__input w-100'
            name='search'
            placeholder={intl.formatMessage({id: 'KIDLING.CHAT_TYPE_TO_SEARCH'})}
            value={value}
            onChange={(e: any) => onChange(e.target.value)}
          />
          <i
            className='fa-solid fa-xmark cursor-pointer fs-4'
            onClick={() => setShowSearch(false)}
          ></i>
        </div>
      )}
    </>
  )
}

export default SearchBoxIconToOpen
