import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import {Feeds} from '../pages/Feeds'
import {Media} from '../pages/Media'
import {Documents} from '../pages/Documents'
import MessagesWrapper from '../views/messages/MessagesWrapper'
import CalenderPage from '../views/calender'
import AccountPage from '../views/accounts/AccountPage'
import SuspensedView from '../general-components/SuspensedView/SuspensedView'
import Dashboard from '../pages/Dashboard'
import {EatingHabit} from '../pages/EatingHabit'
import KidRequests from '../views/requests'
import CompanyInfo from '../views/companyInfo'
import {PageLink, PageTitle} from '../../_metronic/layout/core'
import {useIntl} from 'react-intl'
import KidAttendance from '../views/kid-attendance'
import ActivitiesPage from '../views/activities'
import MedicalRecordsPage from '../views/medical-records'
import MealtimesPage from '../views/meals'
import ProfilePage from '../views/kid-profile'
import ProtocolPage from '../views/protocols'
import DrinksPage from '../views/drinks'
import SleeptimesPage from '../views/sleep-times'
import MoodPage from '../views/Mood'
import KidAbsense from '../views/kid-absenses'
import DiapersPage from '../views/diapers'
import AssessmentsPage from '../views/Assessments'
import Chat from '../views/chat/Chat'

const PrivateRoutes = () => {
  const company = window.location.pathname.split('/')[1]
  const intl = useIntl()

  const KidBreadcrumbs: Array<PageLink> = [
    {
      title: intl.formatMessage({id: 'KIDLING.MENU.KIDSMANAGEMENT'}),
      path: '/apps/kid-management/kids',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route
          path='auth/*'
          element={<Navigate to={company ? `/${company}/dashboard` : '/kidling/dashboard'} />}
        />

        {/* Pages */}

        <Route
          path={`/dashboard`}
          element={
            <SuspensedView>
              <Dashboard />
            </SuspensedView>
          }
        />
        <Route path='activities/*' element={<ActivitiesPage />} />
        <Route path='medical-records/*' element={<MedicalRecordsPage />} />
        <Route path='meals/*' element={<MealtimesPage />} />
        <Route path='kidprofile/*' element={<ProfilePage />} />
        <Route path='protocols/*' element={<ProtocolPage />} />
        <Route path='drinks/*' element={<DrinksPage />} />
        <Route path='sleeptimes/*' element={<SleeptimesPage />} />
        <Route path='mood/*' element={<MoodPage />} />
        <Route path='KidMood/*' element={<MoodPage />} />
        <Route path='diapers/*' element={<DiapersPage />} />
        <Route path='assessments/*' element={<AssessmentsPage />} />
        <Route
          path='attendance'
          element={
            <>
              <PageTitle breadcrumbs={KidBreadcrumbs}>
                {intl.formatMessage({id: 'KIDLING.MENU.KIDSATTENDANCE'})}
              </PageTitle>
              <KidAttendance />
            </>
          }
        />
        <Route
          path='KidAttendance'
          element={
            <>
              <PageTitle breadcrumbs={KidBreadcrumbs}>
                {intl.formatMessage({id: 'KIDLING.MENU.KIDSATTENDANCE'})}
              </PageTitle>
              <KidAttendance />
            </>
          }
        />
        <Route
          path='absense'
          element={
            <>
              <KidAbsense />
            </>
          }
        />
        <Route
          path='Kidabsent'
          element={
            <>
              <KidAbsense />
            </>
          }
        />
        <Route
          path={`/Events/*`}
          element={
            <SuspensedView>
              <CalenderPage />
            </SuspensedView>
          }
        />

        <Route
          path={`/calender/*`}
          element={
            <SuspensedView>
              <CalenderPage />
            </SuspensedView>
          }
        />

        <Route
          path={`/notifications`}
          element={
            <SuspensedView>
              <Feeds />
            </SuspensedView>
          }
        />
        <Route
          path={`/media`}
          element={
            <SuspensedView>
              <Media />
            </SuspensedView>
          }
        />
        <Route
          path={`/KidMedia`}
          element={
            <SuspensedView>
              <Media />
            </SuspensedView>
          }
        />

        <Route
          path={`/eating-habit`}
          element={
            <SuspensedView>
              <EatingHabit />
            </SuspensedView>
          }
        />
        <Route
          path={`/KidMealTime`}
          element={
            <SuspensedView>
              <EatingHabit />
            </SuspensedView>
          }
        />

        <Route
          path={`/requests`}
          element={
            <SuspensedView>
              <KidRequests />
            </SuspensedView>
          }
        />

        <Route
          path={`/RequestKid`}
          element={
            <SuspensedView>
              <KidRequests />
            </SuspensedView>
          }
        />

        <Route
          path={`/documents`}
          element={
            <SuspensedView>
              <Documents />
            </SuspensedView>
          }
        />
        <Route
          path={`/Attachment`}
          element={
            <SuspensedView>
              <Documents />
            </SuspensedView>
          }
        />
        <Route
          path={`/messages/*`}
          element={
            <SuspensedView>
              <MessagesWrapper />
            </SuspensedView>
          }
        />
        <Route
          path='chat/*'
          element={
            <SuspensedView>
              <Chat />
            </SuspensedView>
          }
        />
        <Route
          path={`/Messaging/*`}
          element={
            <SuspensedView>
              <MessagesWrapper />
            </SuspensedView>
          }
        />

        <Route
          path={`/profile/*`}
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />

        <Route
          path='companyInformation/*'
          element={
            <SuspensedView>
              <CompanyInfo />
            </SuspensedView>
          }
        />
        <Route path={`/`} element={<Navigate to={'dashboard'} />} />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

export {PrivateRoutes}
