import React, {FC, useState, ReactElement} from 'react'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import {IconButton, ListItemIcon, Tooltip} from '@mui/material'
interface Props {
  data: any
}
const DropDownMenu: FC<Props> = ({data}): ReactElement => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div className='d-flex align-items-center justify-content-between'>
      <Tooltip title={data?.title}>
        <IconButton
          onClick={handleClick}
          size='small'
          sx={{ml: 2}}
          aria-controls={open ? 'account-menu' : undefined}
          aria-haspopup='true'
          aria-expanded={open ? 'true' : undefined}
          className='px-2 py-1 m-0 d-flex align-items-center justify-content-center'
        >
          {data?.menuButton}
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        id='account-menu'
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        transformOrigin={{horizontal: 'right', vertical: 'top'}}
        anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
      >
        {data?.items?.map((singleItem: any, index: number) => (
          <MenuItem
            onClick={() => {
              singleItem?.onClick()
              handleClose()
            }}
            key={index}
          >
            <div className='d-flex align-items-center'>
              {singleItem.icon && <ListItemIcon>{singleItem.icon}</ListItemIcon>}

              <span>{singleItem?.title}</span>
            </div>
          </MenuItem>
        ))}
      </Menu>
    </div>
  )
}

export default DropDownMenu
