/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useMemo} from 'react'
import {useIntl} from 'react-intl'
import TableCardsContainer from '../../../general-components/table-cards-container/TableCardsContainer'
import _columns from '../_columns'
import {MedicalsHeader} from './header/MedicalsHeader'
import useMedicalRecords from '../../../hooks/useMedicalRecords'
function MedicalRecords() {
  const intl = useIntl()
  const columns: any = useMemo(() => _columns(), [])
  const {readMedicalRecords, data} = useMedicalRecords()

  useEffect(() => {
    readMedicalRecords()
  }, [])

  return (
    <div className='container'>
      <MedicalsHeader title={intl.formatMessage({id: 'KIDLING.MENU.MEDICALRECORDS'})} />
      <div className='mt-4'>
        <TableCardsContainer data={data} columns={columns} />
      </div>
    </div>
  )
}

export default MedicalRecords
