import moment from 'moment'

export const getBirthday = (dob: any) => {
  var a = moment()
  var b = moment(new Date(dob), 'MM-YYYY')
  var age = moment.duration(a.diff(b))
  var years = age.years()
  var months = age.months()
  return {years, months}
}

export const getCroppedImage = async (image: any, crop: any) => {
  const canvas = document.createElement('canvas')
  const scaleX = image.naturalWidth / image.width
  const scaleY = image.naturalHeight / image.height
  canvas.width = crop.width
  canvas.height = crop.height
  const ctx: any = canvas.getContext('2d')

  ctx.drawImage(
    image,
    crop.x * scaleX,
    crop.y * scaleY,
    crop.width * scaleX,
    crop.height * scaleY,
    0,
    0,
    crop.width,
    crop.height
  )

  return new Promise((resolve, reject) => {
    canvas.toBlob((blob: any) => {
      if (!blob) {
        reject(new Error('Canvas is empty'))
        return
      }
      blob.name = 'test'
      resolve(blob)
    }, 'image/jpeg')
  })
}
