/* eslint-disable react-hooks/exhaustive-deps */
import {FC, ReactElement, useEffect, useState} from 'react'
import {useMediaQuery} from 'react-responsive'
import {useAuth} from '../../auth'
import {useThemeMode} from '../../../../_metronic/partials/layout/theme-mode/ThemeModeProvider'
import {Link} from 'react-router-dom'

interface Props {
  setCurrentTab: any
  currentTab: number
}
const ProfileSidebar: FC<Props> = ({setCurrentTab, currentTab}): ReactElement => {
  const company = window.location.pathname.split('/')[1]
  const {mode} = useThemeMode()
  const isMobile = useMediaQuery({
    query: '(max-width:992px)',
  })
  const [menuData, setMenuData] = useState<any>([])
  const {currentUser} = useAuth()
  useEffect(() => {
    getKidsList()
  }, [currentUser])
  const getKidsList = async () => {
    let kids = []

    if (currentUser?.kids) {
      for (const kid of currentUser?.kids) {
        kids.push({
          imagePath: kid?.image,
          firstName: kid.firstName,
          lastName: kid.lastName,
          id: kid.kidId,
          //class:kid.class
        })
      }
    }
    setMenuData(kids)
  }

  return (
    <div className={` card-body  ${isMobile && 'pb-0'} `}>
      <div
        className={`menu ${
          isMobile ? ' menu-row flex-wrap ' : ' menu-column  mb-10'
        } menu-rounded menu-state-bg menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary`}
      >
        {menuData?.map(
          (item: any, index: number) =>
            item.firstName && (
              <div className='menu-item mb-0' key={index}>
                <Link
                  to={
                    company
                      ? `/${company}/kidprofile?kidId=${item.id}`
                      : `/itemling/kidprofile?kidId=${item.id}`
                  }
                  className=''
                >
                  <span
                    className={`menu-link ${currentTab === index && 'active'}`}
                    onClick={() => {
                      setCurrentTab(index, item.id)
                    }}
                  >
                    <span className='menu-icon'>
                      {item.imagePath ? (
                        <div className='symbol symbol-20px symbol-fixed position-relative'>
                          <img src={item.imagePath} alt='kid' />
                        </div>
                      ) : (
                        <i
                          className={`fa-solid fa-user fs-3 me-3 ${mode === 'dark' && 'text-dark'}`}
                        ></i>
                      )}
                    </span>
                    <span className={`menu-title fw-bold ${mode === 'dark' && 'text-dark'}`}>
                      {item.firstName}
                    </span>
                  </span>
                </Link>
              </div>
            )
        )}
      </div>
    </div>
  )
}

export default ProfileSidebar
