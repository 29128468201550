import * as endpoints from '../config/endpoints'
import axios from 'axios'

export const getParentKidsRequests = async (
  filter: string,
  parentId: number,
  IsDelivered: boolean,
  From: string,
  To: string
) => {
  return axios
    .get(`${endpoints.KID_REQUESTS_LIST_PARENT}/${filter}`, {
      params: {parentId, IsDelivered, From: From && To ? From : null, To: From && To ? To : null},
    })
    .then((response) => response.data.data)
    .catch((error) => {
      console.log(error)
    })
}
