import React, {FC, ReactElement, useEffect, useState} from 'react'
import 'react-photo-view/dist/react-photo-view.css'
import moment from 'moment'
import ImageWithEnlarge from '../image-with-enlarge'
import AvatarWithLetters from '../avatar-with-letters'
import ReactPlayer from 'react-player'
import {decodeBase64} from '../../utils/getBase64'
import {readFileFrom} from '../../../controller/image'
import ImageWithEnlargeMedia from '../image-with-enlarge-media'
import CustomVideo from '../custom-video/CustomVideo'
import {Avatar} from '@mui/material'

interface Props {
  image: string
  userImagePath: string
  userName: string
  date: string
  kidName: string
  title: string
  details: string
  KidMediaFileName?: string
  locked?: boolean
  Hashtags?: any
  KidMediaFileId?: number
  readData?: any
  KidId?: number
  hideCheckbox: boolean
}
const DashboardImageCard: FC<Props> = ({
  image,
  userImagePath,
  userName,
  title,
  kidName,
  details,
  date,
  KidMediaFileName,
  locked,
  Hashtags,
  KidMediaFileId,
  readData,
  KidId,
  hideCheckbox,
}): ReactElement => {
  const [fileExtension, setFileExtension] = useState('')

  useEffect(() => {
    if (KidMediaFileName) {
      const splitted = KidMediaFileName?.split('.')
      setFileExtension(splitted[splitted?.length - 1])
    }
  }, [KidMediaFileName])

  return (
    <div className='d-flex flex-column justify-content-between shadow rounded p-3 h-100 '>
      <div className=''>
        <div className='d-flex align-items-center mt-4 mb-2 '>
          {userImagePath && <Avatar src={userImagePath} alt={userName?.charAt(0)?.toUpperCase()} />}

          <div className='ms-2 w-100 d-flex justify-content-between'>
            <div>
              <h5 className='m-0 p-0'>{userName}</h5>
              <p className='fs-7 text-muted m-0 p-0'>{moment(date).format('DD.MM.yyyy')}</p>
            </div>
          </div>
        </div>

        {/* <div className='d-flex flex-wrap mt-2'>
          {Hashtags.map((item: any, index: number) => (
            <span
              className='badge badge-info fw-bold m-1 p-0 d-flex align-content-center justify-content-center tag__container'
              key={index}
            >
              <Link
                to={
                  company
                    ? `/${company}/media?tag=${item.HashtagName}`
                    : `/kidling/media?tag=${item.HashtagName}`
                }
                className='text-white p-1 px-2'
              >
                {item.HashtagName}
              </Link>
              <button
                type='button'
                className='btn rounded-start-0  d-flex align-content-center justify-content-center m-0 p-0 tag__btn'
                onClick={() => setShowDelete({show: true, id: item.HashtagId})}
              >
                <i className='fa-solid fa-xmark p-0 m-0 text-white icon'></i>
              </button>
            </span>
          ))}
          <button
            className='btn border border-1 border-success border-dashed py-1 my-0 ms-1 px-2 d-flex align-items-center justify-content-center add_btn'
            onClick={() => setShowAddHashtagDialog(true)}
          >
            <i className='fa-solid fa-plus p-0 m-0'></i>
          </button>
        </div> */}
      </div>
      <div className='position-relative h-100'>
        {fileExtension === 'mp4' ? (
          // <ReactPlayer url={image} width='100%' height='100%' controls={true} />
          <CustomVideo id={image} />
        ) : (
          <ImageWithEnlargeMedia
            img={image}
            desc={KidMediaFileName}
            KidMediaFileId={KidMediaFileId}
            activateLongPress
            hideCheckbox={hideCheckbox}
          />
        )}

        {locked && (
          <div className='position-absolute ' style={{right: '3rem', top: '-5px'}}>
            <i className='fa-solid fa-lock fs-3 text-info'></i>
          </div>
        )}
        <p className='mb-4 p-0 text-muted fs-4 '>{details}</p>
      </div>
    </div>
  )
}

export default DashboardImageCard
