import axios from 'axios'
import * as endpoints from '../config/endpoints'

export const getKidsMedicalRecords = async (filter: string) => {
  return axios
    .get(`${endpoints.KID_MEDICAL_RECORDS}/${filter}`)
    .then((response) => response.data.data)
    .catch((error) => {
      console.log(error)
    })
}
