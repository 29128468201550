import {useState} from 'react'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import GroupMembersDialog from './GroupMembersDialog'
import {GroupChatInner} from './GroupChatInner'
import '../styles.scss'
import {Link} from 'react-router-dom'
import SearchBoxIconToOpen from '../../../general-components/searchBoxIconToOpen/SearchBoxIconToOpen'
import DropDownMenu from '../../../general-components/dropDownMenu/DropDownMenu'
import {useIntl} from 'react-intl'
function GroupDetailsMessages() {
  const intl = useIntl()
  const company = window.location.pathname.split('/')[1]
  const [search, setSearch] = useState('')
  const [showSearch, setShowSearch] = useState(false)
  const [showMembersDialog, setShowMembersDialog] = useState(false)

  const optionsDropDownMenu = {
    title: intl.formatMessage({id: 'KIDLING.CHAT_SETTINGS'}),
    menuButton: <i className='fa-solid fa-ellipsis-vertical fs-3 me-2'></i>,
    items: [
      {
        title: '',
        icon: (
          <div className='form-check form-switch form-switch-sm form-check-custom form-check-solid '>
            <input
              className='form-check-input'
              type='checkbox'
              value=''
              name='notifications'
              defaultChecked={true}
            />
            <label className='form-check-label'>
              {intl.formatMessage({id: 'KIDLING.CHAT_MUTE_NOTIFICATIONS'})}
            </label>
          </div>
        ),
        onClick: () => {},
      },
      {
        title: intl.formatMessage({id: 'KIDLING.CHAT_EXIT_GROUP'}),

        onClick: () => {},
      },
    ],
  }

  return (
    <div className='flex-lg-row-fluid  mh-100 h-100 bg-transparent '>
      <div className='card mh-100 h-100 p-0 m-0 ' id='kt_chat_messenger'>
        <div
          className='card-header d-flex flex-row align-items-center  position-relative w-100'
          id='kt_chat_messenger_header'
        >
          {!showSearch && (
            <Link to={company ? `/${company}/chat` : '/kidling/chat'}>
              <i className='fa-solid fa-arrow-left fs-3 me-4'></i>
            </Link>
          )}
          <div className='d-flex align-items-center justify-content-between flex-grow-1  '>
            {!showSearch && (
              <div className='d-flex align-items-center'>
                <div className='card-title'>
                  <div className='symbol-group symbol-hover'>
                    <div className='symbol symbol-35px symbol-circle'>
                      <img alt='Pic' src={toAbsoluteUrl('/media/avatars/blank.png')} />
                    </div>
                    <div className='symbol symbol-35px symbol-circle'>
                      <img alt='Pic' src={toAbsoluteUrl('/media/avatars/blank.png')} />
                    </div>
                    <div className='symbol symbol-35px symbol-circle'>
                      <span className='symbol-label bg-light-warning text-warning 40px'>M</span>
                    </div>
                    <div className='symbol symbol-35px symbol-circle'>
                      <img alt='Pic' src={toAbsoluteUrl('/media/avatars/blank.png')} />
                    </div>
                    <div className='symbol symbol-35px symbol-circle'>
                      <span className='symbol-label bg-light-danger text-danger 40px'>O</span>
                    </div>
                    <div className='symbol symbol-35px symbol-circle'>
                      <span className='symbol-label bg-light-primary text-primary 40px'>N</span>
                    </div>
                    <div className='symbol symbol-35px symbol-circle'>
                      <img alt='Pic' src={toAbsoluteUrl('/media/avatars/blank.png')} />
                    </div>
                    <a
                      href='#!'
                      className='symbol symbol-35px symbol-circle'
                      // data-bs-toggle='modal'
                      // data-bs-target='#kt_modal_view_users'
                    >
                      <span
                        className='symbol-label fs-8 fw-bolder'
                        data-bs-toggle='tooltip'
                        data-bs-trigger='hover'
                        title='View more users'
                      >
                        +42
                      </span>
                    </a>
                  </div>
                </div>

                <div className='card-toolbar'>
                  <div className='me-n3'>
                    <button
                      className='btn btn-sm btn-icon btn-active-light-primary'
                      onClick={() => setShowMembersDialog(!showMembersDialog)}
                    >
                      <i className='bi bi-three-dots fs-2'></i>
                    </button>
                  </div>
                </div>
              </div>
            )}
            <div className={`me-n3 d-flex align-items-centerv ${showSearch && 'w-100'}`}>
              <SearchBoxIconToOpen
                value={search}
                onChange={setSearch}
                setShowSearch={setShowSearch}
                showSearch={showSearch}
              />
              {!showSearch && <DropDownMenu data={optionsDropDownMenu} />}
            </div>
          </div>
        </div>

        <GroupChatInner />
      </div>
      <GroupMembersDialog
        showModal={showMembersDialog}
        setShowModal={setShowMembersDialog}
        title={intl.formatMessage({id: 'KIDLING.GROUP_MEMBERS'})}
      />
    </div>
  )
}

export default GroupDetailsMessages
