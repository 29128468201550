import {useEffect} from 'react'
import {KidAbsenseModalHeader} from './KidAbsenseModalHeader'
import {KidAbsenseModalForm} from './KidAbsenseModalForm'

const KidAbsenseModal = (props: any) => {
  const kidId = props.kidId
  const companyId = props.companyId || undefined
  const setShowAbsenceModel = props.setShowAbsenceModel
  useEffect(() => {
    document.body.classList.add('modal-open')
    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [])

  return (
    <>
      <div
        className='modal modalfade show d-block'
        id='kt_modal_add_user'
        role='dialog'
        tabIndex={-1}
        aria-modal='true'
      >
        {/* begin::Modal dialog */}
        <div className='modal-dialog modal-dialog-centered mw-650px'>
          {/* begin::Modal content */}
          <div className='modal-content'>
            <KidAbsenseModalHeader setShowAbsenceModel={setShowAbsenceModel} />
            {/* begin::Modal body */}
            <div className='modal-body  overflow-auto mx-5 mx-xl-15 my-7'>
              <KidAbsenseModalForm
                isUserLoading={false}
                companyId={companyId}
                kidId={kidId}
                setShowAbsenceModel={setShowAbsenceModel}
              />
            </div>
            {/* end::Modal body */}
          </div>
          {/* end::Modal content */}
        </div>
        {/* end::Modal dialog */}
      </div>
      {/* begin::Modal Backdrop */}
      <div className='modal-backdrop fade show'></div>
      {/* end::Modal Backdrop */}
    </>
  )
}

export {KidAbsenseModal}
