import * as endpoints from '../config/endpoints'
import axios from 'axios'

export const BulkAbsence = async (data: any) => {
  return axios
    .post(endpoints.BULK_ABSENCE, data)
    .then((response) => response.data)
    .catch((error) => {
      console.log(error)
    })
}

export const BulkMedia = async (data: any) => {
  return axios
    .post(endpoints.BULK_MEDIA, data)
    .then((response) => response.data)
    .catch((error) => {
      console.log(error)
    })
}

export const KidDeRegistration = async (data: any) => {
  return axios
    .post(endpoints.DEREGISTRATION, data)
    .then((response) => response.data)
    .catch((error) => {
      console.log(error)
    })
}

export const BulkDownloadFiles = async (data: string) => {
  return axios
    .get(`${endpoints.BULK_DOWNLOAD_MEDIA}?${data}`, {
      responseType: 'arraybuffer',
    })
    .then((response) => response.data)
    .catch((error) => {
      console.log(error)
    })
}
