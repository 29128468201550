import moment from 'moment'
import {getKidsSleeptimes} from '../../controller/sleeps'
import {useEffect, useState} from 'react'
import {useAppContext} from '../../_metronic/layout/core/AppContextProvider'
import {useAuth} from '../views/auth'

const useSleepTimes = () => {
  const {
    kidsSleeptimes,
    setKidsSleeptimes,
    setGlobalLoader,
    internalPagesFilters,
    internalPagesDatesFilters,
    globalLoader,
    setInternalPagesFilters,
    setInternalPagesDatesFilters,
  } = useAppContext()
  const [initalCall, setinitalCall] = useState(true)
  const [data, setData] = useState([])
  const {currentUser} = useAuth()
  const [date, setDate] = useState<any>({})
  const [clicked, setClicked] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')

  useEffect(() => {
    filterData()
  }, [searchTerm])

  useEffect(() => {
    if (clicked && !globalLoader) {
      readSleeptimes()
      setClicked(false)
    }
  }, [clicked])
  useEffect(() => {
    const newFilters: any = {...internalPagesDatesFilters}

    newFilters.sleeptimes = {
      ...newFilters.sleeptimes,
      From: moment(date?.startDate).startOf('day').format('yyyy-MM-DDTHH:mm:ss'),
      To: moment(date?.endDate).endOf('day').format('yyyy-MM-DDTHH:mm:ss'),
    }

    setInternalPagesDatesFilters(newFilters)
  }, [date])
  useEffect(() => {
    let temp: any = []
    kidsSleeptimes?.map((item: any) => {
      let Sleeptimes: any = []
      item.kidSleeps?.map((sleep: any) =>
        Sleeptimes.push({
          id: sleep.kidSleepTimeId,

          title: sleep?.sleepHabit?.sleepHabitName,
          subtitle: sleep?.sleepHabit?.description,
          subtitle1: `${moment(sleep.date).format('DD.MM.yyyy')} ${
            sleep.fromTime ? moment(sleep.fromTime).format('HH:mm') : '--'
          } - ${sleep.toTime ? moment(sleep.toTime).format('HH:mm') : '--'}`,
          tag: `${Math.ceil(
            moment
              .duration(
                moment(
                  sleep?.toTime
                    ? moment(sleep?.date)
                        .set('hours', moment(sleep?.toTime).hours())
                        .set('minutes', moment(sleep?.toTime).minutes())
                        .format('yyyy-MM-DDTHH:mm:ss')
                    : moment().format('yyyy-MM-DDTHH:mm:ss')
                ).diff(
                  moment(sleep?.date)
                    .set('hours', moment(sleep?.fromTime).hours())
                    .set('minutes', moment(sleep?.fromTime).minutes())
                    .format('yyyy-MM-DDTHH:mm:ss')
                )
              )
              .asMinutes()
          )} minutes`,
        })
      )
      temp.push({
        image: item.imagePath,
        name: `${item.firstName} ${item.lastName}`,
        subItems: Sleeptimes,
        // handleDelete: (id: string) => doDelete(id),
      })
    })
    setData(temp)
  }, [kidsSleeptimes])

  const readSleeptimes = async () => {
    let filter: string = ''
    setGlobalLoader(true)

    currentUser?.kids?.map((kid: any, index: number) => {
      if (index === 0) filter += '('
      filter += 'kidId=' + kid.kidId
      if (index !== currentUser?.kids?.length - 1) filter += ' OR '
      else filter += ')'
    })
    if (filter === '') filter = 'kidId=-1'

    if (internalPagesFilters.sleeptimes.searchTerm) filter = filter + ' AND '
    if (internalPagesFilters.sleeptimes.searchTerm)
      filter = filter + internalPagesFilters.sleeptimes.searchTerm

    const res = await getKidsSleeptimes(
      filter,
      currentUser?.contact?.contactId || 0,
      internalPagesDatesFilters.sleeptimes.From
        ? moment(internalPagesDatesFilters.sleeptimes.From).format('yyyy-MM-DDTHH:mm:ss')
        : moment().startOf('day').format('yyyy-MM-DDTHH:mm:ss'),
      internalPagesDatesFilters.sleeptimes.To
        ? moment(internalPagesDatesFilters.sleeptimes.To).format('yyyy-MM-DDTHH:mm:ss')
        : moment().endOf('day').format('yyyy-MM-DDTHH:mm:ss'),
      initalCall ? 20 : -1
    )

    setKidsSleeptimes(res)
    setGlobalLoader(false)
    setinitalCall(false)
  }
  const handleDateChange = (selected: any) => {
    setDate(selected[0])
  }
  const filterData = async () => {
    const newFilters: any = {...internalPagesFilters}

    newFilters.sleeptimes = {
      ...newFilters.sleeptimes,
      searchTerm: `kidName.toLower().contains("${searchTerm.toLowerCase().trim()}")`,
    }

    setInternalPagesFilters(newFilters)
  }
  return {readSleeptimes, data, handleDateChange, clicked, setClicked, searchTerm, setSearchTerm}
}
export default useSleepTimes
