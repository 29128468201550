import {FC} from 'react'

import {DeliveredFilter} from './DeliveredFilter'
import moment from 'moment'
import CustomDateRangePicker from '../../../../general-components/date-range-picker/DateRangePicker'
import useRequests from '../../../../hooks/useRequests'

interface Props {
  title?: string
}
const RequestsHeader: FC<Props> = ({title}) => {
  const {handleDateChange} = useRequests()
  return (
    <div className=' border-0 pt-6 d-flex align-items-center  pe-4 ps-0 pb-8 w-100'>
      {title && <h4 className='m-0 d-flex aling-items-center h-100'>{title}</h4>}
      <div className='d-flex flex-column-reverse flex-sm-row align-items-sm-center justify-content-between w-100'>
        <div className='d-flex  flex-wrap flex-sm-row flex-column w-100  w-md-auto'>
          {/* <RequestsToolbar /> */}
        </div>
        <div className='d-flex align-items-center gap-5 justify-content-end'>
          <DeliveredFilter />
          <CustomDateRangePicker
            handleDateChange={handleDateChange}
            initialDate={{
              startDate: moment().startOf('year').toDate(),
              endDate: moment().endOf('year').toDate(),
            }}
          />
        </div>
      </div>
    </div>
  )
}

export {RequestsHeader}
