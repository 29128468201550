/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect} from 'react'
import {useAppContext} from '../../../_metronic/layout/core/AppContextProvider'
import {getAttendanceLocations} from '../../../controller/location'
import {getAbsentReasons, getActivities} from '../../../controller/kids'
import {getAllMessagesCounts} from '../../../controller/messages'
import {useAuth} from '../../views/auth'
import {getMealTimes} from '../../../controller/meal'
import {groupBy} from '../../utils/common'
import {getMobileConfiguration} from '../../../controller/users'

import {getAllUserChats, getChatCount} from '../../../controller/chat'
import {useChatContext} from '../../../store/context/ChatProvider'
import {getCompaniesByContact} from '../../../controller/company'

function GlobalDataLoader() {
  const {
    setMealtime,
    setAttendanceLocations,
    setAbsentReasons,
    setActivities,
    setTabMessagesCounts,
    setMobileConfiguration,
    setAllHashtags,
    setCompanies,
  } = useAppContext()
  const {setAllChats, setChatCount} = useChatContext()
  const {currentUser, auth} = useAuth()
  useEffect(() => {
    if (currentUser) {
      readAttendanceLocations()
      readAbsentList()
      readAactivities()
      readMoods()
      readRequestItems()

      readAllRooms()
      readSleepHabits()
      readMessageCounts()
      readMealTimes()
      readMobileConfiguration()
      readCompanies()
      getAllChatCount()
    }
  }, [currentUser])

  useEffect(() => {
    if (auth) constructMobileConfiguration(auth?.configurtations)
  }, [auth])

  const readMobileConfiguration = async () => {
    const resp = await getMobileConfiguration()

    if (resp) constructMobileConfiguration(resp)
  }

  const constructMobileConfiguration = (conf: any) => {
    const resp = groupBy(conf, 'mobileConfigurationName')
    if (resp) setMobileConfiguration(resp)
  }

  const readAttendanceLocations = async () => {
    const resp = await getAttendanceLocations()
    if (resp) setAttendanceLocations(resp)
  }
  const readAbsentList = async () => {
    const resp = await getAbsentReasons()
    if (resp) setAbsentReasons(resp)
  }

  const readAactivities = async () => {
    const resp = await getActivities()
    if (resp) setActivities(resp)
  }

  const readMoods = async () => {
    // const resp = await getMoods()
    // if (resp) setMoods(resp)
  }

  const readRequestItems = async () => {
    // const resp = await getRequestItems()
    //if (resp) setRequestItems(resp)
  }

  const readAllRooms = async () => {
    //const resp = await getAllRooms()
    //if (resp) setAllRooms(resp)
  }

  const readSleepHabits = async () => {
    //const resp = await getSleepHabits()
    //if (resp) setSleepHabits(resp)
  }
  const readMessageCounts = async () => {
    if (currentUser) {
      let resp = await getAllMessagesCounts(currentUser?.usersId ?? 0)
      setTabMessagesCounts(resp)
    }
  }
  const readMealTimes = async () => {
    const resp = await getMealTimes()
    if (resp) setMealtime(resp)
  }

  const readAllChats = async () => {
    const resp = await getAllUserChats()
    setAllChats(resp)
  }
  const getAllChatCount = async () => {
    const resp = await getChatCount()

    setChatCount(resp)
  }

  const readCompanies = async () => {
    const resp = await getCompaniesByContact(currentUser?.contact?.contactId || 0)
    const temp: any = []
    resp?.map((item: any) => temp.push({value: item.companyId, label: item.companyName}))
    setCompanies(temp)
  }
  return <></>
}

export default GlobalDataLoader
