/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {TitleHeader} from '../../general-components/TitleHeader'
import {AttachModal} from './attach-modal/AttachModal'
import {useAppContext} from '../../../_metronic/layout/core/AppContextProvider'
import DocumentCard from './Document-Card/DocumentCard'
import {NoRecords} from '../../general-components/loading/NoRecords'
import {UsersListLoading} from '../../general-components/loading/UsersListLoading'
import useDocument from '../../hooks/useDocuments'

const List: React.FC = () => {
  const intl = useIntl()
  const [showAttachModal, setShowAttachModal] = useState(false)
  const {globalLoader, kidMediaFiles, childFilter} = useAppContext()
  const {readKidsFiles, kidIds} = useDocument()

  useEffect(() => {
    if (kidIds.length > 0) {
      readKidsFiles()
    }
  }, [kidIds, childFilter])

  return (
    <>
      <div className='container border-0  d-flex flex-column align-items-start w-100'>
        <TitleHeader
          title={intl.formatMessage({id: 'KIDLING.MENU.DOCUMENTS'})}
          showChildFilter
          showButton={() => setShowAttachModal(!showAttachModal)}
        />
      </div>

      <div className='row g-4 mt-8'>
        {kidMediaFiles &&
          kidMediaFiles?.length > 0 &&
          kidMediaFiles?.map((item: any) => {
            const splittedName = item?.kidMediaFileName?.split('.')
            const type = splittedName[splittedName.length - 1]

            return (
              !item.confidential &&
              !globalLoader && (
                <div className='row g-6 g-xl-9 mb-6 mb-xl-9'>
                  <div className='col-12 col-sm-12 col-xl'>
                    <DocumentCard
                      id={item.kidMediaFileId}
                      title={item.kidMediaFileName}
                      description={item.kidMediaFileDescription}
                      icon={
                        type === 'jpeg' || type === 'png' || type === 'jpg' || type === 'webp'
                          ? '/media/icons/image.png'
                          : type === 'pdf'
                          ? '/media/svg/files/pdf.svg'
                          : type === 'docx'
                          ? '/media/svg/files/doc.svg'
                          : '/media/svg/files/upload.svg'
                      }
                      userName={item.userName}
                      date={item.createdDate}
                      ownerId={item?.usersId}
                      filePath={item.filePath?.split('FilePath=')[1]?.split('&OTP=')[0]}
                      kidMediaFileName={item.kidMediaFileName}
                    />
                  </div>
                </div>
              )
            )
          })}
        {!globalLoader && kidMediaFiles?.length === 0 && <NoRecords />}
        {globalLoader && <UsersListLoading />}
      </div>
      <AttachModal
        showModal={showAttachModal}
        setShowAttachModal={setShowAttachModal}
        readKidsFiles={readKidsFiles}
      />
    </>
  )
}

export {List}
