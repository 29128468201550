import {useIntl} from 'react-intl'
import {KTSVG} from '../../../../_metronic/helpers'
import {FC} from 'react'

type KidProps = {
  setShowDeregisteration: (state: boolean) => void
}
const KidDeRegisterationModalHeader: FC<KidProps> = ({setShowDeregisteration}) => {
  const intl = useIntl()
  return (
    <div className='modal-header'>
      {/* begin::Modal title */}
      <h2 className='fw-bold'> {intl.formatMessage({id: 'KIDLING.HOME.DEREGISTERATION'})}</h2>
      {/* end::Modal title */}

      {/* begin::Close */}
      <div
        className='btn btn-icon btn-sm btn-active-icon-primary'
        data-kt-users-modal-action='close'
        style={{cursor: 'pointer'}}
        onClick={() => setShowDeregisteration(false)}
      >
        <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
      </div>
      {/* end::Close */}
    </div>
  )
}

export {KidDeRegisterationModalHeader}
