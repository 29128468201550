/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useState} from 'react'
import MessageDetailsHeader from './MessageDetailsHeader'
import {Accordion} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import ComposeMessage from './ComposeMessage'
import {Link, useLocation, useParams} from 'react-router-dom'
import moment from 'moment'
import {useAuth} from '../../auth'
import {useAppContext} from '../../../../_metronic/layout/core/AppContextProvider'
import {
  getAllMessagesV2,
  getMessage,
  getSubMessages,
  updateReceivedMessages,
} from '../../../../controller/messages'
import {readFileFrom} from '../../../../controller/image'
import {decodeBase64} from '../../../utils/getBase64'
import {UsersListLoading} from '../../../general-components/loading/UsersListLoading'
import {MESSAGE_FOLDER_ID, MESSAGE_TYPE} from '../../../../config/constants'
import ReciepiantsModal from '../components/sent-table/ReciepiantsModal'

function MessageDetails() {
  const [currentSelected, setCurrentSelected] = useState('0')
  const company = window.location.pathname.split('/')[1]
  const params = useParams()
  const location = useLocation()
  const intl = useIntl()
  const {currentUser} = useAuth()
  const [isLoading, setIsLoading] = useState(false)
  const [reciepiantDialog, setReciepiantDialog] = useState({show: false, data: null})
  const {
    currentUserImage,
    allSubMessages,
    setAllSubMessages,
    getAllMessagesCounts,
    setTabMessagesCounts,
    setInboxData,
  } = useAppContext()
  const [mainMessage, setMainMessage] = useState<any>({})
  const [isReading, setIsReading] = useState('')
  const [currentDownload, setCurrentDownload] = useState<any>({})
  const [unAuthorized, setUnAuthorized] = useState(false)

  useEffect(() => {
    if (params?.id && !isLoading) {
      setAllSubMessages([])
      readMessages()
    }
  }, [])

  const readMessages = async () => {
    try {
      setIsLoading(true)
      const message = await getMessage(params.id ?? '-1')
      setMainMessage(message[0])

      if (message) {
        const allowed = message[0]?.recipients.find((i: any) => i?.usersId === currentUser?.usersId)

        if (!allowed && message[0]?.usersId !== currentUser?.usersId) setUnAuthorized(true)
        else {
          setUnAuthorized(false)
          const subMessages = await getSubMessages({
            userId: currentUser?.usersId,
            parentId: params.id,
          })
          if (subMessages) {
            setCurrentSelected(subMessages.length.toString())
            setAllSubMessages([
              ...message,
              ...subMessages?.sort((a: any, b: any) => moment(a?.dateTime).diff(b?.dateTime)),
            ])
          } else {
            setAllSubMessages(message)
          }
        }
      }
    } catch (e) {
      console.log(e)
      setIsLoading(false)
    } finally {
      setIsLoading(false)
    }
  }

  const openFile = async (id: string) => {
    try {
      setIsReading(id)

      const resp = await readFileFrom(id)

      const type = resp.split(';')[0].split(':')[1]

      const name = id.split('/')[2].split('___')[0]

      const blob = await decodeBase64({base64: resp, name: name, type: type})

      const url = URL.createObjectURL(blob)
      window.open(url)
      setIsReading('')
      let temp = {...currentDownload}

      temp[id] = url
      setCurrentDownload(temp)

      if ((window as any)['ReactNativeWebView'])
        (window as any)['ReactNativeWebView'].postMessage(
          JSON.stringify({file: {id: id, base64: resp}})
        )
    } catch (e) {
      setIsReading('')
      console.log(e)
    }
  }

  useEffect(() => {
    if (allSubMessages) markRead()
  }, [allSubMessages])

  const markRead = async () => {
    const ids: any = []
    allSubMessages?.forEach((item: any) => {
      ids.push(item.notificationUsersId)
    })
    const data = {
      ids: ids,
      isRead: true,
    }

    await updateReceivedMessages(data)
    const resp = await getAllMessagesV2(
      currentUser?.usersId ?? 0,
      '1=1',
      1,
      15,
      MESSAGE_FOLDER_ID.Received,
      MESSAGE_TYPE.Message
    )

    setInboxData(resp)
    const count = await getAllMessagesCounts(currentUser?.usersId || 0)
    if (count) setTabMessagesCounts(count)
  }
  const markUnread = async () => {
    const ids: any = []
    allSubMessages?.forEach((item: any) => {
      ids.push(item.notificationUsersId)
    })
    const data = {
      ids: ids,
      isRead: false,
      readDateTime: null,
    }

    await updateReceivedMessages(data)
    const resp = await getAllMessagesV2(
      currentUser?.usersId ?? 0,
      '1=1',
      1,
      15,
      MESSAGE_FOLDER_ID.Received,
      MESSAGE_TYPE.Message
    )

    setInboxData(resp)
    const count = await getAllMessagesCounts(currentUser?.usersId || 0)
    if (count) setTabMessagesCounts(count)
  }
  if (unAuthorized)
    return (
      <div className='d-flex flex-column justify-content-center align-items-center p-16'>
        <h1 className='text-danger'> {intl.formatMessage({id: 'KIDLING.UNAUTHORIZED_ACCESS'})}</h1>
        <Link
          to={company ? `/${company}/messages/inbox` : `/kidling/messages/inbox`}
          className='btn btn-primary mt-8'
        >
          <i className='fa-solid fa-arrow-left fs-3 '></i>
        </Link>
      </div>
    )
  return isLoading ? (
    <UsersListLoading />
  ) : (
    <>
      <MessageDetailsHeader markUnread={markUnread} id={mainMessage?.notificationUsersId} />
      <div className='card-body p-4 p-sm-8'>
        <div className='d-flex flex-wrap gap-2 justify-content-between mb-8'>
          <div className='d-flex align-items-center flex-wrap gap-2'>
            <h2 className='fw-semibold me-3 my-1'>{allSubMessages[0]?.notificationTitle}</h2>
            {/* <span className='badge badge-light-primary my-1 me-2'>
              {intl.formatMessage({id: 'KIDLING.HOME.INBOX'})}
            </span> */}
            {allSubMessages[0]?.highPriority && (
              <span className='badge badge-light-danger my-1'>
                {intl.formatMessage({id: 'KIDLING.HOME.IMPORTANT'})}
              </span>
            )}
          </div>
        </div>
        <Accordion defaultActiveKey={currentSelected} className='w-100'>
          {allSubMessages &&
            allSubMessages?.map((item: any, index: number) => (
              <>
                <Accordion.Item eventKey={`${index}`}>
                  <Accordion.Header onClick={() => setCurrentSelected(`${index}`)}>
                    <div
                      className='d-flex flex-wrap gap-2 flex-stack cursor-pointer w-100'
                      data-kt-inbox-message='header'
                    >
                      <div className='d-flex align-items-center'>
                        <div className='symbol symbol-50 me-4'>
                          <span
                            className='symbol-label'
                            style={{
                              backgroundImage: `url(${
                                item?.usersId === currentUser?.usersId
                                  ? currentUserImage
                                  : item.imagePath
                                  ? item.imagePath
                                  : '/media/avatars/blank.png'
                              })`,
                            }}
                          ></span>
                        </div>
                        <div className='pe-5'>
                          <div className='d-flex align-items-center flex-wrap gap-1'>
                            <div>
                              <a href='#' className='fw-bold text-dark text-hover-primary'>
                                {item?.usersId === currentUser?.usersId
                                  ? location.pathname.split('/')[3] === 'sent'
                                    ? intl.formatMessage({id: 'COMMON.SENT_ME'})
                                    : intl.formatMessage({id: 'COMMON.ME'})
                                  : item.usersName}
                              </a>
                              <div className='d-flex align-items-center flex-wrap'>
                                <p className='mb-0'>{intl.formatMessage({id: 'COMMON.TO'})} </p>

                                {item?.usersId === currentUser?.usersId ? (
                                  <div className='d-flex flex-wrap align-items-center  '>
                                    {item?.recipients.length === 0 ? (
                                      <p>--</p>
                                    ) : (
                                      <button
                                        className='btn d-flex align-items-center text-gray-400 text-hover-primary  p-0 m-0 ms-2'
                                        onClick={() =>
                                          item?.recipients.length > 1 &&
                                          setReciepiantDialog({show: true, data: item?.recipients})
                                        }
                                      >
                                        <span className='badge badge-info  d-flex align-items-center m-1  '>
                                          <p className='ms-1 mb-0'>
                                            {item?.recipients?.lenght > 0 &&
                                            item?.recipients[0]?.usersId === currentUser?.usersId
                                              ? intl.formatMessage({id: 'COMMON.ME'})
                                              : item?.recipients[0].usersName}
                                          </p>
                                          {item?.recipients.length > 1 && (
                                            <i className='fa-solid fa-caret-down ms-2'></i>
                                          )}
                                        </span>
                                      </button>
                                    )}
                                  </div>
                                ) : (
                                  <span className='badge badge-info  d-flex align-items-center m-1  '>
                                    <p className='ms-1 mb-0'>
                                      {intl.formatMessage({id: 'COMMON.ME'})}
                                    </p>
                                  </span>
                                )}
                              </div>
                            </div>
                            {item.readDateTime && item?.usersId !== currentUser?.usersId && (
                              <i className='fa-solid fa-spinner fs-7 text-success mx-3'></i>
                            )}
                          </div>

                          <div
                            className='text-muted fw-semibold mw-450px'
                            data-kt-inbox-message='preview'
                            dangerouslySetInnerHTML={{__html: item.notificationTitle}}
                          ></div>
                        </div>
                      </div>

                      <div className='d-flex align-items-center flex-wrap gap-2'>
                        <span className='fw-semibold text-muted text-end me-3'>
                          {moment(item.dateTime).format('DD.MM.yyyy HH:mm')}
                        </span>
                      </div>
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className='collapse fade show' data-kt-inbox-message='message'>
                      <div className='py-5' dangerouslySetInnerHTML={{__html: item.message}}></div>
                      <div className='p-5'>
                        {item?.attachments?.map(
                          (item: any) =>
                            item.split('/')[2] && (
                              <div className='d-flex align-items-center '>
                                <div
                                  className='d-flex align-items-center btn btn-link'
                                  onClick={() => openFile(item)}
                                >
                                  <i className={`fa-solid fa-paperclip fs-5 m-0  `}></i>
                                  <p className='px-2  mb-0 fs-5'>
                                    {' '}
                                    {item.split('/')[2].split('___')[0]}
                                  </p>
                                </div>

                                {isReading === item && (
                                  <span className='fs-5 text-warning '>
                                    {intl.formatMessage({id: 'KIDLING.HOME.PLEASE_WAIT'})}...{' '}
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                  </span>
                                )}

                                {currentDownload[item] && (
                                  <a
                                    className='btn btn-link text-warning fs-5'
                                    target='_blank'
                                    href={currentDownload[item]}
                                    rel='noreferrer'
                                  >
                                    {intl.formatMessage({id: 'KIDLING.HOME.DOWNLOAD'})}
                                  </a>
                                )}
                              </div>
                            )
                        )}
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </>
            ))}
        </Accordion>
        <ReciepiantsModal modal={reciepiantDialog} setModal={setReciepiantDialog} />
        {location.pathname.split('/')[3] === 'inbox' && allSubMessages?.length > 0 && (
          <ComposeMessage
            parentId={params?.id}
            senderId={
              allSubMessages &&
              (allSubMessages[0]?.usersId !== currentUser?.usersId
                ? allSubMessages[0]?.usersId
                : allSubMessages[1]?.usersId)
            }
            companyId={
              allSubMessages?.length > 0 && allSubMessages[0]?.usersId === currentUser?.usersId
                ? allSubMessages[1]?.companyId
                : allSubMessages[0]?.companyId
            }
          />
        )}
      </div>{' '}
    </>
  )
}

export default MessageDetails
