import React, {FC, ReactElement, useState} from 'react'
import './styles.scss'
import useLongPress from '../../hooks/useLongPress'
import {useAppContext} from '../../../_metronic/layout/core/AppContextProvider'
import {useMediaQuery} from 'react-responsive'
import CustomImage from '../custom-image/CustomImage'

interface ImageProps {
  img: string
  desc?: string
  KidMediaFileId?: number
  activateLongPress?: boolean
  hideCheckbox?: boolean
  currentIndex: number
  index: number
}
const SliderImageWithEnlarge: FC<ImageProps> = ({
  img,
  desc,
  KidMediaFileId,
  activateLongPress,
  hideCheckbox,
  currentIndex,
  index,
}): ReactElement => {
  const isMobile = useMediaQuery({
    query: '(max-width:1000px)',
  })
  const {selectedImages, setSelectedImages} = useAppContext()

  const handleLongPress = () => {
    setSelectedImages((prev: any) => [
      ...prev,
      {id: KidMediaFileId, filePath: img?.split('FilePath=')[1]?.split('&OTP=')[0]},
    ])
  }

  const {action, handlers} = useLongPress({
    initialClick: () => {},
    handleLongPress: activateLongPress ? handleLongPress : () => {},
  })
  const handleUncheck = () => {
    let selected: any[] = []
    const exists = selectedImages.find((item: any) => item.id === KidMediaFileId)
    if (exists)
      selectedImages?.map((item: any) => {
        if (item.id !== KidMediaFileId) selected.push({id: item.id, filePath: img})
      })
    else {
      selected = [...selectedImages, {id: KidMediaFileId, filePath: img}]
    }
    setSelectedImages(selected)
  }

  return (
    <div className='w-100  h-100' {...handlers}>
      <div className={` image__container  w-100  h-100 position-relative rounded  `}>
        <CustomImage
          id={img}
          className={`${'image-gallery-image'}`}
          index={index}
          currentIndex={currentIndex}
        />
        {/* <img src={img} alt={desc} className={`${'image-gallery-image'}`} /> */}
        {isMobile && !hideCheckbox && !selectedImages?.some((i: any) => i.id === KidMediaFileId) && (
          <div className='form-check form-check-sm form-check-custom form-check-solid position-absolute image_checkbox_mobile rounded'>
            <input
              className='form-check-input  '
              type='checkbox'
              checked={selectedImages?.some((i: any) => i.id === KidMediaFileId)}
              defaultChecked={true}
              name='notifications'
              onClick={handleUncheck}
            />
          </div>
        )}
        {!hideCheckbox && selectedImages?.some((i: any) => i.id === KidMediaFileId) && (
          <div>
            <div className='checkbox__container rounded' onClick={handleUncheck}></div>
            <div className='form-check form-check-lg form-check-custom form-check-solid position-absolute image_checkbox'>
              <input
                className='form-check-input  rounded-circle'
                type='checkbox'
                checked={selectedImages?.some((i: any) => i.id === KidMediaFileId)}
                defaultChecked={true}
                name='notifications'
                onClick={handleUncheck}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default SliderImageWithEnlarge
