/* eslint-disable react-hooks/exhaustive-deps */
import {FC, ReactElement, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {useNavigate} from 'react-router-dom'
import {useAppContext} from '../../../../_metronic/layout/core/AppContextProvider'
import SearchBox from '../../../general-components/search-textbox/SearchBox'
import PrivateChats from './PrivateChats'
import DropDownMenu from '../../../general-components/dropDownMenu/DropDownMenu'
import {Avatar, IconButton} from '@mui/material'
import {useAuth} from '../../auth'
import NewChatDialog from './NewChatDialog'
import {getAllUserChats} from '../../../../controller/chat'
import {useChatContext} from '../../../../store/context/ChatProvider'
import {UsersListLoading} from '../../../general-components/loading/UsersListLoading'

const ChatSidebar: FC = (): ReactElement => {
  const {currentUserImage} = useAppContext()
  const {allChats, setAllChats} = useChatContext()
  const [currentChats, setCurrentChats] = useState([])
  const [showNewChatDialog, setShowNewChatDialog] = useState(false)
  const {currentUser, logout} = useAuth()
  const navigate = useNavigate()
  const company = window.location.pathname.split('/')[1]
  const intl = useIntl()
  const [search, setSearch] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const gotoProfilePage = () => {
    navigate(company ? `/${company}/profile` : '/kidling/profile')
  }

  const userDropDownMenu = {
    title: intl.formatMessage({id: 'KIDLING.CHAT_ACCOUNT_SETTINGS'}),
    menuButton: (
      <Avatar sx={{width: 32, height: 32}} src={currentUserImage}>
        {currentUser?.usersName?.charAt(0).toUpperCase() ?? ''}
      </Avatar>
    ),
    items: [
      {
        title: intl.formatMessage({id: 'KIDLING.HOME.MY_PROFILE'}),
        icon: (
          <Avatar sx={{width: 32, height: 32}} src={currentUserImage}>
            {currentUser?.usersName?.charAt(0).toUpperCase() ?? ''}
          </Avatar>
        ),
        onClick: gotoProfilePage,
      },
      {
        title: intl.formatMessage({id: 'KIDLING.HOME.SIGN_OUT'}),
        icon: <i className='fa-solid fa-arrow-right-from-bracket'></i>,
        onClick: logout,
      },
    ],
  }

  useEffect(() => {
    readAllChats()
  }, [])
  useEffect(() => {
    let temp: any = []

    if (search && search !== '') {
      allChats?.forEach((element: any) => {
        if (
          element?.chatGroupName.includes(search) ||
          element.users.find((e: any) => e.usersName.toLowerCase().includes(search.toLowerCase()))
        )
          temp.push(element)
      })
      setCurrentChats(temp)
    } else {
      setCurrentChats(allChats)
    }
  }, [search, allChats])

  const readAllChats = async () => {
    setIsLoading(true)
    const resp = await getAllUserChats()
    setAllChats(resp)

    setIsLoading(false)
  }

  return (
    <div className='d-flex flex-column flex-lg-row-auto w-100 mw-100 mb-10 mb-lg-0 border border-right-1 h-100'>
      <div className='  d-flex flex-column align-items-between overflow-hidden h-100'>
        <div className=' p-4  mh-100 ' id='kt_chat_contacts_header '>
          <div className='d-flex align-items-center justify-content-between mb-8'>
            <DropDownMenu data={userDropDownMenu} />
            <IconButton onClick={() => setShowNewChatDialog(!showNewChatDialog)}>
              <i className='fa fa-plus-circle fs-1 text-primary' />
            </IconButton>
          </div>
          <form className='w-100 position-relative' autoComplete='off'>
            <SearchBox value={search} onChange={setSearch} />
          </form>
        </div>

        <div className='group__tabs__container  overflow-y-auto'>
          <PrivateChats chats={currentChats} />
        </div>
      </div>
      {isLoading && <UsersListLoading />}
      <NewChatDialog setShowModal={setShowNewChatDialog} showModal={showNewChatDialog} />
    </div>
  )
}

export default ChatSidebar
