import axios from 'axios'
import {
  ABSENT_REASONS,
  ACTIVITES,
  CLASSES_WITH_EXPRESSION,
  UPDATEKIDIMG,
  MEAL_DEREGISTRATION,
  REGISTER_KID,
  KIDMEDIA_GALLARY,
  UPDATE_KID_INFO,
  DELETE_KIDMEDIA,
} from '../config/endpoints'

export const getClasses = async () => {
  return axios
    .get(CLASSES_WITH_EXPRESSION)
    .then((response) => response.data)
    .catch((error) => {
      console.log(error)
    })
}

export const getAbsentReasons = async () => {
  return axios
    .get(ABSENT_REASONS)
    .then((response) => response.data)
    .catch((error) => {
      console.log(error)
    })
}

export const getActivities = async () => {
  return axios
    .get(ACTIVITES)
    .then((response) => response.data)
    .catch((error) => {
      console.log(error)
    })
}

export const addNewActivity = async (data: any) => {
  return axios
    .post(ACTIVITES, data)
    .then((response) => response.data)
    .catch((error) => {
      console.log(error)
    })
}

export const updateKidInfo = async (data: any) => {
  return axios
    .put(UPDATE_KID_INFO, data)
    .then((response) => response.data)
    .catch((error) => {
      console.log(error)
    })
}

export const updateKidImage = async (data: any) => {
  return axios
    .put(UPDATEKIDIMG, data)
    .then((response) => response.data)
    .catch((error) => {
      console.log(error)
    })
}

export const deleteKidMediaFile = async (id: string) => {
  return axios
    .delete(`${DELETE_KIDMEDIA}/${id}`)
    .then((response) => response)
    .catch((error) => {
      console.log(error)
    })
}

export const getAlldeRegisterMeal = async (filter: string, From: string, To: string) => {
  return axios
    .get(`${MEAL_DEREGISTRATION}/${filter}`, {
      params: {From: From && To ? From : null, To: From && To ? To : null},
    })
    .then((response) => response.data.data)
    .catch((error) => {
      console.log(error)
    })
}

export const registerNewKid = async (data: any) => {
  return axios
    .post(`${REGISTER_KID}`, data)
    .then((response) => response.data.data)
    .catch((error) => {
      console.log(error)
    })
}

export const getMobileGallary = async (
  filter: string,
  isImages?: boolean,
  isDocument?: boolean
) => {
  return axios
    .get(
      `${KIDMEDIA_GALLARY}/listwhere/${filter}?isMedia=${isImages}&isDocument=${
        isDocument ?? false
      }`
    )
    .then((response) => response.data)
    .catch((error) => {
      console.log(error)
    })
}
