import moment from 'moment'
import {getKidsDrinks} from '../../controller/drinks'
import {useEffect, useState} from 'react'
import {useAuth} from '../views/auth'
import {useAppContext} from '../../_metronic/layout/core/AppContextProvider'

const useDrinks = () => {
  const {
    kidsDrinks,
    setKidsDrinks,
    setGlobalLoader,
    internalPagesFilters,
    internalPagesDatesFilters,
    setInternalPagesDatesFilters,
    setInternalPagesFilters,
  } = useAppContext()
  const [clicked, setClicked] = useState(false)
  const [data, setData] = useState([])
  const {currentUser} = useAuth()
  const [initalCall, setinitalCall] = useState(true)
  const [date, setDate] = useState<any>({})
  const [searchTerm, setSearchTerm] = useState('')
  const filterData = async () => {
    const newFilters: any = {...internalPagesFilters}

    newFilters.drinks = {
      ...newFilters.drinks,
      searchTerm: `kidName.toLower().contains("${searchTerm.toLowerCase().trim()}")`,
    }

    setInternalPagesFilters(newFilters)
  }

  useEffect(() => {
    filterData()
  }, [searchTerm])

  useEffect(() => {
    if (clicked) {
      readDrinks()
      setClicked(false)
    }
  }, [clicked])
  useEffect(() => {
    const newFilters: any = {...internalPagesDatesFilters}

    newFilters.drinks = {
      ...newFilters.drinks,
      From: date?.startDate
        ? moment(date?.startDate).startOf('day').format('yyyy-MM-DDTHH:mm:ss')
        : moment().startOf('day').format('yyyy-MM-DDTHH:mm:ss'),
      To: date?.endDate
        ? moment(date?.endDate).endOf('day').format('yyyy-MM-DDTHH:mm:ss')
        : moment().endOf('day').format('yyyy-MM-DDTHH:mm:ss'),
    }

    setInternalPagesDatesFilters(newFilters)
  }, [date])
  useEffect(() => {
    let temp: any = []
    kidsDrinks?.map((item: any) => {
      let Drinks: any = []
      item.kidDrinks?.map((drink: any) =>
        Drinks.push({
          id: drink.kidDrinkId,
          title: drink.kidDrinkName,
          date: drink.dateTime,
          tag: drink.kidDrinkMl,
        })
      )
      temp.push({
        image: item.imagePath,
        name: `${item.firstName} ${item.lastName}`,
        subItems: Drinks,
      })
    })
    setData(temp)
  }, [kidsDrinks])

  const readDrinks = async () => {
    let filter: string = ''
    setGlobalLoader(true)

    currentUser?.kids?.map((kid: any, index: number) => {
      if (index === 0) filter += '('
      filter += 'kidId=' + kid.kidId
      if (index !== currentUser?.kids?.length - 1) filter += ' OR '
      else filter += ')'
    })
    if (filter === '') filter = 'kidId=-1'
    if (internalPagesFilters.drinks.searchTerm) filter = filter + ' AND '
    if (internalPagesFilters.drinks.searchTerm)
      filter = filter + internalPagesFilters.drinks.searchTerm

    const res = await getKidsDrinks(
      filter,
      currentUser?.contact?.contactId || 0,
      internalPagesDatesFilters.drinks.From
        ? moment(internalPagesDatesFilters.drinks.From).format('yyyy-MM-DDTHH:mm:ss')
        : moment().startOf('day').format('yyyy-MM-DDTHH:mm:ss'),
      internalPagesDatesFilters.drinks.To
        ? moment(internalPagesDatesFilters.drinks.To).format('yyyy-MM-DDTHH:mm:ss')
        : moment().endOf('day').format('yyyy-MM-DDTHH:mm:ss'),
      initalCall ? 20 : -1
    )
    setKidsDrinks(res)
    setGlobalLoader(false)
    setinitalCall(false)
  }
  const handleDateChange = (selected: any) => {
    setDate(selected[0])
  }
  return {readDrinks, data, handleDateChange, clicked, setClicked, searchTerm, setSearchTerm}
}

export default useDrinks
