/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect} from 'react'
import {ContactModel} from '../../../model/_models'
import {getDashboard} from '../../../controller/parent'
import {useAppContext} from '../../../_metronic/layout/core/AppContextProvider'
import ParentKidCard from '../../general-components/parent-kid-card/ParentKidCard'
import {CardLoader} from '../../general-components/card-loader/CardLoader'
import {useIntl} from 'react-intl'
import moment from 'moment'
import './styles.scss'
import StoreDownload from '../../general-components/Store-download/StoreDownload'
import {useAuth} from '../auth'
type Props = {
  contact?: ContactModel
}

const colors = ['danger', 'success', 'info', 'warning', 'secondary', 'light', 'primary']
const DashboardHeader: React.FC<Props> = ({contact}) => {
  const {globalLoader, setGlobalLoader, setAccounts, accounts, pinboard} = useAppContext()
  const {currentUser} = useAuth()
  const intl = useIntl()

  useEffect(() => {
    async function load() {
      setGlobalLoader(true)
      const contactId = contact?.contactId
      const data = await getDashboard('?contactId=' + contactId)

      if (data.kids) {
        setAccounts(data?.kids)
      }
      setGlobalLoader(false)
    }
    load()
  }, [])

  return (
    <>
      {currentUser?.username !== 'apptest@kidling.email' && (
        <div className='mb-2'>
          <StoreDownload />
        </div>
      )}
      <div
        className=' rounded py-4 py-lg-16 d-flex flex-column align-items-center w-100 bg-body'
        style={{minHeight: '20rem'}}
      >
        {pinboard?.length > 0 && (
          <div className='d-flex flex-column w-100 p-4 p-sm-8 '>
            {pinboard?.map((item: any, index: number) => (
              <div
                className={`alert alert-${colors[index % colors.length]}`}
                role='alert'
                key={index}
              >
                <div className='d-flex'>
                  <i
                    className={`fa-solid fa-thumbtack me-2 fs-2 text-${
                      colors[index % colors.length]
                    }`}
                  ></i>
                  <div className='d-flex flex-column'>
                    <h5 className='my-0 py-0'>{item.pinboardName}</h5>
                    <p className='my-0 py-0 text-body fs-7'>
                      {moment(item.pinboardDatetime).format('DD.MM.yyyy')}
                    </p>
                  </div>
                </div>

                <p
                  className='my-0 mt-1 py-0 text-body fs-5'
                  dangerouslySetInnerHTML={{__html: item.pinboardMessage}}
                ></p>
              </div>
            ))}
          </div>
        )}
        {globalLoader || !accounts ? (
          <CardLoader blockNumber={3} />
        ) : (
          <div className='d-flex flex-column align-items-center w-100'>
            <div className='row  justify-content-center w-100  '>
              {accounts?.map((kid: any, index: number) => (
                <div className='col-lg-4 col-sm-6 col-12 gy-8' key={index}>
                  <ParentKidCard kid={kid} />
                </div>
              ))}
            </div>{' '}
          </div>
        )}
        {!globalLoader && accounts.length === 0 && (
          <div className='card mb-5 mb-xl-10' style={{minHeight: 400}}>
            <div className='card-body pt-9 pb-0'>
              <div className=' mt-8 w-100 d-flex flex-column align-items-center justify-content-center'>
                <i className='fa-solid fa-comments' style={{fontSize: '5rem'}}></i>
                <p className='mt-4 text-muted fs-3'>
                  {intl.formatMessage({id: 'KIDLING.COMMON.WELCOME'})} {contact?.contactName}
                  <br />
                  {intl.formatMessage({id: 'KIDLING.CONTACT.ZEROKIDS'})}
                  <br />
                  {intl.formatMessage({id: 'KIDLING.CONTACT.TALKTODAYCARE'})}
                  <br />
                  {intl.formatMessage({id: 'KIDLING.COMMON.THANKYOU'})}
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export {DashboardHeader}
