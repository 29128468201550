import {FC, useState, createContext, useContext, useMemo} from 'react'
import {
  calculatedGroupingIsDisabled,
  calculateIsAllDataSelected,
  groupingOnSelect,
  groupingOnSelectAll,
} from './crud-helper/helpers'
import {ListViewContextProps, initialListView} from './crud-helper/models'
import {WithChildren} from '../../../../_metronic/helpers'
import {useAppContext} from '../../../../_metronic/layout/core/AppContextProvider'

const ListViewContext = createContext<ListViewContextProps>(initialListView)

const ListViewProvider: FC<WithChildren> = ({children}) => {
  const [selected, setSelected] = useState<Array<any>>(initialListView.selected)
  const [itemIdForUpdate, setItemIdForUpdate] = useState<any>(initialListView.itemIdForUpdate)
  const [data, setData] = useState([])
  const {globalLoader} = useAppContext()
  const disabled = useMemo(
    () => calculatedGroupingIsDisabled(globalLoader, data),
    [globalLoader, data]
  )
  const isAllSelected = useMemo(() => calculateIsAllDataSelected(data, selected), [data, selected])

  return (
    <ListViewContext.Provider
      value={{
        selected,
        itemIdForUpdate,
        setItemIdForUpdate,
        disabled,
        isAllSelected,
        onSelect: (id: any, isSent?: boolean) => {
          groupingOnSelect(id, selected, setSelected)
        },
        onSelectAll: (data: any, isMarkedTable?: boolean) => {
          setData(data)

          groupingOnSelectAll(isAllSelected, setSelected, data, isMarkedTable)
        },
        clearSelected: () => {
          setSelected([])
        },
      }}
    >
      {children}
    </ListViewContext.Provider>
  )
}

const useListView = () => useContext(ListViewContext)

export {ListViewProvider, useListView}
