import DateEditableCell from '../../../../../general-components/date-editable/DateEditableCell'
import moment from 'moment'
import {UserCustomHeader} from '../../../../../general-components/table-custom-header/UserCustomHeader'

function _columns({handleDeleteAbsense, editCell, contactId, contactName}: any) {
  return [
    {
      Header: (props: any) => <UserCustomHeader tableProps={props} title='KIDLING.HOME.FROM' />,
      accessor: 'fromDate',
      id: 'in',
      sortable: true,

      Cell: ({cell}: any) => {
        const fromDate = cell.row.original.fromDate
          ? moment(cell.row.original.fromDate).toDate()
          : ''

        const handleStartTimeChange = (value: any) => {
          const attendance = cell.row.original
          editCell(
            {
              ...attendance,
              fromDate: attendance.fromDate,
              toDate: attendance.toDate,
            },
            'fromDate',
            value
          )
        }
        if (fromDate !== '' && new Date(fromDate) >= new Date()) {
          return <DateEditableCell value={fromDate} onChange={handleStartTimeChange} />
        } else {
          return (
            <div>
              <i className='fa-solid fa-calendar-days me-2 '></i>
              {moment(fromDate).format('DD.MM.yyyy ')}
            </div>
          )
        }
      },
    },
    {
      Header: (props: any) => <UserCustomHeader tableProps={props} title='KIDLING.HOME.TO' />,

      id: 'out',
      sortable: true,
      accessor: 'toDate',
      Cell: ({cell}: any) => {
        const toDate = cell.row.original.fromDate ? moment(cell.row.original.toDate).toDate() : ''
        const handleStartTimeChange = (value: any) => {
          const attendance = cell.row.original
          editCell(
            {
              ...attendance,
              fromDate: attendance.fromDate,
              toDate: attendance.toDate,
            },
            'toDate',
            value
          )
        }
        if (toDate !== '' && new Date(toDate) >= new Date()) {
          return <DateEditableCell value={toDate} onChange={handleStartTimeChange} />
        } else {
          return (
            <div>
              <i className='fa-solid fa-calendar-days me-2 '></i>
              {moment(toDate).format('DD.MM.yyyy ')}
            </div>
          )
        }
      },
    },
    {
      Header: (props: any) => (
        <UserCustomHeader tableProps={props} title='KIDLING.HOME.LASTUPDATE' />
      ),

      id: 'lastupdate',
      width: 50,

      Cell: ({cell}: any) => {
        return (
          <div>
            <i className='fa-solid fa-clock me-2 text-primary'></i>
            {moment(cell.row.original.updatedDateTime).format('DD.MM.yyyy HH:mm')}
          </div>
        )
      },
    },
    // {
    //   Header: (props: any) => (
    //     <UserCustomHeader
    //       tableProps={props}
    //       title='KIDLING.HOME.REPORTER'
    //
    //     />
    //   ),

    //   id: 'reporter',
    //   width: 50,

    //   Cell: ({cell}: any) => {
    //     return (
    //       <div>
    //         <i className='fa-solid fa-user text-primary'></i>{' '}
    //         {cell.row.original?.contactId
    //           ? cell.row.original.contactName
    //           : cell.row.original.employeeName}
    //       </div>
    //     )
    //   },
    // },
    {
      Header: (props: any) => <UserCustomHeader tableProps={props} title='KIDLING.REASON' />,
      id: 'reason',
      width: 50,
      Cell: ({cell}: any) => {
        return (
          <div>
            {cell.row.original?.kidAbsentReasonName && (
              <>
                <i className='fa-solid fa-note-sticky text-primary'></i>{' '}
                {cell.row.original?.kidAbsentReasonName}
              </>
            )}
          </div>
        )
      },
    },
    {
      Header: (props: any) => <UserCustomHeader tableProps={props} title='KIDLING.PARENT.REMARK' />,
      id: 'remark',
      width: 50,
      Cell: ({cell}: any) => {
        return (
          <div>
            {cell.row.original.contactRemarks && (
              <>
                <i className='fa-solid fa-note-sticky text-primary'></i>{' '}
                {cell.row.original.contactRemarks}
              </>
            )}
          </div>
        )
      },
    },

    // {
    //   Header: (props: any) => (
    //     <UserCustomHeader tableProps={props} title='MENU.ACTIONS' className='  ' />
    //   ),
    //   id: 'Attributes',
    //   Cell: ({cell}: any) => {
    //     const fromDate = cell.row.original.fromDate
    //       ? moment(cell.row.original.fromDate).toDate()
    //       : ''

    //     if (fromDate != '' && new Date(fromDate) >= new Date()) {
    //       return (
    //         <div className='d-flex   align-items-center'>
    //           <OverlayTrigger
    //             placement='left'
    //             delay={{show: 250, hide: 400}}
    //             overlay={(props: any) => (
    //               <Tooltip style={{border: 0}} id='button-tooltip' {...props}>
    //                 Delete
    //               </Tooltip>
    //             )}
    //           >
    //             <button
    //               className='btn text-muted p-0 me-4'
    //               type='button'
    //               onClick={() => handleDeleteAbsense(cell.row.original.kidAbsentId)}
    //             >
    //               <i className='fa-solid fa-trash text-danger   fs-3'></i>
    //             </button>
    //           </OverlayTrigger>
    //         </div>
    //       )
    //     }
    //   },
    // },
  ]
}

export default _columns
