import {FC, useMemo} from 'react'
import {useListView} from '../../../core/ListViewProvider'

type Props = {
  id: any
}

const MarkedSelectionCell: FC<Props> = ({id}) => {
  const {selected, onSelect} = useListView()
  const isSelected = useMemo(
    () =>
      selected.find(
        (i) =>
          i === id ||
          (id?.notificationId &&
            i?.notificationUsersId === id?.notificationUsersId &&
            i?.notificationId === id?.notificationId)
      ),
    [id, selected]
  )

  return (
    <div className='form-check form-check-sm form-check-custom form-check-solid'>
      <input
        className='form-check-input'
        type='checkbox'
        data-kt-check={isSelected}
        data-kt-check-target='#kt_table_users .form-check-input'
        checked={isSelected}
        onChange={() => onSelect(id)}
      />
    </div>
  )
}

export {MarkedSelectionCell}
