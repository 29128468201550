/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {useIntl} from 'react-intl'
import {updateContactInfo} from '../../../../../../controller/users'
import {useAuth} from '../../../../../views/auth'
import {toast} from 'react-toastify'
import {useAppContext} from '../../../../../../_metronic/layout/core/AppContextProvider'

const ProfileAddress: React.FC = () => {
  const intl = useIntl()
  const {currentUser, setCurrentUser} = useAuth()
  const {states, cities, countries} = useAppContext()
  const account = currentUser?.contact
  const [selectedCountry, setSelectedCountry] = useState<any>(null)
  const [selectedCity, setSelectedCity] = useState<any>(null)
  const [selectedState, setSelectedState] = useState<any>(null)
  const [initialValues] = useState<any>({
    remarks: account?.remarks,
    streetName: account?.streetName,
    houseNumber: account?.houseNumber,
    postalCode: account?.postalCode,
  })

  useEffect(() => {
    if (account) {
      if (account?.nationalityId) {
        setSelectedCountry(
          countries.find((item: any) => item.countryId === account?.nationalityId)?.countryId
        )
      }
      if (account.cityId) {
        setSelectedCity(cities.find((item: any) => item.cityId === account?.cityId)?.cityId)
      }
      if (account.stateId) {
        setSelectedState(states.find((item: any) => item.stateId === account?.stateId)?.stateId)
      }
    }
  }, [account, countries, cities, states])

  const addressSchema = Yup.object().shape({
    streetName: Yup.string().nullable(),
    houseNumber: Yup.string().nullable(),
    postalCode: Yup.string().nullable(),
    remarks: Yup.string().nullable(),
  })

  const formik: any = useFormik({
    initialValues,
    validationSchema: addressSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      try {
        const data = {
          ...account,
          nationalityId: selectedCountry,
          streetName: values?.streetName,
          houseNumber: values?.houseNumber,
          postalCode: values?.postalCode,
          stateId: selectedState,
          cityId: selectedCity,
          remarks: values?.remarks,
        }

        const resp = await updateContactInfo(data)
        if (resp) {
          toast.success(intl.formatMessage({id: 'COMMON.SUCCESS'}))
          let temp = currentUser
          if (temp) {
            temp.contact = resp
            setCurrentUser(temp)
          }
        } else {
          toast.error(intl.formatMessage({id: 'COMMON.FAIL'}))
        }
      } catch (ex) {
        console.log(ex)
      } finally {
        setSubmitting(false)
      }
    },
  })

  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#kt_account_profile_details'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bold m-0'>Profile Address</h3>
        </div>
      </div>

      <div id='kt_account_profile_details' className='collapse show'>
        <form className='form  ' onSubmit={formik.handleSubmit} noValidate>
          <div className='card-body border-top p-9'>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-semibold fs-6'>
                <span className='required'>{intl.formatMessage({id: 'PARENT.StreetName'})}</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder={'Street Name'}
                  {...formik.getFieldProps('streetName')}
                />
                {formik.touched.streetName && formik.errors.streetName && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.streetName}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-semibold fs-6'>
                <span className='required'>{intl.formatMessage({id: 'HouseNumber'})}</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder={'House Number'}
                  {...formik.getFieldProps('houseNumber')}
                />
                {formik.touched.houseNumber && formik.errors.houseNumber && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.houseNumber}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-semibold fs-6'>
                <span className='required'>{intl.formatMessage({id: 'PARENT.PostolCode'})}</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder={'Postol Code'}
                  {...formik.getFieldProps('postalCode')}
                />
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-semibold fs-6'>
                <span className='required'>{intl.formatMessage({id: 'PARENT.Country'})}</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <select
                  className='form-select form-select-solid form-select-lg fw-semibold'
                  {...formik.getFieldProps('countryId')}
                  name='countryId'
                  onChange={(e) => {
                    setSelectedCountry(e.target.value)
                  }}
                >
                  <option value=''>{intl.formatMessage({id: 'COMMON.SELECT'})}..</option>
                  {countries?.map((s: any) => (
                    <option value={s.countryId} selected={s.countryId === selectedCountry}>
                      {s.countryName}
                    </option>
                  ))}
                </select>
                {formik.touched.country && formik.errors.country && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.country}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-semibold fs-6'>
                {intl.formatMessage({id: 'PARENT.State'})}
              </label>
              <div className='col-lg-8 fv-row'>
                <select
                  className='form-select form-select-solid form-select-lg'
                  {...formik.getFieldProps('stateId')}
                  name='stateId'
                  onChange={(e) => {
                    setSelectedState(e.target.value)
                  }}
                >
                  <option value=''>{intl.formatMessage({id: 'COMMON.SELECT'})}..</option>
                  {states?.map((s: any) => (
                    <option value={s.stateId} selected={s.stateId === selectedState}>
                      {s.stateName}
                    </option>
                  ))}
                </select>

                {formik.touched.stateId && formik.errors.stateId && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.stateId}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-semibold fs-6'>
                {intl.formatMessage({id: 'PARENT.City'})}
              </label>

              <div className='col-lg-8 fv-row'>
                <select
                  className='form-select form-select-solid form-select-lg'
                  {...formik.getFieldProps('cityId')}
                  onChange={(e) => {
                    setSelectedCity(e.target.value)
                  }}
                >
                  <option value=''>{intl.formatMessage({id: 'COMMON.SELECT'})}..</option>

                  {cities?.map((s: any) => (
                    <option value={s.cityId} selected={s.cityId === selectedCity}>
                      {s.cityName}
                    </option>
                  ))}
                </select>
                {formik.touched.cityId && formik.errors.cityId && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.cityId}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-semibold fs-6'>
                {' '}
                {intl.formatMessage({id: 'PARENT.Remarks'})}
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder={'Remarks'}
                  {...formik.getFieldProps('remarks')}
                />
              </div>
            </div>
          </div>

          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button
              type='submit'
              className='btn btn-primary'
              data-kt-users-modal-action='submit'
              disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
            >
              {!formik.isSubmitting && intl.formatMessage({id: 'AUTH.GENERAL.SUBMIT_BUTTON'})}
              {formik.isSubmitting && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  {intl.formatMessage({id: 'KIDLING.HOME.PLEASE_WAIT'})}...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export {ProfileAddress}
