import {RectShape} from 'react-placeholder/lib/placeholders'

import {FC} from 'react'
interface Props {
  blockNumber: number
}
export const CardLoader: FC<Props> = ({blockNumber}) => (
  <div className='show-loading-animation row  justify-content-center w-100 py-8 '>
    {[...Array(blockNumber)].map((_, index) => (
      <div
        className='my-awesome-placeholder d-flex align-items-center col-lg-3 col-md-4 col-sm-6 col-12 my-4'
        key={index}
      >
        <RectShape color='#ccc' className=' h-200px' />
      </div>
    ))}
  </div>
)
