import {FC, useEffect} from 'react'
import CustomDateRangePicker from '../date-range-picker/DateRangePicker'
import {TitleHeader} from '../TitleHeader'
import {useAppContext} from '../../../_metronic/layout/core/AppContextProvider'
import useActivities from '../../hooks/useActivities'

interface Props {
  title?: string
}
const ActivityHeader: FC<Props> = ({title}) => {
  const {childFilter, internalPagesDatesFilters} = useAppContext()
  const {handleDateChange, readActivities} = useActivities()

  useEffect(() => {
    readActivities()
  }, [internalPagesDatesFilters, childFilter])

  return (
    <div className=' border-0   d-flex flex-column  w-100'>
      <TitleHeader
        title={title}
        showChildFilter={true}
        filter={<CustomDateRangePicker handleDateChange={handleDateChange} />}
        hidePrint
      />
    </div>
  )
}

export {ActivityHeader}
