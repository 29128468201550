import axios from 'axios'
import * as endpoints from '../config/endpoints'
import moment from 'moment'

export const getAbsenseById = async (kidId: string, From: string, To: string) => {
  const params = {From, To}
  return axios
    .get(`${endpoints.GET_ABSENCE_BY_KID}/${kidId}`, {params})
    .then((response) => {
      return response.data?.data?.sort((a: any, b: any) => moment(b?.fromDate).diff(a?.fromDate))
    })
    .catch((error) => {
      console.log(error)
    })
}

export const deleteAbsense = async (id: string) => {
  return axios
    .delete(`${endpoints.DELETE_ABSENCE_BY_KID}/${id}`)
    .then((response) => response)
    .catch((error) => {
      console.log(error)
    })
}

export const updateAbsense = async (data: any) => {
  return axios
    .put(`${endpoints.UPDATE_ABSENCE_BY_KID}`, data)
    .then((response) => response)
    .catch((error) => {
      console.log(error)
    })
}
