import React, {useState} from 'react'
import {useAppContext} from '../../../_metronic/layout/core/AppContextProvider'
import {BulkDownloadFiles} from '../../../controller/bulk-actions'
import moment from 'moment'
import {readFileFrom} from '../../../controller/image'

function DownloadImagesButton() {
  const {selectedImages, setSelectedImages} = useAppContext()
  const [isLoading, setIsLoading] = useState(false)

  const handleDownload = async () => {
    let data: string = ''
    selectedImages?.map((item: any, index: number) => {
      if (index !== 0) data += `&`
      data += `kidMediaFileIds=${item.id}`
    })
    setIsLoading(true)
    const resp = await BulkDownloadFiles(data)

    const url = window.URL.createObjectURL(new Blob([resp], {type: 'application/zip'}))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', `Media-${moment().format('yyyy-MM-DDTHH-mm-ss')}.zip`)
    document.body.appendChild(link)
    link.click()
    setIsLoading(false)

    if ((window as any)['ReactNativeWebView']) {
      const filePath = selectedImages[selectedImages?.length - 1]?.filePath
      const resp = await readFileFrom(filePath)
      ;(window as any)['ReactNativeWebView'].postMessage(
        JSON.stringify({file: {id: filePath, base64: resp}})
      )
    }

    setSelectedImages([])
  }

  function blobToBase64(blob: any) {
    return new Promise((resolve, _) => {
      const reader = new FileReader()
      reader.onloadend = () => resolve(reader.result)
      reader.readAsDataURL(blob)
    })
  }
  return (
    <div
      className='position-fixed d-flex justify-content-end '
      style={{bottom: '4rem', right: '4rem', zIndex: 10000}}
    >
      <button
        className='btn rounded-circle btn-primary w-60px h-60px  d-flex justify-content-center align-items-center shadow p-0'
        onClick={handleDownload}
      >
        {isLoading ? (
          <span className='spinner-border spinner-border-md  align-middle '></span>
        ) : (
          <i className='fa-solid fa-file-zipper text-white fs-2 mb-1 ms-1'></i>
        )}
      </button>
    </div>
  )
}

export default DownloadImagesButton
