import {FC} from 'react'
import {DrinksSearchComponent} from './DrinksSearchComponent'
import {DrinksToolbar} from './DrinksToolbar'
import CustomDateRangePicker from '../../../../general-components/date-range-picker/DateRangePicker'
import useDrinks from '../../../../hooks/useDrinks'

interface Props {
  title?: string
}
const DrinksHeader: FC<Props> = ({title}) => {
  const {handleDateChange} = useDrinks()
  return (
    <div className='border-0 pt-6  w-100'>
      <div className='d-flex align-items-center justify-content-between'>
        {title && <h4 className='d-flex align-items-center m-0'>{title}</h4>}
        <CustomDateRangePicker handleDateChange={handleDateChange} />
      </div>

      <div className='d-flex flex-column-reverse flex-sm-row align-items-sm-center justify-content-between w-100'>
        <div className='d-flex  flex-wrap flex-sm-row flex-column w-100  w-md-auto'>
          <DrinksSearchComponent />
          <DrinksToolbar />
        </div>
      </div>
    </div>
  )
}

export {DrinksHeader}
