/* eslint-disable react-hooks/exhaustive-deps */
import {FC, useEffect} from 'react'
import {useAppContext} from '../../../_metronic/layout/core/AppContextProvider'
import {NoRecords} from '../../general-components/loading/NoRecords'
import {UsersListLoading} from '../../general-components/loading/UsersListLoading'
import {useNavigate, useSearchParams} from 'react-router-dom'
import DownloadImagesButton from '../../general-components/download-images/DownloadImagesButton'
import {TitleHeader} from './components/TitleHeader'
import DashboardImageCard from '../../general-components/DashboardImageCard/DashboardImageCard'
import ImagesSlider from '../../general-components/Images-slider/ImagesSlider'
import {useIntl} from 'react-intl'
import useMedia from '../../hooks/useMedia'

const MediaGallery: FC = () => {
  const company = window.location.pathname.split('/')[1]
  const navigate = useNavigate()
  const intl = useIntl()
  const {globalLoader, selectedImages, internalPagesDatesFilters} = useAppContext()
  const [searchParams] = useSearchParams()
  const {readData, data} = useMedia()

  useEffect(() => {
    readData()
  }, [searchParams, internalPagesDatesFilters])
  return (
    <div className='container border-0  d-flex flex-column align-items-center w-100 '>
      <TitleHeader
        title={intl.formatMessage({id: 'KIDLING.HOME.MEDIA'})}
        showButton={() => navigate(company ? `/${company}/documents` : '/kidling/documents')}
      />

      <div className='row w-100 gy-6 p-0 justify-content-center'>
        <div className='row g-4 justify-content-center'>
          {Object.keys(data)?.map(
            (key: any, index: number) =>
              data[key].length > 0 && (
                <div className={`${'col-12 col-sm-6 col-md-4'}  `} key={index}>
                  {data[key].length > 1 ? (
                    <div className='shadow rounded h-100 w-100'>
                      <ImagesSlider data={data[key]} hideCheckbox={false} />
                    </div>
                  ) : (
                    data[key].length === 1 && (
                      <DashboardImageCard
                        date={data[key][0].createdDate}
                        image={data[key][0].filePath}
                        userName={data[key][0].userName}
                        userImagePath={data[key][0].userImagePath}
                        details={data[key][0].kidMediaFileDescription}
                        title=''
                        kidName={data[key][0].kidName}
                        KidMediaFileName={data[key][0].kidMediaFileName}
                        locked={data[key][0].confidential}
                        Hashtags={data[key][0]?.hashtags}
                        KidMediaFileId={data[key][0]?.kidMediaFileId}
                        readData={readData}
                        hideCheckbox={false}
                      />
                    )
                  )}
                </div>
              )
          )}
        </div>

        {selectedImages?.length > 0 && <DownloadImagesButton />}
      </div>

      {!globalLoader && data?.length === 0 && <NoRecords />}
      {globalLoader && <UsersListLoading />}
    </div>
  )
}

export default MediaGallery
