import moment from 'moment'
import {getKidsMoods} from '../../controller/moods'
import {useEffect, useState} from 'react'
import {useAppContext} from '../../_metronic/layout/core/AppContextProvider'
import {useAuth} from '../views/auth'

const useMood = () => {
  const {
    kidMoods,
    setKidMoods,
    setGlobalLoader,
    internalPagesFilters,
    internalPagesDatesFilters,
    setInternalPagesDatesFilters,
    globalLoader,
    setInternalPagesFilters,
  } = useAppContext()
  const [data, setData] = useState([])
  const [initalCall, setinitalCall] = useState(true)
  const [date, setDate] = useState<any>({})
  const {currentUser} = useAuth()
  const [clicked, setClicked] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')

  useEffect(() => {
    filterData()
  }, [searchTerm])

  useEffect(() => {
    if (clicked && !globalLoader) {
      readMoods()
      setClicked(false)
    }
  }, [clicked])

  useEffect(() => {
    const newFilters: any = {...internalPagesDatesFilters}

    newFilters.mood = {
      ...newFilters.mood,
      From: moment(date?.startDate).startOf('day').format('yyyy-MM-DDTHH:mm:ss'),
      To: moment(date?.endDate).endOf('day').format('yyyy-MM-DDTHH:mm:ss'),
    }

    setInternalPagesDatesFilters(newFilters)
  }, [date])
  useEffect(() => {
    let temp: any = []
    kidMoods?.map((item: any) => {
      let KidsMoods: any = []
      item.kidMoods?.map((mood: any) => {
        KidsMoods.push({
          id: mood.kidMoodId,
          title: mood.moodName,
          subtitle: `<span>${moment(mood?.date)
            .set('hours', moment(mood?.fromTime).hours())
            .set('minutes', moment(mood?.fromTime).minutes())
            .format(
              'DD.MM.yyyy HH:mm'
            )} </span> <i class="fa-solid fa-angles-right " style={{margin-left:"5px",margin-right:"5px"}}></i>  <span>${moment(
            mood?.date
          )
            .set('hours', moment(mood?.toTime).hours())
            .set('minutes', moment(mood?.toTime).minutes())
            .format('HH:mm')}</span>`,
          desc: mood.notes,

          tag: `${Math.ceil(
            moment
              .duration(
                moment(
                  mood?.toTime
                    ? moment(mood?.date)
                        .set('hours', moment(mood?.toTime).hours())
                        .set('minutes', moment(mood?.toTime).minutes())
                        .format('yyyy-MM-DDTHH:mm:ss')
                    : moment().format('yyyy-MM-DDTHH:mm:ss')
                ).diff(
                  moment(mood?.date)
                    .set('hours', moment(mood?.fromTime).hours())
                    .set('minutes', moment(mood?.fromTime).minutes())
                    .format('yyyy-MM-DDTHH:mm:ss')
                )
              )
              .asMinutes()
          )} minutes`,
        })
      })
      temp.push({
        image: item.imagePath,
        name: `${item.firstName} ${item.lastName}`,
        subItems: KidsMoods,
        // handleDelete: (id: string) => doDelete(id),
      })
    })
    setData(temp)
  }, [kidMoods])
  const readMoods = async () => {
    let filter: string = ''
    setGlobalLoader(true)

    currentUser?.kids?.map((kid, index: number) => {
      if (index === 0) filter += '('
      filter += 'kidId=' + kid.kidId
      if (index !== currentUser?.kids?.length - 1) filter += ' OR '
      else filter += ')'
    })
    if (filter === '') filter = 'kidId=-1'
    if (internalPagesFilters.mood.searchTerm) filter = filter + ' AND '
    if (internalPagesFilters.mood.searchTerm) filter = filter + internalPagesFilters.mood.searchTerm
    const res = await getKidsMoods(
      filter,
      currentUser?.contact?.contactId || 0,
      internalPagesDatesFilters.mood.From
        ? moment(internalPagesDatesFilters.mood.From).format('yyyy-MM-DDTHH:mm:ss')
        : moment().startOf('day').format('yyyy-MM-DDTHH:mm:ss'),
      internalPagesDatesFilters.mood.To
        ? moment(internalPagesDatesFilters.mood.To).format('yyyy-MM-DDTHH:mm:ss')
        : moment().endOf('day').format('yyyy-MM-DDTHH:mm:ss'),
      initalCall ? 20 : -1
    )

    setKidMoods(res)
    setGlobalLoader(false)
    setinitalCall(false)
  }
  const handleDateChange = (selected: any) => {
    setDate(selected[0])
  }
  const filterData = async () => {
    const newFilters: any = {...internalPagesFilters}

    newFilters.mood = {
      ...newFilters.mood,
      searchTerm: `kidName.toLower().contains("${searchTerm.toLowerCase().trim()}")`,
    }
    setInternalPagesFilters(newFilters)
  }
  return {readMoods, data, handleDateChange, setClicked, clicked, searchTerm, setSearchTerm}
}

export default useMood
