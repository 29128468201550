import axios from 'axios'
import {MEALTIME, FOOD_MENU, KID_MEALS_PARENT} from '../config/endpoints'

export const getMealTimes = async () => {
  return axios
    .get(`${MEALTIME}`)
    .then((response) => response.data)
    .catch((error) => {
      console.log(error)
    })
}

export const getKidsMealTimesByDate = async (
  filter: string,
  parentId: number,
  From: string,
  To: string,
  nbRecords?: number
) => {
  let params: any = {parentId, From: From && To ? From : null, To: From && To ? To : null}
  if (nbRecords && nbRecords > 0) params = {...params, nbRecords: nbRecords}
  return axios
    .get(`${KID_MEALS_PARENT}/${filter}`, {
      params,
    })
    .then((response) => response.data.data)
    .catch((error) => {
      console.log(error)
    })
}

export const getFOODMenu = async (params: any) => {
  return axios
    .get(`${FOOD_MENU}`, {params: {...params}})
    .then((response) => response.data.data)
    .catch((error) => {
      console.log(error)
    })
}
