import React, {FC, ReactElement} from 'react'
import {useIntl} from 'react-intl'
import {Modal, ModalHeader, ModalBody} from 'reactstrap'

import {Avatar} from '@mui/material'
import moment from 'moment'

interface Props {
  showModal: boolean
  setShowModal: any
  enableDelete?: boolean
  title?: string
  users?: any
}
const GroupMembersDialog: FC<Props> = ({
  showModal,
  setShowModal,
  enableDelete,
  title,
  users,
}): ReactElement => {
  const intl = useIntl()

  const close = () => {
    setShowModal(false)
  }

  return (
    <Modal isOpen={showModal} toggle={close} centered>
      <ModalHeader toggle={close}>{title}</ModalHeader>
      <ModalBody>
        {users?.map((item: any, index: number) => {
          return (
            <div
              className='d-flex align-items-center justify-content-between chat__header__contact p-2 '
              key={index}
            >
              <div className='d-flex align-items-center '>
                <Avatar src={item?.imageUrl} alt={item?.usersName.charAt(9).toUpperCase()} />
                <div>
                  <h4 className='mx-2 my-0'>{item?.usersName}</h4>
                  {item?.chatGroupMessageStatusReadDatetime && (
                    <p className='mx-3 my-0 text-muted'>
                      {moment(item?.chatGroupMessageStatusReadDatetime).format('DD.MM.yyyy HH:mm')}
                    </p>
                  )}
                </div>
              </div>
              {item?.admin && (
                <span className='badge badge-sm badge badge-success fs-7'>
                  {intl.formatMessage({id: 'KIDLING.CHAT.GROUP_ADMIN'})}
                </span>
              )}
              {enableDelete && (
                <button className='btn'>
                  <i className='fa-solid fa-trash text-danger' />
                </button>
              )}
            </div>
          )
        })}
      </ModalBody>
    </Modal>
  )
}

export default GroupMembersDialog
