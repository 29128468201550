import {FC, ReactElement, useEffect} from 'react'

import {useAppContext} from '../../../_metronic/layout/core/AppContextProvider'
import {useAuth} from '../../views/auth'
import {useSearchParams} from 'react-router-dom'
import {Avatar} from '@mui/material'

const KidsFilter: FC = (): ReactElement => {
  const {setChildFilter, childFilter} = useAppContext()
  const [searchParams, setSearchParams] = useSearchParams()
  const {currentUser}: any = useAuth()

  useEffect(() => {
    const kidId = searchParams.get('kidId') || searchParams.get('KidId')

    if (currentUser?.kids?.length > 0) {
      if (Number(kidId) > 0) {
        const kidItem = currentUser?.kids?.find((item: any) => item.kidId === Number(kidId))

        setChildFilter(kidItem)
      } else {
        setChildFilter(currentUser?.kids[currentUser?.kids.length - 1])
      }
    }
  }, [searchParams, currentUser])

  return (
    <div className='w-100 d-flex align-items-center justify-content-center flex-wrap'>
      {currentUser?.kids?.length > 1 &&
        currentUser?.kids?.map(
          (kid: any, index: number) =>
            kid.firstName && (
              <div
                className={`btn btn-outline  btn-outline-dashed btn-active-light-primary  text-start pt-8 px-12 pb-2 m-2  d-flex align-items-center flex-column position-relative ${
                  childFilter?.kidId === kid.kidId && 'active'
                } `}
                data-kt-button='true'
                key={index}
                onClick={() => {
                  //setChildFilter(kid)
                  setSearchParams({kidId: kid.kidId})
                }}
              >
                <Avatar
                  src={kid.imagePath}
                  alt={`${kid.firstName.charAt(0).toUpperCase()} ${kid.lastName
                    .charAt(0)
                    .toUpperCase()} `}
                />

                <input
                  className={`form-check-input  position-absolute top-0 start-0 m-2`}
                  type='radio'
                  name='childFilter'
                  checked={childFilter?.kidId === kid.kidId}
                  onChange={() => {}}
                />
                <div className='form-check form-check-custom form-check-solid form-check-sm d-flex align-items-center   '>
                  <div className='fs-4 fw-bold text-gray-800  '>{kid.firstName}</div>
                </div>
              </div>
            )
        )}
    </div>
  )
}

export {KidsFilter}
