/* eslint-disable react-hooks/exhaustive-deps */
import {useIntl} from 'react-intl'
import {TitleHeader} from '../../../../../general-components/TitleHeader'
import {AttendanceFilter} from './AttendanceFilter'
import {useEffect} from 'react'
import useKidAttendance from '../../../../../hooks/useKidAttendance'
import {useAppContext} from '../../../../../../_metronic/layout/core/AppContextProvider'

const AttendanceHeader = () => {
  const intl = useIntl()
  const {load} = useKidAttendance()
  const {childFilter, dateFilter} = useAppContext()

  useEffect(() => {
    load()
  }, [childFilter, dateFilter])

  return (
    <div className='  border-0   d-flex flex-column align-items-start w-100 '>
      <TitleHeader
        title={intl.formatMessage({id: 'KIDLING.MENU.KIDSATTENDANCE'})}
        showChildFilter={true}
        filter={<AttendanceFilter />}
      />
    </div>
  )
}

export {AttendanceHeader}
