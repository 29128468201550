import moment from 'moment'
import {getParentKidsRequests} from '../../controller/requests'
import {useEffect, useState} from 'react'
import {useAuth} from '../views/auth'
import {useAppContext} from '../../_metronic/layout/core/AppContextProvider'

const useRequests = () => {
  const {
    setGlobalLoader,
    internalPagesDatesFilters,
    delivered,
    kidsRequests,
    setKidsRequests,
    globalLoader,
    setInternalPagesDatesFilters,
  } = useAppContext()

  const {auth, currentUser} = useAuth()
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [data, setData] = useState([])
  const [currentRequest, setCurrentRequest] = useState({})
  const [date, setDate] = useState<any>({})

  useEffect(() => {
    const newFilters: any = {...internalPagesDatesFilters}

    newFilters.requests = {
      ...newFilters.requests,
      From: moment(date?.startDate).startOf('day').format('yyyy-MM-DDTHH:mm:ss'),
      To: moment(date?.endDate).endOf('day').format('yyyy-MM-DDTHH:mm:ss'),
    }

    setInternalPagesDatesFilters(newFilters)
  }, [date])

  useEffect(() => {
    let temp: any = []

    kidsRequests?.map((item: any) => {
      let requests: any = []
      item.requests?.map((request: any, index: number) => {
        if (
          internalPagesDatesFilters?.requests?.From ===
            moment().startOf('year').format('yyyy-MM-DDTHH:mm:ss') &&
          internalPagesDatesFilters?.requests?.To ===
            moment().endOf('year').format('yyyy-MM-DDTHH:mm:ss')
        ) {
          if (index < 10) {
            requests.push({
              kidId: item.kidId,
              id: request.requestKidId,
              title: request.requestItemName,
              desc: request.note,
              date: request.dueDate,
              Icon: !request.delivered && (
                <button
                  className='btn p-0 m-0 d-flex justify-content-end align-items-center'
                  onClick={() => {
                    setCurrentRequest({...request, kidId: item.kidId})
                    setShowConfirmation(true)
                  }}
                >
                  <i className='fa-solid fa-people-arrows m-0 p-0' />
                </button>
              ),
            })
          }
        } else {
          requests.push({
            kidId: item.kidId,
            id: request.requestKidId,
            title: request.requestItemName,
            desc: request.note,
            date: request.dueDate,
            Icon: !request.delivered && (
              <button
                className='btn p-0 m-0 d-flex justify-content-end align-items-center'
                onClick={() => {
                  setCurrentRequest({...request, kidId: item.kidId})
                  setShowConfirmation(true)
                }}
              >
                <i className='fa-solid fa-people-arrows m-0 p-0' />
              </button>
            ),
          })
        }
      })
      temp.push({
        image: item.imagePath,
        name: `${item.firstName} ${item.lastName}`,
        subItems: requests,
      })
    })
    setData(temp)
  }, [kidsRequests])

  const readRequests = async () => {
    let filter: string = ''
    setGlobalLoader(true)

    filter = '1=1'

    const res = await getParentKidsRequests(
      filter,
      currentUser?.contact?.contactId || 0,
      delivered === 1,
      internalPagesDatesFilters.requests?.From
        ? moment(internalPagesDatesFilters?.requests?.From).format('yyyy-MM-DDTHH:mm:ss')
        : moment().startOf('year').format('yyyy-MM-DDTHH:mm:ss'),
      internalPagesDatesFilters?.requests?.To
        ? moment(internalPagesDatesFilters?.requests?.To).format('yyyy-MM-DDTHH:mm:ss')
        : moment().endOf('year').format('yyyy-MM-DDTHH:mm:ss')
    )

    setKidsRequests(res)
    setGlobalLoader(false)
  }

  const handleDateChange = (selected: any) => {
    setDate(selected[0])
  }
  return {readRequests, data, showConfirmation, handleDateChange}
}
export default useRequests
