import {useIntl} from 'react-intl'
import {KTSVG} from '../../../../_metronic/helpers'

const KidAbsenseModalHeader = (props: any) => {
  const setShowAbsenceModel = props.setShowAbsenceModel
  const intl = useIntl()
  return (
    <div className='modal-header'>
      {/* begin::Modal title */}
      <h2 className='fw-bold'> {intl.formatMessage({id: 'KIDLING.HOME.ADD_ABSENSE'})}</h2>
      {/* end::Modal title */}

      {/* begin::Close */}
      <div
        className='btn btn-icon btn-sm btn-active-icon-primary'
        data-kt-users-modal-action='close'
        onClick={() => setShowAbsenceModel(false)}
        style={{cursor: 'pointer'}}
      >
        <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
      </div>
      {/* end::Close */}
    </div>
  )
}

export {KidAbsenseModalHeader}
