import React from 'react'
import clsx from 'clsx'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import ResponsiveDatePicker from '../responsive-material-datepicker/ResponsiveDatePicker'

const DateEditableCell = ({value, onChange}: any) => {
  const [mode, setMode] = React.useState('read')
  const [text, setText] = React.useState(value ?? undefined)

  React.useEffect(() => {
    setText(value)
  }, [value]) // <--- when value is changed text state is changed too
  if (mode === 'edit') {
    const handleInputChange = (val: any) => {
      setText(val)
    }
    const handleSaveClick = () => {
      setMode('read')
      if (onChange) {
        onChange(text)
      }
    }
    return (
      <div className='p-2px d-flex align-items-center gap-2 '>
        <ResponsiveDatePicker onAccept={handleInputChange} value={text} />

        <button
          className='btn btn-sm btn-success btn-shadow rounded py-1 px-0 d-flex justify-content-center align-items-center'
          onClick={handleSaveClick}
        >
          <i className='fa-solid fa-check text-white ms-1'></i>
        </button>
      </div>
    )
  }
  if (mode === 'read') {
    const handleEditClick = () => {
      setMode('edit')
    }
    return (
      <div className='p-5px d-flex align-items-center' onClick={handleEditClick}>
        <i className='fa-solid fa-calendar-days me-2'></i>
        {moment(text).format('DD.MM.yyyy')}
      </div>
    )
  }
  return null
}

export default DateEditableCell
