import moment from 'moment'
import {getAllWindingProtocols} from '../../controller/diapers'
import {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {useAuth} from '../views/auth'
import {useAppContext} from '../../_metronic/layout/core/AppContextProvider'

const useDiaper = () => {
  const {
    kidsDiapers,
    setKidsDiapers,
    setGlobalLoader,
    internalPagesFilters,
    internalPagesDatesFilters,
    globalLoader,
    setInternalPagesDatesFilters,
    setInternalPagesFilters,
  } = useAppContext()
  const [data, setData] = useState([])
  const {currentUser} = useAuth()
  const intl = useIntl()
  const [initalCall, setinitalCall] = useState(true)
  const [date, setDate] = useState<any>({})
  const [clicked, setClicked] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const filterData = async () => {
    const newFilters: any = {...internalPagesFilters}

    newFilters.diapers = {
      ...newFilters.diapers,
      searchTerm: `kidName.toLower().contains("${searchTerm.toLowerCase().trim()}")`,
    }

    setInternalPagesFilters(newFilters)
  }

  useEffect(() => {
    filterData()
  }, [searchTerm])

  useEffect(() => {
    if (clicked && !globalLoader) {
      readWindingProtocols()
      setClicked(false)
    }
  }, [clicked])

  useEffect(() => {
    const newFilters: any = {...internalPagesDatesFilters}

    newFilters.diapers = {
      ...newFilters.diapers,
      From: moment(date?.startDate).startOf('day').format('yyyy-MM-DDTHH:mm:ss'),
      To: moment(date?.endDate).endOf('day').format('yyyy-MM-DDTHH:mm:ss'),
    }

    setInternalPagesDatesFilters(newFilters)
  }, [date])

  useEffect(() => {
    let temp: any = []
    kidsDiapers?.map((item: any) => {
      let Diapers: any = []
      item.windingProtocols?.map((diaper: any) =>
        Diapers.push({
          id: diaper.windingProtocolId,
          title: diaper.diaperContentName,
          desc: diaper.notes,
          date: diaper.dateTime,
          tag: diaper.employeeName,
        })
      )
      temp.push({
        image: item.imagePath,
        name: `${item.firstName} ${item.lastName}`,
        subItems: Diapers,
      })
    })
    setData(temp)
  }, [kidsDiapers])

  const readWindingProtocols = async () => {
    let filter: string = ''
    setGlobalLoader(true)

    currentUser?.kids?.map((kid, index: number) => {
      if (index === 0) filter += '('
      filter += 'kidId=' + kid.kidId
      if (index !== currentUser?.kids?.length - 1) filter += ' OR '
      else filter += ')'
    })
    if (filter === '') filter = 'kidId=-1'
    if (internalPagesFilters.diapers.searchTerm) filter = filter + ' AND '
    if (internalPagesFilters.diapers.searchTerm)
      filter = filter + internalPagesFilters.diapers.searchTerm

    const res = await getAllWindingProtocols(
      filter,
      currentUser?.contact?.contactId || 0,
      internalPagesDatesFilters.diapers.From
        ? moment(internalPagesDatesFilters.diapers.From).format('yyyy-MM-DDTHH:mm:ss')
        : moment().startOf('day').format('yyyy-MM-DDTHH:mm:ss'),
      internalPagesDatesFilters.diapers.To
        ? moment(internalPagesDatesFilters.diapers.To).format('yyyy-MM-DDTHH:mm:ss')
        : moment().endOf('day').format('yyyy-MM-DDTHH:mm:ss'),
      initalCall ? 20 : -1
    )

    setKidsDiapers(res)
    setGlobalLoader(false)
    setinitalCall(false)
  }

  const handleDateChange = (selected: any) => {
    setDate(selected[0])
  }
  return {
    readWindingProtocols,
    data,
    handleDateChange,
    clicked,
    setClicked,
    searchTerm,
    setSearchTerm,
  }
}
export default useDiaper
