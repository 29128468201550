import React, {FC, ReactElement, useEffect, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import clsx from 'clsx'
import {useIntl} from 'react-intl'
import {Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap'
import {toast} from 'react-toastify'
import {useAuth} from '../../auth'
import {registerNewKid} from '../../../../controller/kids'
import {getDashboard} from '../../../../controller/parent'
import {useAppContext} from '../../../../_metronic/layout/core/AppContextProvider'

interface Props {
  modal: any
  setModal: any
}
const RegisterKidDialog: FC<Props> = ({modal, setModal}): ReactElement => {
  const intl = useIntl()
  const {currentUser} = useAuth()
  const {setAccounts} = useAppContext()
  const [eventInitialValues, setEventInitialValues] = useState<any>({
    kidName: '',
    code: '',
  })

  const kidSchema = Yup.object().shape({
    kidName: Yup.string().required(intl.formatMessage({id: 'KIDLING.REQUIRED'})),
    code: Yup.string().required(intl.formatMessage({id: 'KIDLING.REQUIRED'})),
  })

  useEffect(() => {
    setEventInitialValues({
      kidName: '',
      code: '',
    })
  }, [])

  const handleClose = () => {
    formik.resetForm()
    setModal(false)
  }

  const formik: any = useFormik({
    initialValues: eventInitialValues,
    enableReinitialize: true,
    validationSchema: kidSchema,

    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      try {
        const data = {
          kidName: values.kidName,
          kidParentVerificationCode: values.code,
          contactId: currentUser?.contact?.contactId || 0,
        }
        const resp = await registerNewKid(data)
        if (resp) {
          const contactId = currentUser?.contact?.contactId
          const data = await getDashboard('?contactId=' + contactId)

          if (data.kids) {
            setAccounts(data?.kids)
          }

          formik.resetForm()
          toast.success(intl.formatMessage({id: 'COMMON.SUCCESS'}))
          setModal(false)
        } else {
          toast.error(intl.formatMessage({id: 'COMMON.FAIL'}))
        }
      } catch (ex) {
        console.log(ex)
      } finally {
        setSubmitting(true)
      }
    },
  })

  return (
    <Modal isOpen={modal} toggle={handleClose} zIndex={1000} onClosed={handleClose}>
      <ModalHeader toggle={handleClose}>
        {intl.formatMessage({id: 'KIDLING.REGISTER_KID'})}
      </ModalHeader>

      <form
        id='kt_modal_add_user_form'
        className='form container'
        onSubmit={formik.handleSubmit}
        noValidate
      >
        <ModalBody>
          <div className='row align-items-center'>
            <div className='col-12'>
              <label className='required form-label fs-6 fw-semibold text-body'>
                {intl.formatMessage({id: 'KIDLING.HOME.KID_NAME'})}
              </label>
              <input
                {...formik.getFieldProps('kidName')}
                className={clsx(
                  'form-control  mb-3 mb-lg-0',
                  {'is-invalid': formik.touched.kidName && formik.errors.kidName},
                  {
                    'is-valid': formik.touched.kidName && !formik.errors.kidName,
                  }
                )}
                name='kidName'
                autoComplete='off'
                disabled={formik.isSubmitting}
                rows={1}
              />
              {formik.touched.kidName && formik.errors.kidName && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span className='alert-danger'>{formik.errors.kidName}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='col-12 my-7'>
              <label className='required form-label fs-6 fw-semibold text-body'>
                {intl.formatMessage({id: 'KIDLING.CODE'})}
              </label>
              <input
                {...formik.getFieldProps('code')}
                className={clsx(
                  'form-control  mb-3 mb-lg-0',
                  {'is-invalid': formik.touched.code && formik.errors.code},
                  {
                    'is-valid': formik.touched.code && !formik.errors.code,
                  }
                )}
                name='code'
                autoComplete='off'
                disabled={formik.isSubmitting}
                rows={1}
              />
              {formik.touched.code && formik.errors.code && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span className='alert-danger'>{formik.errors.code}</span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color='secondary' onClick={handleClose}>
            {intl.formatMessage({id: 'KIDLING.HOME.DISCARD'})}{' '}
          </Button>

          <Button color='primary' type='submit' disabled={formik.isSubmitting}>
            {intl.formatMessage({id: 'AUTH.GENERAL.SUBMIT_BUTTON'})}
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  )
}

export default RegisterKidDialog
