/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useState, FC, ReactElement} from 'react'
import {selectStyle, selectStyleDarkMode} from '../../../utils/select-style'
import makeAnimated from 'react-select/animated'
import SelectWithAllOption from '../../../general-components/select-with-all/SelectWithAllOption'
import {useIntl} from 'react-intl'
import {useAppContext} from '../../../../_metronic/layout/core/AppContextProvider'
import {useAuth} from '../../auth'
import {Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap'
import {getAllEmployeesByKita} from '../../../../controller/employee'
import {useThemeMode} from '../../../../_metronic/partials/layout/theme-mode/ThemeModeProvider'
import {addNewChat} from '../../../../controller/chat'
import {toast} from 'react-toastify'
import {Avatar} from '@mui/material'
import {UsersListLoading} from '../../../general-components/loading/UsersListLoading'
const animatedComponents = makeAnimated()

interface Props {
  showModal: boolean
  setShowModal: any
}
const NewChatDialog: FC<Props> = ({showModal, setShowModal}): ReactElement => {
  const {mode} = useThemeMode()
  const intl = useIntl()
  const [selectedCompany, setSelectedCompany] = useState<any>([])
  const {currentUser} = useAuth()
  const {employees, setEmployees, companies} = useAppContext()
  const [selectedEmployee, setSelectedEmployee] = useState<any>([])
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (companies?.length === 1) setSelectedCompany([companies[0]])
  }, [companies])

  useEffect(() => {
    if (selectedCompany[0]?.value) readEmployees()
  }, [selectedCompany, companies])

  useEffect(() => {
    setSelectedEmployee([])
  }, [])

  const readEmployees = async () => {
    try {
      setIsLoading(true)
      const resp = await getAllEmployeesByKita(selectedCompany[0]?.value)

      let temp: any = []
      resp?.map((item: any) =>
        temp.push({
          value: item.employeeId,
          label: item.employeeName,
          imagePath: item.imagePath,
          usersId: item.usersId,
          companyId: item.companyId,
        })
      )
      temp.sort((a: any, b: any) => a.label.localeCompare(b.label))
      setEmployees(temp)
      setIsLoading(false)
    } catch (e) {}
  }

  const close = () => {
    setShowModal(false)
    setSelectedEmployee([])
    setSelectedCompany(companies[0])
  }
  const addChat = async () => {
    setIsLoading(true)
    let chatName = selectedEmployee[0]?.label
    let employeeIds: any = [selectedEmployee[0]?.value]
    if (selectedEmployee?.length > 1) {
      chatName = ''
      employeeIds = []
      selectedEmployee?.forEach((em: any, ind: number) => {
        employeeIds.push(em.value)
        if (ind !== 0) chatName = chatName + ',' + em.label
        else chatName = em.label
      })
    }

    const data = {
      chatGroupName: chatName,
      isPublic: false,
      companyId: selectedCompany?.companyId,
      classesIds: [],
      employeeIds: employeeIds,
      contactIds: [currentUser?.usersId],
    }

    const resp = await addNewChat(data)
    if (resp) {
      toast.success(intl.formatMessage({id: 'COMMON.SUCCESS'}))
      close()
    }
    setIsLoading(false)
  }

  return (
    <Modal isOpen={showModal} toggle={close}>
      <ModalHeader toggle={close}>{intl.formatMessage({id: 'KIDLING.CHAT_NEW_CHAT'})}</ModalHeader>
      <ModalBody>
        {companies?.length > 1 && (
          <SelectWithAllOption
            openMenuOnFocus={true}
            value={selectedCompany}
            onChange={(val: any) => setSelectedCompany([val])}
            options={companies}
            isClearable={true}
            components={animatedComponents}
            formatOptionLabel={(item: any) => (
              <div className='d-flex align-items-center '>
                <div className='d-flex flex-column'>
                  <p className='p-0 m-0'>{item.label}</p>
                </div>
              </div>
            )}
            className='w-100'
            styles={mode === 'dark' ? selectStyleDarkMode : selectStyle}
            theme={(theme: any) => ({
              ...theme,

              colors: {
                ...theme.colors,
                primary25: 'var(--bs-dark-light)',
                primary: 'var(--bs-gray)',
                neutral0: 'var(--bs-dark-light)',
              },
            })}
            placeholder={intl.formatMessage({id: 'KIDLING.COMPANY'})}
          />
        )}

        <div className=' px-2   mh-100 mh-450px overflow-y-auto '>
          {selectedCompany &&
            employees?.map((emp: any, index: number) => (
              <div className='d-flex align-items-center border-bottom my-1 py-1' key={index}>
                <input
                  className='form-check-input w-20px h-20px me-2'
                  type='checkbox'
                  checked={
                    selectedEmployee?.find((ee: any) => ee.value === emp.value) ? true : false
                  }
                  name='notifications'
                  onChange={(e: any) => {
                    if (e.target.checked && selectedEmployee?.length < 3) {
                      setSelectedEmployee((prev: any) => [...prev, emp])
                    } else {
                      const temp = selectedEmployee.filter((ee: any) => ee.value !== emp.value)
                      setSelectedEmployee(temp)
                    }
                    // formik.setFieldValue('active', e.target.checked)
                  }}
                />
                <Avatar src={emp.imagePath} alt={`${emp.label[0].charAt(0).toUpperCase()}`} />

                <div className='d-flex flex-column ms-2  '>
                  <p className='p-0 m-0'>{emp.label}</p>
                </div>
              </div>
            ))}
          {/* <SelectWithAllOption
            openMenuOnFocus={true}
            value={selectedEmployee}
            onChange={(val: any) => setSelectedEmployee(val)}
            options={selectedCompany && selectedCompany?.length > 0 ? employees : []}
            isClearable={true}
            components={animatedComponents}
            isLoading={false}
            formatOptionLabel={(item: any) => (
              <div className='d-flex align-items-center '>
                {item.value !== '*' && (
                  <AvatarWithLetters
                    image={item.imagePath}
                    alt={`${item.label[0].charAt(0).toUpperCase()}  `}
                    rounded
                    extrasmall
                  />
                )}

                <div className='d-flex flex-column ms-2  '>
                  <p className='p-0 m-0'>{item.label}</p>
                  <p className='p-0 m-0 text-muted'>{item.email}</p>
                </div>
              </div>
            )}
            className='w-100'
            styles={mode === 'dark' ? selectStyleDarkMode : selectStyle}
            theme={(theme: any) => ({
              ...theme,

              colors: {
                ...theme.colors,
                primary25: 'var(--bs-dark-light)',
                primary: 'var(--bs-gray)',
                neutral0: 'var(--bs-dark-light)',
              },
            })}
            placeholder={intl.formatMessage({id: 'KIDLING.EMPLOYEE'})}
          /> */}
        </div>
        {isLoading && <UsersListLoading />}
      </ModalBody>
      <ModalFooter>
        <Button color='secondary' onClick={close}>
          {intl.formatMessage({id: 'COMMON.CANCEL'})}
        </Button>
        <Button color='primary' type='submit' onClick={addChat}>
          {!isLoading &&
            intl.formatMessage({
              id: 'AUTH.GENERAL.SUBMIT_BUTTON',
            })}

          {isLoading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              {intl.formatMessage({id: 'KIDLING.HOME.PLEASE_WAIT'})}...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default NewChatDialog
