import {useAuth} from '../../../../app/views/auth'
import {MenuItem} from './MenuItem'

import {useIntl} from 'react-intl'
import {useAppContext} from '../../core/AppContextProvider'

export function MenuInner() {
  const {messagesCounts, mobileConfiguration} = useAppContext()
  const intl = useIntl()
  const {currentUser} = useAuth()
  const contact = currentUser?.contact
  const totalKids = currentUser?.kids?.length || 0
  const company = window.location.pathname.split('/')[1]

  return (
    <>
      <MenuItem
        title={intl.formatMessage({id: 'KIDLING.MENU.HOME'})?.toLocaleUpperCase()}
        to={company ? `/${company}/dashboard` : '/kidling/dashboard'}
        hasBullet={false}
        fontIcon='fa-solid fa-home'
      ></MenuItem>

      {mobileConfiguration?.KidActivity &&
        mobileConfiguration?.KidActivity[0]?.showInParentApp &&
        totalKids > 0 && (
          <MenuItem
            title={intl.formatMessage({id: 'KIDLING.MENU.ACTIVITIES'})?.toLocaleUpperCase()}
            to={company ? `/${company}/activities` : '/kidling/activities'}
            hasBullet={false}
            fontIcon='fa-solid fa-person-running'
            //  iconColor={menuColors[1]}
          ></MenuItem>
        )}

      {totalKids > 0 &&
        mobileConfiguration?.KidAttendance &&
        mobileConfiguration?.KidAttendance[0]?.showInParentApp && (
          <>
            <MenuItem
              title={intl.formatMessage({id: 'KIDLING.MENU.KIDSATTENDANCE'})?.toLocaleUpperCase()}
              to={company ? `/${company}/attendance` : '/kidling/attendance'}
              hasBullet={false}
              fontIcon='fa-solid fa-person-circle-plus'
            ></MenuItem>
          </>
        )}
      {totalKids > 0 &&
        mobileConfiguration?.KidAbsent &&
        mobileConfiguration?.KidAbsent[0]?.showInParentApp && (
          <MenuItem
            title={intl.formatMessage({id: 'KIDLING.MENU.KIDSABSENSES'})?.toLocaleUpperCase()}
            to={company ? `/${company}/absense` : '/kidling/absense'}
            hasBullet={false}
            fontIcon='fa-solid fa-person-circle-minus'
          ></MenuItem>
        )}
      {mobileConfiguration?.Events && mobileConfiguration?.Events[0]?.showInParentApp && (
        <MenuItem
          title={intl.formatMessage({id: 'KIDLING.MENU.CALENDER'})?.toLocaleUpperCase()}
          to={company ? `/${company}/calender` : '/kidling/calender'}
          hasBullet={false}
          fontIcon='fa-solid fa-calendar'
        ></MenuItem>
      )}
      {totalKids > 0 && (
        <>
          {mobileConfiguration?.KidMedia && mobileConfiguration?.KidMedia[0]?.showInParentApp && (
            <MenuItem
              title={intl.formatMessage({id: 'KIDLING.HOME.MEDIA'})?.toLocaleUpperCase()}
              to={company ? `/${company}/media` : '/kidling/media'}
              hasBullet={false}
              fontIcon='fa-solid fa-image'
            ></MenuItem>
          )}
          {mobileConfiguration?.KidMedicalRecord &&
            mobileConfiguration?.KidMedicalRecord[0]?.showInParentApp &&
            totalKids > 0 && (
              <MenuItem
                title={intl.formatMessage({id: 'KIDLING.MENU.MEDICALRECORDS'})?.toLocaleUpperCase()}
                to={company ? `/${company}/medical-records` : '/kidling/medical-records'}
                hasBullet={false}
                fontIcon='fa-solid fa-notes-medical'
              ></MenuItem>
            )}
          {/*<MenuItem
        title={intl.formatMessage({id: 'KIDLING.HOME.ASSESSMENT'})}
        to='/assesment'
        hasBullet={false}
        fontIcon='fa-solid fa-hands-holding-child'
  ></MenuItem>*/}
          {mobileConfiguration?.Attachment &&
            mobileConfiguration?.Attachment[0]?.showInParentApp &&
            totalKids > 0 && (
              <MenuItem
                title={intl.formatMessage({id: 'KIDLING.MENU.DOCUMENTS'})?.toLocaleUpperCase()}
                to={company ? `/${company}/documents` : '/kidling/documents'}
                hasBullet={false}
                fontIcon='fa-solid fa-paperclip'
              ></MenuItem>
            )}
        </>
      )}
      {/*      <MenuItem
        title={intl.formatMessage({id: 'COMMON.CONTACTS'})}
        to='/contacts'
        hasBullet={false}
        fontIcon='fa-solid fa-user'
></MenuItem>*/}
      {/* {totalKids && (
        <MenuItem
          title={intl.formatMessage({id: 'KIDLING.MENU.NOTIFICATIONS'})}
          to={company ? `/${company}/notifications` : '/kidling/notifications'}
          hasBullet={false}
          fontIcon='fa-solid fa-bullhorn'
        ></MenuItem>
      )} */}
      {/* {mobileConfiguration?.Messaging && mobileConfiguration?.Messaging[0]?.showInParentApp && (
        <MenuItem
          title={intl.formatMessage({id: 'KIDLING.MENU.INBOX'}) + ` (${messagesCounts})`}
          to={company ? `/${company}/messages` : '/kidling/messages'}
          hasBullet={false}
          fontIcon='fa-solid fa-message'
        ></MenuItem>
      )} */}

      {totalKids > 0 &&
        mobileConfiguration?.KidFood &&
        mobileConfiguration?.KidFood[0]?.showInParentApp && (
          <MenuItem
            title={intl.formatMessage({id: 'KIDLING.MENU.FOOD'})?.toLocaleUpperCase()}
            to={company ? `/${company}/meals` : '/kidling/meals'}
            hasBullet={false}
            fontIcon='fa-solid fa-utensils'
          ></MenuItem>
        )}

      {mobileConfiguration?.RequestKid &&
        mobileConfiguration?.RequestKid[0]?.showInParentApp &&
        totalKids > 0 && (
          <MenuItem
            title={intl.formatMessage({id: 'KIDLING.MENU.REQUESTS'})?.toLocaleUpperCase()}
            to={company ? `/${company}/requests` : '/kidling/requests'}
            hasBullet={false}
            fontIcon='fa-solid fa-people-arrows'
          ></MenuItem>
        )}

      {mobileConfiguration?.KidMealTime &&
        mobileConfiguration?.KidMealTime[0]?.showInParentApp &&
        totalKids > 0 && (
          <MenuItem
            title={intl.formatMessage({id: 'KIDLING.MENU.EATING_HABIT'})?.toLocaleUpperCase()}
            to={company ? `/${company}/eating-habit` : '/kidling/eating-habit'}
            hasBullet={false}
            fontIcon='fa-solid fa-bars'
            // iconColor={menuColors[9]}
          ></MenuItem>
        )}

      {mobileConfiguration?.KidProtocolRemark &&
        mobileConfiguration?.KidProtocolRemark[0]?.showInParentApp &&
        totalKids > 0 && (
          <MenuItem
            title={intl.formatMessage({id: 'KIDLING.MENU.PROTOCOLS'})?.toLocaleUpperCase()}
            to={company ? `/${company}/protocols` : '/kidling/protocols'}
            hasBullet={false}
            fontIcon='fa-solid fa-handshake-simple'
          ></MenuItem>
        )}

      {mobileConfiguration?.KidDrink &&
        mobileConfiguration?.KidDrink[0]?.showInParentApp &&
        totalKids > 0 && (
          <MenuItem
            title={intl.formatMessage({id: 'KIDLING.MENU.DRINKS'})?.toLocaleUpperCase()}
            to={company ? `/${company}/drinks` : '/kidling/drinks'}
            hasBullet={false}
            fontIcon='fa-solid fa-mug-hot'
            //  iconColor={menuColors[12]}
          ></MenuItem>
        )}

      {mobileConfiguration?.KidSleepTime &&
        mobileConfiguration?.KidSleepTime[0]?.showInParentApp &&
        totalKids > 0 && (
          <MenuItem
            title={intl.formatMessage({id: 'KIDLING.MENU.SLEEPS'})?.toLocaleUpperCase()}
            to={company ? `/${company}/sleeptimes` : '/kidling/sleeptimes'}
            hasBullet={false}
            fontIcon='fa-solid fa-bed'
          ></MenuItem>
        )}

      {mobileConfiguration?.KidMood &&
        mobileConfiguration?.KidMood[0]?.showInParentApp &&
        totalKids > 0 && (
          <MenuItem
            title={intl.formatMessage({id: 'KIDLING.HOME.MOODS'})?.toLocaleUpperCase()}
            to={company ? `/${company}/mood` : '/kidling/mood'}
            hasBullet={false}
            fontIcon='fa-solid fa-face-smile'
            //  iconColor={menuColors[14]}
          ></MenuItem>
        )}

      {mobileConfiguration?.KidWindingProtocol &&
        mobileConfiguration?.KidWindingProtocol[0]?.showInParentApp &&
        totalKids > 0 && (
          <MenuItem
            title={intl.formatMessage({id: 'KIDLING.HOME.DIAPERS'})?.toLocaleUpperCase()}
            to={company ? `/${company}/diapers` : '/kidling/diapers'}
            hasBullet={false}
            fontIcon='fa-solid fa-baby'
          ></MenuItem>
        )}
      {mobileConfiguration?.KidAssessment &&
        mobileConfiguration?.KidAssessment[0]?.showInParentApp &&
        totalKids > 0 && (
          <MenuItem
            title={intl.formatMessage({id: 'KIDLING.HOME.ASSESSMENT'})?.toLocaleUpperCase()}
            to={company ? `/${company}/assessments` : '/kidling/assessments'}
            hasBullet={false}
            fontIcon='fa-solid fa-people-arrows'
          ></MenuItem>
        )}

      {/* <MenuItem
        title={intl.formatMessage({id: 'KIDLING.MENU.PROFILE'})}
        to={company ? `/${company}/profile` : '/kidling/profile'}
        hasBullet={false}
        fontIcon='fa-solid fa-person'
      ></MenuItem> */}
      {totalKids > 0 && currentUser?.username !== 'apptest@kidling.email' ? (
        <MenuItem
          title={intl.formatMessage({id: 'KIDLING.MENU.KID.PROFILE'})?.toLocaleUpperCase()}
          to={company ? `/${company}/kidprofile` : '/kidling/profile'}
          hasBullet={false}
          fontIcon='fa-solid fa-children'
        ></MenuItem>
      ) : null}
      {/* <MenuItem
        title={intl.formatMessage({id: 'KIDLING.COMPANY_INFO'})}
        to={company ? `/${company}/companyInformation` : '/kidling/companyInformation'}
        hasBullet={false}
        fontIcon='fa-solid fa-school'
      ></MenuItem> */}

      {currentUser?.username !== 'apptest@kidling.email' && (
        <div className={' mt-auto border-top'}>
          <MenuItem
            title={intl.formatMessage({id: 'KIDLING.NEED_HELP'})?.toLocaleUpperCase()}
            to={'https://quintic.atlassian.net/servicedesk/customer/portal/5'}
            hasBullet={false}
            fontIcon='fa-solid fa-circle-question fa-2x'
            external
          ></MenuItem>
        </div>
      )}
    </>
  )
}
