/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {useIntl} from 'react-intl'
import {useAuth} from '../../../../../views/auth'
import {updateContactInfo} from '../../../../../../controller/users'
import {toast} from 'react-toastify'
import {useAppContext} from '../../../../../../_metronic/layout/core/AppContextProvider'
import ResponsiveDatePicker from '../../../../../general-components/responsive-material-datepicker/ResponsiveDatePicker'
import LanguageSelect from '../../../../../../_metronic/partials/layout/language-select'
import '../../../styles.scss'
const ProfileDetails: React.FC = () => {
  const {currentUser, setCurrentUser} = useAuth()
  const account = currentUser?.contact
  const {salutations, genders, languages} = useAppContext()
  const intl = useIntl()
  const [currentLang, setCurrentLang] = useState<any>({})
  const [personLanguageSecondary, setPersonLanguageSecondary] = useState<any>({})
  const [salution, setSalution] = useState<any>(null)
  const [gender, setGender] = useState<any>(null)
  const [initialValues] = useState<any>({
    contactId: account?.contactId,
    contactName: account?.contactName,
    firstName: account?.firstName,
    lastName: account?.lastName,
    dateOfBirth: account?.dateOfBirth ? new Date(account.dateOfBirth) : '',
    cellPhoneNumber: account?.cellPhoneNumber,
    emailAddress: account?.emailAddress,
    profession: account?.profession,
    personLanguageId: account?.personLanguageId,
    personLanguageSecondaryId: account?.personLanguageSecondaryId,
    taxNumber: account?.taxNumber,
  })

  const profileDetailsSchema = Yup.object().shape({
    salutationId: Yup.string().nullable(),
    firstName: Yup.string().required(intl.formatMessage({id: 'KIDDLING.REQUIRED'})),
    lastName: Yup.string().required(intl.formatMessage({id: 'KIDDLING.REQUIRED'})),
    dateOfBirth: Yup.string().nullable(),
    cellPhoneNumber: Yup.string().nullable(),
    emailAddress: Yup.string().email(intl.formatMessage({id: 'KIDDLING.INVALID_EMAIL'})),
  })

  useEffect(() => {}, [account])

  useEffect(() => {
    if (account) {
      salutations?.forEach((s: any) => {
        if (s.salutationId === account?.salutationId) setSalution(s.salutationId)
      })
      genders?.forEach((s: any) => {
        if (s.genderId === account?.genderId) setGender(s.genderId)
      })
      setPersonLanguageSecondary(account?.personLanguageSecondaryId)
      setCurrentLang(account?.personLanguageId)
    }
  }, [account, currentUser, languages, genders, salutations])

  const formik: any = useFormik({
    initialValues: initialValues,
    validationSchema: profileDetailsSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      try {
        const data = {
          ...account,
          contactId: values.contactId,
          contactName: values.firstName + ' ' + values.lastName,
          salutationId: salution,
          firstName: values.firstName,
          lastName: values.lastName,
          genderId: gender,
          dateOfBirth: values?.dateOfBirth,
          countryId: values?.countryId,
          cellPhoneNumber: values?.cellPhoneNumber,
          emailAddress: values?.emailAddress,
          profession: values?.profession,
          personLanguageId: currentLang,
          personLanguageSecondaryId: personLanguageSecondary,
          taxNumber: values?.taxNumber,
        }

        const resp = await updateContactInfo(data)
        if (resp) {
          toast.success(intl.formatMessage({id: 'COMMON.SUCCESS'}))
          let temp = currentUser
          if (temp) {
            temp.contact = resp
            setCurrentUser(temp)
          }
        } else {
          toast.error(intl.formatMessage({id: 'COMMON.FAIL'}))
        }
      } catch (ex) {
        console.error(ex)
      } finally {
        setSubmitting(false)
      }
    },
  })

  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#kt_account_profile_details'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bold m-0'>{intl.formatMessage({id: 'PARENT.PROFILEDETAILS'})}</h3>
        </div>
      </div>

      <div id='kt_account_profile_details' className='collapse show'>
        <form onSubmit={formik.handleSubmit} noValidate className='form'>
          <div className='card-body border-top p-9'>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-semibold fs-6'>
                {intl.formatMessage({id: 'PARENT.SALUTATION'})}
              </label>

              <div className='col-lg-8 fv-row'>
                <select
                  className='form-select form-select-solid form-select-lg'
                  {...formik.getFieldProps('salutationId')}
                  disabled={formik.isSubmitting}
                  onChange={(e) => {
                    setSalution(e.target.value)
                  }}
                >
                  <option value=''>{intl.formatMessage({id: 'COMMON.SELECT'})}..</option>
                  {salutations?.map((s: any) => (
                    <option value={s.salutationId} selected={s.salutationId === salution}>
                      {s.salutationTitle}
                    </option>
                  ))}
                </select>
                {formik.touched.salutationId && formik.errors.salutationId && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.salutationId}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-semibold fs-6'>
                <span className='required'>{intl.formatMessage({id: 'PARENT.FIRSTNAME'})}</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  name='firstName'
                  className='form-control form-control-lg form-control-solid'
                  placeholder={intl.formatMessage({id: 'PARENT.FIRSTNAME'})}
                  {...formik.getFieldProps('firstName')}
                  onChange={(e) => {
                    formik.setFieldValue('firstName', e.target.value)
                    formik.setFieldValue(
                      'contactName',
                      e.target.value + ' ' + formik.getFieldProps('lastName').value
                    )
                  }}
                />
                {formik.touched.firstName && formik.errors.firstName && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.firstName}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-semibold fs-6'>
                <span className='required'>{intl.formatMessage({id: 'PARENT.LASTNAME'})}</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  {...formik.getFieldProps('lastName')}
                  type='text'
                  name='lastName'
                  className='form-control form-control-lg form-control-solid'
                  placeholder={intl.formatMessage({id: 'PARENT.LASTNAME'})}
                  onChange={(e) => {
                    formik.setFieldValue('lastName', e.target.value)
                    formik.setFieldValue(
                      'contactName',
                      formik.getFieldProps('firstName').value + ' ' + e.target.value
                    )
                  }}
                />
                {formik.touched.lastName && formik.errors.lastName && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.lastName}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-semibold fs-6'>
                <span className='required'>{intl.formatMessage({id: 'PARENT.CONTACTNAME'})}</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  {...formik.getFieldProps('contactName')}
                  type='text'
                  disabled={true}
                  name='contactName'
                  className='form-control form-control-lg form-control-solid'
                  placeholder={intl.formatMessage({id: 'PARENT.CONTACTNAME'})}
                />
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-semibold fs-6'>
                <span className='required'>{intl.formatMessage({id: 'PARENT.GENDER'})}</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <select
                  {...formik.getFieldProps('genderId')}
                  name='genderId'
                  className='form-select form-select-solid form-select-lg'
                  onChange={(e) => {
                    setGender(e.target.value)
                  }}
                >
                  <option value=''>{intl.formatMessage({id: 'COMMON.SELECT'})}..</option>
                  {genders?.map((s: any) => (
                    <option value={s.genderId} selected={s.genderId === gender}>
                      {s.genderName}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-semibold fs-6'>
                <span className='required'>{intl.formatMessage({id: 'PARENT.DOB'})}</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <ResponsiveDatePicker
                  onAccept={(val: any) => {
                    formik.setFieldValue('dateOfBirth', val)
                  }}
                  value={formik.values.dateOfBirth}
                />

                {formik.touched.dateOfBirth && formik.errors.dateOfBirth && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.dateOfBirth}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-semibold fs-6'>
                <span className='required'>{intl.formatMessage({id: 'PARENT.PHONE'})}</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='tel'
                  className='form-control form-control-lg form-control-solid'
                  placeholder={intl.formatMessage({id: 'PARENT.PHONE'})}
                  {...formik.getFieldProps('cellPhoneNumber')}
                />
                {formik.touched.cellPhoneNumber && formik.errors.cellPhoneNumber && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.cellPhoneNumber}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-semibold fs-6'>
                <span className='required'>{intl.formatMessage({id: 'PARENT.EMAILADDRESS'})}</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='email'
                  className='form-control form-control-lg form-control-solid'
                  placeholder={intl.formatMessage({id: 'PARENT.EMAIL'})}
                  {...formik.getFieldProps('emailAddress')}
                  disabled
                />
                {formik.touched.emailAddress && formik.errors.emailAddress && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.emailAddress}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-semibold fs-6'>
                {intl.formatMessage({id: 'PARENT.Profession'})}{' '}
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  className='form-control form-control-lg form-control-solid'
                  placeholder={intl.formatMessage({id: 'PARENT.Profession'})}
                  {...formik.getFieldProps('profession')}
                />
                {formik.touched.profession && formik.errors.profession && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.profession}</div>
                  </div>
                )}
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label  fw-semibold fs-6'>
                {intl.formatMessage({id: 'KIDLING.LANGUAGE'})}
              </label>
              <div className='col-lg-8  ps-1 pe-5 '>
                <LanguageSelect
                  setCurrentLang={setCurrentLang}
                  value={languages?.find(
                    (x: any, index: number) =>
                      index + 1 === (currentLang ?? currentUser?.languageId)
                  )}
                />
                {formik.touched.language && formik.errors.language && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span className='alert-danger'>{formik.errors.language}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label  fw-semibold fs-6'>
                {intl.formatMessage({id: 'PARENT.PersonLanguageSecondary'})}
              </label>

              <div className='col-lg-8  ps-1 pe-5 '>
                <LanguageSelect
                  setCurrentLang={setPersonLanguageSecondary}
                  value={languages?.find(
                    (x: any, index: number) =>
                      index + 1 === (personLanguageSecondary ?? currentUser?.languageId)
                  )}
                />
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-semibold fs-6'>
                {intl.formatMessage({id: 'PARENT.TaxNumber'})}
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder={intl.formatMessage({id: 'PARENT.TaxNumber'})}
                  {...formik.getFieldProps('taxNumber')}
                />
              </div>
            </div>
          </div>

          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button
              type='submit'
              className='btn btn-primary'
              data-kt-users-modal-action='submit'
              disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
            >
              {!formik.isSubmitting && intl.formatMessage({id: 'AUTH.GENERAL.SUBMIT_BUTTON'})}
              {formik.isSubmitting && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  {intl.formatMessage({id: 'KIDLING.HOME.PLEASE_WAIT'})}...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export {ProfileDetails}
