import {FC, ReactElement, useState} from 'react'
import {Button} from 'react-bootstrap'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import clsx from 'clsx'
import {useIntl} from 'react-intl'
import {toast} from 'react-toastify'
import {changePassword} from '../../../../../controller/users'

const ChangePassword: FC = (): ReactElement => {
  const intl = useIntl()

  const [initialValues] = useState<any>({
    newPassword: '',
    password: '',
    confirmPassword: '',
  })

  const Schema = Yup.object().shape({
    password: Yup.string().required(intl.formatMessage({id: 'KIDLING.REQUIRED'})),
    newPassword: Yup.string()
      .required(intl.formatMessage({id: 'KIDLING.REQUIRED'}))
      .min(8, intl.formatMessage({id: 'KIDLING.SHORT_PASSWORD'}))
      .test(
        'isValidPass',
        intl.formatMessage({id: 'KIDLING.NOT_COMPLEX_PASSWORD'}),
        (value: any, context: any) => {
          const hasUpperCase = /[A-Z]/.test(value)
          const hasNumber = /[0-9]/.test(value)
          const hasLowerCase = /[a-z]/.test(value)
          const hasSymbole = /["!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~"]/.test(value)
          let validConditions = 0
          const numberOfMustBeValidConditions = 3
          const conditions = [hasUpperCase, hasLowerCase, hasNumber, hasSymbole]
          conditions.forEach((condition) => (condition ? validConditions++ : null))
          if (validConditions >= numberOfMustBeValidConditions) {
            return true
          }
          return false
        }
      ),
    confirmPassword: Yup.string()
      .required(intl.formatMessage({id: 'KIDLING.REQUIRED'}))
      .oneOf(
        [Yup.ref('newPassword'), null],
        intl.formatMessage({id: 'KIDLING.PASSWORDS_DONT_MATCH'})
      ),
  })
  const formik: any = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: Schema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      try {
        const data = {
          oldPassword: values.password,
          newPassword: values.newPassword,
        }

        const resp: any = await changePassword(data)

        if (resp?.status === 200) {
          toast.success(intl.formatMessage({id: 'COMMON.SUCCESS'}))
          formik.resetForm()
        } else {
          toast.error(intl.formatMessage({id: 'COMMON.FAIL'}))
        }
      } catch (ex) {
        console.error(ex)
      } finally {
        setSubmitting(false)
      }
    },
  })

  return (
    <div className='card mb-5 mb-xl-10'>
      <form
        id='kt_modal_add_user_form'
        className='form container p-8 d-flex flex-column align-items-end'
        onSubmit={formik.handleSubmit}
        noValidate
      >
        <div className='row align-items-center w-100'>
          <div className=' col-12 my-3'>
            <label className='required form-label fs-6 fw-semibold text-muted'>
              {intl.formatMessage({id: 'KIDLING.OLD_PASSWORD'})}
            </label>
            <input
              type='password'
              {...formik.getFieldProps('password')}
              className={clsx(
                'form-control  mb-3 mb-lg-0',
                {'is-invalid': formik.touched.password && formik.errors.password},
                {
                  'is-valid': formik.touched.password && !formik.errors.password,
                }
              )}
              name='password'
              autoComplete='off'
              value={formik.values.password}
            />
            {formik.touched.password && formik.errors.password && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span className='alert-danger'>{formik.errors.password}</span>
                </div>
              </div>
            )}
          </div>
          <div className=' col-12 my-3'>
            <label className='required form-label fs-6 fw-semibold text-muted'>
              {intl.formatMessage({id: 'KIDLING.NEW_PASSWORD'})}
            </label>
            <input
              type='password'
              {...formik.getFieldProps('newPassword')}
              className={clsx(
                'form-control  mb-3 mb-lg-0',
                {'is-invalid': formik.touched.newPassword && formik.errors.newPassword},
                {
                  'is-valid': formik.touched.newPassword && !formik.errors.newPassword,
                }
              )}
              name='newPassword'
              autoComplete='off'
              value={formik.values.newPassword}
            />
            {formik.errors.newPassword && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span className='alert-danger'>{formik.errors.newPassword}</span>
                </div>
              </div>
            )}
          </div>
          <div className=' col-12 my-3'>
            <label className='required form-label fs-6 fw-semibold text-muted'>
              {intl.formatMessage({id: 'KIDLING.CONFIRM_PASSWORD'})}
            </label>
            <input
              type='password'
              {...formik.getFieldProps('confirmPassword')}
              className={clsx(
                'form-control  mb-3 mb-lg-0',
                {'is-invalid': formik.touched.confirmPassword && formik.errors.confirmPassword},
                {
                  'is-valid': formik.touched.confirmPassword && !formik.errors.confirmPassword,
                }
              )}
              name='confirmPassword'
              autoComplete='off'
              value={formik.values.confirmPassword}
            />
            {formik.errors.confirmPassword && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span className='alert-danger'>{formik.errors.confirmPassword}</span>
                </div>
              </div>
            )}
          </div>
        </div>

        <Button color='primary' type='submit' disabled={formik.isSubmitting} className=' '>
          {!formik.isSubmitting &&
            intl.formatMessage({
              id: 'AUTH.GENERAL.SUBMIT_BUTTON',
            })}
          {formik.isSubmitting && (
            <span className='indicator-progress' style={{display: 'block'}}>
              {intl.formatMessage({id: 'KIDLING.HOME.PLEASE_WAIT'})}...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </Button>
      </form>
    </div>
  )
}

export default ChangePassword
