/* eslint-disable react-hooks/exhaustive-deps */
import {FC, useEffect, useState} from 'react'
import {Modal, ModalHeader, ModalBody} from 'reactstrap'
import {useIntl} from 'react-intl'
import {useAuth} from '../../auth'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import Select from 'react-select'
import {useDropzone} from 'react-dropzone'
import SweetAlert from 'react-bootstrap-sweetalert'
import {toast} from 'react-toastify'
import clsx from 'clsx'
import {UsersListLoading} from '../../../general-components/loading/UsersListLoading'
import {getBase64} from '../../../utils/getBase64'
import {BulkMedia} from '../../../../controller/bulk-actions'
import AvatarWithLetters from '../../../general-components/avatar-with-letters'

export const thumbsContainer: any = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 16,
}

export const thumb: any = {
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: 'border-box',
}

export const thumbInner: any = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden',
}

interface ModalProps {
  setShowAttachModal: (val: boolean) => void
  showModal: boolean
  readKidsFiles: any
  imagesOnly?: boolean
}
const AttachModal: FC<ModalProps> = ({
  showModal,
  setShowAttachModal,
  imagesOnly,
  readKidsFiles,
}) => {
  const intl = useIntl()
  const [files, setFiles] = useState<any>([])
  const [selectedKidsList, setSelectedKidsList] = useState([])
  const [allKids, setAllKids] = useState([])
  const [base64Files, setBase64Files] = useState([])
  const {currentUser} = useAuth()
  const [showError, setShowError] = useState(false)
  useEffect(() => {
    async function convertToBase64() {
      let temp: any = []
      files?.map(async (singleFile: any) => {
        getBase64(singleFile, (result: string) => {
          temp.push({
            filePath: result,
            fileName: singleFile.name,
            description: singleFile.name,
          })
        })
      })
      setBase64Files(temp)
    }
    convertToBase64()
  }, [files])

  useEffect(() => {
    let temp: any = []
    currentUser?.kids?.map(
      (item: any) =>
        item.kidName &&
        temp.push({
          label: item.kidName,
          value: item.kidId,
          image: item.image,
        })
    )
    setAllKids(temp)
  }, [currentUser])

  const kidSchema = Yup.object().shape({
    description: Yup.string(),
  })
  const {getRootProps, getInputProps} = useDropzone({
    validator: validateFileType,
    accept: {
      '*/*': [],
    },
    onDrop: (acceptedFiles: any) => {
      setFiles([
        ...files,
        ...acceptedFiles?.map((file: any) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        ),
      ])
    },
  })
  function validateFileType(file: any) {
    // if (imagesOnly) {
    //   if (!file.type.startsWith('image/')) {
    //     setShowError(true)
    //     return {
    //       code: 'Invalid-File-Type',
    //       message: `Invalid File type`,
    //     }
    //   }
    // }
    // else if (file.type.startsWith('image/')) {
    //   setShowError(true)
    //   return {
    //     code: 'Invalid-File-Type',
    //     message: `Invalid File type`,
    //   }
    // }

    return null
  }
  const removeFile = (file: any) => {
    const temp = files.filter((item: any) => item !== file)
    setFiles(temp)
  }
  const thumbs = files?.map((file: any) => (
    <div style={thumb} key={file.name} className='position-relative image__preview'>
      <div style={thumbInner}>
        <img
          src={
            file.type === 'image/jpeg' ||
            file.type === 'image/png' ||
            file.type === 'image/jpg' ||
            file.type === 'image/webp'
              ? file.preview
              : file.type === 'application/pdf'
              ? '/media/svg/files/pdf.svg'
              : file.type ===
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
              ? '/media/svg/files/doc.svg'
              : '/media/svg/files/upload.svg'
          }
          className='image-input image-input-outline image-input-wrapper w-100 h-100 contain'
          // Revoke data uri after image is loaded
          onLoad={() => {
            URL.revokeObjectURL(file.preview)
          }}
          alt={file.name}
        />
      </div>

      <span
        className='btn btn-icon position-absolute p-0 end-0 btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
        data-kt-image-input-action='cancel'
        data-bs-toggle='tooltip'
        title='Remove'
        onClick={() => removeFile(file)}
      >
        <i className='fa-solid fa-xmark'>
          <span className='path1'></span>
          <span className='path2'></span>
        </i>
      </span>
    </div>
  ))

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => files?.forEach((file: any) => URL.revokeObjectURL(file.preview))
  }, [])

  const [userForEdit] = useState({
    description: '',
  })

  const cancel = () => {
    handleClose()
  }

  const formik: any = useFormik({
    initialValues: userForEdit,
    validationSchema: kidSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      try {
        let kidIdList: number[] = []
        selectedKidsList?.map((kid: any) => kidIdList.push(kid.value))
        let attachmentList: any = []
        base64Files?.map((file: any) =>
          attachmentList.push({...file, description: values.description, shareWithParents: true})
        )
        if (kidIdList.length > 0 && attachmentList.length > 0) {
          const data = {
            kidIdList,
            attachmentList,
            createdDate: new Date(),
            usersId: currentUser?.usersId,
          }

          const resp = await BulkMedia(data)
          if (!!resp) {
            await readKidsFiles()
            toast.success(intl.formatMessage({id: 'COMMON.SUCCESS'}))
            cancel()
          } else {
            toast.error(intl.formatMessage({id: 'COMMON.FAIL'}))
          }
        }
      } catch (ex) {
        console.error(ex)
      } finally {
        setSubmitting(false)
      }
    },
  })

  const handleClose = () => {
    setShowAttachModal(false)
    setFiles([])
    setSelectedKidsList([])
    formik.resetForm()
  }
  return (
    <Modal isOpen={showModal} toggle={handleClose} centered zIndex={1000} onClosed={handleClose}>
      <ModalHeader toggle={handleClose}>
        {intl.formatMessage({id: 'KIDLING.HOME.ATTACH'})}
      </ModalHeader>
      <ModalBody>
        <>
          <form
            id='kt_modal_add_user_form'
            className='form '
            onSubmit={formik.handleSubmit}
            noValidate
          >
            {/* begin::Scroll */}
            <div className='fv-row mb-10'>
              <label className='  form-label fs-6 fw-semibold text-muted'>
                {intl.formatMessage({id: 'KIDLING.HOME.TAGGED_KIDS'})}
              </label>
              <Select
                value={selectedKidsList}
                onChange={(val: any) => setSelectedKidsList(val)}
                isMulti
                name='kids'
                options={allKids}
                className='basic-multi-select'
                classNamePrefix='select'
                isLoading={allKids.length === 0}
                formatOptionLabel={(item: any) => (
                  <div className='d-flex align-items-center '>
                    <AvatarWithLetters
                      image={item.image}
                      alt={item.label.charAt(0).toUpperCase()}
                      verysmall
                      rounded
                    />
                    <div className='d-flex flex-column ms-2'>
                      <p className='p-0 m-0'>{item.label}</p>
                    </div>
                  </div>
                )}
              />
            </div>
            <div className='fv-row mb-7'>
              <label className='  form-label fs-6 fw-semibold text-muted'>
                {' '}
                {intl.formatMessage({id: 'KIDLING.HOME.DESCRIPTION'})}
              </label>
              <textarea
                {...formik.getFieldProps('description')}
                className={clsx(
                  'form-control  mb-3 mb-lg-0',
                  {'is-invalid': formik.touched.description && formik.errors.description},
                  {
                    'is-valid': formik.touched.description && !formik.errors.description,
                  }
                )}
                name='description'
                autoComplete='off'
                disabled={formik.isSubmitting}
                rows={1}
              />

              {formik.touched.description && formik.errors.description && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span className='alert-danger'>{formik.errors.description}</span>
                  </div>
                </div>
              )}
            </div>
            <section className='container mt-7'>
              <div {...getRootProps({className: 'dropzone'})}>
                <input {...getInputProps()} />
                <p>
                  {intl.formatMessage({
                    id: 'KIDLING.HOME.DRAG_AND_DROP_SOME_FILES_HERE_OR_CLICK_TO_SELECT_FILES',
                  })}
                </p>
              </div>
              <aside style={thumbsContainer}>{thumbs}</aside>
            </section>
          </form>

          {/* begin::Actions */}
          <div className='text-center pt-15'>
            <button
              type='reset'
              onClick={() => cancel()}
              className='btn btn-light me-3'
              data-kt-users-modal-action='cancel'
              disabled={formik.isSubmitting}
            >
              {intl.formatMessage({id: 'KIDLING.HOME.DISCARD'})}{' '}
            </button>

            <button
              type='submit'
              className='btn btn-primary'
              data-kt-users-modal-action='submit'
              disabled={
                formik.isSubmitting || !formik.isValid || !formik.touched || files.length === 0
              }
              onClick={formik.handleSubmit}
            >
              <span className='indicator-label'>
                {intl.formatMessage({id: 'AUTH.GENERAL.SUBMIT_BUTTON'})}
              </span>
              {formik.isSubmitting && (
                <span className='indicator-progress'>
                  {intl.formatMessage({id: 'KIDLING.HOME.PLEASE_WAIT'})}...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
          {/* end::Actions */}
          {formik.isSubmitting && <UsersListLoading />}
        </>
      </ModalBody>
      <SweetAlert
        show={showError}
        error
        confirmBtnText={intl.formatMessage({id: 'COMMON.YES'})}
        title={intl.formatMessage({id: 'KIDLING.INVALID_FILE_TYPE'})}
        focusCancelBtn
        onConfirm={() => setShowError(false)}
      />
    </Modal>
  )
}

export {AttachModal}
