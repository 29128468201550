/* eslint-disable react-hooks/exhaustive-deps */
import {Outlet, Route, Routes, useNavigate} from 'react-router-dom'
import {useEffect} from 'react'
import Assessments from './components/Assessments'
import {useAppContext} from '../../../_metronic/layout/core/AppContextProvider'
import {KTCard} from '../../../_metronic/helpers'

const AssessmentsPage = () => {
  const {mobileConfiguration} = useAppContext()
  const navigate = useNavigate()
  useEffect(() => {
    if (!mobileConfiguration?.KidAssessment[0]?.showInEducatorApp) {
      navigate('/')
    }
  }, [mobileConfiguration])
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='*'
          element={
            <>
              <KTCard>
                <Assessments />
              </KTCard>
            </>
          }
        />
      </Route>
    </Routes>
  )
}

export default AssessmentsPage
