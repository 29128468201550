import {AttendanceHeader} from './components/header/AttendanceHeader'
import {KidsTable} from './table/KidsTable'
import {KTCard} from '../../../../_metronic/helpers'

const AttendanceList = () => {
  return (
    <>
      <KTCard>
        <AttendanceHeader />
        <KidsTable />
      </KTCard>
    </>
  )
}

const AttendanceListWrapper = () => <AttendanceList />

export {AttendanceListWrapper}
