/* eslint-disable react-hooks/exhaustive-deps */
import {FC, ReactElement, useEffect, useState} from 'react'
import {useAppContext} from '../../../../_metronic/layout/core/AppContextProvider'
import {useAuth} from '../../auth'
import {Avatar} from '@mui/material'

interface Props {
  setFilter: any
}
const CompanyFilter: FC<Props> = ({setFilter}): ReactElement => {
  const {currentUser} = useAuth()
  const {translations} = useAppContext()
  const [filters, setFilters] = useState([])
  const [selectedFilter, setSelectedFilter] = useState<any>(null)

  useEffect(() => {
    let temp: any = []
    currentUser?.kids?.map(
      (item: any, index: number) =>
        item.kidName &&
        temp.push({
          id: item.kidId,
          companyId: item.companyId,
          type: item.firstName,
          icon: 'fa-user',
          filter: `kidIds=${item.kidId}`,
          imagePath: item.imagePath,
        })
    )
    setFilters(temp)
    setSelectedFilter(temp[0])
  }, [currentUser, translations])

  useEffect(() => {
    setFilter(selectedFilter)
  }, [selectedFilter])

  return (
    <div className='w-100 d-flex align-items-center justify-content-center flex-wrap'>
      {filters?.map((item: any, index: number) => (
        <div
          className={`btn btn-outline  btn-outline-dashed btn-active-light-primary  text-start pt-8 px-12 pb-2 m-2  d-flex align-items-center flex-column position-relative ${
            selectedFilter.id === item.id && 'active'
          } `}
          data-kt-button='true'
          key={index}
          onClick={() => {
            setSelectedFilter(item)
          }}
        >
          <Avatar src={item.imagePath} alt={`${item.type.charAt(0).toUpperCase()}   `} />
          <input
            className={`form-check-input  position-absolute top-0 start-0 m-2`}
            type='radio'
            name='childFilter'
            checked={selectedFilter.id === item.id}
          />

          <div className='form-check form-check-custom form-check-solid form-check-sm d-flex align-items-center   '>
            <div className='fs-4 fw-bold text-gray-800  '>{item.type}</div>
          </div>
        </div>
      ))}
    </div>
  )
}

export {CompanyFilter}
