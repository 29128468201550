import {FC} from 'react'
import {OverlayTrigger, Popover} from 'react-bootstrap'
import EventDetailsView from '../../../general-components/EventDetailsView'
import '../styles.scss'
const EventCell: FC = (eventInfo: any) => {
  const data = eventInfo.event._def.extendedProps

  return (
    <>
      <OverlayTrigger
        trigger={['focus', 'click']}
        placement='auto'
        rootClose
        overlay={
          <Popover id='popover-trigger-hover-focus' className='custom_popover_calendar'>
            <EventDetailsView data={data} />
          </Popover>
        }
      >
        <a
          tabIndex={0}
          role='button'
          data-toggle='popover'
          data-trigger='click'
          className=' btn   rounded   fs-9 d-flex align-items-center h-100 w-100 text-truncate p-0 '
          style={{
            backgroundColor: `${data.colorCode ?? 'bg-color'}`,
          }}
          href='#!'
        >
          <label className='p-1 w-100   text-center label   form-label fs-9 fw-semibold text-black'>
            {data.eventsName ?? eventInfo.event._def.title}
          </label>
        </a>
      </OverlayTrigger>
    </>
  )
}

export default EventCell
