import React, {FC, ReactElement} from 'react'
import {UncontrolledAccordion, AccordionBody, AccordionHeader, AccordionItem} from 'reactstrap'
import AvatarWithLetters from '../../../general-components/avatar-with-letters'
import {useThemeMode} from '../../../../_metronic/partials/layout/theme-mode/ThemeModeProvider'

interface Props {
  data: any
}
const AssessmentAccordions: FC<Props> = ({data}): ReactElement => {
  const {mode} = useThemeMode()
  return (
    <div>
      {data?.map((item: any, index: number) => (
        <UncontrolledAccordion toggle={() => {}}>
          <AccordionItem>
            <AccordionHeader targetId={`${index}`}>
              <div className='d-flex align-items-center'>
                <AvatarWithLetters
                  image={item.image}
                  alt={item.name.charAt(0).toUpperCase()}
                  verysmall
                />
                <div className='m-0 ms-3 '>
                  <h5 className='m-0'>{item.name}</h5>
                  <p className='m-0 text-muted'>
                    {item?.classes?.map(
                      (cc: any, index: number) =>
                        `${cc} ${item.classes.length - 1 > index ? ',' : ' '} `
                    )}
                  </p>
                </div>
              </div>
            </AccordionHeader>
            <AccordionBody accordionId={`${index}`}>
              {item.subItems?.map((subItem: any, index1: number) => {
                return (
                  <div className=' border border-1 p-5'>
                    <div className='d-flex align-items-center'>
                      <p className='m-0 p-0 text-info'>{subItem.assessmentName}</p>
                      <h5 className='m-0 mx-2 p-0'>/</h5>
                      <p className='m-0 p-0 text-info'>{subItem.assessmentCategoryName}</p>
                      <h5 className='m-0 mx-2 p-0'>/</h5>
                      <p className='m-0 p-0 text-info'>{subItem.assessmentSubCategoryName}</p>
                    </div>
                    <div
                      className='d-flex align-items-center justify-content-between'
                      key={subItem.id}
                    >
                      <div className='d-flex flex-column'>
                        <p className='m-0 p-0 fw-bold'>{subItem.title}</p>
                        <p className='text-muted'>{subItem.desc}</p>
                      </div>
                      <div className='d-flex flex-column align-items-start'>
                        {/* <h5 className='m-0 p-0'>{subItem.tag}</h5> */}
                        <span
                          className={`badge ${mode === 'dark' && 'text-dark'}`}
                          style={{backgroundColor: 'var(--bs-primary)'}}
                        >
                          {subItem.tag}
                        </span>
                      </div>
                    </div>
                    <div className='w-100 d-flex justify-content-end'>
                      <p className='m-0   p-0 text-muted'>{subItem.date}</p>
                    </div>
                  </div>
                )
              })}
            </AccordionBody>
          </AccordionItem>
        </UncontrolledAccordion>
      ))}
    </div>
  )
}

export default AssessmentAccordions
