import {FC, useEffect, useState} from 'react'
import clsx from 'clsx'
import {HeaderUserMenu, ThemeModeSwitcher} from '../../../partials'
import {Link} from 'react-router-dom'
import {useAppContext} from '../../core/AppContextProvider'
import AvatarWithLetters from '../../../../app/general-components/avatar-with-letters'
import {useAuth} from '../../../../app/views/auth'
import {useThemeMode} from '../../../partials/layout/theme-mode/ThemeModeProvider'
import {useChatContext} from '../../../../store/context/ChatProvider'
import Menu from '@mui/material/Menu'
import {IconButton} from '@mui/material'
import {useMediaQuery} from 'react-responsive'
import RegisterKidDialog from '../../../../app/views/dashboard/RegisterKidDialog'
import './styles.scss'
import {readFileFrom} from '../../../../controller/image'
interface Props {
  countOfNewChats: number
}
const Topbar: FC<Props> = ({countOfNewChats}) => {
  const {
    currentUserImage,
    setCurrentUserImage,
    messagesCounts,
    mobileConfiguration,
    companyMetaData,
    tabMessagesCounts,
  } = useAppContext()
  const company = window.location.pathname.split('/')[1]
  const {currentUser} = useAuth()
  const {mode} = useThemeMode()
  const {allChats, chatCount} = useChatContext()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [modal, setModal] = useState(null)
  const isMobile = useMediaQuery({
    query: '(max-width:762px)',
  })

  useEffect(() => {
    getImage()
  }, [currentUser])

  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const getImage = async () => {
    const resp = await readFileFrom(currentUser?.imagePath ?? '')
    if (resp) setCurrentUserImage(resp)
  }
  return (
    <div className='d-flex align-items-stretch flex-shrink-0'>
      <div className='topbar d-flex align-items-center flex-shrink-0'>
        {/* Search */}
        {/* <div className={clsx('d-flex align-items-stretch', toolbarButtonMarginClass)}>
          <Search />
        </div> */}
        <button className='btn btn-icon  p-0 mx-sm-1' onClick={() => window.location.reload()}>
          <i
            className='fa-solid fa-arrows-rotate fs-3 '
            style={{
              color: mode !== 'dark' && companyMetaData.iconColor ? companyMetaData.iconColor : '',
            }}
          ></i>
        </button>
        {mobileConfiguration?.Messaging &&
          mobileConfiguration?.Messaging[0]?.showInParentApp &&
          !isMobile && (
            <Link
              to={company ? `/${company}/messages` : '/kidling/messages'}
              className='btn btn-icon btn-active-light-primary btn-custom position-relative p-0 mx-sm-1'
            >
              <i
                className='fas fa-envelope fs-3 '
                style={{
                  color:
                    mode !== 'dark' && companyMetaData.iconColor ? companyMetaData.iconColor : '',
                }}
              ></i>

              <span
                className='position-absolute   translate-middle badge rounded-pill bg-danger'
                style={{top: '0.8rem', right: '-1rem'}}
              >
                {tabMessagesCounts?.unread > 0 && tabMessagesCounts?.unread}
              </span>
            </Link>
          )}

        {mobileConfiguration?.ChatGroup &&
          mobileConfiguration?.ChatGroup[0]?.showInParentApp &&
          !isMobile && (
            <Link
              to={company ? `/${company}/chat` : '/kidling/chat'}
              className='btn btn-icon btn-active-light-primary btn-custom position-relative p-0 mx-sm-1'
            >
              <i
                className='fa-sharp fa-solid fa-comments fs-3 '
                style={{
                  color:
                    mode !== 'dark' && companyMetaData.iconColor ? companyMetaData.iconColor : '',
                }}
              ></i>

              <span
                className='position-absolute   translate-middle badge rounded-pill bg-danger'
                style={{top: '0.8rem', right: '-1rem'}}
              >
                {chatCount > 0 && chatCount}
              </span>
            </Link>
          )}

        {/* begin::Theme mode */}
        {/* <div className={clsx('d-flex align-items-center mx-sm-1')}> */}
        <ThemeModeSwitcher />
        {/* </div> */}

        {!isMobile && (
          <Link
            to={company ? `/${company}/companyInformation` : '/kidling/companyInformation'}
            className='btn btn-icon  position-relative p-0 mx-sm-1 '
          >
            <i
              className='fa-solid fa-school fs-3'
              style={{
                color:
                  mode !== 'dark' && companyMetaData.iconColor ? companyMetaData.iconColor : '',
              }}
            ></i>
          </Link>
        )}

        {/* end::Theme mode */}

        {/* begin::User */}
        <div className={clsx('d-flex align-items-center ')} id='kt_header_user_menu_toggle'>
          {/* begin::Toggle */}
          <IconButton
            onClick={handleClick}
            size='small'
            sx={{ml: 2}}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup='true'
            aria-expanded={open ? 'true' : undefined}
            className='p-4 m-0'
          >
            <AvatarWithLetters
              image={currentUserImage}
              alt={currentUser?.usersName?.charAt(0).toUpperCase() ?? ''}
              extrasmall
            />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            id='account-menu'
            open={open}
            onClose={handleClose}
            transformOrigin={{horizontal: 'right', vertical: 'top'}}
            anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
            className='py-0'
          >
            <HeaderUserMenu setModal={setModal} />
          </Menu>
          {/* <div
            className={clsx('cursor-pointer symbol', toolbarUserAvatarHeightClass)}
            data-kt-menu-trigger='click'
            data-kt-menu-attach='parent'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='bottom'
          >
            <AvatarWithLetters
              image={currentUserImage}
              alt={currentUser?.usersName?.charAt(0).toUpperCase() ?? ''}
              extrasmall
            />
          </div>
          <HeaderUserMenu /> */}
          {/* end::Toggle */}
        </div>
        {/* end::User */}
      </div>

      <RegisterKidDialog modal={modal} setModal={setModal} />
    </div>
  )
}

export {Topbar}
