/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useMemo} from 'react'
import {useIntl} from 'react-intl'
import _columns from '../_columns'
import {MealsHeader} from './header/MealsHeader'
import {useAppContext} from '../../../../_metronic/layout/core/AppContextProvider'
import TableCardsContainer from '../../../general-components/table-cards-container/TableCardsContainer'
import useEatingHabits from '../../../hooks/useEatingHabits'

function EatingHabits() {
  const {globalLoader, internalPagesDatesFilters} = useAppContext()
  const columns: any = useMemo(() => _columns(), [])
  const intl = useIntl()
  const {readMealTimes, data} = useEatingHabits()
  useEffect(() => {
    if (!globalLoader) readMealTimes()
  }, [internalPagesDatesFilters])
  return (
    <div className='container  '>
      <MealsHeader title={intl.formatMessage({id: 'KIDLING.MENU.EATING_HABIT'})} />
      <div className='mt-4'>
        <TableCardsContainer data={data} columns={columns} />
      </div>
    </div>
  )
}

export default EatingHabits
