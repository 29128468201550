import React, {FC} from 'react'
import ReactPlayer from 'react-player'

interface Props {
  id: string
}
const CustomVideo: FC<Props> = ({id}) => {
  // const [videoUrl, setVideoUrl] = useState('')

  // useEffect(() => {
  //   getImage()
  // }, [id])

  // const getImage = async () => {
  //   const resp = await readFileFrom(id)
  //   const typeArray = id.split('.')
  //   const type = typeArray[typeArray.length - 1]
  //   const blob = await decodeBase64({
  //     base64: resp,
  //     name: id,
  //     type: 'video/mp4',
  //   })

  //   const url = URL.createObjectURL(blob)

  //   setVideoUrl(resp)
  // }

  // useEffect(() => {
  //   // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
  //   return () => setVideoUrl('')
  // }, [])
  return (
    <div className='position-relative h-100 mh-200px'>
      <ReactPlayer
        url={id}
        width='100%'
        height='100%'
        controls={true}
        config={{
          file: {
            attributes: {
              crossOrigin: 'anonymous',
              forceVideo: true,
            },
          },
        }}
      />

      {id === '' && (
        <span
          className='spinner-border spinner-border-sm align-middle ms-2 position-absolute  '
          style={{top: '45%', left: '45%'}}
        ></span>
      )}
    </div>
  )
}

export default CustomVideo
