import {FC} from 'react'
import {DiapersSearchComponent} from './DiapersSearchComponent'
import {DiapersToolbar} from './DiapersToolbar'
import CustomDateRangePicker from '../../../../general-components/date-range-picker/DateRangePicker'
import useDiaper from '../../../../hooks/useDiaper'

interface Props {
  title?: string
}
const DiapersHeader: FC<Props> = ({title}) => {
  const {handleDateChange} = useDiaper()
  return (
    <div className=' border-0 pt-6 w-100'>
      <div className='d-flex align-items-center justify-content-between'>
        {title && <h4>{title}</h4>}
        <CustomDateRangePicker handleDateChange={handleDateChange} />
      </div>

      <div className='d-flex align-items-center w-100  w-md-auto'>
        <DiapersSearchComponent />
        <DiapersToolbar />
      </div>
    </div>
  )
}

export {DiapersHeader}
