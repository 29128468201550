const API_URL = process.env.REACT_APP_API_URL
export const LOGIN_ADMIN = `${API_URL}/authentication/loginApp`
export const LOGIN = `authentication/login`
export const LANGUAGE = `${API_URL}/language/listwhere/1=1`
export const LOGIN_PARENT = `authentication/contact/login`
export const TRANSLATIONS = `${API_URL}/mobile/GetTranslationByLanguageId`
export const CHANGE_PASSWORD = `mobile/ChangePassword`
export const SEND_OTP_BY_EMAIL = `${API_URL}/authentication/SendOTPMaster`
export const CHANGE_PASSWORD_BY_OTP = `${API_URL}/authentication/ChangePasswordOTPMaster`

///kids

export const ABSENT_REASONS = `kidabsentreason`
export const ACTIVITES = `activity`
export const MOOD = `mood`
export const EVENTS_WITH_RECURRENTS = `parent/GetEventDetails`
export const ROOMS = `room`
export const SLEEP_HABITS = `sleephabit`

export const MEAL_DEREGISTRATION = `mobile/GetKidsDeregistrations`
export const EVENTS_CATEGORY = `eventscategory`
export const REGISTER_KID = `parent/RegisterKid`

//Bulk

export const BULK_ABSENCE = `kidabsent/listByKidIds`

export const BULK_MEDIA = `mobile/AddKidMediaFile`
export const DEREGISTRATION = `kidmealdailyderegistration`

//Menu pages

export const KID_MEALS_PARENT = `parent/GetKidsMealTime`
export const KID_SLEEP_TIMES = `parent/GetKidsSleeps`
export const KID_DRINKS_LIST = `parent/GetKidsDrinks`
export const KID_PROTOCOLS_LIST = `parent/GetKidsProtocols`

export const KID_REQUESTS_LIST_PARENT = `parent/GetRequestsKid`

export const CLASSES_WITH_EXPRESSION = `mobile/GetClasses`
export const PARNET_KID_ACTIVITIES = `parent/GetKidsActivities`
export const KID_MOODS = `parent/GetKidsMoods`
//Meal
export const MEALTIME = `mealtime`

export const FOOD_MENU = `mobile/GetFoodMenuWithItemsByDate`

//location
export const ATTENDANCE_LOCATION = `attendancelocation`

//Dashboard
export const PARENT_DASHBOARD = `Dashboard`
export const PARENT_PINBOARD = `mobile/pinboard`
export const DASHBOARD_NOTIFICATIONS = `mobile/GetLastNotificationForKid`

// Get User Contacts
export const USER_CONTACTS = `contact/ContactParent`
export const CONTACT_ATTENDACE_UPDATE = `parent/UpdateContactAttending`
export const KID_ATTENDACE_UPDATE = `parent/UpdateKidAttending`

//translations
export const LABEL = `label`
export const TRANSLATION = `translation`

// read image
export const READFILE = `authentication/ReadFile`

//company

export const COMPANY_INFO = `mobile/CompanyInfo`
//messages
export const SEND_MESSAGE = `mobile/AddNotification`
export const GET_MESSAGES = `mobile/GetNotificationsByUsersId`
export const UPDATE_RECEIVED_MESSAGE = `mobile/UpdateReceivedNotifications`
export const UPDATE_SENT_MESSAGE = `mobile/UpdateSentNotifications`
export const GET_SUB_MESSAGES = `mobile/GetAllSubMessages`
export const GET_MESSAGE = `mobile/GetNotificationById`
export const NEW_MESSAGES_COUNT = `mobile/v2/GetUnreadMessagesCountByUserId/UsersId`
export const GET_MESSAGES_V2 = `mobile/v2/GetNotificationsForUser`
export const GET_ALL_MESSAGES_COUNTS = `mobile/GetNotificationsCountByUsersId/UsersId`
//update parent Image
export const UPDATEPARENTIMG = `mobile/UpdateUserImagePath`

//update kid Image
export const UPDATEKIDIMG = `mobile/UpdateKidImagePath`

//general apis
export const SALUTATION = `salutation`
export const BLOODTYPE = `bloodtype`
export const RELIGION = `religion`
export const GENDER = `gender`
export const COUNTRY = `country`
export const STATE = `state`
export const CITY = `city`
export const ADDRESSTYPE = `addresstype`

export const KIDCONTACT = `kidcontact`

export const CONTACT_RELATION_TYPE = `contactrelationtype`
export const NATIONALITY = `nationality`

//media
export const DELETE_KIDMEDIA = `KidMediaFile/ParentDelete`
export const KIDMEDIA_GALLARY = `mobile/kidmedia`

//medical

export const KID_MEDICAL_RECORDS = `mobile/GetKidMedicalRecords`

//white label
export const WHITE_LABEL = `${API_URL}/whitelabel`

//kitas
export const GET_KITAS_BY_CONTACT = `mobile/GetKitasByContactId`

//holiday
export const HOLIDAY = `mobile/holiday`

//dayOff
export const DAYOFF = `mobile/dayoff`

//mobileconfiguration
export const MOBILE_CONFIGURATION = `mobileconfiguration`

export const BULK_DOWNLOAD_MEDIA = `authentication/DownloadArrayFile`

//winding protocols

export const GET_ALL_WINDING_PROTOCOLS = `parent/GetWindingProtocols`

export const ALL_KID_ASSESSEMTS = `parent/GetKidAssessmentsByParentId`

export const KID_CONTACTS = `mobile/GetKidContactsParentApp`

//chat
export const GET_AL_CHATS = `chatgroup/getOwnChatsGroup`
export const GET_CHAT_DETAILS = `chatgroup/getDetailedChatGroup`
export const GET_CHAT_DETAILS_WITH_PAGINATION = `chatgroupmessage/getMessageByPagination`
export const UPLOAD_FILE_TO_GROUP = `chatgroupmessage/CreateFile`
export const GET_CHAT_FILE = `chatgroupmessage/GetFile`
export const ADD_NEW_CHAT = `chatGroup/CreateGroupParent`
export const CHAT_MESSAGE = `chatgroupmessage`
export const MARK_MESSAGES_READ = `chatgroupmessagestatus/MarkAsReadCollection`
export const MARK_SINGLE_READ_MESSAGE = `chatgroupmessagestatus/MarkAsReadSingle`
export const GET_CHAT_COUNT = `chatgroupmessage/getCountOfNewMessages`

///new endpoints
export const GET_ABSENCE_BY_KID = `mobile/GetAbsentByKidId`
export const UPDATE_ABSENCE_BY_KID = `mobile/UpdateAbsentByAbsentId`
export const DELETE_ABSENCE_BY_KID = `mobile/DeleteAbsentByAbsentId`
export const GET_ATTENDANCE_BY_KID = `mobile/GetKidAttendances/1=1`
export const ATTENDANCE_BY_KID = `mobile/kidattendance`
export const UPDATE_KID_INFO = `mobile/Kid`
export const GET_EMPLOYEES_BY_KITA = `mobile/EmployeeByCompanyId`
export const GET_EMPLOYEES_BY_USER = `mobile/EmployeeByUserId`
export const ADD_NEW_CONTACT = `mobile/KidContact`
export const GET_USER_DETAILS = `mobile/GetUserDetails`
