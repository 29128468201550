/* eslint-disable react-hooks/exhaustive-deps */
import {useMemo} from 'react'
import {KTCardBody} from '../../../../../_metronic/helpers'
import {useAppContext} from '../../../../../_metronic/layout/core/AppContextProvider'
import TableContainer from '../../../../general-components/table-container/TableContainer'
import _columns from './columns/_columns'
import SweetAlert from 'react-bootstrap-sweetalert'
import {useIntl} from 'react-intl'
import ErrorText from '../../../../general-components/error-text/ErrorText'
import {NoRecords} from '../../../../general-components/loading/NoRecords'
import {UsersListLoading} from '../../../../general-components/loading/UsersListLoading'
import useKidAttendance from '../../../../hooks/useKidAttendance'

const KidsTable = () => {
  const intl = useIntl()
  const {
    totalOverTime,
    setTotalOverTime,
    data,
    showDelete,
    setShowDelete,
    handleDeleteAttendance,
    editCell,
    doDelete,
  } = useKidAttendance()
  const {globalLoader} = useAppContext()
  const columns: any = useMemo(() => _columns({totalOverTime, setTotalOverTime}), [])

  return (
    <>
      {data.length > 0 && (
        <KTCardBody className='py-4'>
          <TableContainer
            columns={columns}
            data={data}
            handleDelete={handleDeleteAttendance}
            editCell={editCell}
          />
        </KTCardBody>
      )}

      {!globalLoader && data.length === 0 && <NoRecords />}
      {(globalLoader || !columns) && <UsersListLoading />}
      <SweetAlert
        show={showDelete}
        warning
        showCancel
        confirmBtnText={intl.formatMessage({id: 'COMMON.YES'})}
        cancelBtnText={intl.formatMessage({id: 'COMMON.CANCEL'})}
        confirmBtnBsStyle='danger'
        title={<ErrorText text={intl.formatMessage({id: 'KIDLING.MENU.ARE_YOU_SURE?'})} />}
        focusCancelBtn
        onConfirm={() => doDelete()}
        onCancel={() => setShowDelete(false)}
      />
    </>
  )
}

export {KidsTable}
