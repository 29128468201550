import {Suspense, useEffect, useState} from 'react'
import {Outlet} from 'react-router-dom'
import {I18nProvider} from '../_metronic/i18n/i18nProvider'
import {LayoutProvider} from '../_metronic/layout/core'
import {MasterInit} from '../_metronic/layout/MasterInit'
import {AuthInit} from './views/auth'
import AppContextProvider from '../_metronic/layout/core/AppContextProvider'
import {registerLocale} from 'react-datepicker'
import de from 'date-fns/locale/de' // the locale you want
import TranslationDialog from './general-components/translation-dialog/TranslationDialog'
import GlobalDataLoader from './general-components/global-data-loader'
import {ToastContainer} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import 'react-datepicker/dist/react-datepicker.css'
import 'react-placeholder/lib/reactPlaceholder.css'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import InstallWpaButton from './general-components/inatall-wpa/InstallWpaButton'
import {Helmet} from 'react-helmet'
import 'moment/locale/de'
import {useLang} from '../_metronic/i18n/Metronici18n'
import Whitelabel from './general-components/whitelabel/Whitelabel'
import {ErrorBoundary} from 'react-error-boundary'
import moment from 'moment'
import PageLoader from './general-components/pageLoader/PageLoader'
import '../editor.css'
import ChatContextProvider from '../store/context/ChatProvider'
import ChatInitializer from './general-components/Chat-Initializer/ChatInitializer'

registerLocale('de', de)

const App = () => {
  const locale = useLang()
  const [showTranslations, setShowTranslations] = useState(false)

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown)
    window.addEventListener('message', (event) => event)
  }, [])

  useEffect(() => {
    locale === 'en' ? moment.locale('en') : moment.locale(locale)
  }, [locale])

  const handleKeyDown = (event: any) => {
    if (event.keyCode === 84 && event.shiftKey && event.ctrlKey && event.altKey)
      setShowTranslations(true)
  }
  function Fallback({error, resetErrorBoundary}: any) {
    return (
      <div className='d-flex justify-content-center align-items-center shadow bg-white h-100 w-100'>
        <div role='alert ' className='d-flex align-items-center flex-column'>
          <i className='fa-solid fa-circle-exclamation text-danger  ' style={{fontSize: '5rem'}} />
          <p className='fs-1 bw-bold'>Etwas ist schief gelaufen </p>
          <p className='fs-1 bw-bold'>Versuchen Sie es in einigen Minuten erneut</p>
          <pre style={{color: 'red'}}>{error.message}</pre>
          <button
            className='btn btn-primary'
            onClick={() => {
              window.location.reload()
            }}
          >
            Erneut versuchen
          </button>
        </div>
      </div>
    )
  }
  const logError = (error: Error, info: {componentStack: string}) => {
    console.log('error', error)
  }
  return (
    <Suspense fallback={<PageLoader />}>
      <ErrorBoundary FallbackComponent={Fallback} onError={logError}>
        <AppContextProvider>
          <I18nProvider>
            <ChatContextProvider>
              <Helmet
                htmlAttributes={{
                  lang: locale,
                }}
              >
                <meta charSet='utf-8' />
                <meta http-equiv='content-language' content={locale ?? 'de'} />
                <title>Eltern-App - Kidling</title>

                <meta
                  name='description'
                  content='Die Eltern-App Kidling für Elternkommunikation ist 100% DSGVO-konform garantiert effektive Elternkommunikation in Ihrer Kita in Echtzeit. Zu der Eltern-App gehören: Abmeldung von Mahlzeiten, Kita-Messenger und -Nachrichten, Abwesenheitsbenachrichtigung, Kalender und Terminplanung, Fotogalerie, Elternabrechnung und Anwesenheitsliste.'
                />
                <meta
                  name='viewport'
                  content='width=device-width,minimum-scale=1,maximum-scale=1,initial-scale=1'
                />
                <meta name='keywords' content='Kita' />
              </Helmet>
              <InstallWpaButton />
              <LayoutProvider>
                <AuthInit>
                  <Outlet />
                  <MasterInit />
                  <TranslationDialog
                    showModal={showTranslations}
                    setShowModal={setShowTranslations}
                  />
                  <GlobalDataLoader />
                  <Whitelabel />
                  <ToastContainer />
                </AuthInit>
              </LayoutProvider>
              <ChatInitializer />
            </ChatContextProvider>
          </I18nProvider>
        </AppContextProvider>
      </ErrorBoundary>
    </Suspense>
  )
}

export {App}
