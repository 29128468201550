import React, {FC, ReactElement, useEffect, useState} from 'react'

interface Props {
  image?: string
  alt: string
  rounded?: boolean
  small?: boolean
  verysmall?: boolean
  extrasmall?: boolean
  objectContain?: boolean
}
const AvatarWithLetters: FC<Props> = ({
  image,
  alt,
  rounded,
  small,
  verysmall,
  extrasmall,
  objectContain,
}): ReactElement => {
  const [rand, setRand] = useState(0)

  useEffect(() => {
    setRand(Math.random())
  }, [])

  return (
    <div
      className={`rounded symbolmb-8 mb-sm-0 ${
        small
          ? 'w-100px h-100px w-md-60px h-md-60px'
          : verysmall
          ? 'w-md-40px h-md-40px'
          : extrasmall
          ? 'w-30px h-30px mb-0  fs-7'
          : ' w-60px h-60px  w-md-100px h-md-100px '
      }  ${rounded && 'rounded-circle'} ${
        objectContain ? 'object-fit-contain' : 'object-fit-cover'
      }`}
    >
      {image ? (
        <img
          src={image}
          alt={alt}
          className={`rounded  bg-secondary text-primary  ${
            small
              ? 'w-100px h-100px w-md-60px h-md-60px fs-2 '
              : verysmall
              ? 'w-40px h-40px fs-4 '
              : extrasmall
              ? 'w-30px h-30px mb-0  fs-7'
              : 'w-60px h-60px w-md-100px h-md-100px fs-2  '
          }    mb-md-0 d-flex justify-content-center align-items-center  shadow ${
            rounded && 'rounded-circle  '
          } ${objectContain ? 'object-fit-contain' : 'object-fit-cover'}`}
        />
      ) : (
        <div
          className={`rounded mb-8 bg-secondary text-primary symbol-label  ${
            small
              ? 'w-100px h-100px w-md-60px h-md-60px'
              : verysmall
              ? 'w-md-40px h-md-40px'
              : extrasmall
              ? 'w-30px h-30px mb-0 fs-7'
              : ' w-60px h-60px w-md-100px h-md-100px '
          }   mb-md-0 d-flex justify-content-center align-items-center fs-3 shadow text-black ${
            rounded && 'rounded-circle  '
          } ${objectContain ? 'object-fit-contain' : 'object-fit-cover'} `}
        >
          {alt}
        </div>
      )}
    </div>
  )
}

export default AvatarWithLetters
