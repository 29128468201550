import React, {FC, ReactElement, useEffect, useRef, useState} from 'react'
import {DateRangePicker, defaultInputRanges, defaultStaticRanges} from 'react-date-range'
import {useIntl} from 'react-intl'
import {useLang} from '../../../_metronic/i18n/Metronici18n'
import * as locales from 'react-date-range/dist/locale'
import {useMediaQuery} from 'react-responsive'
import {Modal, ModalBody, ModalHeader} from 'reactstrap'
import moment from 'moment'
import {useAppContext} from '../../../_metronic/layout/core/AppContextProvider'
import {Button, ModalFooter} from 'react-bootstrap'
import './styles.scss'
interface Props {
  handleDateChange: any
  initialDate?: any
}
const CustomDateRangePicker: FC<Props> = ({handleDateChange, initialDate}): ReactElement => {
  const locale = useLang()
  const intl = useIntl()
  const {translations} = useAppContext()
  const [inputRanges, setInputRanges] = useState<any>([])
  const [staticRanges, setStaticRanges] = useState<any>([])
  const [mobileDate, setMobileDate] = useState<any>([
    {
      startDate: initialDate
        ? initialDate.startDate
        : new Date(moment().startOf('day').format('yyyy-MM-DDTHH:mm:ss')),
      endDate: initialDate
        ? initialDate.endDate
        : new Date(moment().endOf('day').format('yyyy-MM-DDTHH:mm:ss')),
    },
  ])
  const [date, setDate] = useState<any>([
    {
      startDate: initialDate
        ? initialDate.startDate
        : new Date(moment().startOf('day').format('yyyy-MM-DDTHH:mm:ss')),
      endDate: initialDate
        ? initialDate.endDate
        : new Date(moment().endOf('day').format('yyyy-MM-DDTHH:mm:ss')),
    },
  ])

  const modalRef: any = useRef()
  const [openDateRange, setOpenDateRange] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const isMobile = useMediaQuery({
    query: '(max-width:1000px)',
  })

  useEffect(() => {
    setStaticRanges([
      intl.formatMessage({id: 'COMMON.TODAY'}),
      intl.formatMessage({id: 'COMMON.YESTERDAY'}),
      intl.formatMessage({id: 'COMMON.THIS_WEEK'}),
      intl.formatMessage({id: 'COMMON.LAST_WEEK'}),
      intl.formatMessage({id: 'COMMON.THIS_MONTH'}),
      intl.formatMessage({id: 'COMMON.LAST_MONTH'}),
    ])
    setInputRanges([
      intl.formatMessage({id: 'COMMON.DAYS_UP_TO_TODAY'}),
      intl.formatMessage({id: 'COMMON.DAYS_STARTING_TODAY'}),
    ])
  }, [translations])

  useEffect(() => {
    handleDateChange(date)
    handleClose()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date])

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setOpenDateRange(false)
      }
    }
    if (modalRef) document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [modalRef])

  const handleClose = () => {
    setShowModal(false)
  }
  return isMobile ? (
    <>
      <button
        type='button'
        className={`btn btn-icon btn-light-primary   d-flex align-items-center justify-content-center   print__btn w-50px align-self-end   `}
        onClick={() => setShowModal(true)}
      >
        <i className='fa-solid fa-filter fs-3 text-primary'></i>
      </button>
      <Modal
        isOpen={showModal}
        toggle={handleClose}
        fullscreen={true}
        zIndex={10000}
        onClosed={handleClose}
      >
        <ModalHeader toggle={handleClose}>{intl.formatMessage({id: 'COMMON.FILTER'})}</ModalHeader>
        <ModalBody>
          <div className='d-flex align-items-center justify-content-end'>
            <DateRangePicker
              onChange={(item: any) => {
                setMobileDate([item.range1])
              }}
              moveRangeOnFirstSelection={true}
              months={1}
              ranges={mobileDate}
              direction='vertical'
              locale={locales[locale]}
              inputRanges={[
                ...defaultInputRanges.map((item: any, index: number) => ({
                  ...item,
                  label: inputRanges[index],
                })),
              ]}
              staticRanges={[
                ...defaultStaticRanges.map((item: any, index: number) => ({
                  ...item,
                  label: staticRanges[index],
                })),
              ]}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            type='submit'
            className='btn btn-primary'
            data-kt-users-modal-action='submit'
            onClick={() => {
              setDate(mobileDate)
              setShowModal(false)
            }}
          >
            <span className='indicator-label'>
              {intl.formatMessage({id: 'AUTH.GENERAL.SUBMIT_BUTTON'})}
            </span>
          </button>
        </ModalFooter>
      </Modal>
    </>
  ) : (
    <div className='ms-1'>
      <ul className='nav nav-pills'>
        <li className='nav-item d-flex flex-column position-relative ' ref={modalRef}>
          <button
            type='button'
            className={`btn btn-icon btn-light-primary   d-flex align-items-center justify-content-center m-0 print__btn   px-1`}
            onClick={() => setOpenDateRange(!openDateRange)}
          >
            <i className='fa-solid fa-filter fs-3 text-primary'></i>
          </button>
          {openDateRange && (
            <div
              className='position-absolute modal_container p-5 shadow border'
              style={{zIndex: 1000, top: '2rem', right: 0}}
            >
              <DateRangePicker
                onChange={(item: any) => {
                  setMobileDate([item.range1])
                }}
                moveRangeOnFirstSelection={true}
                months={1}
                ranges={mobileDate}
                direction='vertical'
                locale={locales[locale]}
                inputRanges={[
                  ...defaultInputRanges.map((item: any, index: number) => ({
                    ...item,
                    label: inputRanges[index],
                  })),
                ]}
                staticRanges={[
                  ...defaultStaticRanges.map((item: any, index: number) => ({
                    ...item,
                    label: staticRanges[index],
                  })),
                ]}
                startDatePlaceholder={intl.formatMessage({id: 'COMMON.EARLY'})}
                endDatePlaceholder={intl.formatMessage({id: 'COMMON.CONTINUOUS'})}
              />
              <div className='w-100 d-flex justify-content-end'>
                <button
                  type='submit'
                  className='btn btn-primary  '
                  data-kt-users-modal-action='submit'
                  onClick={() => {
                    setDate(mobileDate)
                    setOpenDateRange(false)
                  }}
                >
                  <span className='indicator-label'>
                    {intl.formatMessage({id: 'AUTH.GENERAL.SUBMIT_BUTTON'})}
                  </span>
                </button>
              </div>
            </div>
          )}
        </li>
      </ul>
    </div>
  )
}

export default CustomDateRangePicker
