/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import clsx from 'clsx'
import {
  defaultMessages,
  defaultUserInfos,
  MessageModel,
  UserInfoModel,
} from '../../../../_metronic/helpers'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Popover from 'react-bootstrap/Popover'
import data from '@emoji-mart/data'
import Picker from '@emoji-mart/react'
import {useIntl} from 'react-intl'
import '../styles.scss'
import {Avatar, AvatarGroup} from '@mui/material'
import {shadeColor} from '../../../utils/common'
import {useAppContext} from '../../../../_metronic/layout/core/AppContextProvider'
import GroupMembersDialog from './GroupMembersDialog'
type Props = {
  isDrawer?: boolean
}

const bufferMessages = defaultMessages

const GroupChatInner: FC<Props> = ({isDrawer = false}) => {
  const intl = useIntl()
  const {companyMetaData} = useAppContext()
  const [messages] = useState<MessageModel[]>(bufferMessages)
  const [userInfos] = useState<UserInfoModel[]>(defaultUserInfos)
  const [showAttachModal, setShowAttachModal] = useState(false)
  const [generatedColor, setGeneratedColor] = useState('')
  const [showMembersDialog, setShowMembersDialog] = useState(false)
  useEffect(() => {
    const temp = shadeColor(companyMetaData?.primaryColor ?? '#8d4290', 20)
    setGeneratedColor(temp)
  }, [companyMetaData])

  return (
    <div className='card-body p-0 m-0 mb-4 h-100 overflow-hidden chat__background position-relative'>
      <div
        className={clsx(' scroll-y h-100 p-8 pb-16  scrollthin ')}
        data-kt-element='messages'
        data-kt-scroll='true'
        data-kt-scroll-activate='{default: true}'
        data-kt-scroll-max-height='100%'
      >
        {messages?.map((message, index) => {
          const userInfo = userInfos[message.user]
          const state = message.type === 'in' ? '#fff' : generatedColor
          const templateAttr = {}
          if (message.template) {
            Object.defineProperty(templateAttr, 'data-kt-element', {
              value: `template-${message.type}`,
            })
          }
          const contentClass = `${isDrawer ? '' : 'd-flex'} justify-content-${
            message.type === 'in' ? 'start' : 'end'
          } mb-10`
          return (
            <div
              key={`message${index}`}
              className={clsx('d-flex', contentClass, 'mb-10', {'d-none': message.template})}
              {...templateAttr}
            >
              <div
                className={clsx(
                  'd-flex flex-column align-items',
                  `align-items-${message.type === 'in' ? 'start' : 'end'}`
                )}
              >
                <div className='d-flex align-items-center mb-2'>
                  {message.type === 'in' ? (
                    <>
                      <div className='symbol  symbol-35px symbol-circle '>
                        <Avatar alt='Remy Sharp' src={`/media/${userInfo.avatar}`} />
                      </div>
                      <div className='ms-3'>
                        <a href='#' className='fs-5 fw-bold text-gray-900 text-hover-primary me-1'>
                          {userInfo.name}
                        </a>
                        <span className='text-muted fs-7 mb-1'>{message.time}</span>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className='me-3'>
                        <span className='text-muted fs-7 mb-1'>{message.time}</span>
                        <a href='#' className='fs-5 fw-bold text-gray-900 text-hover-primary ms-1'>
                          You
                        </a>
                      </div>
                      <div className='symbol  symbol-35px symbol-circle '>
                        <Avatar alt='Remy Sharp' src={`/media/${userInfo.avatar}`} />
                      </div>
                    </>
                  )}
                </div>
                <div
                  style={{backgroundColor: state}}
                  className={clsx(
                    'p-4 rounded boxShadow mb-1',
                    '  fw-semibold mw-lg-400px',
                    `text-${message.type === 'in' ? 'start' : 'end'}`
                  )}
                >
                  <div
                    className={clsx(
                      ' fw-semibold mw-lg-400px ',
                      `${message.type !== 'in' ? 'mb-2 text-white' : 'text-dark'}`,
                      `text-${message.type === 'in' ? 'start' : 'end'}`
                    )}
                    data-kt-element='message-text'
                    dangerouslySetInnerHTML={{__html: message.text}}
                  ></div>
                </div>
                {message.type !== 'in' && (
                  <AvatarGroup
                    total={7}
                    sx={{
                      '& .MuiAvatar-root': {width: 15, height: 15, fontSize: 8},
                    }}
                    renderSurplus={(surplus) => (
                      <span className='ms-1'>+{surplus.toString()[0]}</span>
                    )}
                    onClick={() => setShowMembersDialog(!showMembersDialog)}
                  >
                    <Avatar alt='Remy Sharp' src={`/media/${userInfo.avatar}`} />
                    <Avatar alt='Travis Howard' src={`/media/${userInfo.avatar}`} />
                  </AvatarGroup>
                )}
              </div>
            </div>
          )
        })}
      </div>
      <div
        className='w-100 py-2 px-8  bg-white border-top border-light '
        style={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          height: '5rem',

          overflow: 'hidden',
        }}
      >
        <div
          className='d-flex align-items-center w-100 ps-4 '
          style={{
            borderRadius: '10rem',
            border: '1px solid #ccc',
            width: '100%',
          }}
        >
          <input type='text' className='flex-grow-1 px-4 py-2 fs-4  input__noborders' />
          <div className='d-flex flex-stack flex-wrap gap-2 p-2 border-top'>
            <div className='d-flex align-items-center me-3'>
              <OverlayTrigger
                placement='top'
                trigger='click'
                rootClose
                overlay={
                  <Popover>
                    <Picker
                      data={data}
                      onEmojiSelect={(data: any) => {
                        // editor.model.change((writer: any) => {
                        //   const selection = editor.model.document.selection
                        //   const currentAttributes = selection.getAttributes()
                        //   const insertPosition = selection.getFirstPosition()
                        //   writer.insertText(data.native, currentAttributes, insertPosition)
                        // })
                      }}
                    />
                  </Popover>
                }
              >
                <button className='btn p-1 ' type='button'>
                  <i className='fa-regular fa-face-smile fs-2 text-primary'></i>
                </button>
              </OverlayTrigger>
              <button
                className='btn px-2 p-1'
                type='button'
                onClick={() => setShowAttachModal(!showAttachModal)}
              >
                <i className='fa-solid fa-paperclip fs-3 m-0'></i>
              </button>
              <div className='btn-group '>
                <button
                  type='submit'
                  className='btn p-1 d-flex aling-items-center'
                  data-kt-users-modal-action='submit'
                  // disabled={formik.isSubmitting}
                >
                  <span className='indicator-label '>
                    <i className='fa-solid fa-paper-plane text-primary fs-3'></i>
                  </span>

                  {/* {isSending && (
                <span className=''>
                  {intl.formatMessage({id: 'KIDLING.HOME.PLEASE_WAIT'})}...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )} */}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <GroupMembersDialog
        showModal={showMembersDialog}
        setShowModal={setShowMembersDialog}
        title={intl.formatMessage({id: 'KIDLING.CHAT_SEEN_BY'})}
      />
    </div>
  )
}

export {GroupChatInner}
