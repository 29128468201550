/* eslint-disable react-hooks/exhaustive-deps */
import {FC, ReactElement, useEffect, useState} from 'react'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import {useIntl} from 'react-intl'
import {useAppContext} from '../../../../../_metronic/layout/core/AppContextProvider'

import * as Yup from 'yup'
import {useFormik} from 'formik'
import {useAuth} from '../../../auth'
import {sendNewMessage} from '../../../../../controller/messages'
import {toast} from 'react-toastify'
import {getBase64} from '../../../../utils/getBase64'

const animatedComponents = makeAnimated()

const style = {
  control: (base: any) => ({
    ...base,
    width: '100%',
    minWidth: '150px',
    border: 0,
    borderRaduis: '0.5rem',
    padding: '2px',
    boxShadow: 'none',
    backgroundColor: 'transparent',
    '&:hover': {
      borderColor: '#eee',
    },
  }),
  menu: (provided: any) => ({...provided, zIndex: 9999}),
}

const NewGroup: FC = (): ReactElement => {
  const intl = useIntl()
  const {companies, employees, classes, kidsList} = useAppContext()
  const {auth} = useAuth()
  const [selectedCompany, setSelectedCompany] = useState(companies[0])
  const [selectedEmployee, setSelectedEmployee] = useState<any>([])
  const [selectedClasses, setSelectedClasses] = useState<any>([])
  const [selectedKids, setSelectedKids] = useState<any>([])
  const [filterdKids, setFilterdKids] = useState([])
  const [selectedFiles, setSelectedFiles] = useState([])
  const [base64Files, setBase64Files] = useState([])
  const [selectError, setSelectError] = useState(true)
  const [showSelectError, setShowSelectError] = useState(false)
  const [highPriority] = useState(false)
  const [audio] = useState(new Audio('/audio/message.mp3'))
  const [initalValues] = useState({
    subject: '',
    message: '',
  })

  const schema = Yup.object().shape({
    subject: Yup.string().required(intl.formatMessage({id: 'KIDLING.REQUIRED'})),
    message: Yup.string().required(intl.formatMessage({id: 'KIDLING.REQUIRED'})),
  })

  const formik = useFormik({
    initialValues: initalValues,
    validationSchema: schema,
    onSubmit: async (values, {setSubmitting}) => {
      try {
        setSubmitting(true)
        if (!selectError) {
          let classesIdList: any = [],
            kidIdList: any = [],
            employeeIdList: any = []

          selectedEmployee?.map((emp: any) => employeeIdList.push(emp.value))
          selectedClasses?.map((emp: any) => classesIdList.push(emp.value))
          selectedKids?.map((emp: any) => kidIdList.push(emp.value))

          const data = {
            notification: {
              notificationTitle: values.subject,
              notificationTypeId: 1,
              message: values.message,
              companyId: auth?.companyId,
              dateTime: new Date(),
              notificationStatusId: 2,
              usersId: 0,
              parentId: 0,
              highPriority,
            },
            acknowledgeRequired: true,
            classesIdList: classesIdList,
            kidIdList: kidIdList,
            employeeIdList: employeeIdList,
            attachmentList: base64Files,
          }

          const resp: any = await sendNewMessage(data)

          if (resp.status === 200) {
            audio.play()
            if ('vibrate' in navigator) {
              // vibration API supported
              navigator.vibrate(1000)
            }
            toast.success(intl.formatMessage({id: 'COMMON.SUCCESS'}))
            setSelectedKids(null)
            setSelectedClasses(null)
            setSelectedEmployee(null)
            formik.resetForm()
            setBase64Files([])
            setSelectedFiles([])
          } else {
            toast.error(intl.formatMessage({id: 'COMMON.FAIL'}))
          }
        } else {
          setShowSelectError(true)
        }
      } catch (ex) {
        console.error(ex)
      } finally {
        setSubmitting(false)
      }
    },
  })

  useEffect(() => {
    if (
      selectedKids?.length === 0 &&
      selectedClasses?.length === 0 &&
      selectedEmployee?.length === 0
    )
      setSelectError(true)
    else setSelectError(false)
  }, [selectedKids, selectedClasses, selectedEmployee])

  useEffect(() => {
    readKids()
  }, [])

  useEffect(() => {
    if (selectedClasses) {
      let filterd: any = []
      for (let i = 0; i < kidsList.length; i++) {
        const kid: any = kidsList[i]
        let classExists = false
        for (let j = 0; j < kid.classes.length; j++) {
          const classVal = kid.classes[j]

          for (let k = 0; k < selectedClasses.length; k++) {
            if (classVal.classesId === selectedClasses[k].value) {
              classExists = true
              break
            }
          }
          if (classExists) break
        }
        if (!classExists) {
          filterd.push(kid)
        }
      }
      let temp: any = []

      filterd?.map((item: any) =>
        temp.push({
          value: item.kidId,
          label: item.kidName,
          classes: item.classes,
          imagePath: item.imagePath,
        })
      )

      setFilterdKids(temp)
    }
  }, [selectedClasses])

  useEffect(() => {
    if (companies.length > 0) setSelectedCompany(companies[0])
  }, [companies])

  useEffect(() => {
    const convertToBase64 = async () => {
      let temp: any = []
      selectedFiles?.map(async (singleFile) => {
        getBase64(singleFile, (result: string) => {
          temp.push(result)
        })
      })
      setBase64Files(temp)
    }
    convertToBase64()
  }, [selectedFiles])

  const readKids = async () => {}

  return (
    <div className='card'>
      <div className='card-header'>
        <div className='card-title'>{intl.formatMessage({id: 'KIDLING.HOME.NEW_GROUP'})}</div>
      </div>

      <div className='card-body p-1 m-0 m-sm-2'>
        <form
          id='kt_inbox_reply_form'
          className='rounded border mt-10'
          onSubmit={formik.handleSubmit}
        >
          {showSelectError && (
            <div className='alert alert-danger'>
              {intl.formatMessage({
                id: 'KIDLING.MESSAGE.YOU_NEED_TO_SELECT_AT_LEAST_SINGLE_GROUP_EMPLOYEE_OR_KID',
              })}
            </div>
          )}

          <div className='d-block'>
            <div className='d-flex align-items-center border-bottom px-8 min-h-50px'>
              <div className='text-dark fw-bold w-100px'>
                {intl.formatMessage({id: 'KIDLING.COMPANY'})}:
              </div>

              <Select
                openMenuOnFocus={true}
                value={selectedCompany}
                onChange={(val: any) => setSelectedCompany(val)}
                options={classes}
                isClearable={true}
                components={animatedComponents}
                placeholder='Select company'
                isLoading={companies.length === 0}
                formatOptionLabel={(item: any) => (
                  <div className='d-flex align-items-center '>
                    <div className='d-flex flex-column'>
                      <p className='p-0 m-0'>{item.companyName}</p>
                    </div>
                  </div>
                )}
                isDisabled
                className='w-100'
                styles={style}
              />
            </div>
            <div className='d-flex align-items-center border-bottom px-8 min-h-50px'>
              <div className='text-dark fw-bold w-100px'>
                {intl.formatMessage({id: 'KIDLING.HOME.CLASS'})}:
              </div>

              <Select
                openMenuOnFocus={true}
                value={selectedClasses}
                onChange={(val: any) => setSelectedClasses(val)}
                options={classes}
                isClearable={true}
                components={animatedComponents}
                isMulti
                isLoading={classes.length === 0}
                className='w-100'
                styles={style}
              />
            </div>
            <div className='d-flex align-items-center border-bottom px-8 min-h-50px'>
              <div className='text-dark fw-bold w-100px'>
                {intl.formatMessage({id: 'KIDLING.EMPLOYEE'})}:
              </div>

              <Select
                openMenuOnFocus={true}
                value={selectedEmployee}
                onChange={(val: any) => setSelectedEmployee(val)}
                options={employees}
                isClearable={true}
                components={animatedComponents}
                isMulti
                isLoading={employees.length === 0}
                formatOptionLabel={(item: any) => (
                  <div className='d-flex align-items-center '>
                    <img
                      src={item.imagePath || '/media/avatars/blank.png'}
                      alt='avatar'
                      className='rounded-circle h-40px w-40px me-2'
                    />
                    <div className='d-flex flex-column'>
                      <p className='p-0 m-0'>{item.label}</p>
                      <p className='p-0 m-0 text-muted'>{item.email}</p>
                    </div>
                  </div>
                )}
                className='w-100'
                styles={style}
              />
            </div>
            <div className='d-flex align-items-center border-bottom px-8 min-h-50px'>
              <div className='text-dark fw-bold w-100px'>
                {intl.formatMessage({id: 'KIDLING.MESSAGE.KIDS'})}:
              </div>

              <Select
                openMenuOnFocus={true}
                value={selectedKids}
                onChange={(val: any) => setSelectedKids(val)}
                options={filterdKids}
                isClearable={true}
                components={animatedComponents}
                isMulti
                formatOptionLabel={(item: any) => (
                  <div className='d-flex align-items-center '>
                    <img
                      src={item.imagePath || '/media/avatars/blank.png'}
                      alt='avatar'
                      className='rounded-circle h-40px w-40px me-2  '
                    />
                    <div className='d-flex flex-column'>
                      <p className='p-0 m-0'>{item.label}</p>
                      <div className='d-flex align-items-center'>
                        {item.classes?.map((val: any, index: number) => (
                          <p className='p-0 m-0 text-muted'>
                            {val.classesName}
                            {index !== item.classes.length - 1 && ','}
                          </p>
                        ))}
                      </div>
                    </div>
                  </div>
                )}
                className='w-100  '
                styles={style}
              />
            </div>
            <div className='border-bottom mt-4 '>
              <input
                className='form-control border-3 border-primary px-8 min-h-50px required mb-2 fs-4'
                placeholder={intl.formatMessage({id: 'KIDLING.HOME.GROUP_NAME'})}
                name='subject'
                onChange={formik.handleChange}
                value={formik.values.subject}
              />
              {formik.touched.subject && formik.errors.subject && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span className='alert-danger'>{formik.errors.subject}</span>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className='d-flex flex-stack flex-wrap gap-2 py-5 ps-8 pe-5 border-top'>
            <div className='d-flex align-items-center me-3'>
              <div className='btn-group me-4'>
                <button
                  type='submit'
                  className='btn btn-primary d-flex aling-items-center'
                  data-kt-users-modal-action='submit'
                  disabled={formik.isSubmitting || !formik.touched}
                >
                  <span className='indicator-label mx-4'>
                    {intl.formatMessage({id: 'AUTH.GENERAL.SUBMIT_BUTTON'})}
                  </span>
                  {formik.isSubmitting && (
                    <span className='indicator-progress'>
                      {intl.formatMessage({id: 'KIDLING.HOME.PLEASE_WAIT'})}...{' '}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default NewGroup
