/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useMemo} from 'react'
import {useIntl} from 'react-intl'
import _columns from '../_columns'
import {DiapersHeader} from './header/DiapersHeader'
import {useAppContext} from '../../../../_metronic/layout/core/AppContextProvider'
import {useAuth} from '../../auth'
import TableCardsContainer from '../../../general-components/table-cards-container/TableCardsContainer'
import useDiaper from '../../../hooks/useDiaper'

function Diapers() {
  const {internalPagesDatesFilters} = useAppContext()
  const {currentUser} = useAuth()
  const intl = useIntl()
  const columns: any = useMemo(() => _columns(), [])
  const {readWindingProtocols, data} = useDiaper()

  useEffect(() => {
    readWindingProtocols()
  }, [internalPagesDatesFilters, currentUser])

  return (
    <div className='container  '>
      <DiapersHeader title={intl.formatMessage({id: 'KIDLING.HOME.DIAPERS'})} />
      <div className='mt-4'>
        <TableCardsContainer data={data} columns={columns} />
      </div>
    </div>
  )
}

export default Diapers
