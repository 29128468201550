import {FC} from 'react'
import {ChildFilter} from './ChildFilter'
import {useThemeMode} from '../../_metronic/partials/layout/theme-mode/ThemeModeProvider'
import {useAuth} from '../views/auth'
interface Props {
  title?: string
  showChildFilter?: boolean | false
  showButton?: any
  hidePrint?: boolean
  filter?: any
}

const TitleHeader: FC<Props> = ({title, showChildFilter, showButton, hidePrint, filter}) => {
  const {currentUser} = useAuth()
  const {mode} = useThemeMode()
  const printPage = () => {
    window.print()
  }

  return (
    <div className='  border-0  mt-4  d-flex justify-content-center  w-100'>
      <div className='  row w-100'>
        <div className='col-6 col-sm-9'>
          {title && <h4 className='h-100 d-flex align-items-center m-0'>{title}</h4>}
        </div>
        <div className='col-6 col-sm-3  d-flex align-items-center justify-content-end'>
          {filter}
          {currentUser?.username !== 'apptest@kidling.email' && !hidePrint && (
            <button
              className='btn btn-icon btn-light-primary   d-flex align-items-center justify-content-center mx-2 print__btn'
              data-kt-users-modal-action='close'
              onClick={() => printPage()}
              style={{cursor: 'pointer'}}
            >
              <i
                className={`fa-solid fa-print fs-2 fs-sm-3 ${mode === 'dark' && 'text-dark'} m-0`}
              ></i>
            </button>
          )}
          {showButton && (
            <div className='d-flex my-2'>
              <button
                className='btn btn-icon btn-light-primary p-0  d-flex align-items-center justify-content-center mx-2 print__btn'
                type='button'
                onClick={showButton}
              >
                <i
                  className={`fa-solid fa-paperclip fs-2 fs-sm-3 ${
                    mode === 'dark' && 'text-dark'
                  }   m-0`}
                ></i>
              </button>
            </div>
          )}
        </div>
        {showChildFilter && (
          <div className='col-12 mt-2'>
            <ChildFilter />
          </div>
        )}
      </div>
    </div>
  )
}

export {TitleHeader}
