import React, {FC, ReactElement, useCallback, useEffect, useRef, useState} from 'react'
import {useIntl} from 'react-intl'
import {Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap'
import 'react-image-crop/dist/ReactCrop.css'
import ReactCrop from 'react-image-crop'
import {getCroppedImage} from '../../../helpers/Utility'

interface Props {
  showModal: {show: boolean; data: any}
  setShowModal: (show: boolean, data: any) => void
  setImage: (data: any) => void
}
const CropImageModal: FC<Props> = ({showModal, setShowModal, setImage}): ReactElement => {
  const imgRef = useRef<any>(null)
  const [crop, setCrop] = useState<any>({
    unit: 'px',
    x: 25,
    y: 25,
    width: 150,
    height: 150,
    aspect: 1 / 1,
  })
  const intl = useIntl()
  const handleClose = () => {
    setCrop({unit: 'px', x: 25, y: 25, width: 50, height: 50, aspect: 1 / 1})
    setShowModal(false, null)
  }
  const handleSubmit = async () => {
    if (imgRef.current && crop.width && crop.height) {
      const blob: any = await getCroppedImage(imgRef.current.target, crop)
      setImage(blob)
      handleClose()
    }
  }

  const onLoad = useCallback((img: any) => {
    console.log('onLoad', img.naturalWidth, img.width, img.naturalHeight, img.height)
    imgRef.current = img
  }, [])

  return (
    <Modal isOpen={showModal.show} toggle={handleClose} zIndex={500} onClosed={handleClose}>
      <ModalHeader toggle={handleClose}>
        {intl.formatMessage({id: 'KIDLING.CROP_IMAGE'})}
      </ModalHeader>
      <ModalBody>
        <div className='w-100 d-flex flex-column align-items-center justify-content-center'>
          <ReactCrop crop={crop} onChange={(c) => setCrop(c)}>
            <img src={showModal.data ?? ''} onLoad={onLoad} />
          </ReactCrop>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color='secondary' onClick={handleClose}>
          {intl.formatMessage({id: 'COMMON.CANCEL'})}{' '}
        </Button>

        <Button color='primary' type='button' onClick={handleSubmit}>
          {intl.formatMessage({id: 'AUTH.GENERAL.SUBMIT_BUTTON'})}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default CropImageModal
