import axios from 'axios'
import * as endpoints from '../config/endpoints'

export const getCompaniesByContact = async (contactId: number) => {
  return axios
    .get(`${endpoints.GET_KITAS_BY_CONTACT}`, {params: {contactId}})
    .then((response) => response.data.data)
    .catch((error) => {
      console.log(error)
    })
}

export const getCompanyInfo = async (CompanyId: string) => {
  const params = {CompanyId}
  return axios
    .get(`${endpoints.COMPANY_INFO}`, {params})
    .then((response) => response.data)
    .catch((error) => {
      console.log(error)
    })
}
