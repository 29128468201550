import React from 'react'
import {Link} from 'react-router-dom'
import {useLocation} from 'react-router'
import clsx from 'clsx'
import {checkIsActive, KTSVG} from '../../../helpers'
import {useThemeMode} from '../../../partials/layout/theme-mode/ThemeModeProvider'

type Props = {
  to: string
  title: string
  icon?: string
  fontIcon?: string
  hasArrow?: boolean
  hasBullet?: boolean
  external?: boolean
}

const MenuItem: React.FC<Props> = ({
  to,
  title,
  icon,
  fontIcon,
  hasArrow = false,
  hasBullet = false,
  external,
}) => {
  const {pathname} = useLocation()
  const {mode} = useThemeMode()
  return (
    <div className='menu-item me-lg-1 py-0'>
      {external ? (
        <a href={to} className={clsx('menu-link py-3')} target={external ? '_blank' : '_self'}>
          {hasBullet && (
            <span className='menu-bullet'>
              <span className='bullet bullet-dot fs-4 '></span>
            </span>
          )}

          {icon && (
            <span className='menu-icon fs-4 '>
              <KTSVG path={icon} className={`svg-icon-2 ${mode === 'dark' && 'text-dark'}`} />
            </span>
          )}

          {fontIcon && (
            <span className={`menu-icon  ${mode === 'dark' && 'text-dark'}`}>
              <i className={clsx(` fs-4 ${mode === 'dark' && 'text-dark'}`, fontIcon)}></i>
            </span>
          )}

          <span className={`menu-title fs-7  ${mode === 'dark' && 'text-dark'}`}>{title}</span>

          {hasArrow && <span className='menu-arrow fs-4 '></span>}
        </a>
      ) : (
        <Link
          className={clsx('menu-link py-4  border-bottom', {
            active: checkIsActive(pathname, to),
          })}
          to={to}
        >
          {hasBullet && (
            <span className='menu-bullet'>
              <span className='bullet bullet-dot fs-4 '></span>
            </span>
          )}

          {icon && (
            <span className='menu-icon fs-4 '>
              <KTSVG path={icon} className={`svg-icon-2 ${mode === 'dark' && 'text-dark'}`} />
            </span>
          )}

          {fontIcon && (
            <span className='menu-icon'>
              <i className={clsx(`fs-4 ${mode === 'dark' && 'text-dark'}`, fontIcon)}></i>
            </span>
          )}

          <span className={`menu-title fs-7 ${mode === 'dark' && 'text-dark'}`}>{title}</span>

          {hasArrow && <span className={`menu-arrow ${mode === 'dark' && 'text-dark'}`}></span>}
        </Link>
      )}
    </div>
  )
}

export {MenuItem}
